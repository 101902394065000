import React, { useCallback, useEffect, useState } from 'react';
import { WidgetConfigsGenericWrapper } from '../../common/chart-generic-wrapper';
import { Checkbox, HfInput, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { t } from 'i18next';
import { CustomColorProps } from './config-components.interfaces';
import SingleColorSelectorComponent from '../../common/single-color-selector.component';

interface ColorCustomizedProps {
  widgetConfiguration: WidgetConfigsGenericWrapper;
  control: Control;
  setDefaultColor?: (color: string) => void;
  setSecondColor?: (color: string) => void;
}

const ColorCustomisationConfiguration = ({
  widgetConfiguration,
  control,
  setDefaultColor,
  setSecondColor,
}: ColorCustomizedProps) => {
  const [showThresholdValue, setShowThresholdValue] = useState<boolean>(
    Boolean(widgetConfiguration.widgetConfig?.secondColorEnabled)
  );
  const [paletteVisible, setPaletteVisible] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<CustomColorProps>();
  const [defaultColumnUpdatedColor, setDefaultColumnUpdatedColor] = useState<CustomColorProps>();
  const [thresholdUpdatedColor, setThresholdUpdatedColor] = useState<CustomColorProps>();
  const [settingsColors, setSettingsColors] = useState<Record<string, string>>();

  const updateColor = useCallback((newColorSetting: CustomColorProps) => {
    if (newColorSetting.label === 'defaultColor') {
      setDefaultColumnUpdatedColor(newColorSetting);
      if (setDefaultColor) {
        setDefaultColor(newColorSetting.color);
      }
    } else if (newColorSetting.label === 'thresholdColor') {
      setThresholdUpdatedColor(newColorSetting);
      if (setSecondColor) {
        setSecondColor(newColorSetting.color);
      }
    }

    setSettingsColors({
      ...settingsColors,
      [newColorSetting.label]: newColorSetting.color,
    });
  }, []);

  useEffect(() => {
    if (widgetConfiguration.widgetConfig?.defaultColor) {
      setDefaultColumnUpdatedColor({
        label: 'defaultColor',
        color: widgetConfiguration.widgetConfig?.defaultColor,
      });
    }
    if (widgetConfiguration.widgetConfig?.secondColor) {
      setThresholdUpdatedColor({
        label: 'thresholdColor',
        color: widgetConfiguration.widgetConfig?.secondColor,
      });
    }
  }, [widgetConfiguration.widgetConfig?.defaultColor, widgetConfiguration.widgetConfig?.secondColor]);

  const onDefaultColorClickHandler = useCallback(() => {
    setPaletteVisible(true);
    setSelectedElement({
      label: 'defaultColor',
      color: defaultColumnUpdatedColor?.color || '',
    });
  }, [defaultColumnUpdatedColor]);

  const onThresholdColorClickHandler = useCallback(() => {
    setPaletteVisible(true);
    setSelectedElement({
      label: 'thresholdColor',
      color: thresholdUpdatedColor?.color || '',
    });
  }, [thresholdUpdatedColor]);

  return (
    <div className="flex flex-col gap-2 pt-2.5">
      <h4 className="block pb-1 text-sm font-bold">{String(t('dynamicColours'))}</h4>

      <div className="flex flex-col gap-3 pl-5">
        <div className="my-1 flex flex-col">
          <SingleColorSelectorComponent
            text={t('defaultColumnColour')}
            onClick={onDefaultColorClickHandler}
            fillColor={defaultColumnUpdatedColor?.color}
            paletteVisible={paletteVisible}
            selectedItem={selectedElement}
            updateColor={updateColor}
            onPaletteClosed={() => {
              setPaletteVisible(false);
              setSelectedElement(undefined);
            }}
          />
        </div>
        <div>
          <OdinController
            name="secondColorEnabled"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                label={t('changeColourIfThresholdIsExceeded')}
                initialChecked={widgetConfiguration.widgetConfig?.secondColorEnabled}
                onChange={(value) => {
                  if (value) {
                    setShowThresholdValue(value.checked);
                    onChange(value.checked);
                  }
                }}
              />
            )}
          />
          {showThresholdValue && (
            <div className="flex w-full gap-1 pl-5 pt-2.5">
              <HfInput
                control={control}
                name="threshold"
                label={t('thresholdValue')}
                inputStyles="h-8"
                value={widgetConfiguration.widgetConfig?.threshold}
              />

              <div className="pl-5">
                <SingleColorSelectorComponent
                  text={t('colours')}
                  onClick={onThresholdColorClickHandler}
                  fillColor={thresholdUpdatedColor?.color}
                  paletteVisible={paletteVisible}
                  selectedItem={selectedElement}
                  updateColor={updateColor}
                  onPaletteClosed={() => {
                    setPaletteVisible(false);
                    setSelectedElement(undefined);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ColorCustomisationConfiguration;
