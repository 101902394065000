import { JsonDataItem } from '@myosh/odin-components';
import { t } from 'i18next';

export type WidgetTypeValue = {
  typeValue: string;
  subTypeValue?: string;
};

export type WidgetType = JsonDataItem & {
  name: string;
  type: WidgetTypeValue;
  field: boolean;
  secondField: boolean;
  timeFrequency?: boolean;
};

export const widgetTypes: Array<WidgetType> = [
  {
    name: 'Bar',
    type: {
      typeValue: 'CHART_BAR',
    },
    secondField: true,
    field: true,
  },
  {
    name: 'Pie',
    type: {
      typeValue: 'CHART_PIE',
    },
    secondField: false,
    field: true,
  },
  {
    name: 'Line',
    type: {
      typeValue: 'CHART_LINE',
    },
    secondField: false,
    field: true,
  },
  {
    name: 'Table',
    type: {
      typeValue: 'TABLE',
    },
    secondField: false,
    field: false,
  },
  {
    name: 'Stacked Column',
    type: {
      typeValue: 'CHART_COLUMN',
      subTypeValue: 'Stacked_Column',
    },
    secondField: true,
    field: true,
  },
  {
    name: 'Side-by-Side Column',
    type: {
      typeValue: 'CHART_COLUMN',
      subTypeValue: 'Side_by_Side_Column',
    },
    secondField: true,
    field: true,
  },
  {
    name: 'Grouped-by-date Column',
    type: {
      typeValue: 'CHART_COLUMN',
      subTypeValue: 'Grouped_by_date_Column',
    },
    secondField: true,
    field: true,
    timeFrequency: true,
  },
  {
    name: 'KPI Percentage Column',
    type: {
      typeValue: 'CHART_COLUMN_KPI_PERCENTAGE',
    },
    secondField: false,
    field: true,
  },
  {
    name: 'Cross Tab Report',
    type: {
      typeValue: 'CROSS_TAB_REPORT',
    },
    secondField: true,
    field: true,
  },
  {
    name: 'Map',
    type: {
      typeValue: 'MAP',
    },
    secondField: false,
    field: false,
  },
];

export const getTranslatedWidgetFrequencyTypes = () => [
  {
    name: t('monthly'),
    type: 'MONTHLY',
  },
  {
    name: t('next12Months'),
    type: 'FORTHCOMING_12_MONTHS',
  },
  {
    name: t('yearly'),
    type: 'YEARLY',
  },
];
