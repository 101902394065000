import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import MapWidget from '../Map/map-component';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import useStartEndDates from './hooks/useStartEndDates';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { useMapWidgetQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinMapWidget({ widgetId, layoutProps }: ChartProps) {
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useMapWidgetQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  const handleRefetch = useInvalidateChartResponse(widgetId);

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  return (
    <ChartGenericWrapper
      widgetId={widgetId}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetChartData={widgetChartData}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      renderNoDataByDefault={!isWidgetChartDataFetching && !widgetChartData?.length}
    >
      {configurationData && widgetChartData && (
        <MapWidget data={widgetChartData} widgetId={widgetId} configurationData={configurationData} />
      )}
    </ChartGenericWrapper>
  );
}
