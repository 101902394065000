import { format } from 'date-fns';
import { FieldFiltersProps } from './table-data.interfaces';

export const getFilters = (fieldFilters: FieldFiltersProps) => {
  let tmp = '';
  const keys = Object.keys(fieldFilters);
  for (let i = 0; i < keys.length; i++) {
    const filter = fieldFilters[keys[i]];
    const comparison = filter.comparison;
    const value = filter.value;
    if (comparison === 'contains') {
      if (Array.isArray(value)) {
        tmp += `&filter=${keys[i]}:in:${value}`;
      } else {
        tmp += `&filter=${keys[i]}:like:${value}`;
      }
    } else if (comparison === '=') {
      tmp += `&filter=${keys[i]}:eq:${value}`;
    } else if (comparison === '<=') {
      tmp += `&filter=${keys[i]}:lte:${value}`;
    } else if (comparison === '>=') {
      tmp += `&filter=${keys[i]}:gte:${value}`;
    } else if (comparison === 'between' && Array.isArray(value)) {
      if (value[0] instanceof Date && value[1] instanceof Date) {
        const from = format(value[0], 'yyyy-MM-dd');
        const to = format(value[1], 'yyyy-MM-dd');
        tmp += `&filter=${keys[i]}:gte:${from}&filter=${keys[i]}:lte:${to}`;
      }
    }
  }

  return tmp;
};
