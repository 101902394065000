import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { CombinedData } from './common-data.interface';

export class SecondFields implements IDataCommand<CombinedData, WidgetConfigurationData, CombinedData> {
  process(input: CombinedData): CombinedData {
    const secondFields: Set<string> = new Set();
    const data = input.data;
    for (let i = 0, length = data?.length; i < length; i++) {
      const item = data[i];
      if (item.secondField && (item.field || item.field === '')) {
        const retrievedItem = new Map<string, Map<string, number>>().get(item.field);
        retrievedItem && retrievedItem.set(item.secondField, Number(item.value));
        secondFields.add(item.secondField);
      }
    }

    return {
      ...input,
      secondFields,
    };
  }
}
