import { useMemo, useState } from 'react';

export function useTicksCountBasedOnDomainScale(highest?: number) {
  const [ticksCount, setTicksCount] = useState<number>();

  useMemo(() => {
    if (highest) {
      if (highest > 10 && highest <= 60) {
        setTicksCount(6);
      } else if (highest >= 60 && highest <= 200) {
        setTicksCount(10);
      } else if (highest >= 200 && highest < 1000) {
        setTicksCount(Math.ceil(highest / 50) + 2);
      } else if (highest > 1000 && highest <= 5000) {
        setTicksCount(Math.ceil(highest / 500) + 1);
      } else if (highest > 5000 && highest <= 10000) {
        setTicksCount(Math.ceil(highest / 1000) + 1);
      } else if (highest > 10000 && highest <= 100000) {
        setTicksCount(Math.ceil(highest / 10000 + 1));
      } else if (highest > 100000 && highest <= 1000000) {
        setTicksCount(Math.ceil(highest / 100000 + 1));
      }
    }
  }, [highest]);

  return ticksCount;
}
