import { useEffect, useState } from 'react';
import { getStatusColor } from '../../../helpers/getStatusColor';
import { getValueOrDefault } from '../../../helpers/getvalueOrDefault';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';

export type KPIConfigs = {
  lineColor: string;
  caption: string;
  available: boolean;
};

const defaultKPIConfig: KPIConfigs = {
  available: false,
  caption: '',
  lineColor: '',
};
export function useKpiConfigs(configurationData?: WidgetConfigurationData) {
  const [kpiConfigs, setKPIConfigs] = useState<KPIConfigs>(defaultKPIConfig);

  useEffect(() => {
    let newConfig = defaultKPIConfig;
    const kpiTargetConfiguration = configurationData && configurationData.widgetConfig?.kpiTargetConfiguration;

    if (kpiTargetConfiguration) {
      const caption = getValueOrDefault(kpiTargetConfiguration.caption, 'KPI');
      const lineColor = kpiTargetConfiguration.lineColor ?? getStatusColor(0);
      const available = !kpiTargetConfiguration.hideKPI;
      newConfig = { caption, lineColor, available };
    }
    setKPIConfigs(newConfig);
  }, [configurationData]);

  return kpiConfigs;
}
