import React, { useMemo, useState } from 'react';
import { Input, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import ActionIcon from '../action-icon';
import { ViewNameOption } from './view-dropdown';
import { t } from 'i18next';

interface ViewNameEditorProps {
  view: ViewNameOption;
  isEven: boolean;
  onRemove: (view: string) => void;
  onNameUpdate: (newName: string) => void;
}

function ViewNameEditor({ view, isEven, onRemove, onNameUpdate }: ViewNameEditorProps) {
  const [editMode, setEditMode] = useState(false);
  const [viewName, setViewName] = useState<string>(view.text);
  const [hasError, setHasError] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const enableEditMode = () => setEditMode(true);
  const disableEditMode = () => {
    setHasError(false);
    setEditMode(false);
    setViewName(view.text ?? '');
  };

  const handleViewNameChange = (value?: string) => {
    setHasError(false);
    setViewName(value || '');
  };

  const removeItem = () => setShowDeleteConfirmation(true);
  const confirmRemoveItem = () => onRemove(view.value);
  const cancelRemoveItem = () => setShowDeleteConfirmation(false);

  const applyChanges = () => {
    if (viewName.trim().length > 0) {
      setEditMode(false);
      onNameUpdate(viewName);
    } else {
      setHasError(true);
    }
  };

  const inputStyles = useMemo(() => {
    let classes = 'view-name__input';
    if (hasError) {
      classes += ' input-error';
    }

    return classes;
  }, [hasError]);

  return (
    <div className="view-name-editor__wrapper" data-even={isEven}>
      <OdinIcon
        icon="Global"
        size={OdinIconSize.ExtraSmall}
        type={OdinIconType.Line}
        className={`mr-2 text-primary-1 ${view.privateView ? 'invisible' : 'visible'}`}
      />
      {view?.defaultView ? (
        <p className="view-name-editor" title={viewName}>
          {viewName}
        </p>
      ) : (
        <>
          {showDeleteConfirmation && (
            <div className="delete-confirmation">
              <div className="delete-confirmation__text">
                <span>{String(t('delete'))}</span>
                <span className="delete-confirmation__title" title={viewName}>{`"${viewName}"`}</span>
                <span>?</span>
              </div>
              <div className="delete-confirmation__actions">
                <ActionIcon icon="Check" onClick={confirmRemoveItem} iconTheme="default" />
                <ActionIcon icon="Close" onClick={cancelRemoveItem} iconTheme="default" />
              </div>
            </div>
          )}
          {!showDeleteConfirmation && (
            <>
              <div className="view-name-editor">
                {editMode ? (
                  <Input value={viewName} onChange={handleViewNameChange} inputStyles={inputStyles} name={''} />
                ) : (
                  <p title={viewName}>{viewName}</p>
                )}
                <div className="view-name-editor__actions">
                  {editMode ? (
                    <>
                      <ActionIcon icon="Check" onClick={applyChanges} />
                      <ActionIcon icon="Close" onClick={disableEditMode} />
                    </>
                  ) : (
                    <>
                      <ActionIcon icon="Pencil" onClick={enableEditMode} />
                      <ActionIcon icon="DeleteBin5" onClick={removeItem} />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ViewNameEditor;
