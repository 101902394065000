import { ColumnLineChartValues } from '../../../shared/widget-data.interfaces';
import {
  BasicDataChartResponse,
  ChartResponse,
  ColumnKpiPercentageResponse,
  ColumnRateResponse,
  DataKPI,
} from '../../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { getApplicableKPIValues } from '../../../data-transformer/chart-kpi/hide-kpi-values';
import { ColumnSeries } from '../chart.interface';

/**
 * To make sure that yAxis are displayed and rendered correctly
 *  and there is room for labels, we ceil the highest data's value
 *  based on the pre-greed thresholds.
 *
 * @param highest value that is found in a chart's data that is to be rounded upwards
 * @param lowest value that is found in a chart's data
 * @returns the rounded value
 */
export const roundHighest = (high: number, low = 0) => {
  let highest = high;

  const getCeiledValue = (ceilBy: number) => {
    let multiplier = ceilBy;
    let incrementValue = 1;

    // To avoid too many Y-axis ticks,
    // round up highest value if it's a non-integer but not divisible by 0.5
    // else then set multiplier and increment values to +.5s.
    if (!Number.isSafeInteger(highest) || !Number.isSafeInteger(low)) {
      if (highest % 0.5 !== 0) {
        highest = Math.ceil(high);
      } else {
        multiplier += 0.5;
        incrementValue = 1.5;
      }
    }

    return Math.ceil((highest + incrementValue) / multiplier) * multiplier;
  };

  if (highest < 1) {
    return 2;
  }
  if (highest <= 5) {
    return getCeiledValue(1);
  }
  if (highest <= 10) {
    return getCeiledValue(2);
  }
  if (highest <= 100) {
    return getCeiledValue(5);
  }
  if (highest <= 1000) {
    return getCeiledValue(50);
  }
  if (highest <= 10000) {
    return getCeiledValue(500);
  }
  if (highest <= 100000) {
    return getCeiledValue(1000);
  }
  return getCeiledValue(100000);
};

function getDomainValue<T extends ColumnRateResponse | ColumnSeries | BasicDataChartResponse>(data: T[]) {
  const highest = Math.max(
    ...data?.map((item) => {
      return Math.max(Number(item.value));
    })
  );
  return roundHighest(highest);
}

function getKpiDomainValue<T extends DataKPI>(data: T[], configurationData: WidgetConfigurationData) {
  const filteredData = getApplicableKPIValues(data, configurationData);
  const allKpiValues = filteredData.flatMap((item) =>
    Object.values(item)
      .filter((item) => !isNaN(Number(item)))
      .map((item) => Number(item))
  );
  const isEmpty = allKpiValues.length === 0;
  const highest = isEmpty ? 0 : Math.max(...allKpiValues);
  const lowest = isEmpty ? 0 : Math.min(0, ...allKpiValues);
  return {
    max: roundHighest(highest, lowest),
    min: lowest,
  };
}

function getHighestColumnChartValue<T extends ChartResponse | ColumnLineChartValues | ColumnKpiPercentageResponse>(
  data: T[],
  field?: string
) {
  if (!data || data.length === 0) {
    return 0;
  }
  const fieldColumn = field || 'value_column';
  const highest = Math.max(
    ...data.map((item) => (item[fieldColumn as keyof typeof item] ? Number(item[fieldColumn as keyof typeof item]) : 0))
  );
  return roundHighest(highest);
}

export { getDomainValue, getKpiDomainValue, getHighestColumnChartValue };
