import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { WidgetConfigurationData } from './widgetApi';

export interface LayoutWidget extends WidgetDimensions {
  id: string;
  widget: WidgetConfigurationData;
}

export interface LayoutBasicProps {
  id: string;
  canAdmin: boolean;
  canModify: boolean;
  canView: boolean;
  isUserLayout: boolean;
}
export interface Layout extends LayoutBasicProps {
  layoutWidgets: Array<LayoutWidget>;
  weight: number;
  position: number;
  reportTab: boolean;
  title: string;
}

export interface LayoutRoles {
  rolesAdmin: string[];
  rolesModify: string[];
  rolesView: string[];
}

export interface LayoutWidgetsProps extends Partial<WidgetDimensions> {
  layout: string;
  widget: number;
}

export interface WidgetDimensions {
  position: number;
  height: string;
  width: string;
}

export const layoutApi = createApi({
  reducerPath: 'layout',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['layoutInfo', 'LayoutRoles'],
  endpoints: (builder) => ({
    getLayouts: builder.query<Array<Layout>, unknown | void>({
      query: () => {
        return {
          url: `/layouts/simplified/all`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'layoutInfo' as const, id })), 'layoutInfo'] : ['layoutInfo'],
    }),
    getLayoutRoles: builder.query<LayoutRoles, string>({
      query: (id: string) => {
        return {
          url: `/layouts/${id}/roles`,
          method: 'GET',
        };
      },
      providesTags: (result, error, id) =>
        result ? [{ type: 'LayoutRoles' as const, id }, 'LayoutRoles'] : ['LayoutRoles'],
    }),

    createLayout: builder.mutation<string, Partial<Layout>>({
      query: (body) => {
        return {
          url: `/layouts`,
          method: 'POST',
          body,
          responseHandler: 'text',
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'layoutInfo', id: arg.id }],
    }),
    updateLayout: builder.mutation<Partial<Layout>, Partial<Layout> & { keepOldTags?: boolean }>({
      query: ({ id, ...body }) => ({
        url: `/layouts/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.keepOldTags) {
          return [];
        }
        return [{ type: 'layoutInfo', id: arg.id }];
      },
    }),
    addLayoutWidgets: builder.mutation<
      string | void,
      Array<LayoutWidgetsProps> & { keepOldTags?: boolean; id?: number }
    >({
      query: (body) => ({
        url: `/layout-widgets`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.keepOldTags) {
          return [];
        }
        return [{ type: 'layoutInfo', id: arg.id }];
      },
    }),
    getLayout: builder.query<Layout, string>({
      query: (id) => ({
        url: `/layouts/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'layoutInfo', id: result?.id }],
    }),
    replaceLayout: builder.mutation<Partial<Layout>, Partial<Layout>>({
      query: ({ id, ...body }) => ({
        url: `/layouts/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'layoutInfo', id: arg.id }],
    }),
    deleteLayout: builder.mutation<void, { id: string; keepOldTags?: boolean }>({
      query: (args) => ({
        url: `/layouts/${args.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id, keepOldTags }) => {
        if (keepOldTags) {
          return [];
        }
        return [{ type: 'layoutInfo', id }];
      },
    }),
  }),
});

export const {
  useGetLayoutsQuery,
  useGetLayoutRolesQuery,
  useUpdateLayoutMutation,
  useCreateLayoutMutation,
  useAddLayoutWidgetsMutation,
  useReplaceLayoutMutation,
  useDeleteLayoutMutation,
  useGetLayoutQuery,
} = layoutApi;
