import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHierarchySearchQuery } from '../../../redux/slices/globalFilter/GlobalFilterHierarchySlice';
import { HierarchyFilterValues } from '../../../shared/Hierarchy';

/**
 *
 * @returns Hierachy filters set in the app when clicking 'Apply'
 */
export function useHierarchyFilters() {
  const [hierarchyFilter, setHierarchyFilter] = useState<HierarchyFilterValues>();

  const globalFilter = useSelector(selectHierarchySearchQuery);

  useEffect(() => {
    setHierarchyFilter(globalFilter.values);
  }, [globalFilter]);

  return hierarchyFilter;
}
