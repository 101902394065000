import { sortBy, uniq } from 'lodash';
import { BasicDataChartResponse } from '../../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { ColumnLineChartValues } from '../../../shared/widget-data.interfaces';
import { IDataCommand } from '../../data-transformer.interface';

export class SortByCustomSorting<T extends BasicDataChartResponse | ColumnLineChartValues>
  implements IDataCommand<T[], WidgetConfigurationData, T[]>
{
  process(data: T[], config: WidgetConfigurationData): T[] {
    const sortOrder = config.widgetConfig?.sortOrder;
    const sortKeys = sortOrder && uniq([...sortOrder, '[blank]', '[none]']);
    //sort first alphabetically
    sortBy(data, ['field', 'name', 'secondField']);

    if (sortKeys) {
      const sortedArray: T[] = [];
      for (let i = 0; i < sortKeys.length; i++) {
        const currentSortKey = sortKeys[i];
        const itemToSort = data.find((item) => item.field === currentSortKey || item.name === currentSortKey);
        if (itemToSort) {
          sortedArray.push(itemToSort);
        }
      }
      //find items that are in the `data`, but don't have a custom sorting defined
      //append them to the end
      const notPresentedField = data.filter((item) => item.field && !sortKeys.includes(item.field));
      const notPresentedName = data.filter(
        (item) => item.name && typeof item.name === 'string' && !sortKeys.includes(item['name'])
      );
      return [...sortedArray, ...notPresentedField, ...notPresentedName];
    }
    return data;
  }
}
