import { useCallback, useEffect, useState } from 'react';
import {
  ContinuousScrollerSort,
  DataGrid,
  DataGridColumnSettings,
  JsonData,
  OverlayScrollDetectionType,
} from '@myosh/odin-components';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import useStartEndDates from './hooks/useStartEndDates';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import { tableDataApi, useTableRollingTrifrDataQuery } from '../../redux/config/table-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import customTableColumnSorter from '../../helpers/column-sorter';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinTableRollingTRIFR({ widgetId, layoutProps }: ChartProps) {
  const [transformedData, setTransformedData] = useState<JsonData>();
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    isUninitialized: isDataUninitialized,
    error,
  } = useTableRollingTrifrDataQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (data) {
      setTransformedData(data.row);
    }
  }, [data]);

  useEffect(() => {
    if (data && !isLoading && !isWidgetChartDataFetching && !columns) {
      const gridColumns = data.header.map((column) => {
        const isIdField = column.field === 'businessUnit';
        return {
          id: column.field,
          title: column.caption,
          field: column.field,
          visible: true,
          isIdField,
          customDataProperties: {
            columnType: isIdField ? 'string' : 'number',
          },
        };
      });
      setColumns(gridColumns);
    }
  }, [data, isLoading, isWidgetChartDataFetching, columns]);

  const handleRefetch = useCallback(() => {
    if (!isDataUninitialized && transformedData && !isWidgetChartDataFetching) {
      dispatch(tableDataApi.util.invalidateTags([{ type: 'RollingTrifrData', id: `LIST-${widgetId}` } as const]));
    }
  }, [isDataUninitialized, transformedData, isWidgetChartDataFetching, widgetId]);

  return (
    <ChartGenericWrapper
      isLoading={isLoading}
      isFetching={isWidgetChartDataFetching}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={data?.row}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {data && columns && (
        <DataGrid
          data={transformedData as JsonData}
          gridSettings={{
            columns,
            fullHeight: true,
            stopRequestsOnNoData: true,
            filterLocation: 0,
            scrollDetection: OverlayScrollDetectionType.All,
            sortFunction: (data: JsonData, sorts: Array<ContinuousScrollerSort>) =>
              customTableColumnSorter(data, sorts, columns),
          }}
          customSettingsMenuItems={[]}
          showSettings={false}
        />
      )}
    </ChartGenericWrapper>
  );
}
