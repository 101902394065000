import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import { v4 } from 'uuid';
import { getTabs } from '../../../redux/slices/layout-tabs-slice';
import { selectActiveTabId } from '../../../redux/slices/active-layout-tab-id-slice';
import {
  useGetExportWidgetJobIdMutation,
  useExportJobStateQuery,
  ExportJobDetailsResult,
  useGetExportLayoutJobIdMutation,
} from '../../../redux/config/export-api';
import { selectTableDataSettings, TableProperties } from '../../../redux/slices/update-widget-table-sort-field';
import useStartEndDates from './useStartEndDates';
import { useSelectedView } from './use-selected-view';

export interface ExportTypeProps {
  widgetId?: string;
  layoutId?: string;
}

export function useExportFile({ widgetId }: ExportTypeProps) {
  const [keyFileGenerated, setKeyFileGenerated] = useState<string>();
  const [exportLayoutJobActive, setExportLayoutJobActive] = useState<boolean>(false);
  const [createWidgetExportJob] = useGetExportWidgetJobIdMutation();
  const [createLayoutExportJob] = useGetExportLayoutJobIdMutation();
  const { data: exportedWidget } = useExportJobStateQuery(keyFileGenerated ?? '', {
    skip: !keyFileGenerated,
    pollingInterval: keyFileGenerated ? 6000 : undefined,
  });
  const [downloadedField, setDownloadedFile] = useState<ExportJobDetailsResult>();
  const { tables } = useSelector(selectTableDataSettings);

  const layoutTabs = useSelector(getTabs);
  const activeTabId = useSelector(selectActiveTabId);
  const view = useSelectedView();
  const { startDate, endDate } = useStartEndDates();
  const timeZoneId = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const toastId = useRef<string>(v4());
  const locale = useRef<string>(navigator.language);

  const layoutProps = useMemo(() => {
    if (layoutTabs) {
      return layoutTabs.find((tab) => tab.id === activeTabId);
    }
  }, [activeTabId, layoutTabs]);

  useEffect(() => {
    setDownloadedFile(exportedWidget);
  }, [exportedWidget]);

  const exportWidgetHandler = useCallback(
    (extension: string, detailsWindow = false) => {
      toast.loading(t('exportInProgress'), { id: toastId.current });

      const tableId = detailsWindow ? `detailsWindow-${widgetId}` : widgetId;
      const dataTableSettings: TableProperties | undefined = tables.find((table) => table.id === tableId);
      const sortedFields = dataTableSettings?.sortedFields ?? dataTableSettings?.getSortedFields?.();
      createWidgetExportJob({
        widgetId,
        timeZoneId: timeZoneId.current,
        exportFileType: extension,
        viewId: view?.id,
        from: startDate,
        to: endDate,
        layoutId: layoutProps?.id,
        detailsWindow: detailsWindow,
        languageTag: locale.current,
        ...dataTableSettings,
        sortedFields,
      })
        .then((response) => {
          if ('data' in response) {
            setKeyFileGenerated(response.data);
          } else if ('error' in response) {
            toast.error(t('exportFailed'), { id: toastId.current });
            setKeyFileGenerated(undefined);
          }
        })
        .catch(() => toast.error(t('exportFailed'), { id: toastId.current }));
    },
    [startDate, endDate, tables, layoutProps, view]
  );

  const exportLayoutHandler = useCallback(() => {
    if (activeTabId) {
      toast.loading(t('exportInProgress'), { id: toastId.current });

      setExportLayoutJobActive(true);
      createLayoutExportJob({
        timeZoneId: timeZoneId.current,
        viewId: view?.id,
        from: startDate,
        to: endDate,
        layoutId: activeTabId,
        languageTag: locale.current,
      })
        .then((response) => {
          if ('data' in response) {
            setKeyFileGenerated(response.data);
          } else if ('error' in response) {
            toast.error(t('exportFailed'), { id: toastId.current });
            setKeyFileGenerated(undefined);
          }
        })
        .catch(() => {
          toast.error(t('exportFailed'), { id: toastId.current });
          setExportLayoutJobActive(false);
        });
    } else {
      toast.error(t('exportFailed'), { id: toastId.current });
    }
  }, [startDate, endDate, activeTabId, view]);

  useEffect(() => {
    if (keyFileGenerated && downloadedField) {
      const status = downloadedField.status?.toUpperCase() ?? '';
      if (status === 'STAGED' || status === 'FINISHED') {
        if (status === 'FINISHED') {
          setExportLayoutJobActive(false);
          setKeyFileGenerated(undefined);
          setDownloadedFile(undefined);
          toast.success(t('exportComplete'), { id: toastId.current });
          const button = document.createElement('a');
          button.href = downloadedField.s3FileUrl ?? '';
          button.download = `file-${downloadedField.id}.${downloadedField.exportFileType.toLowerCase()}`;
          button.click();
        }
      } else if (status === 'ERROR' || status === 'ERROR_ENCOUNTERED') {
        setExportLayoutJobActive(false);
        setKeyFileGenerated(undefined);
        setDownloadedFile(undefined);
        toast.error(t('exportFailed'), { id: toastId.current });
      }
    }
  }, [downloadedField, keyFileGenerated]);

  return { exportWidgetHandler, exportLayoutHandler, exportLayoutJobActive };
}
