import { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, CheckboxChangeEventType, Input, ModalDialog } from '@myosh/odin-components';
import { ViewNameOption } from './view-dropdown';
import {
  PostViewExtended,
  PutPatchViewInterface,
  useCreateViewMutation,
  useGetAllViewsQuery,
  useUpdateViewMutation,
} from '../../../redux/config/views';
import { useAppDispatch } from '../../../redux/hooks';
import { setSelectedView } from '../../../redux/slices/selected-view';
import { customDateRangeFlag } from '../utilities';
import { useUser } from '@myosh/myosh-login';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useSelectedView } from '../../Charts/hooks/use-selected-view';
import useStartEndDates from '../../Charts/hooks/useStartEndDates';
import { useHierarchyFilters } from '../../Charts/hooks/useHierarchyFilter';
import { ManageViewsModalProps } from './view-handler.component';

export const ViewSaveModal = ({ visible, onClose, viewSaved }: ManageViewsModalProps) => {
  const [viewsOptions, setViewOptions] = useState<Array<ViewNameOption>>([]);
  const { data, isFetching } = useGetAllViewsQuery();
  const navigateToView = useRef<boolean>(true);
  const [addView] = useCreateViewMutation();
  const [updateView] = useUpdateViewMutation();
  const dispatch = useAppDispatch();
  const user = useUser();
  const applicationView = useSelectedView();
  const dates = useStartEndDates();
  const hierachyFilters = useHierarchyFilters();
  const newViewName = useRef<string>();
  const [isGlobalView, setIsGlobalView] = useState<boolean>();

  useEffect(() => {
    if (applicationView) {
      newViewName.current = applicationView?.name ?? '';
      setIsGlobalView(!applicationView.privateView);
    }
  }, [applicationView]);

  useEffect(() => {
    if (data && !isFetching) {
      const viewOptions = data.map((view) => {
        return {
          text: view.name,
          value: view.id,
          defaultView: view.defaultView,
          ...view,
        };
      });
      setViewOptions(viewOptions);
    }
  }, [data, isFetching]);

  const updateViewHandler = useCallback((updatedView: PutPatchViewInterface) => {
    updateView(updatedView)
      .unwrap()
      .then(() => toast.success(t('updateViewSuccess')))
      .catch(() => {
        toast.error(t('updateViewFailure'));
      });
  }, []);

  const addViewHandler = useCallback((newView: PostViewExtended) => {
    navigateToView.current = true;
    addView(newView)
      .unwrap()
      .then((result) => {
        if (result) {
          dispatch(setSelectedView({ id: result }));
          toast.success(t('viewCreated'), { duration: 3000 });
        }
      });
  }, []);
  /**
   * This method takes care of updating an existing view or creating a new one.
   * - Clicking save when view name is not updated and no changes done --> nothing happens
   * - Clicking save with the same name, updates the view
   * - Clicking save with a new name provided
   */
  const saveChangesCallback = () => {
    if (newViewName.current) {
      if (newViewName.current === 'Default') {
        toast.error(t('defaultErrorUpdate'));
        return;
      } else if (
        applicationView &&
        applicationView.name === newViewName.current &&
        applicationView.privateView === !isGlobalView
      ) {
        updateViewHandler({ id: applicationView.id });
        viewSaved?.();
        return;
      }
      // as applicationView is not empty, new name is provided
      // copy properties from the current view
      else if (applicationView && applicationView.dateFiltersForView) {
        const { dateFiltersForView, ...rest } = applicationView;
        const dateValues =
          dateFiltersForView.dateFilterRangeType === customDateRangeFlag
            ? { fromDate: dateFiltersForView.fromDate, toDate: dateFiltersForView.toDate }
            : { dateFilterRangeType: dateFiltersForView.dateFilterRangeType };
        addViewHandler({
          ...rest,
          ...dateValues,
          defaultView: false,
          privateView: !isGlobalView,
          name: newViewName.current,
        });
        viewSaved?.();
      } else {
        // Date filters or global hierachies were updated
        // Creating new view if new name is provided or updating an existing view if name is not changed
        const existingView = viewsOptions.find((viewOption) => viewOption.name === newViewName.current);
        //only range OR from&to must be sent
        const dateValues =
          dates.quickDateRange === customDateRangeFlag
            ? { fromDate: dates.startDate, toDate: dates.endDate }
            : { dateFilterRangeType: dates.quickDateRange };
        const payload: PostViewExtended = {
          name: newViewName.current,
          ...dateValues,
          hierarchyFilters: { hierarchyValues: hierachyFilters },
        };

        if (existingView) {
          navigateToView.current = false;
          updateViewHandler({ id: existingView.id, ...payload });
          viewSaved?.();
        } else {
          payload.userId = user.state.user?.prefered_username ?? '';
          payload.privateView = !isGlobalView;
          payload.defaultView = false;
          addViewHandler(payload);
          viewSaved?.();
        }
      }
    } else {
      toast.error(t('nameRequired'), { duration: 3000 });
    }
  };

  const handleViewNameChange = (value?: string) => {
    newViewName.current = value;
  };

  const handleIsGlobalChange = (value?: CheckboxChangeEventType) => {
    setIsGlobalView(!!value?.checked);
  };

  return (
    <>
      <ModalDialog
        header={t('saveCurrentView')}
        visible={visible}
        hidden={onClose}
        buttons={[
          {
            type: 'primary',
            name: 'save',
            text: t('save'),
            onClick: saveChangesCallback,
          },
        ]}
        buttonType="submit"
        minDialogWidth={350}
        maxDialogWidth={350}
      >
        <div className="my-2 flex flex-col gap-4">
          <Input
            name="viewName"
            label="Name"
            inputStyles="rounded py-2"
            value={newViewName.current}
            onChange={handleViewNameChange}
          />
          <Checkbox
            name="globalView"
            label={t('globalView')}
            className="rounded"
            initialChecked={isGlobalView}
            value={String(isGlobalView)}
            onChange={handleIsGlobalChange}
          />
        </div>
      </ModalDialog>
    </>
  );
};
