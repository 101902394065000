import { useEffect, useState } from 'react';
import { getStatusColor } from '../../../helpers/getStatusColor';
import { getValueOrDefault } from '../../../helpers/getvalueOrDefault';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';

export type RollingTRIFRConfigs = {
  lineColor: string;
  caption: string;
  available: boolean;
  displayValues: boolean;
};

const defaultState: RollingTRIFRConfigs = {
  caption: '',
  lineColor: '',
  available: false,
  displayValues: false,
};

export function useTRIFRConfigs(configurationData?: WidgetConfigurationData) {
  const [trifrConfig, setTrifrConfig] = useState<RollingTRIFRConfigs>(defaultState);

  useEffect(() => {
    let newConfig = defaultState;
    const widgetData = configurationData?.widgetData;

    if (widgetData) {
      const rollingTRIFR = widgetData.rollingTRIFR;
      if (rollingTRIFR) {
        const caption = getValueOrDefault(rollingTRIFR.title, 'TRIFR');
        let lineColor = getStatusColor(0);
        const colors = rollingTRIFR.colors;
        if (colors && colors.length > 0) {
          lineColor = colors[0];
        }
        newConfig = { caption, lineColor, available: true, displayValues: rollingTRIFR.displayValues };
      }
    }
    setTrifrConfig(newConfig);
  }, [configurationData]);

  return trifrConfig;
}
