import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export type FiltersType = {
  field: string;
  values: Array<string>;
};

interface FiltersQueryParameters {
  field: string;
  form: Array<string>;
}

export const filtersApi = createApi({
  reducerPath: 'filtersApi',
  baseQuery: baseQueryWithReAuth,

  endpoints: (builder) => ({
    getFilters: builder.query<FiltersType, FiltersQueryParameters>({
      query: (args) => {
        let url = `/filters?field=${args.field}`;

        if (args.form) {
          url += `&form=${args.form}`;
        }

        return { url, method: 'GET' };
      },
    }),
  }),
});

export const { useGetFiltersQuery } = filtersApi;
