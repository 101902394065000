import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Cell,
} from 'recharts';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';

import { LegendPayloadOnClick } from './chart-events';
import { ChartProps } from './chart.interface';
import { findCustomColor, includeLegend, intervalCounter } from './generalFunctions/general-functions';
import { roundHighest } from './generalFunctions/get-domaincharts';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import OdinCustomDetailsWindow, { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';

import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import CustomYAxis from '../common/custom-Y-axis.component';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { sortOrderStrings } from '../common/sort-order-strings';

import { BarChartSecondFieldsCommandFactory } from '../../data-transformer/bar-chart/bar-chart-secondFields-command.factory';
import { DataExecutor } from '../../data-transformer/data-executor';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';
import { BarChartSimpleCommandFactory } from '../../data-transformer/bar-chart/bar-chart-simple-command.factory';

import { getColorsArray } from '../../helpers/get-colors-arrays';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import { useTicksCountBasedOnDomainScale } from './hooks/useTicksCountBasedOnDomainScale';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { ChartResponse, useBarChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinBarChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [transformedData, setTransformedData] = useState<ChartResponse[]>();
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [barChartKeys, setBarChartKeys] = useState<string[]>([]);
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CustomModalProps>();
  const [secondFieldExists, setSecondFieldExists] = useState<boolean>(false);
  const [ticksMaxLength, setTicksMaxLength] = useState<number>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const customColors = useRef<CustomColorProps[]>([]);
  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings('', 'number', false);
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isFetching: isWidgetChartDataFetching,
    isLoading,
    isError,
    error,
  } = useBarChartQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  const staticChartKeys = ['field', 'color'];

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    const commandExecutor = new DataExecutor();

    if (widgetChartData && configurationData) {
      const isSecondField = widgetChartData.some((obj) => obj.hasOwnProperty('secondField'));
      setSecondFieldExists(isSecondField);

      const commandFactory = isSecondField
        ? new BarChartSecondFieldsCommandFactory(configurationData)
        : new BarChartSimpleCommandFactory(configurationData);

      let commandResponse: DataCommandResponse<unknown, WidgetConfigurationData, unknown> | undefined;
      let outputData: unknown = widgetChartData;
      while ((commandResponse = commandFactory.getNextCommand())) {
        const inputData = outputData || widgetChartData;
        outputData = commandExecutor.execute(commandResponse.command, inputData, configurationData);
      }
      const newTransformedData = outputData as Array<ChartResponse>;
      const keys = uniq(
        newTransformedData.flatMap((item) => Object.keys(item)).filter((key) => !staticChartKeys.includes(key))
      );
      setBarChartKeys(sortOrderStrings(newTransformedData, configurationData, keys));
      setTransformedData(newTransformedData);

      customColors.current = getColorsArray(
        uniq(widgetChartData.flatMap((item) => getValueOrDefault(item.secondField ?? item.field, '[none]'))),
        configurationData
      );
    }
  }, [widgetChartData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };

  const onShowModal = (data: CustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };

  const onDialogHidden = () => setDetailsWindowVisible(false);
  const handleRefetch = useInvalidateChartResponse(widgetId);

  // Calculate the length of the longest label and set YAxis width according to the result.
  const longestLabelLength = useMemo(() => {
    if (transformedData) {
      const labelLength = Math.max(...transformedData.map((el) => el.field?.length ?? 0));
      return labelLength <= 20 ? labelLength + 75 : 150;
    }
  }, [transformedData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const domainValue = useCallback(([dataMin, dataMax]: [number, number]): [number, number] => {
    const roundMaxValue = roundHighest(dataMax);
    setTicksMaxLength(roundMaxValue);

    return [0, roundMaxValue];
  }, []);

  const tickCounter = useTicksCountBasedOnDomainScale(ticksMaxLength);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: customColors.current, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.fieldValue || modalData?.field}
            secondFieldValue={modalData?.tooltipPayload[0]?.dataKey}
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
          />
          <ResponsiveContainer className="mx-auto flex justify-center" height="99%">
            <ComposedChart
              data={transformedData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              layout="vertical"
            >
              <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
              <>
                <CartesianGrid vertical horizontal={false} />
                <XAxis
                  {...xAxisSettings}
                  tickCount={tickCounter}
                  domain={domainValue}
                  interval={intervalCounter(transformedData)}
                  allowDuplicatedCategory={false}
                  padding={{ left: 0, right: 0 }}
                  allowDecimals
                  tick
                />
                <YAxis
                  type="category"
                  dataKey="field"
                  allowDecimals={false}
                  axisLine={false}
                  tickLine={false}
                  label={undefined}
                  tickFormatter={compactNumberFormatter}
                  width={longestLabelLength}
                  interval={intervalCounter(transformedData)}
                  tick={CustomYAxis}
                />
                {includeLegend(configurationData) && (
                  <Legend
                    formatter={renderLegend}
                    verticalAlign="bottom"
                    wrapperStyle={defaultLegendWrapperStyle}
                    onClick={selectItem}
                  />
                )}
                {barChartKeys.map((uniqueElementKey) => {
                  const name = secondFieldExists ? uniqueElementKey : 'Quantity';
                  return (
                    <Bar
                      name={name}
                      key={uniqueElementKey}
                      dataKey={uniqueElementKey}
                      fill={secondFieldExists ? findCustomColor(uniqueElementKey, customColors.current) : '#FFC350'}
                      hide={Boolean(legendProps && legendProps[uniqueElementKey])}
                      onMouseEnter={() => handleMouseEnter(name)}
                      onMouseLeave={handleMouseLeave}
                      onClick={(data) => onShowModal(data)}
                      cursor="pointer"
                      className="barChartVertical"
                      minPointSize={MIN_COLUMN_BAR_HEIGHT}
                      isAnimationActive={activateAnimation}
                    >
                      <LabelList dataKey={uniqueElementKey} position="middle" fill="#fff" fontSize={12} />
                      {!secondFieldExists &&
                        transformedData.map((item) => {
                          return (
                            item.field && (
                              <Cell key={item.field} fill={findCustomColor(item.field, customColors.current)} />
                            )
                          );
                        })}
                    </Bar>
                  );
                })}
              </>
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
