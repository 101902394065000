import React, { useEffect, useRef, useState } from 'react';
import L, { LatLng, LeafletMouseEvent } from 'leaflet';
import { MapChartData } from '../../redux/config/chart-data';
import { v4 as uuidv4 } from 'uuid';
import OdinCustomDetailsWindow from '../CustomDetailsWindow/custom-details-window.component';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { useSelector } from 'react-redux';
import { selectWidgetChartHeight } from '../../redux/slices/update-width-height-of-chart';

export interface MapWidgetProps {
  data: MapChartData[];
  widgetId: string;
  configurationData: WidgetConfigurationData;
}

export default function MapWidget({ data, widgetId, configurationData }: MapWidgetProps) {
  const [latLngCoords, setLatLongCoords] = useState<LatLng>();
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [map, setMap] = useState<L.Map>();
  const assignedMarkers = useRef<Array<L.Marker>>();

  //make sure map works in strict mode
  // the same idea as here https://github.com/PaulLeCam/react-leaflet/pull/964
  const initialRender = useRef(true);
  const mapId = useRef<string>(`map-${uuidv4()}`);
  const { widgetWidthHeight } = useSelector(selectWidgetChartHeight);

  useEffect(() => {
    //Needed due to scrict mode executing effects twice
    if (initialRender.current) {
      const mapObject = L.map(mapId.current, {
        attributionControl: false,
      });
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: 1,
      }).addTo(mapObject);
      setMap(mapObject);
      initialRender.current = false;
    }
    () => {
      if (map) {
        map.eachLayer((layer) => layer.remove());
        map.off();
        map.remove();
      }
    };
  }, []);

  //https://stackoverflow.com/questions/53879753/leaflet-map-does-not-appear-correctly-until-resize
  useEffect(() => {
    if (map && (widgetWidthHeight.updateWidth || widgetWidthHeight.updateHeight)) {
      setTimeout(() => map.invalidateSize(), 10);
    }
  }, [widgetWidthHeight]);

  useEffect(() => {
    if (map) {
      if (assignedMarkers.current) {
        for (let i = 0; i < assignedMarkers.current.length; i++) {
          map.removeLayer(assignedMarkers.current[i]);
        }
      }
      if (data.length > 0) {
        const latestMarkers = [];
        map.setView([data[0].lat, data[0].lon], 4);
        for (let i = 0, length = data.length; i < length; i++) {
          latestMarkers.push(
            L.marker([data[i].lat, data[i].lon])
              .bindTooltip(`Records: ${data[i].recordIDs.length}`)
              .addTo(map)
              .on('click', (e) => onClick(e))
          );
        }
        assignedMarkers.current = latestMarkers;
      } else {
        map.setView([0, 0], 0);
      }
    }
  }, [map, data]);

  function onClick(e: LeafletMouseEvent) {
    setLatLongCoords(e.latlng);
    setDetailsWindowVisible(true);
  }

  const onDialogHidden = () => setDetailsWindowVisible(false);

  return (
    <>
      <OdinCustomDetailsWindow
        widgetId={widgetId}
        field="lat"
        secondField="lon"
        moduleViewId={configurationData.widgetData.moduleViewId}
        fieldValue={String(latLngCoords?.lat)}
        secondFieldValue={String(latLngCoords?.lng)}
        visible={latLngCoords ? detailsWindowVisible : false}
        onDialogHidden={onDialogHidden}
      />
      <div id={`${mapId.current}`} className="h-full w-full" style={{ zIndex: 1 }} />
    </>
  );
}
