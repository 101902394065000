import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GlobalFilterDateDetails,
  GlobalFilterDetails,
  getLocalStorageDates,
  setGlobalDateFilters,
} from '../redux/slices/globalFilter/global-dates-filter-slice';
import { useAppDispatch } from '../redux/hooks';
import { useDefaultDateRange } from '../hooks/useDefaultDateRange';
import { DateRange } from '../components/Header/Header.interface';
import { appendTimeUnit } from '../helpers/dateFormater';

export const DateRangeContext = createContext<GlobalFilterDetails | undefined>(undefined);

export const GlobalDateRangeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const defaultDateRange = useDefaultDateRange();

  const dispatch = useAppDispatch();
  const [dateFilter, setDateFilter] = useState<GlobalFilterDetails>();

  useEffect(() => {
    let updateDateFilter;
    //if there is a value in local storage, we should use it
    const localStorageValue = getLocalStorageDates();
    const lastUsedView = sessionStorage.getItem('lastUsedView');

    const forcedApiDates = sessionStorage.getItem('forced-date-api');
    if (forcedApiDates) {
      updateDateFilter = JSON.parse(forcedApiDates);
    } else if (localStorageValue?.quickDateRange?.value) {
      updateDateFilter = localStorageValue;
    }
    //otherwise, using default range received from the backend}
    else if (defaultDateRange) {
      updateDateFilter = convertToGlobalFilterDetails(defaultDateRange, true);
    }

    if (updateDateFilter && !lastUsedView) {
      dispatch(setGlobalDateFilters({ DateDetails: updateDateFilter }));
      setDateFilter({ DateDetails: updateDateFilter });
    }
  }, [defaultDateRange]);

  return <DateRangeContext.Provider value={dateFilter}>{children}</DateRangeContext.Provider>;
};

export const useDateRangeContext = () => {
  return useContext(DateRangeContext);
};

export const convertToGlobalFilterDetails = (
  dataTab?: DateRange,
  appendTime = false
): GlobalFilterDateDetails | undefined => {
  if (dataTab) {
    const dateStart = String(dataTab.from);
    const dateEnd = String(dataTab.to);
    return {
      dateStart: appendTime ? appendTimeUnit(dateStart) : dateStart,
      dateEnd: appendTime ? appendTimeUnit(dateEnd) : dateEnd,
      quickDateRange: {
        value: dataTab.id ?? '',
        text: dataTab.caption ?? '',
      },
    };
  }
};
