import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { Hierarchy } from '../../shared/Hierarchy';
import { genericSort, SortDirection } from '../../components/Charts/generalFunctions/general-functions';
import { orderBy } from 'lodash';

export const hierarchyApi = createApi({
  reducerPath: 'hierarchyApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Hierarchy'],

  endpoints: (builder) => ({
    getHierarchyWithValue: builder.query<Hierarchy[], void>({
      query: () => ({
        url: `/hierarchy`,
        method: 'GET',
      }),
      transformResponse: (response: Hierarchy[]) => {
        return orderBy(response, ['weight', 'name']).map((hierarchy: Hierarchy) => {
          const sortedValues = hierarchy.values ? genericSort(hierarchy.values, 'name', SortDirection.Ascending) : [];
          return {
            ...hierarchy,
            values: sortedValues,
          };
        });
      },
      providesTags: ['Hierarchy'],
    }),
  }),
});

export const { useGetHierarchyWithValueQuery } = hierarchyApi;
