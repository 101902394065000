import React from 'react';
import { Text } from 'recharts';
import { AxisProps } from '../../shared/widget-data.interfaces';

const CustomYAxis = ({ y, payload }: AxisProps) => {
  return (
    <Text
      x={0}
      y={y}
      textAnchor="start"
      verticalAnchor="middle"
      fontSize={12}
      maxLines={payload.value.length > 10 ? 1 : undefined}
      width={payload.value.length <= 10 ? 25 : 250}
    >
      {payload.value}
    </Text>
  );
};

export default CustomYAxis;
