import React, { useCallback, useRef } from 'react';
import ConfirmationWindow from '../common/confirmation-window';
import { t } from 'i18next';
import { useDeleteWidgetMutation, widgetConfigurationApi } from '../../redux/config/widgetApi';
import { layoutApi } from '../../redux/config/layout';
import { DropDownResult } from '@myosh/odin-components';
import { useAppDispatch } from '../../redux/hooks';
import toast from 'react-hot-toast';

type DeleteWidgetType = {
  isVisible: boolean;
  setIsVisible: (show: boolean, closeOverlays: boolean) => void;
  chart: DropDownResult;
};
export const DeleteWidgetConfirmation = ({ isVisible, setIsVisible, chart }: DeleteWidgetType) => {
  const dispatch = useAppDispatch();
  const closeOverlays = useRef<boolean>(false);
  const onHideModal = useCallback(() => {
    setIsVisible(false, closeOverlays.current);
    closeOverlays.current = false;
  }, []);

  const [deleteWidget] = useDeleteWidgetMutation();

  const onDeleteConfirmation = useCallback(() => {
    deleteWidget(Number(chart.value))
      .then(() => {
        dispatch(layoutApi.util.invalidateTags(['layoutInfo']));
        dispatch(widgetConfigurationApi.util.invalidateTags(['widgetConfigSimplified']));
        toast.success(t('deleteWidgetSuccess'));
      })
      .catch(() => {
        toast.error(t('deleteViewFailure'));
      });
    closeOverlays.current = true;
  }, [chart]);

  return (
    <ConfirmationWindow
      onSaveHandler={onDeleteConfirmation}
      showWindow={isVisible}
      onDiscardChanges={{
        onHideModal: onHideModal,
        onDiscard: onHideModal,
      }}
      title={t('thisWidgetWillBeDeleted')}
      text={<div>{String(t('doYouWantToContinue'))}</div>}
      discard={t('no')}
      save={t('yes')}
    />
  );
};
