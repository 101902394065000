import { useCallback, useEffect, useRef, useState } from 'react';
import { ModalDialog } from '@myosh/odin-components';
import { ViewDropdownRef, ViewNameOption } from './view-dropdown';
import {
  PutPatchViewInterface,
  useCreateViewMutation,
  useDeleteViewMutation,
  useGetAllViewsQuery,
  useLazyGetViewQuery,
  useUpdateViewMutation,
} from '../../../redux/config/views';
import { useDefaultDateRange } from '../../../hooks/useDefaultDateRange';
import { useConfiguredDateRanges } from '../../../hooks/useConfiguredDateRanges';
import { getStorageItem } from '../../../helpers/storage';
import { convertToGlobalFilterDetails } from '../../../context/globalDateRange.context';
import { useAppDispatch } from '../../../redux/hooks';
import { removeGlobalDateFilters } from '../../../redux/slices/globalFilter/global-dates-filter-slice';
import { setSelectedView } from '../../../redux/slices/selected-view';
import { customDateRangeFlag, quickDateRange } from '../utilities';
import { useUser } from '@myosh/myosh-login';
import ViewNameEditor from './view-name-editor';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { ManageViewsModalProps } from './view-handler.component';

export const ViewManageModal = ({ visible, onClose }: ManageViewsModalProps) => {
  const [viewsOptions, setViewOptions] = useState<Array<ViewNameOption>>([]);
  const ref = useRef<ViewDropdownRef>(null);
  const newViewName = useRef<string>();
  const { data, isFetching, isLoading } = useGetAllViewsQuery();
  const navigateToView = useRef<boolean>(true);
  const defaultDateRange = useDefaultDateRange();
  const dateRanges = useConfiguredDateRanges();
  const [addView] = useCreateViewMutation();
  const [deleteView] = useDeleteViewMutation();
  const [updateView] = useUpdateViewMutation();
  const [getView] = useLazyGetViewQuery();
  const dispatch = useAppDispatch();
  const user = useUser();

  useEffect(() => {
    if (data && !isFetching) {
      const viewOptions = data.map((view) => {
        return {
          text: view.name,
          value: view.id,
          defaultView: view.defaultView,
          ...view,
        };
      });
      setViewOptions(viewOptions);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (viewsOptions && dateRanges) {
      if (navigateToView.current) {
        const lastelyUsedView = getStorageItem('lastUsedView', 'sessionStorage');
        const defaultView = viewsOptions.find((view) => view.defaultView);
        const lastUsedView = lastelyUsedView && viewsOptions.find((view) => view.value === lastelyUsedView['id']);
        const id = lastUsedView?.value ?? defaultView?.value;

        if (id) {
          updateSelectedView(id);
        }
      }
      navigateToView.current = true;
    }
  }, [viewsOptions, dateRanges]);

  /** Adds a default view for a user, if there are none exist in its views. */
  useEffect(() => {
    if (
      !isLoading &&
      defaultDateRange &&
      (data?.length === 0 || (data && data.find((view) => view.defaultView) === undefined))
    ) {
      const dates = convertToGlobalFilterDetails(defaultDateRange);
      addView({
        name: 'Default',
        privateView: false,
        defaultView: true,
        hierarchyFilters: {},
        userId: user.state.user?.prefered_username ?? '',
        dateFilterRangeType: String(dates?.quickDateRange.value),
      });
    }
  }, [data, isLoading, defaultDateRange]);

  const updateSelectedView = (viewId?: string) => {
    viewId && ref.current?.setSelectedView(viewId);
    const view = data?.find((view) => view.id === viewId);
    newViewName.current = view?.name ?? '';
    if (view) {
      getView(view.id)
        .unwrap()
        .then((result) => {
          dispatch(removeGlobalDateFilters());
          const predefinedRange = result?.dateFiltersForView
            ? dateRanges?.find((range) => range.id === result.dateFiltersForView?.dateFilterRangeType)
            : //if no date filters are supplied, use explicitly the dault ones
              defaultDateRange;
          if (predefinedRange) {
            const convertedDate = convertToGlobalFilterDetails(predefinedRange);
            dispatch(
              setSelectedView({
                ...result,
                quickDateRange: convertedDate?.quickDateRange,
                dateFiltersForView: {
                  fromDate: convertedDate?.dateStart,
                  toDate: convertedDate?.dateEnd,
                  dateFilterRangeType: predefinedRange.id,
                },
              })
            );
            //there are some dates returned for a view
          } else {
            dispatch(
              setSelectedView({
                ...result,
                quickDateRange: quickDateRange,
                dateFiltersForView: {
                  ...result.dateFiltersForView,
                  dateFilterRangeType: customDateRangeFlag,
                },
              })
            );
          }
        });
    }
  };

  const removeViewHandler = useCallback((nameValue: string) => {
    deleteView(nameValue).then(() => toast.success(t('deleteViewSuccess'), { duration: 3000 }));
  }, []);

  const updateViewHandler = useCallback((updatedView: PutPatchViewInterface) => {
    updateView(updatedView)
      .unwrap()
      .then(() => toast.success(t('updateViewSuccess')))
      .catch(() => {
        toast.error(t('updateViewFailure'));
      });
  }, []);

  return (
    <>
      {viewsOptions && (
        <ModalDialog header={t('manageViews')} visible={visible} hidden={onClose}>
          <div className="my-2 flex w-full flex-col gap-1">
            {viewsOptions?.map((item, index) => (
              <ViewNameEditor
                key={item.value}
                view={item}
                isEven={index % 2 === 0}
                onRemove={removeViewHandler}
                onNameUpdate={(viewName: string) => updateViewHandler({ id: item.id, name: viewName })}
              />
            ))}
          </div>
        </ModalDialog>
      )}
    </>
  );
};
