import React, { useEffect, useRef, useState } from 'react';
import {
  DropDownMultiSelect,
  DropDownNoSelection,
  DropDownRef,
  DropDownResult,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useGetTextSearchCustomFiltersQuery } from '../../redux/config/CustomFiltersApi';
import { JsonData } from '@myosh/odin-components/dist/types/components/generic-types';
import { t } from 'i18next';
import { dropDownInputStyle, dropDownLabelStyle } from '../../constants/common-style-constants';
import { uniqBy, concat } from 'lodash';

export interface OdinInputProps {
  id: string;
  field?: string;
  onChange: (value?: DropDownResult | DropDownResult[] | DropDownNoSelection) => void;
  currentValues: Array<string>;
}

type OdinInputDropDownType = JsonData & DropDownResult[];

const UserOdinInput = ({ id, field, onChange, currentValues }: OdinInputProps) => {
  const [dropdownSelectedValues, setDropdownSelectedValues] = useState<OdinInputDropDownType>();
  const [dropdownOptions, setDropdownOptions] = useState<OdinDataRetrievalOptions>();
  const dataSender = useRef<OdinDataSender<JsonDataWrapper>>();
  const dropDownRef = useRef<DropDownRef>(null);
  const { data: textSearchData, isFetching } = useGetTextSearchCustomFiltersQuery(
    {
      widgetId: id,
      field: field,
      filterValue: dropdownOptions?.fieldFilters?.text.value,
    },
    { skip: dropdownOptions?.fieldFilters?.text.value === undefined }
  );
  useEffect(() => {
    if (currentValues) {
      setDropdownSelectedValues(
        currentValues.map((field) => {
          return {
            id: field,
            text: field,
            value: field,
          };
        })
      );
    }
  }, [currentValues]);

  /**
   * Called by the component that is trying to retrieve data.
   * @param sendData {OdinDataSender<JsonData | JsonDataWrapper>} dataResult - The OdinDataSender object that the user sends the data through.
   *
   *  DropDownMultiSelect values that are being passed in the sendData to display necessary data:
   *  @param dropdownSelectedValues - data that comes from BE and are previously saved, only shown if no value is being searched
   *
   *  @param textSearchData - data that are retrieved after searching, shown if there is no selection from previous section and the searched values are being retrieved
   *
   *  @param updatedArray - data that were saved in previous section + data searched from user
   */

  useEffect(() => {
    if (!isFetching) {
      dropDownRef.current?.resetDropDownData();
      if (currentValues.length > 0 && (!textSearchData || textSearchData.length === 0)) {
        dataSender.current?.sendData({ data: dropdownSelectedValues, requestId: dropdownOptions?.requestId });
      } else if (textSearchData && textSearchData.length > 0 && currentValues?.length === 0) {
        dataSender.current?.sendData({ data: textSearchData, requestId: dropdownOptions?.requestId });
      } else if (textSearchData && dropdownSelectedValues) {
        const updatedArray = uniqBy(concat(textSearchData, dropdownSelectedValues), 'id');
        dataSender.current?.sendData({ data: updatedArray, requestId: dropdownOptions?.requestId });
      } else {
        dataSender.current?.sendData();
      }
    } else {
      dataSender.current?.sendData();
    }
  }, [textSearchData, currentValues, isFetching]);

  const getOptionsData: OdinDataRetrieval = {
    getSubscriber: (subscriber) => (dataSender.current = subscriber),
    getData: (dataResult, options) => {
      if (options?.page === 1) {
        if (options.fieldFilters) {
          setDropdownOptions(options);
        } else if (!textSearchData) {
          dataSender.current?.sendData({ data: uniqBy(dropdownSelectedValues, 'id'), requestId: options.requestId });
        } else {
          dataResult.sendData();
        }
      } else {
        dataResult.sendData();
      }
    },
  };

  /**
   * A DropDownMultiSelect component to be used dynamically over the custom filter component for TEXT field type
   */

  return (
    <DropDownMultiSelect
      ref={dropDownRef}
      data={getOptionsData}
      label={field}
      value={dropdownSelectedValues}
      onChange={onChange}
      placeholder={t('typeToSearch')}
      textField="text"
      valueField="id"
      allowSearch={true}
      allowClear={true}
      labelStyles={dropDownLabelStyle}
      className={dropDownInputStyle}
      filterLocation={1}
      useChips={true}
      allowSelectAll={false}
    />
  );
};

export default UserOdinInput;
