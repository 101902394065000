import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import type { LayoutWidget } from './layout';

export const layoutWidgetApi = createApi({
  reducerPath: 'layoutWidget',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Widgets'],

  endpoints: (builder) => ({
    getLayoutWidget: builder.query<Array<LayoutWidget>, string | undefined>({
      query: (layoutId) => ({
        url: `/layout-widgets/all/${layoutId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, layoutId) =>
        result
          ? [
              ...result.map(({ widget }) => ({ type: 'Widgets' as const, id: widget.id })),
              { type: 'Widgets', id: `Widgets-${layoutId}` },
            ]
          : [{ type: 'Widgets', id: `Widgets-${layoutId}` }],
    }),
    updateLayoutWidget: builder.mutation<void, Partial<LayoutWidget>[]>({
      query: (body) => {
        return {
          url: `layout-widgets`,
          method: 'PATCH',
          body: body,
        };
      },
      invalidatesTags: (_result, _error, widgets) => [
        ...widgets.map((widget) => {
          return { type: 'Widgets' as const, id: `${widget.id}` };
        }),
      ],
    }),
    updateLayoutWidgetData: builder.mutation<Partial<LayoutWidget>, Partial<LayoutWidget>>({
      query: ({ id, ...body }) => ({
        url: `/layout-widgets/${id}`,
        method: 'PATCH',
        body: { id: id, ...body },
      }),
      invalidatesTags: (_result, _error, widget) => [{ type: 'Widgets' as const, id: `${widget.id}` }],
    }),
  }),
});

export const { useUpdateLayoutWidgetMutation, useUpdateLayoutWidgetDataMutation, useGetLayoutWidgetQuery } =
  layoutWidgetApi;
