import React from 'react';
import { Loader, OdinIcon, OverlayPanel } from '@myosh/odin-components';

export enum DomElementAlignment {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3,
}

export enum DomTargetPosition {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3,
  Left = 4,
  Right = 5,
}
export interface CloseableOverlayProps {
  target: React.MouseEvent<Element, MouseEvent> | HTMLElement | Element | null;
  loading: boolean;
  visible: boolean;
  onHidden: () => void;
  alignment?: DomElementAlignment;
  position?: DomTargetPosition;
  children: React.ReactNode;
  closeOnScroll?: boolean;
  classNames?: string;
}

/**
 * A closeable overlay with unified styling to be used across the application.
 */
export default function CloseableOverlay({
  target,
  loading = false,
  visible,
  alignment = DomElementAlignment.BottomRight,
  position = DomTargetPosition.BottomLeft,
  onHidden,
  children,
  closeOnScroll = false,
  classNames = 'min-h-64 max-h-fit',
}: CloseableOverlayProps) {
  return (
    <OverlayPanel
      target={target}
      visible={visible}
      hidden={onHidden}
      elementAlignment={alignment}
      targetPosition={position}
      shouldScrollCloseOverlay={closeOnScroll}
      mountOnEnter={false}
      unmountOnExit={false}
      shouldCheckZIndex={true}
      shouldUseCreatePortal={false}
      shouldRemainOnScreen={true}
      className="w-96 min-w-min max-w-lg bg-mono-1 p-4"
    >
      <div className={classNames}>
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Loader size="sm" title="Loading" />
          </div>
        ) : (
          <>
            <div className="absolute right-1 top-1 cursor-pointer" onClick={onHidden}>
              <OdinIcon icon="Close" />
            </div>
            {children}
          </>
        )}
      </div>
    </OverlayPanel>
  );
}
