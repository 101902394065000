import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export interface FieldTypesQueryParams {
  module: string;
  form?: Array<string>;
}

export type VisibleFieldType = {
  field: string;
  type: string;
};

export type ModuleFormType = {
  id: string;
  name: string;
  caption: string;
};

export const fieldTypesApi = createApi({
  reducerPath: 'fieldTypesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['ModuleForms'],

  endpoints: (builder) => ({
    getFieldTypes: builder.query<Array<VisibleFieldType>, FieldTypesQueryParams>({
      query: (args) => {
        let url = `/field-types/visible-fields?module=${args.module}`;

        if (args.form) {
          url += `&form=${args.form}`;
        }
        return { url, method: 'GET' };
      },
    }),
    getModuleForms: builder.query<Array<ModuleFormType>, number>({
      query: (moduleId) => {
        const url = `/field-types/${moduleId}/forms`;
        return { url, method: 'GET' };
      },
      providesTags: (result, error, moduleId) => [{ type: 'ModuleForms', id: moduleId }],
    }),
  }),
});

export const { useGetFieldTypesQuery, useGetModuleFormsQuery } = fieldTypesApi;
