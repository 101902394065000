import {
  DataSearchLocation,
  DropDownMultiSelect,
  DropDownNoSelection,
  DropDownResult,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { dropDownInputStyle, dropDownLabelStyle } from '../../constants/common-style-constants';
import { useUserControllerQuery } from '../../redux/config/CustomFiltersApi';

export interface OdinDropDownProps {
  data?: Array<string>;
  field: string;
  onChange?: (value?: DropDownResult | DropDownResult[] | DropDownNoSelection) => void;
  currentValues?: Array<string>;
}

const UserDropDownMultiSelect = ({ field, onChange, currentValues }: OdinDropDownProps) => {
  const templateSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const [dropDownOptions, setDropDownOptions] = useState<OdinDataRetrievalOptions>();
  const { data: userNameList } = useUserControllerQuery(
    {
      page: dropDownOptions?.page || -1,
      pageSize: dropDownOptions?.pageSize || 50,
      fieldFilters: dropDownOptions?.fieldFilters,
    },
    { skip: !templateSubscriber.current }
  );

  useEffect(() => {
    if (
      (dropDownOptions?.page === 0 || dropDownOptions?.page) &&
      dropDownOptions.pageSize &&
      userNameList &&
      userNameList.length > 0
    ) {
      templateSubscriber.current?.sendData({ data: userNameList, requestId: dropDownOptions.requestId });
    } else {
      templateSubscriber.current?.sendData();
    }
  }, [userNameList]);

  const subscriberDataRetrieval: OdinDataRetrieval = {
    getSubscriber: (subscriber) => (templateSubscriber.current = subscriber),
    getData: (_dataResult, options) => {
      setDropDownOptions(options);
    },
  };

  const selectedUsers: Array<DropDownResult> | undefined = useMemo(() => {
    if (currentValues) {
      return currentValues.map((data) => {
        return {
          text: data,
          value: data,
        };
      });
    }
  }, [currentValues]);

  return (
    <DropDownMultiSelect
      data={subscriberDataRetrieval}
      label={field}
      value={selectedUsers}
      onChange={onChange}
      allowSelectAll={false}
      placeholder="Select User"
      textField="field"
      valueField="id"
      allowSearch={true}
      labelStyles={dropDownLabelStyle}
      className={dropDownInputStyle}
      filterLocation={DataSearchLocation.Api}
      allowClear={true}
      useChips={true}
    />
  );
};

export default UserDropDownMultiSelect;
