import { DecimalValues } from '../../../data-transformer/chart-kpi/decimal-values';
import { KpiValues } from '../../../data-transformer/chart-kpi/kpi-values';
import { DataExecutor } from '../../../data-transformer/data-executor';
import { ChartDataKPI } from '../../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';

export default function transformDataChartKPI(widgetConfiguartion: WidgetConfigurationData, chartData: ChartDataKPI) {
  if (widgetConfiguartion) {
    const commandExecutor = new DataExecutor();
    const kpiValuesResponse = commandExecutor.execute(new KpiValues(), chartData, widgetConfiguartion);
    const decimalValuesResponse =
      kpiValuesResponse && commandExecutor.execute(new DecimalValues(), kpiValuesResponse, widgetConfiguartion);
    return decimalValuesResponse;
  }
  return undefined;
}
