import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export type SchemaInfo = {
  version: string;
  'build-time': string;
  schema: string;
  region: string;
  cluster: string;
  'time-zone': string;
  copyright: string;
  system_hostname?: string;
};

export const currentSchemaApi = createApi({
  reducerPath: 'currentSchemaApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, { ...extraOptions, apiType: 'apiV4Url' }),
  tagTypes: ['currentSchema'],
  endpoints: (builder) => ({
    getCurrentSchemaInfo: builder.query<SchemaInfo, void>({
      query: () => ({
        url: `/schemas/current`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCurrentSchemaInfoQuery } = currentSchemaApi;
