import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { ColumnLineChartValues } from '../../shared/widget-data.interfaces';
import { IDataCommand } from '../data-transformer.interface';

export class MoveAnElemenToTheEnd
  implements IDataCommand<Array<ColumnLineChartValues>, WidgetConfigurationData, Array<ColumnLineChartValues>>
{
  process(data: Array<ColumnLineChartValues>): Array<ColumnLineChartValues> {
    const index = data.findIndex((object) => {
      return object.field === '[blank]' || object.field === '[none]';
    });
    if (index !== -1) {
      data.push(data.splice(index, 1)[0]);
    }
    return data;
  }
}
