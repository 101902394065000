import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEditableState } from '../../../redux/slices/editable-view-slice';

export function useIsEditableMode() {
  const [editable, setEditable] = useState<boolean>(false);

  const editableState = useSelector(selectEditableState);

  useEffect(() => {
    setEditable(editableState.isEditable);
  }, [editableState]);

  return editable;
}
