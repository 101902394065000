import { CHART_TYPE } from '../../constants/enums';

export const isTableType = (type: CHART_TYPE) => {
  if (type === undefined) {
    return false;
  }
  return [
    CHART_TYPE.Table,
    CHART_TYPE.TableInjuryFreeDays,
    CHART_TYPE.TableRate,
    CHART_TYPE.TableRollingTRIFR,
    CHART_TYPE.CrossTabReport,
    CHART_TYPE.ManagementTable,
    CHART_TYPE.TableInjurySite,
  ].includes(type);
};
