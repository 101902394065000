import { omitBy, isNil } from 'lodash';
import { DataKPI } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
export class DecimalValues implements IDataCommand<DataKPI[], WidgetConfigurationData, Array<DataKPI>> {
  process(data: DataKPI[], widgetConfiguartion: WidgetConfigurationData): DataKPI[] {
    const decimalPlaces = widgetConfiguartion?.widgetConfig?.displayDecimalPlaces ?? 1;
    const decimal = data.map((item) => {
      return omitBy(
        {
          ...item,
          name: item.name,
          value_FR: item.value_FR?.toFixed(decimalPlaces),
          value_TRIFR: item.value_TRIFR?.toFixed(decimalPlaces),
          value_KPI: item.value_KPI?.toFixed(decimalPlaces),
        },
        isNil
      );
    });
    return decimal;
  }
}
