import { AuthService } from '@myosh/myosh-login';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import querystring from 'query-string';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { getStorageItem, setStorageItem } from '../../helpers/storage';

let clusterConfig = getStorageItem('ClusterConfig');

/**
 * Returns the base URL for making API requests.
 *
 * Checks if a custom cluster API URL is set in localStorage
 * and uses that if available, otherwise uses the .env variable.
 *
 * The apiType param can specify 'apiV4Url' to get v4 API url.
 *
 * @param apiType - Optional API version specifier
 * @returns The base URL string for API requests
 */
const getApiUrl = (apiType: string) => {
  let apiUrl = process.env.REACT_APP_API_BASE_URL;

  if (!clusterConfig) {
    clusterConfig = getStorageItem('ClusterConfig');
  }

  const clusterApiBaseUrl = clusterConfig?.apiBaseUrl;
  if (clusterApiBaseUrl) {
    apiUrl = clusterApiBaseUrl;
  }

  let baseUrl = `${apiUrl}services/daas/v1`;

  if (apiType === 'apiV4Url') {
    baseUrl = `${apiUrl}v4/`;
  }

  return baseUrl;
};

/**
 * Returns the API key to use for API requests.
 *
 * Checks if a custom cluster API key is set in localStorage
 * and uses that if available, otherwise uses the .env variable.
 */
const getApiKey = () => {
  let apiKey = process.env.REACT_APP_API_KEY;

  const clusterApiKey = clusterConfig?.apiKey;
  if (clusterApiKey) {
    apiKey = clusterApiKey;
  }

  return apiKey;
};

const notifyUserAndLogout = (id: string, message: string) => {
  toast.error(message, { id, duration: 3000 }); // id prevents duplicate toasts

  const authService = new AuthService();
  setTimeout(() => authService.logout(), 3200);
};

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const userState = getStorageItem('UserState');
      if (userState) {
        const schema = userState.user?.currentSchema;
        const idToken = userState.tokens?.id_token;

        if (idToken) {
          headers.set('authorization', `Bearer ${idToken}`);
        }
        if (schema) {
          headers.set('Myosh-Schema', schema);
        }
      }
      headers.set('x-api-key', getApiKey() as string);

      return headers;
    },
  });

/**
 * Calls a base query function with automatic re-authentication handling.
 *
 * Takes an optional `apiType` string parameter that specifies which API
 * base URL to use. Handles 401 unauthorized responses by refreshing the auth token and
 * retrying the request.
 */
export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const baseApiType = extraOptions && 'apiType' in extraOptions ? String(extraOptions.apiType) : 'dassApiUrl';

  let result = await baseQuery(getApiUrl(baseApiType))(args, api, extraOptions);

  // handle re-auth with refreshToken
  if (result.error && result.error.status === 401) {
    console.error('Api service query failed:', args);
    const authService = new AuthService();
    const refreshAuth = await authService.signinSilent();
    if (refreshAuth.refresh_token) {
      const userState = getStorageItem('UserState') || '{}';
      userState.tokens = {
        ...userState.tokens,
        id_token: refreshAuth.id_token,
        access_token: refreshAuth.access_token,
        refresh_token: refreshAuth.refresh_token,
        expires_at: refreshAuth.expires_at,
        auth_time: Date.now(),
      };
      setStorageItem('UserState', userState);
      result = await baseQuery(getApiUrl(baseApiType))(args, api, extraOptions);
    } else {
      notifyUserAndLogout('401', t('error401'));
      throw new Error('Unauthorized'); // throw to ensure query is marked as errored
    }
  }

  if (result.error && result.error.status === 403) {
    // TODO: The user shuold be notified
    console.error('User unauthorized to request this resource');
  }

  return result;
};

// Candidate for deletion.
// Confirm usage before deleting whole block.
export const api = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    records: builder.query({
      query: (args: {
        dataUrl: string;
        urlQuery: Record<string, unknown>;
        dateStart?: string | null | undefined;
        dateEnd?: string | null | undefined;
        chartType?: string | null | undefined;
      }) => ({
        url:
          `${args.dataUrl as string}?` +
          querystring.stringify(args.urlQuery).replace(/%2F/gi, '/') +
          args?.dateStart +
          args?.dateEnd,
        method: 'GET',
      }),
    }),
  }),
});
export const { useRecordsQuery } = api;
