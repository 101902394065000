import { Tooltip } from '@myosh/odin-components';
import React from 'react';

export interface FilterChipProps {
  text: string;
  tooltip?: string;
}

export const FilterChip = ({ text, tooltip }: FilterChipProps) => {
  const chip = (
    <div
      key={text}
      className="ml-1 flex cursor-pointer items-center justify-center whitespace-nowrap rounded-lg px-1 text-sm text-primary-2"
    >
      {text}
    </div>
  );

  return tooltip ? (
    <Tooltip description={tooltip} tooltipClassName="bg-mono-1">
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};

export interface FilterChipsProps {
  chips: Array<string>;
  chipsShown?: number;
}

export default function FilterChips({ chips, chipsShown = 3 }: FilterChipsProps) {
  return (
    <>
      {chips.length > chipsShown ? (
        <>
          {[...chips.slice(0, chipsShown)].map((text) => (
            <FilterChip key={text} text={text} />
          ))}
          <FilterChip
            text={`${chips.length - chipsShown} more...`}
            tooltip={`${[...chips.slice(chipsShown)].join(', ')}`}
          />
        </>
      ) : (
        chips.map((text) => <FilterChip key={text} text={text} />)
      )}
    </>
  );
}
