import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LayoutBasicProps, LayoutWidget } from '../../redux/config/layout';
import { selectWidgetChartHeight } from '../../redux/slices/update-width-height-of-chart';
import { DynamicChartService } from '../Charts/dynamic-chart.service';
import { useIsExportView } from '../Charts/hooks/useIsExportView';

type TabWidgetType = { layoutWidget: LayoutWidget; layout: LayoutBasicProps };

export const TabWidget = ({ layoutWidget, layout }: TabWidgetType) => {
  const {
    id: layoutWidgetId,
    widget: { id: widgetId, type, title },
    height: layoutWidgetHeight,
    width: layoutWidgetWidth,
    position,
  } = layoutWidget;
  const { id: layoutId, canAdmin, canModify, isUserLayout } = layout;

  const isExportView = useIsExportView();

  const [width, setWidth] = useState('w-1/2');
  const [height, setHeight] = useState('h-1/2');
  const [isMounted, setIsMounted] = useState(false);

  const loadWidgetTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const chartService = useRef(DynamicChartService.getServiceInstance());

  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsMounted(true);
          observer.current.unobserve(widgetRef.current as HTMLDivElement);
        }
      },
      {
        threshold: 0.25,
      }
    )
  );

  const { t } = useTranslation();
  const { widgetWidthHeight } = useSelector(selectWidgetChartHeight);

  const ChartComponent = useMemo(() => chartService.current.getChartComponent(type), [type]);

  useEffect(() => {
    if (layoutWidget) {
      setHeight(layoutWidgetHeight === '100%' ? 'h-full' : 'h-1/2');
      setWidth(layoutWidgetWidth === '100%' ? 'w-full' : 'w-1/2');
    }
  }, [layoutWidgetHeight, layoutWidgetWidth, layoutWidget]);

  useEffect(() => {
    const { updateHeight, updateWidth, id } = widgetWidthHeight;

    if (layoutWidgetId === id) {
      if (updateWidth) {
        setWidth((prev) => (prev === 'w-1/2' ? 'w-full' : 'w-1/2'));
      }
      if (updateHeight) {
        setHeight((prev) => (prev === 'h-1/2' ? 'h-full' : 'h-1/2'));
      }
    }
  }, [widgetWidthHeight, layoutWidgetId]);

  useEffect(() => {
    const widgetObserver = observer.current;
    if (widgetRef.current) {
      widgetObserver.observe(widgetRef.current);
    }

    return () => widgetObserver.disconnect();
  }, []);

  useEffect(() => {
    if (isMounted && loadWidgetTimeout.current) {
      clearTimeout(loadWidgetTimeout.current);
    }
  }, [isMounted]);

  return (
    <div
      ref={widgetRef}
      id={layoutWidgetId}
      className={`widgetCard widget--${type} ${width} ${height} overflow-y-hidden`}
      data-widget-id={widgetId}
      data-id={layoutWidgetId}
      data-draggable
      // This data attribute instructs child `OverlayPanel` components to skip this element when looking up the
      // hierarchy for the scroll target when the `shouldScrollCloseOverlay` is set to `true`.
      data-skip-scroll-element
      data-position={position}
    >
      {isMounted && (
        <div className="chart-wrapper flex h-full flex-col">
          <div className="my-3 text-center">
            <p className="text-xl font-normal">{title}</p>
          </div>
          {ChartComponent ? (
            <ChartComponent
              widgetId={widgetId}
              title={title}
              activateAnimation={!isExportView}
              layoutProps={{
                widgetLayoutId: layoutWidgetId,
                layoutId,
                canAdmin,
                canEdit: canModify,
                isUserLayout,
              }}
            />
          ) : (
            <div className="no-data rendered w-full text-center">
              <p className="flex h-full flex-col justify-center"> {t('coming_soon')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
