import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Input, OdinIcon, OdinIconSize } from '@myosh/odin-components';
import { Layout } from '../../redux/config/layout';
import { useNewTabId } from '../Charts/hooks/useNewTabId';
import { TabHeaderSettingsOverlay } from './tab-header-settings-overlay';
import { useLayoutAdmin } from '../../hooks/useIsLayoutAdmin';
import { DeleteTabIcon } from './delete-tab-icon';
import classNames from 'classnames';
import { t } from 'i18next';
import { newTabStateActions } from '../../redux/slices/new-tab-slice';
import { useAppDispatch } from '../../redux/hooks';

export type DeleteTabHeaderProps = TabHeaderSettingsProps & {
  onlyIcon: boolean;
  title: string;
};
type TabHeaderSettingsProps = {
  updateLayout: (newValues: Partial<Layout>, removeLayout: boolean) => void;
};
type HeaderElementProps = TabHeaderSettingsProps & {
  setTabInEditMode: (edit: boolean) => void;
  isEditable?: boolean;
  layoutId: number | string;
  layout: Layout;
  localIndex: number;
};

export const TabViewHeaderElement = ({
  setTabInEditMode,
  isEditable = false,
  updateLayout,
  layoutId,
  layout,
  localIndex,
}: HeaderElementProps) => {
  const [inputVisible, setInputVisible] = useState<boolean>();
  const [inputValue, setInputValue] = useState<string | undefined>(layout.title);
  const editIconId = `edit-icon-${layoutId}`;
  const isAdminLayout = useLayoutAdmin();
  const [title, setTitle] = useState<string>(layout.title);
  const dispatch = useAppDispatch();

  const updateLayoutOnEscape = (event: { key?: string; type: string }) => {
    if (closeCallback(event) && inputValue && inputValue.trim() !== title) {
      updateLayout({ title: inputValue }, false);
    }
  };

  const closeCallback = useMemo(() => {
    return (event: { key?: string; type: string }): boolean => {
      if ((event.key && (event.key === 'Enter' || event.key === 'Escape')) || event.type === 'blur') {
        setInputVisible(false);
        setTabInEditMode(false);
        return true;
      }
      return false;
    };
  }, []);
  const newTabId = useNewTabId();

  useEffect(() => {
    if (isEditable && newTabId && newTabId === layoutId) {
      document.getElementById(editIconId)?.click();
      dispatch(newTabStateActions.setNewTabId(undefined));
    }
  }, [newTabId]);

  useEffect(() => {
    setTitle(layout.title);
  }, [layout]);

  const titleClassName = classNames('tabTitle', {
    'noDrop h-full justify-center': !isEditable,
    editableTab: isEditable,
    userLayout: layout.isUserLayout,
    publicLayout: !layout.isUserLayout,
  });

  useLayoutEffect(() => {
    if (titleRef.current) {
      titleRef.current?.closest('li')?.setAttribute('data-position', String(localIndex));
    }
  }, []);
  const titleRef = useRef<HTMLDivElement>(null);
  const titleElement = useMemo(() => {
    return (
      <div className={titleClassName} data-id={layoutId} id={`headerTab-${layoutId}`} ref={titleRef}>
        {title}
      </div>
    );
  }, [isEditable, title]);

  const settingsDelete = useMemo(() => {
    const deleteTabElement = <DeleteTabIcon title={title} updateLayout={updateLayout} onlyIcon={!isAdminLayout} />;
    if (isAdminLayout && layout) {
      return <TabHeaderSettingsOverlay layout={layout}> {deleteTabElement}</TabHeaderSettingsOverlay>;
    }
    return deleteTabElement;
  }, [isAdminLayout, title, layout]);

  return (
    <div className="flex h-full flex-row items-center px-2 py-1">
      {isEditable ? (
        <>
          <span title={layout.isUserLayout ? t('privateDropOnly') : t('publicDropOnly')} className="flex max-w-min">
            <i className="ri-more-2-line w-1 not-italic" />
            <i className="ri-more-2-line w-4 not-italic" />
          </span>
          {inputVisible ? (
            <div className="w-48" onBlur={updateLayoutOnEscape} onKeyUp={updateLayoutOnEscape}>
              <Input
                placeholder={title}
                name={title}
                value={inputValue}
                inputStyles="m-1 rounded-md whitespace-nowrap flex items-center p-1 justify-center"
                inputRef={(el) => {
                  if (el) {
                    (el as HTMLInputElement).click();
                    (el as HTMLInputElement).focus();
                  }
                }}
                onChangeEvent={(event) => {
                  setInputValue(event.target.value);
                }}
              />
            </div>
          ) : (
            titleElement
          )}
          <div
            className="p-1 pl-2"
            id={editIconId}
            data-id={layoutId}
            data-pr-at={title}
            onClick={() => {
              setInputVisible(!inputVisible);
              setTabInEditMode(!inputVisible);
            }}
          >
            <OdinIcon icon="Pencil" size={OdinIconSize.ExtraSmall} />
          </div>
          {settingsDelete}
        </>
      ) : (
        titleElement
      )}
    </div>
  );
};
