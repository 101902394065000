export const statusColor = [
  '#FFC350',
  '#21635b',
  '#029387',
  '#214a63',
  '#afb790',
  '#a3d9ff',
  '#adb034',
  '#52da3e',
  '#f7af9d',
  '#FFA820',
  '#e64a19',
  '#0e4b8c',
  '#8c600e',
  '#7bad16',
  '#b60d4e',
  '#4b1354',
  '#af2dc4',
];
