import { ToolTipElement } from '../../shared/widget-data.interfaces';
import { labelListStyle } from '../../constants/line-label-styles';
import React from 'react';
import { FieldDataProps } from '../../data-transformer/column-chart/column-chart-interfaces';

/**
 * @return {number} value or null - display only values that are fully divided by index if we have a lot of data rendering
 *                                  display for all the other indexes return null
 */
const CustomizedLabel = (transformedData: Array<FieldDataProps>, { x, y, value, index }: ToolTipElement) => {
  const transformedDataLength = transformedData && transformedData?.length;

  return (
    <text x={x} y={y} dy={-10} style={labelListStyle} textAnchor="middle">
      {index !== undefined &&
      transformedDataLength &&
      (transformedDataLength < 60 ||
        (transformedDataLength > 60 && index % 2 === 0) ||
        (transformedDataLength > 60 && transformedDataLength < 70 && index % 2 === 0) ||
        (transformedDataLength > 70 && transformedDataLength < 85 && index % 4 === 0) ||
        (transformedDataLength > 85 && index % 5 === 0))
        ? value
        : null}
    </text>
  );
};

export default CustomizedLabel;
