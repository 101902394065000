import React from 'react';
import {
  DropDown,
  DynamicFormRequiredType,
  isDropDownNoSelection,
  isDropDownResult,
  OdinController,
} from '@myosh/odin-components';
import { t } from 'i18next';
import { WidgetValueAndSetter } from '../../types/widget-value-and-setter';

export const WidgetFieldComponent = ({
  value,
  setValue,
  fieldData,
  widgetType,
  control,
}: WidgetValueAndSetter<string>) => {
  return (
    <div className="my-1">
      <OdinController
        name="field"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={widgetType?.type.typeValue === 'CROSS_TAB_REPORT' ? t('fieldYAxis') : t('field')}
            required={DynamicFormRequiredType.True}
            data={fieldData}
            textField="field"
            valueField="field"
            onChange={(value) => {
              if (isDropDownResult(value)) {
                setValue(value.text);
                onChange(value);
              } else if (isDropDownNoSelection(value)) {
                setValue(undefined);
                onChange(value);
              }
            }}
            value={value ? { text: value, value: value } : undefined}
          />
        )}
      />
    </div>
  );
};
