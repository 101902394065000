import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { OdinController, RadioGroup } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { RadioGroupResult } from '@myosh/odin-components/dist/types/components/fields/radio-group/radio-group.component';
import { LabelFormatType } from '../widget-settings-dialog';

export interface DecimalConfigProps {
  labelDecimals?: string | number | undefined;
  labelFormat?: LabelFormatType[];
  control: Control;
  pieChartDecimal?: boolean;
  nearestNumber?: boolean;
  label?: string;
  name?: string;
}

const DecimalConfiguration = ({
  labelDecimals,
  labelFormat,
  control,
  pieChartDecimal,
  nearestNumber,
  label,
  name = 'labelDecimals',
}: DecimalConfigProps) => {
  const [showSelectedOptions, setSelectedOptions] = useState<RadioGroupResult>();

  const decimalOptions = useMemo(() => {
    const options = [
      {
        position: 1,
        text: t('oneDecimalPlace'),
        value: 'one',
      },
      {
        position: 2,
        text: t('twoDecimalPlace'),
        value: 'two',
      },
    ];
    if (nearestNumber) {
      options.unshift({
        position: 0,
        text: t('roundedToWholeNumber'),
        value: 'roundedToNearestWholeNumber',
      });
    }
    return options;
  }, [nearestNumber]);

  useEffect(() => {
    setSelectedOptions(
      decimalOptions.find((el) => {
        if (el.position === labelDecimals) {
          return {
            value: el.value,
            text: el.value,
          };
        }
      })
    );
  }, [labelDecimals]);

  const labelReadOnlyValue = useMemo(() => {
    return labelFormat?.length === 1 && labelFormat[0].value === 'number';
  }, [labelFormat]);

  const configSettings = useMemo(() => {
    return {
      style: `${pieChartDecimal ? 'pl-6 pb-1.5' : 'my-2 pt-2'}`,
      label: pieChartDecimal ? undefined : label,
    };
  }, [pieChartDecimal]);

  return (
    <div className={`flex flex-col ${configSettings.style}`}>
      <OdinController
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <RadioGroup
            id={`${name}Option`}
            name={`${name}Option`}
            data={decimalOptions}
            label={configSettings.label}
            value={showSelectedOptions}
            onChange={onChange}
            readOnly={labelReadOnlyValue}
            orientation="VERTICAL"
            textField="text"
            valueField="value"
          />
        )}
      />
    </div>
  );
};

export default DecimalConfiguration;
