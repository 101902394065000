import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { genericSort, SortDirection } from '../../components/Charts/generalFunctions/general-functions';
import { ChartColumnResponseProps } from './column-chart-interfaces';
import { isNil } from 'lodash';

export class SortByNameColumnChart
  implements IDataCommand<ChartColumnResponseProps[], WidgetConfigurationData, ChartColumnResponseProps[]>
{
  process(data: ChartColumnResponseProps[], config: WidgetConfigurationData) {
    const sortDirection = getSortDirection(config);
    if (!isNil(sortDirection)) {
      return genericSort(data, 'name', sortDirection);
    }
    return data;
  }
}

export const getSortDirection = (config: WidgetConfigurationData): SortDirection | undefined => {
  const widgetData = config.widgetData;
  if (widgetData) {
    if (widgetData.ascendingSort === false && widgetData.timeFrequency !== 'MONTHLY') {
      return SortDirection.Descending;
    }
    return SortDirection.Ascending;
  }
  return undefined;
};
