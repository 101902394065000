import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { ModuleFormType } from './field-types-api';

export const modulesApi = createApi({
  reducerPath: 'getModulesApi',
  baseQuery: baseQueryWithReAuth,

  endpoints: (builder) => ({
    getModules: builder.query<Array<ModuleFormType>, void>({
      query: () => ({
        url: `/modules/all`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetModulesQuery } = modulesApi;
