import { CHART_TYPE } from '../../constants/enums';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { CombinedData } from './common-data.interface';

export class CombineFirstSecondFields
  implements IDataCommand<CombinedData, WidgetConfigurationData, Array<Record<string, unknown>>>
{
  process(data: CombinedData, config: WidgetConfigurationData): Array<Record<string, unknown>> {
    const objValues: Array<Record<string, unknown>> = [];
    const emptyArray: Array<number> = [];
    data.firstFields.forEach((value, key) => {
      const newObject: Record<string, unknown> = {};
      newObject.field = key.length === 0 ? '[none]' : key;
      data.secondFields?.forEach((item: string) => {
        const retrievedItem = value.get(item);
        const { type } = config;
        switch (type) {
          case CHART_TYPE.ChartLine:
            if (retrievedItem) {
              newObject[item] = retrievedItem;
              emptyArray.push(retrievedItem);
            } else {
              newObject[item] = 0;
            }
            break;
          default:
            newObject[item] = retrievedItem;
            emptyArray.push(Number(retrievedItem));
            break;
        }
      });
      objValues.push(newObject);
    });

    return objValues;
  }
}
