import { ChartDataKPI } from '../../redux/config/chart-data';
import { DataCommandResponse, IDataCommand, IDataCommandFactory } from '../data-transformer.interface';
import { BuildColumnChartData } from './build-column-chart-data';
import { SortByNameColumnChart } from './sort-order-column-chart';
import { ChartColumnResponseProps } from './column-chart-interfaces';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getTopAmountToDisplay } from '../common-commands/get-top-items-display';
import { SortByValueReturnTop } from '../common-commands/sort-column-return-top';
import { DeleteUndefinedAttributes } from '../common-commands/delete-undefined-attributes';
import { isEmpty } from 'lodash';
import { HideBlankCategory } from '../common-commands/hide-blank-category';
import { CustomSortOrderColumnChart } from './custom-sort-order-column-chart';

export type ColumnChartType = ChartColumnResponseProps[] | ChartDataKPI;

export class ColumnChartCommandFactory implements IDataCommandFactory {
  private currentCommandIndex = 0;
  private readonly commands: Array<IDataCommand<ColumnChartType, WidgetConfigurationData, ColumnChartType>>;

  constructor(configurationData: WidgetConfigurationData) {
    this.commands = [new BuildColumnChartData()];
    if (configurationData.widgetConfig?.hideBlankCategory) {
      this.commands.push(new HideBlankCategory());
    }
    if (getTopAmountToDisplay(configurationData) !== -1) {
      this.commands.push(new SortByValueReturnTop<ChartColumnResponseProps>());
    } else if (customSortApplicable(configurationData)) {
      this.commands.push(new CustomSortOrderColumnChart());
    } else {
      this.commands.push(new SortByNameColumnChart());
    }
    this.commands.push(new DeleteUndefinedAttributes<ChartColumnResponseProps>());
  }

  getNextCommand<TIn, TInConfig, TOut>(): DataCommandResponse<TIn, TInConfig, TOut> | undefined {
    const command = this.commands[this.currentCommandIndex] as IDataCommand<TIn, TInConfig, TOut>;
    return (
      command && {
        command,
        commandIndex: this.currentCommandIndex++,
      }
    );
  }
}

const customSortApplicable = (configurationData: WidgetConfigurationData): boolean => {
  return (
    configurationData.widgetData?.timeFrequency !== 'MONTHLY' && !isEmpty(configurationData.widgetConfig?.sortOrder)
  );
};
