import React from 'react';
import { Notification } from '@myosh/odin-components';
import { useToaster } from 'react-hot-toast';
import ReactDOM from 'react-dom';

const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const createNotifications = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          display: 'flex',
          width: '100%',
          zIndex: 9999,
          flex: '0 1 auto',
          justifyItems: 'center',
        }}
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {toasts.map((toast) => {
          const offset = calculateOffset(toast, {
            reverseOrder: false,
            gutter: 8,
          });

          const ref = (el: HTMLDivElement) => {
            if (el && !toast.height) {
              const height = el.getBoundingClientRect().height;
              updateHeight(toast.id, height);
            }
          };

          return (
            <Notification
              key={toast.id}
              ref={ref}
              {...toast.ariaProps}
              className="max-w-sm"
              style={{
                position: 'absolute',
                left: '50%',
                top: '1rem',
                transition: 'all 0.5s ease-out',
                opacity: toast.visible ? 1 : 0,
                transform: `translate(-50%, ${offset}px)`,
                zIndex: 9999999,
              }}
              type={toast.type}
            >
              {String(toast.message)}
            </Notification>
          );
        })}
      </div>
    );
  };

  return ReactDOM.createPortal(createNotifications(), document.body);
};

export default Notifications;
