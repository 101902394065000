import { useEffect, useMemo, useState } from 'react';
import { BarChart, ResponsiveContainer, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Bar } from 'recharts';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';

import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { CustomXAxis } from '../common/custom-X-axis.component';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { getDomainValue } from './generalFunctions/get-domaincharts';
import { findCustomColor, includeLegend } from './generalFunctions/general-functions';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { getColorsArray } from '../../helpers/get-colors-arrays';
import { LegendPayloadOnClick } from './chart-events';
import { yAxisSettings } from '../common/y-axis-properties';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { useChartRollingFRQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinRollingFRChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useChartRollingFRQuery({
    widgetId,
    from: startDate,
    to: endDate,
    viewId: view?.id,
  });

  const selectItem = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };

  const rollingFrDomainValue = useMemo(() => {
    if (widgetChartData) {
      return getDomainValue(widgetChartData);
    }
    return 0;
  }, [widgetChartData]);

  const barName = useMemo(() => {
    return configurationData?.widgetData.rollingTRIFR.title ?? 'TRIFR';
  }, [configurationData]);

  const colors: CustomColorProps[] = useMemo(() => getColorsArray([barName], configurationData), [configurationData]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !widgetChartData?.length}
    >
      {configurationData && widgetChartData && widgetChartData.length > 0 && (
        <ResponsiveContainer height="99%">
          <BarChart
            data={widgetChartData} // need to potentially sort this data by time or alpha
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />

            <CartesianGrid vertical={false} horizontal />
            <XAxis {...xAxisSettings} interval={0} tick={CustomXAxis} />
            <YAxis {...yAxisSettings([0, rollingFrDomainValue], 'Values', -90, -40)} />
            {includeLegend(configurationData) && (
              <Legend formatter={renderLegend} wrapperStyle={defaultLegendWrapperStyle} onClick={selectItem} />
            )}
            <Bar
              name={barName}
              dataKey="value"
              barSize={80}
              fill={findCustomColor(barName, colors)}
              hide={legendProps.value}
              onMouseEnter={() => handleMouseEnter(barName)}
              onMouseLeave={handleMouseLeave}
              isAnimationActive={activateAnimation}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}
