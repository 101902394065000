import { useCallback, useEffect, useRef, useState } from 'react';
import { DropDown, DropDownResult, ModalDialog } from '@myosh/odin-components';
import { useWidgetSimplifiedConfigurationDataQuery } from '../../redux/config/widgetApi';
import { useAddLayoutWidgetsMutation } from '../../redux/config/layout';
import { JsonData } from '@myosh/odin-components/dist/types/components/generic-types';
import { useSelector } from 'react-redux';
import { selectActiveTabId } from '../../redux/slices/active-layout-tab-id-slice';
import { t } from 'i18next';
import { v4 } from 'uuid';
import toast from 'react-hot-toast';
interface WidgetAdderProps {
  visible: boolean;
  hidden: () => void;
  setShowAddWidget: (visible: boolean) => void;
}

const CustomWidgetsAdderComponent = ({ visible, setShowAddWidget, hidden }: WidgetAdderProps) => {
  const [displayModalWindow, setDisplayModalWindow] = useState<boolean>();
  const toastId = useRef<string>(v4());
  const lastWidgetPosition = useRef<number>(0);
  const mounted = useRef<boolean>(false);
  const activeTabId = useSelector(selectActiveTabId);
  const [addLayoutWidgets] = useAddLayoutWidgetsMutation();
  const { data: widgetSimplifiedData } = useWidgetSimplifiedConfigurationDataQuery(
    {},
    {
      skip: !visible,
    }
  );

  useEffect(() => {
    if (visible === false) {
      setDisplayModalWindow(false);
    } else if (activeTabId) {
      //this is needed to take into account removed from layout widgets
      lastWidgetPosition.current =
        document.getElementById(`mainWidgetCard-${activeTabId}`)?.getElementsByClassName('widgetCard')?.length ?? 0;
      if (lastWidgetPosition.current > 7) {
        toast.error(t('maxWidgetsTabError'), { id: toastId.current, duration: 2000 });
        setDisplayModalWindow(false);
        setShowAddWidget(false);
      } else {
        setDisplayModalWindow(true);
      }
    }
  }, [visible]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const selectSimplifiedWidget = useCallback(
    (dropDownValue: DropDownResult) => {
      if (widgetSimplifiedData) {
        const selectedValue = widgetSimplifiedData.find((el) => el.id === dropDownValue.value);

        if (selectedValue) {
          addLayoutWidgets([
            {
              layout: activeTabId,
              widget: selectedValue.id,
              position: lastWidgetPosition.current + 1,
            },
          ])
            .unwrap()
            .then(() => {
              if (mounted.current) {
                setShowAddWidget(false);
              }
            });
        }
      }
    },
    [widgetSimplifiedData, activeTabId]
  );

  return (
    <ModalDialog
      header={t('dashboardConfig')}
      visible={displayModalWindow}
      hidden={hidden}
      minDialogWidth="55vw"
      maxDialogWidth={1200}
      minDialogHeight="30vh"
      maxDialogHeight="40vh"
      transitionDuration={0}
    >
      {widgetSimplifiedData && (
        <DropDown
          data={widgetSimplifiedData as unknown as JsonData}
          label={t('availableWidgets')}
          placeholder={`${t('addWidget')}...`}
          setDefaultValue={false}
          textField="text"
          valueField="id"
          pageSize={50}
          className="h-10"
          onChange={(value) => selectSimplifiedWidget(value as DropDownResult)}
        />
      )}
    </ModalDialog>
  );
};

export default CustomWidgetsAdderComponent;
