import { ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { t } from 'i18next';

import { LoaderComponent } from './loading-spinner.component';
import { NoDataToDisplay } from './no-data-label.component';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import ChartBottomPanel from './chart-bottom-panel';
import { ErrorDataLabel } from './error-data-label.component';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import { WidgetLayoutProps } from '../Charts/chart.interface';
import { useHierarchyFilters } from '../Charts/hooks/useHierarchyFilter';
import ExportChartBottomPanel from './chart-bottom-panel-export';
import { useIsExportView } from '../Charts/hooks/useIsExportView';
import { cloneDeep, isEqual } from 'lodash';
import { useGetCustomFiltersQuery } from '../../redux/config/CustomFiltersApi';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isTableType } from './is-table-type';

export interface WidgetConfigsGenericWrapper extends WidgetConfigurationData {
  colors?: CustomColorProps[];
  layoutProps?: WidgetLayoutProps;
}

export interface GenericWrapper {
  widgetId: string;
  isLoading?: boolean;
  isFetching: boolean;
  children: ReactNode | ReactElement;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  refetch: () => void;
  widgetConfigurationData?: WidgetConfigsGenericWrapper | undefined;
  widgetChartData: unknown;
  renderNoDataByDefault?: boolean;
}

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert" className="flex h-full flex-col items-center justify-center">
      <p>{`${t('anErrorOccurred')}`}</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <p>
        <button onClick={resetErrorBoundary}>
          <u>{`${t('tryAgain')}`}</u>
        </button>
      </p>
    </div>
  );
}

export default function ChartGenericWrapper({
  widgetId,
  isLoading,
  isFetching,
  isError,
  refetch,
  children,
  widgetConfigurationData,
  widgetChartData,
  renderNoDataByDefault = false,
  error,
}: GenericWrapper) {
  // Check for updates in Hierarchy filters and refresh widget
  const hierarchies = useHierarchyFilters();
  const isMounted = useRef(false);
  const isExportView = useIsExportView();
  const originalConfigurationData = useRef<WidgetConfigsGenericWrapper | undefined>();
  const { data: customFilterData, isFetching: isFetchingCustomFilters } = useGetCustomFiltersQuery(
    { widgetId },
    { skip: !isExportView }
  );

  useEffect(() => {
    if (isMounted.current && hierarchies && widgetChartData) {
      refetch();
    } else {
      isMounted.current = true;
    }
  }, [hierarchies]);

  /**
   * Refetch data for a widget if its data settings have changed
   */
  useEffect(() => {
    if (
      isMounted.current &&
      widgetConfigurationData?.widgetData &&
      originalConfigurationData.current &&
      !isEqual(originalConfigurationData.current?.widgetData, widgetConfigurationData?.widgetData)
    ) {
      refetch();
    }
    originalConfigurationData.current = cloneDeep(widgetConfigurationData);
  }, [widgetConfigurationData?.widgetData]);

  // Grid element doesn't support taking all available space in a flex element
  // Make sure that when no scroll, the bottom panel will remain in the bottom
  const isTable = useMemo(() => {
    const { type } = widgetConfigurationData || {};
    return type && isTableType(type);
  }, [widgetConfigurationData]);

  const childClassName = useMemo(() => {
    return classNames('rendered h-full', {
      'flex flex-col overflow-hidden relative': isTable,
    });
  }, [isTable]);

  /** Table's rendering is different as we rely on grid's scroller to fetch data without unmounting component*/
  const renderTable = () => {
    if (isError) {
      return <ErrorDataLabel error={error} retry={refetch} />;
    }

    if (isLoading || isFetching) {
      return <LoaderComponent />;
    }

    if (renderNoDataByDefault) {
      return <NoDataToDisplay />;
    }

    return <div className={childClassName}>{children}</div>;
  };

  const renderWidget = () => {
    if (isError) {
      return <ErrorDataLabel error={error} retry={refetch} />;
    }

    if (!widgetChartData || isLoading || isFetching || (isExportView && isFetchingCustomFilters && !customFilterData)) {
      return <LoaderComponent />;
    }

    if (
      renderNoDataByDefault ||
      ((widgetChartData === undefined || (Array.isArray(widgetChartData) && widgetChartData?.length === 0)) &&
        !isLoading &&
        !isFetching)
    ) {
      return <NoDataToDisplay />;
    }

    return <div className={childClassName}>{children}</div>;
  };

  const renderFooter = () => {
    if (isError) {
      return <></>;
    }
    if (isExportView) {
      return (
        <ExportChartBottomPanel data={customFilterData} isFetching={isFetchingCustomFilters} widgetId={widgetId} />
      );
    }
    return (
      <ChartBottomPanel
        refetch={refetch}
        widgetId={widgetId}
        isTable={isTable}
        widgetConfiguration={widgetConfigurationData}
        widgetChartData={widgetChartData}
      />
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {isTable ? renderTable() : renderWidget()}
      {renderFooter()}
    </ErrorBoundary>
  );
}
