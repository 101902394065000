import { statusColor } from '../constants/Palette';

export const getStatusColor = (index: number, colors = statusColor): string => {
  const indexToSearch = index % colors.length;
  return colors[indexToSearch] ?? statusColor[0];
};

export const generateArrayColors = (arrayLength: number): string[] => {
  const colors = [];
  for (let i = 0; i < arrayLength; i++) {
    colors.push(getStatusColor(i));
  }
  return colors;
};

/**
 * Converts an RGB color array to a hex string.
 *
 * @param {number[]} rgb - The RGB color array
 * @returns {string} The RGB value as a hex string
 */
export const convertRgbToHex = (rgb: number[]): string => {
  if (!Array.isArray(rgb) || rgb.length !== 4) {
    throw new Error('Invalid RGB array');
  }

  let red = rgb[0].toString(16);
  let green = rgb[1].toString(16);
  let blue = rgb[2].toString(16);
  let alpha = rgb[3].toString(16);

  if (red.length === 1) red = `0${red}`;
  if (green.length === 1) green = `0${green}`;
  if (blue.length === 1) blue = `0${blue}`;
  if (alpha.length === 1) alpha = `0${alpha}`;

  return `#${red}${green}${blue}${alpha}`;
};

/**
 * Converts a decimal color value to an RGB or RGBA string.
 *
 * @param number - The decimal color value to convert.
 * @param shouldConvertToHex - Whether to return the RGB value as a hex string instead of an RGB string. Defaults to false.
 * @returns {string} An RGB or RGBA string representing the input decimal color.
 */
export const getRGBFromDecimal = (number: number, shouldConvertToHex = false): string => {
  if (typeof number !== 'number') {
    throw new Error('Number must be a number');
  }

  const red = (number >> 16) & 0xff;
  const green = (number >> 8) & 0xff;
  const blue = number & 0xff;
  const alpha = (number >> 24) & 0xff;

  if (shouldConvertToHex) {
    return convertRgbToHex([red, green, blue, alpha]);
  }

  return `rgba(${red},${green},${blue},${alpha})`;
};
