import { useMemo } from 'react';

/**
 * @param dataKey The key of data displayed in the axis.
 * @param type The type of axis.
 * @param tickLine If set false, no axis tick lines will be drawn. If set a object, the option is the configuration of tick lines. DEFAULT: true;
 * @returns An object representing the settings for an X-axis.
 */
export const useXAxisTopSettings = (
  dataKey = 'field',
  type: 'category' | 'number' = 'category',
  tickLine?: boolean
) => {
  const xAxis = useMemo(() => {
    return {
      type: type,
      dataKey: dataKey,
      baseFrequency: 5,
      axisLine: false,
      tickLine: tickLine,
      padding: { left: 20, right: 10 },
      height: 35,
      label: {
        position: 'top',
        fontSize: 10,
      },
    };
  }, [dataKey, type, tickLine]);
  return xAxis;
};
