import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isDropDownResult, DropDownResult } from '@myosh/odin-components';
import { t } from 'i18next';
import { WidgetShowConfigType } from './widget-config-type';
import WidgetCustomFilterComponent from './step-three/widget-custom-filter.component';
import WidgetGlobalFilterComponent from './step-three/widget-global-filter.componnet';
import WidgetNotBlankComponent from './step-three/widget-not-blank.component';
import { VisibleFieldType } from '../../../redux/config/field-types-api';
import { useGetFiltersQuery } from '../../../redux/config/filters-api';

export const WizardTabThird = ({ widgetConfigs, fieldTypeData, formValue }: WidgetShowConfigType) => {
  const [widgetField, setWidgetField] = useState<DropDownResult>();
  const [fieldsData, setFieldsData] = useState<Array<VisibleFieldType>>([]);
  const [globalFilters, setGlobalFilters] = useState<DropDownResult[]>();
  const { control, watch } = useFormContext();
  const watchWidgetType = watch('type');

  const fieldValue = useMemo(() => {
    if (isDropDownResult(widgetField)) {
      return widgetField.text;
    }
    if (widgetConfigs?.widgetData?.globalFilters && !widgetField) {
      return widgetConfigs?.widgetData?.globalFilters[0].id;
    }
    return widgetConfigs?.widgetData.field as string;
  }, [widgetField, widgetConfigs]);

  const { data: filtersData } = useGetFiltersQuery(
    {
      form: formValue ?? (widgetConfigs?.widgetData.forms as string[]),
      field: fieldValue,
    },
    {
      skip: !fieldValue,
    }
  );

  useEffect(() => {
    if (widgetConfigs && !watchWidgetType) {
      const widgetFiltersValue = widgetConfigs?.widgetData?.globalFilters?.map((el) => {
        return {
          value: el.id,
          text: el.id,
        };
      });
      setGlobalFilters(widgetFiltersValue);
    } else {
      setGlobalFilters(undefined);
    }
  }, [widgetConfigs, watchWidgetType]);

  useEffect(() => {
    setFieldsData(fieldTypeData ?? []);
  }, [fieldTypeData]);

  return (
    <div className="flex flex-col justify-center gap-1.5">
      <div className="my-2">{String(t('wizardStep3Description'))}</div>
      <WidgetCustomFilterComponent
        data={fieldsData}
        visibleColumns={widgetConfigs?.widgetData.customFilterField}
        control={control}
      />
      <WidgetGlobalFilterComponent
        data={fieldsData}
        setWidgetFieldValue={setWidgetField}
        control={control}
        globalFiltersValues={globalFilters}
        setGlobalFilters={setGlobalFilters}
      />
      {(widgetField || globalFilters) && (
        <WidgetNotBlankComponent
          sourceData={filtersData}
          targetData={widgetConfigs?.widgetData?.globalFilters?.[0]?.values ?? []}
        />
      )}
    </div>
  );
};
