import React from 'react';
import { Header } from '../../components/Header/Header';
import LayoutTab from '../../components/LayoutTab/LayoutTab';

export const Home = () => {
  return (
    <div className="flex h-screen w-screen flex-col">
      <Header classNames="background-body sticky top-0 left-0 z-40 w-full py-2 mt-1" />
      <LayoutTab />
    </div>
  );
};
