import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Legend,
  Tooltip,
  XAxis,
  LabelList,
  Line,
  CartesianGrid,
  YAxis,
  ResponsiveContainer,
  LineChart,
} from 'recharts';
import { useSelector } from 'react-redux';

import { DataKPI, useChartKPIQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import transformDataChartKPI from './generalFunctions/transform-data-kpi';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { intervalCounter } from './generalFunctions/general-functions';
import useStartEndDates from './hooks/useStartEndDates';
import { DataExecutor } from '../../data-transformer/data-executor';
import { DeleteUndefinedAttributes } from '../../data-transformer/common-commands/delete-undefined-attributes';
import { ChartProps } from './chart.interface';
import { XAxisLineChart } from '../common/custom-X-axis.component';
import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { LegendPayloadOnClick } from './chart-events';
import { useKpiConfigs } from './hooks/useKPIConfigs';
import { useTRIFRConfigs } from './hooks/useRollingTRIFRConfigs';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import { labelListStyle } from '../../constants/line-label-styles';
import { useKPIDomainValue } from './hooks/useKPIDomainValue';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';
import { useFRConfigs } from './hooks/useRollingFRConfigs';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { getStatusColor } from '../../helpers/getStatusColor';
import { useSelectedView } from './hooks/use-selected-view';
import { getDotWithColor } from '../common/line-dot-base-props';

export default function OdinChartKPI({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [legendProps, setLegendProps] = useState<Record<string | keyof DataKPI, boolean>>({});
  const [transformedData, setTransformedData] = useState<DataKPI[]>();
  const [colors, setCustomColors] = useState<CustomColorProps[]>([]);
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const kpiConfigs = useKpiConfigs(configurationData);
  const trifrConfig = useTRIFRConfigs(configurationData);
  const frConfig = useFRConfigs(configurationData);
  const xAxisSettings = useXAxisTopSettings('name');
  const view = useSelectedView();

  const widgets = useSelector(getWidgets);

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useChartKPIQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    const commandExecutor = new DataExecutor();
    if (configurationData && widgetChartData) {
      const transformedDataResponse = transformDataChartKPI(configurationData, widgetChartData);
      const removedUndefinedResponse = commandExecutor.execute(
        new DeleteUndefinedAttributes<DataKPI>(),
        transformedDataResponse,
        configurationData
      );
      setTransformedData(removedUndefinedResponse);
    }
  }, [widgetChartData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) => {
    const key: string = (e.dataKey as string) || (e.value as string) || '';
    setLegendProps({
      ...legendProps,
      [key]: !legendProps[key],
    });
  };

  useMemo(() => {
    const newColors: CustomColorProps[] = [];
    if (kpiConfigs.available) {
      newColors.push({ label: kpiConfigs.caption, color: kpiConfigs.lineColor });
    }
    if (trifrConfig.available) {
      newColors.push({ label: trifrConfig.caption, color: trifrConfig.lineColor });
    }
    setCustomColors(newColors);
  }, [kpiConfigs, trifrConfig]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const domainScale: [number, number] | undefined = useKPIDomainValue(configurationData, transformedData);
  const ticks: number[] | undefined = useTicksBasedOnScale(domainScale);

  const getLabelList = useCallback(
    (dataKey: string) => <LabelList dataKey={dataKey} position="top" dy={-5} style={labelListStyle} />,
    []
  );

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <ResponsiveContainer height="99%">
          <LineChart
            data={transformedData}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20,
            }}
            layout="horizontal"
            id={`${widgetId}`}
          >
            <Tooltip content={<CustomToolTip />} cursor={false} isAnimationActive={false} />
            <>
              <CartesianGrid vertical={false} horizontal strokeDasharray={undefined} />
              <XAxis {...xAxisSettings} interval={intervalCounter<DataKPI>(transformedData)} tick={XAxisLineChart} />
              <YAxis
                type="number"
                domain={domainScale}
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
                label={{
                  value: 'Frequency Rate',
                  angle: -90,
                  dx: -40,
                  position: 'center',
                }}
                tickFormatter={compactNumberFormatter}
                tick
                ticks={ticks}
                allowDataOverflow
              />
              <Legend
                formatter={renderLegend}
                verticalAlign="bottom"
                wrapperStyle={defaultLegendWrapperStyle}
                onClick={selectItem}
              />

              {/* KPI */}
              {kpiConfigs.available && widgetChartData.kpi && (
                <Line
                  name={kpiConfigs.caption}
                  connectNulls
                  type="linear"
                  dataKey="value_KPI"
                  strokeWidth={2}
                  stroke={kpiConfigs.lineColor}
                  fill={kpiConfigs.lineColor}
                  hide={legendProps.value_KPI}
                  isAnimationActive={activateAnimation}
                  dot={getDotWithColor(kpiConfigs.lineColor)}
                  onMouseEnter={() => handleMouseEnter(kpiConfigs.caption)}
                  onMouseLeave={handleMouseLeave}
                >
                  {getLabelList('value_KPI')}
                </Line>
              )}

              {/* rollingTRIFR */}
              {trifrConfig.available && (
                <Line
                  name={trifrConfig.caption}
                  type="linear"
                  dataKey="value_TRIFR"
                  strokeWidth={2}
                  stroke={trifrConfig.lineColor}
                  fill={trifrConfig.lineColor}
                  hide={legendProps.value_TRIFR}
                  isAnimationActive={activateAnimation}
                  dot={getDotWithColor(trifrConfig.lineColor)}
                  onMouseEnter={() => handleMouseEnter(trifrConfig.caption)}
                  onMouseLeave={handleMouseLeave}
                >
                  {trifrConfig.displayValues && getLabelList('value_TRIFR')}
                </Line>
              )}

              {/* rollingFR */}
              {widgetChartData.rollingFR &&
                widgetChartData.rollingFR.map((item, index) => (
                  <Line
                    key={index}
                    name={frConfig.legend[index]}
                    type="linear"
                    dataKey="value_FR"
                    strokeWidth={2}
                    stroke={frConfig.colors[index]}
                    fill={frConfig.colors[index]}
                    hide={legendProps.value_FR}
                    isAnimationActive={activateAnimation}
                    dot={getDotWithColor(frConfig.colors[index])}
                    onMouseEnter={() => handleMouseEnter(frConfig.legend[index])}
                    onMouseLeave={handleMouseLeave}
                  >
                    {frConfig.displayValues && getLabelList('value_FR')}
                  </Line>
                ))}

              {/* rollingSiteTRIFR */}
              {widgetChartData.rollingSiteTRIFR &&
                Object.keys(widgetChartData.rollingSiteTRIFR).map((item, index: number) => (
                  <Line
                    key={item}
                    name={item}
                    dataKey={item}
                    strokeWidth={2}
                    type="linear"
                    stroke={getStatusColor(index)}
                    fill={getStatusColor(index)}
                    hide={legendProps[item]}
                    isAnimationActive={activateAnimation}
                    dot={getDotWithColor(getStatusColor(index))}
                  >
                    {getLabelList(item)}
                  </Line>
                ))}
            </>
          </LineChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}
