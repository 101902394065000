import { ChartResponse } from '../../../redux/config/chart-data';
import { getValueOrDefault } from '../../../helpers/getvalueOrDefault';

export function transformBiDimensionalLineChart(data: Array<ChartResponse>) {
  return data?.map((item: ChartResponse) => {
    return {
      field: getValueOrDefault(item.field, '[none]'),
      value: Number(item.value),
    };
  });
}
