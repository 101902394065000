import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { HideBarChartBlankCategories } from '../column-line-chart/data-commands/hide-bar-chart-blank-categories';
import { SortByCustomSorting } from '../column-line-chart/data-commands/sort-by-custom-sorting';
import { DeleteUndefinedAttributes } from '../common-commands/delete-undefined-attributes';
import { getTopAmountToDisplay } from '../common-commands/get-top-items-display';
import { SortByField } from '../common-commands/sort-by-field';
import { SortByValueReturnTop } from '../common-commands/sort-column-return-top';
import { DataCommandResponse, IDataCommand, IDataCommandFactory } from '../data-transformer.interface';

export class BarChartSimpleCommandFactory implements IDataCommandFactory {
  private currentCommandIndex = 0;
  private readonly commands: Array<IDataCommand<ChartResponse[], WidgetConfigurationData, ChartResponse[]>>;

  constructor(configurationData: WidgetConfigurationData) {
    this.commands = [new HideBarChartBlankCategories()];
    if (getTopAmountToDisplay(configurationData) !== -1) {
      this.commands.push(new SortByValueReturnTop());
    } else if (configurationData.widgetConfig?.sortOrder) {
      this.commands.push(new SortByCustomSorting());
    } else {
      this.commands.push(new SortByField<ChartResponse[]>());
    }

    this.commands.push(new DeleteUndefinedAttributes());
  }

  getNextCommand<TIn, TInConfig, TOut>(): DataCommandResponse<TIn, TInConfig, TOut> | undefined {
    const command = this.commands[this.currentCommandIndex] as IDataCommand<TIn, TInConfig, TOut>;
    return (
      command && {
        command,
        commandIndex: this.currentCommandIndex++,
      }
    );
  }
}
