import { ContinuousScrollerSort, DataGridColumnSettings, JsonData } from '@myosh/odin-components';
import compareDesc from 'date-fns/compareDesc';
import compareAsc from 'date-fns/compareAsc';

const customTableColumnSorter = (
  data: JsonData,
  sorts: Array<ContinuousScrollerSort>,
  columns: Array<DataGridColumnSettings>
): JsonData => {
  const sortedData = [...data];

  sorts.forEach((sort) => {
    const { field, sortDirection } = sort;
    const columnType = columns.find((column: DataGridColumnSettings) => column.field === field)?.customDataProperties
      ?.columnType;

    sortedData.sort((a, b) => {
      let field1: string | number | Date;
      let field2: string | number | Date;

      // TODO: Add sort for Mixed Types, if needed.

      // If field is all Dates
      if (columnType === 'dates') {
        field1 = new Date(a[field] as string);
        field2 = new Date(b[field] as string);
        return sortDirection === 0 ? compareAsc(field1, field2) : compareDesc(field1, field2);
      }

      // If field is all Text
      if (columnType === 'string' && (Number.isNaN(Number(a[field])) || Number.isNaN(Number(b[field])))) {
        field1 = a[field] as string;
        field2 = b[field] as string;
        return sortDirection === 0 ? field1.localeCompare(field2) : field2.localeCompare(field1);
      }

      // Else if Numbers
      field1 = Number(a[field] as string);
      field2 = Number(b[field] as string);

      if (field1 < field2) {
        return sortDirection === 0 ? -1 : 1;
      }

      if (field1 > field2) {
        return sortDirection === 0 ? 1 : -1;
      }

      return 0;
    });
  });

  return sortedData;
};

export default customTableColumnSorter;
