import { useEffect, useState, forwardRef, Ref } from 'react';
import { TabView, TabViewProps, TabViewRef } from '@myosh/odin-components';

type DashboardTabViewType = TabViewProps & {
  isPublicTab?: boolean;
  isEditing?: boolean;
};

const DashboardTabView = (
  { styles, children, id, isPublicTab = true, isEditing = false, onTabChange }: DashboardTabViewType,
  ref?: Ref<TabViewRef>
) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (Array.isArray(children) && 'mainTabsLayout' === id) {
      if (activeTabIndex >= children.length && children.length > 0) {
        setActiveTabIndex(0);
      }
    }
  }, [children]);

  return children ? (
    <TabView
      styles={{
        tabStyles: `mr-4 cursor-pointer text-gray-3 hover:text-primary-1 font-medium tabElement ${
          isEditing ? 'is-editing' : ''
        }`,
        activeTabStyles: `font-medium text-base text-primary-2 border-b-2 border-solid border-primary-2 is-active ${
          isPublicTab ? 'is-public' : 'is-private'
        }`,
        inactiveTabStyles: 'border-none',
        //there is bug in odin components on how styles are set, so adding this class name in case the bug is fixed to be back compatible
        scrollerBorderStyles: 'elementNavigatorStyles',
        ...styles,
      }}
      activeIndex={activeTabIndex}
      onTabChange={(index) => {
        setActiveTabIndex(index);
        onTabChange?.(index);
      }}
      id={id}
      ref={ref}
    >
      {children}
    </TabView>
  ) : null;
};

export default forwardRef(DashboardTabView);
