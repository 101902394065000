import React, { useEffect, useState } from 'react';
import { PickList } from 'primereact/picklist';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import { t } from 'i18next';
import { PickListChangeEvent } from '../../../WidgetSettingWindow/Tabs/data-columns-setting-tab';
import { VisibleFieldType } from '../../../../redux/config/field-types-api';

export interface WidgetCustomFilterProps {
  data: Array<VisibleFieldType>;
  control: Control;
  visibleColumns?: Array<string>;
}

const WidgetCustomFilterComponent = ({ data, visibleColumns, control }: WidgetCustomFilterProps) => {
  const [sourceColumnValue, setSourceColumnValue] = useState<Array<string>>([]);
  const [defaultColumnValue, setDefaultColumnValue] = useState<Array<string>>([]);

  useEffect(() => {
    if (data) {
      const currentData = data.map((field) => field.field).filter((val) => !visibleColumns?.includes(val as string));
      if (currentData) {
        setSourceColumnValue(currentData as string[]);
      }
    }
    if (visibleColumns) {
      setDefaultColumnValue(visibleColumns);
    }
  }, [data, visibleColumns]);

  const onChangeEventHandler = (event: PickListChangeEvent) => {
    setSourceColumnValue(event.source);
    setDefaultColumnValue(event.target);
  };

  return (
    <>
      <span className="whitespace-pre-wrap	text-sm	font-normal	italic">{String(t('customFilter'))}</span>
      <OdinController
        name="customFilterField"
        control={control}
        render={({ field: { onChange } }) => (
          <PickList
            source={sourceColumnValue}
            target={defaultColumnValue}
            onChange={(event) => {
              onChange(event.target);
              onChangeEventHandler(event);
            }}
            sourceStyle={{ height: '30rem' }}
            targetStyle={{ height: '30rem' }}
            className="picklist"
            showSourceControls={false}
            showTargetControls={false}
          />
        )}
      />
    </>
  );
};

export default WidgetCustomFilterComponent;
