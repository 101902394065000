import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export const themeApi = createApi({
  reducerPath: 'theme',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, { ...extraOptions, apiType: 'apiV4Url' }),
  tagTypes: ['Theme'],
  endpoints: (builder) => ({
    getTheme: builder.query<Record<string, string>, void>({
      query: () => {
        return {
          url: `theme`,
          method: 'GET',
        };
      },
      providesTags: ['Theme'],
    }),
  }),
});

export const { useGetThemeQuery } = themeApi;
