import { useRef } from 'react';
import { DropDown, DropDownResult, isDropDownResult, JsonDataItem, OdinController } from '@myosh/odin-components';
import { t } from 'i18next';

import { getTranslatedWidgetFrequencyTypes } from '../../types/widget-type';
import { WidgetValueAndSetter } from '../../types/widget-value-and-setter';

const WidgetTimeFrequency = ({ value, setValue, control }: Partial<WidgetValueAndSetter<DropDownResult>>) => {
  const values = useRef<Array<JsonDataItem>>(getTranslatedWidgetFrequencyTypes());

  return (
    <div className="my-1">
      <OdinController
        name="timeFrequency"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={t('timeFrequency')}
            data={values.current}
            textField="name"
            valueField="type"
            onChange={(value) => {
              if (isDropDownResult(value) && setValue) {
                setValue({
                  value: value.value,
                  text: value.text,
                });
                onChange(value);
              }
            }}
            value={value && { value: value.value, text: value.text }}
          />
        )}
      />
    </div>
  );
};

export default WidgetTimeFrequency;
