import { OdinDataSort } from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getFilters } from './get-filters';
import { baseQueryWithReAuth } from './api';

import type { BasicDataChartResponse, ChartQueryParameters } from './chart-data';
import type { DetailsWindowQueryParameters } from './details-window';
import type {
  CrossTabReportProps,
  DetailsWindowParameters,
  FilteredReponse,
  GroupedTableType,
  ManagementTableProps,
  TableDataColumn,
  TableDataResponse,
  TableFiltersParameters,
} from './table-data.interfaces';
import { getWidgetIdFromUrl } from '../../helpers/general-helpers';
import { appendQueryParameters } from './utils';

export interface TableQueryParameters extends ChartQueryParameters {
  includeHeader?: boolean;
  page?: number;
  pageSize?: number;
  sortedFields?: Record<string, OdinDataSort>;
}

export const tableDataApi = createApi({
  reducerPath: 'table-data',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'RegularTableData',
    'InjuryFreeDaysData',
    'ManagementTableData',
    'CrossTabReportData',
    'RollingTrifrData',
    'TableRate',
    'InjuryNumberSiteTable',
    'TableHeader',
  ],
  endpoints: (builder) => ({
    injuryFreeTable: builder.query<Array<BasicDataChartResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/table-injury-free-days/${args.widgetId}`;
        url = appendQueryParameters(url, args);
        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) =>
        result
          ? [
              ...result.map((item) => ({ type: 'InjuryFreeDaysData', id: item.field as string } as const)),
              { type: 'InjuryFreeDaysData', id: `LIST-${widgetId}` } as const,
            ]
          : [{ type: 'InjuryFreeDaysData', id: `LIST-${widgetId}` } as const],
    }),
    tableData: builder.query<Array<Record<string, unknown>>, DetailsWindowQueryParameters>({
      query: (args) => {
        let url = `/data/table/${args.widgetId}`;
        url = appendQueryParameters(url, args);
        if (args.page) {
          url += `&page=${args.page}`;
        }
        if (args.pageSize) {
          url += `&pageSize=${args.pageSize}`;
        }
        if (args.sortedFields) {
          const sortField = Object.keys(args.sortedFields);
          if (sortField.length >= 1) {
            const sortOrder = args.sortedFields[sortField[0]].direction.toUpperCase();
            // the data can only be sorted by one field at a time
            url += `&sort=${sortField[0]}&orderBy=${sortOrder}`;
          }
        }
        if (args.fieldFilters) {
          url = `${url}${getFilters(args.fieldFilters)}`;
        }
        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) =>
        Array.isArray(result)
          ? [
              ...result?.map((item) => ({ type: 'RegularTableData', id: item.recordId as string } as const)),
              { type: 'RegularTableData', id: `LIST-${widgetId}` } as const,
            ]
          : [{ type: 'RegularTableData', id: `LIST-${widgetId}` } as const],
    }),
    tableHeader: builder.query<Array<TableDataColumn>, DetailsWindowQueryParameters>({
      query: (args) => {
        const url = `/data/table/${args.widgetId}/header`;
        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'TableHeader', id: `${widgetId}` } as const],
    }),
    tableRollingTrifrData: builder.query<TableDataResponse, DetailsWindowQueryParameters>({
      query: (args) => {
        let url = `/data/table-rolling-trifr/${args.widgetId}`;

        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) =>
        result?.row
          ? [
              ...result?.row?.map((item) => ({ type: 'RollingTrifrData', id: item.recordId as string } as const)),
              { type: 'RollingTrifrData', id: `LIST-${widgetId}` },
            ]
          : [{ type: 'RollingTrifrData', id: `LIST-${widgetId}` }],
    }),
    managementTableData: builder.query<ManagementTableProps, TableQueryParameters>({
      query: (args) => {
        let url = `/data/management-table/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ManagementTableData', id: `LIST-${widgetId}` } as const],
    }),
    crossTabReportTableData: builder.query<CrossTabReportProps, ChartQueryParameters>({
      query: (args) => {
        let url = `data/cross-tab-report/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'CrossTabReportData', id: `LIST-${widgetId}` } as const],
    }),
    tableRate: builder.query<TableDataResponse, DetailsWindowQueryParameters>({
      query: (args) => {
        let url = `/data/table-rate/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) =>
        result?.row
          ? [
              ...result?.row?.map((item) => ({ type: 'TableRate', id: item.recordId as string } as const)),
              { type: 'TableRate', id: `LIST-${widgetId}` },
            ]
          : [{ type: 'TableRate', id: `LIST-${widgetId}` }],
    }),
    tableFilters: builder.query<FilteredReponse[], TableFiltersParameters>({
      query: (args) => ({
        url: `/widgets/${args.widgetId}/table-filters`,
        method: 'GET',
      }),
      transformResponse: (data: DetailsWindowParameters[]) => {
        return data.map((item) => {
          const values = item.potentialValues?.map((el) => {
            return {
              id: el,
              title: el,
              field: el,
            };
          });
          return {
            fieldId: item.field,
            values,
            ...item,
          };
        });
      },
    }),
    injuryNumberSiteTable: builder.query<GroupedTableType, ChartQueryParameters>({
      query: (args) => {
        let url = `${args.url}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { url }) => [{ type: 'InjuryNumberSiteTable', id: getWidgetIdFromUrl(url) }],
    }),
  }),
});

export const {
  useInjuryFreeTableQuery,
  useTableDataQuery,
  useTableHeaderQuery,
  useManagementTableDataQuery,
  useCrossTabReportTableDataQuery,
  useTableFiltersQuery,
  useTableRollingTrifrDataQuery,
  useTableRateQuery,
  useInjuryNumberSiteTableQuery,
} = tableDataApi;
