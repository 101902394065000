export const staticChartKeys = [
  'field',
  'color',
  'name',
  'value',
  'value_TRIFR',
  'value_KPI',
  'value_FR',
  'value_column',
];
