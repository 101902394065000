import { ManagementDataColumnProps } from '../../redux/config/table-data.interfaces';
import { FrResponse, TrifrResponse } from './compared-fr-line.interface';

export const frData = (data: FrResponse) => {
  const currentData = Object.values(data.Current);
  const previousData = Object.values(data.Previous);

  // If either current/previous data is undefined above, set values as empty array
  // otherwise get first index
  const currentValues = currentData ? currentData[0] : ([] as ManagementDataColumnProps[]);
  const previousValues = previousData ? previousData[0] : ([] as ManagementDataColumnProps[]);

  return updatedData(currentValues, previousValues, 'fr');
};

export const trifrData = (data: TrifrResponse) => {
  const currentValues = Object.values(data.Current);
  const previousValues = Object.values(data.Previous);
  return updatedData(currentValues, previousValues, 'trifr');
};

/**
 * Updates the data format by combining current and previous data.
 * @param currentData - Current data array.
 * @param previousData - Previous data array.
 * @param prefix - Prefix to be used in the field properties.
 * @returns Updated data array.
 */

const updatedData = (
  currentData: ManagementDataColumnProps[],
  previousData: ManagementDataColumnProps[],
  prefix: string
) => {
  const result = [];
  for (let i = 0; i < currentData.length; i++) {
    result.push({
      field: currentData[i].field,
      [`${prefix}Current`]: currentData?.[i]?.value || '0.0',
      [`${prefix}Previous`]: previousData?.[i]?.value || '0.0',
    });
  }
  return result;
};
