import { useMemo } from 'react';
import { Checkbox, DropDownResult, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { t } from 'i18next';
import { isEmpty } from 'lodash';

import CustomColorsSortingConfiguration from '../components/CustomColorsConfiguration';
import LegendConfigurationElement from '../components/LegendConfiguration';
import LabelConfiguration from '../components/LabelConfiguration';
import LabelPlacement from '../components/LabelPlacement';
import DecimalConfiguration from '../components/DecimalConfiguration';
import DataCategoriesToShowConfiguration from '../components/DataCategoriesToShowConfiguration';
import { useWidgetConfigs } from '../hooks/useWidgetConfigs';
import PieChartLabelConfiguration from '../components/PieChartLabelDisplay';
import ColorCustomisationConfiguration from '../components/ColorCustomisationConfiguration';
import DisplayRollingLineValues from '../components/DisplayRollingLineValues';

import { WidgetConfigsGenericWrapper } from '../../common/chart-generic-wrapper';
import { getWidgetConfigurationSettings, SupportedWidgetSettings } from '../../../constants/widget-configuration';
import { CHART_TYPE } from '../../../constants/enums';
import { WidgetTypeValue } from '../../TopMenu/types/widget-type';
import { LabelFormatType } from '../widget-settings-dialog';

type ConfigurationTabProps = {
  widgetConfiguration: WidgetConfigsGenericWrapper;
  control: Control;
  watchLabelFormat?: LabelFormatType[];
  setColors: (data: Record<string, string> | undefined) => void;
  setDefaultColor?: (data: string | undefined) => void;
  setSecondColor?: (data: string | undefined) => void;
  setSortOrder: (sortOrder?: string[]) => void;
  showLineLabel?: boolean;
  showDecimalPlaces?: boolean;
  showCustomSorting?: boolean;
  watchWidgetType?: DropDownResult;
  sortOrder?: Array<string>;
};

export default function ConfigurationTabContent({
  widgetConfiguration,
  control,
  watchLabelFormat,
  setColors,
  setSortOrder,
  setDefaultColor,
  setSecondColor,
  watchWidgetType,
  sortOrder,
  showLineLabel = true,
  showDecimalPlaces = true,
  showCustomSorting = false,
}: ConfigurationTabProps) {
  const configs = useWidgetConfigs(widgetConfiguration);

  const chartSettings: SupportedWidgetSettings | undefined = useMemo(() => {
    if ((watchWidgetType?.value as unknown as WidgetTypeValue)?.typeValue as CHART_TYPE) {
      return getWidgetConfigurationSettings(
        (watchWidgetType?.value as unknown as WidgetTypeValue)?.typeValue as CHART_TYPE
      );
    } else if (
      ((watchWidgetType?.value as unknown as WidgetTypeValue)?.typeValue as CHART_TYPE) ||
      (null === null && widgetConfiguration?.type)
    ) {
      return getWidgetConfigurationSettings(widgetConfiguration.type);
    }
  }, [widgetConfiguration?.type, (watchWidgetType?.value as unknown as WidgetTypeValue)?.typeValue as CHART_TYPE]);
  // Show custom color configuration settings if one of the fields(defaultColor, secondColor, threshold or calculationType === 'sum') exists in the widget data configuration
  const showColorCustomConfig = useMemo(() => {
    if (widgetConfiguration) {
      const { widgetConfig } = widgetConfiguration;
      return (
        widgetConfig?.defaultColor ||
        widgetConfig?.secondColor ||
        widgetConfig?.threshold ||
        widgetConfiguration.widgetData?.calculationType === 'sum'
      );
    }
  }, [widgetConfiguration]);

  const keepLabelOptions = useMemo(() => {
    if (widgetConfiguration) {
      return widgetConfiguration?.type === 'CHART_COLUMN'
        ? widgetConfiguration.widgetData?.calculationType === 'avg'
        : true;
    }
    return true;
  }, [widgetConfiguration]);

  if (isEmpty(chartSettings)) {
    return <p>No available configurations.</p>;
  }

  return (
    <>
      {chartSettings.LEGEND && <LegendConfigurationElement config={widgetConfiguration} control={control} />}
      {chartSettings.LABEL_DISPLAY && showLineLabel && keepLabelOptions && <LabelConfiguration control={control} />}
      {chartSettings.LABEL_PLACEMENT && <LabelPlacement option={configs.labelPlacement} control={control} />}
      {chartSettings.DECIMAL_PLACES && showDecimalPlaces && keepLabelOptions && (
        <DecimalConfiguration
          labelDecimals={configs.labelDecimals}
          labelFormat={watchLabelFormat}
          control={control}
          nearestNumber={true}
          label={t('decimalPlaces')}
        />
      )}
      {chartSettings.LABEL_CONFIG_PIE && (
        <PieChartLabelConfiguration
          labelDecimals={configs.labelDecimals}
          watchLabelFormat={watchLabelFormat}
          control={control}
        />
      )}
      {chartSettings.DATA_CATEGORIES_TO_SHOW && (
        <DataCategoriesToShowConfiguration showTop={configs.showTop || 'all'} control={control} />
      )}
      {(chartSettings.CUSTOM_COLOR || chartSettings.CUSTOM_SORTING) &&
        widgetConfiguration?.type !== 'CHART_PIE' &&
        widgetConfiguration?.type !== 'CHART_LINE' &&
        showCustomSorting && (
          <div className="py-2">
            <OdinController
              name="customSorting"
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  value="true"
                  label={t('enableCustomSorting')}
                  name="customSorting"
                  onChange={onChange}
                  initialChecked={widgetConfiguration?.widgetData?.disableAutoSort ?? false}
                />
              )}
            />
          </div>
        )}

      {(chartSettings.CUSTOM_COLOR || chartSettings.CUSTOM_SORTING) && (
        <CustomColorsSortingConfiguration
          widgetConfiguration={widgetConfiguration}
          sortOrder={sortOrder}
          control={control}
          setColors={(colors) => setColors(colors)}
          setOrders={chartSettings.CUSTOM_SORTING ? (sortOrder) => setSortOrder(sortOrder) : undefined}
        />
      )}
      {chartSettings.COLOUR_CUSTOMISATION && showColorCustomConfig && (
        <ColorCustomisationConfiguration
          widgetConfiguration={widgetConfiguration}
          control={control}
          setDefaultColor={setDefaultColor}
          setSecondColor={setSecondColor}
        />
      )}
      {chartSettings.DISPLAY_ROLLING_LINE_VALUES && (
        <DisplayRollingLineValues
          control={control}
          displayRollingLine={
            widgetConfiguration.widgetData.rollingFR?.displayValues ||
            widgetConfiguration.widgetData.rollingTRIFR?.displayValues
          }
          labelDecimals={configs.displayDecimalPlaces}
          watchLabelFormat={watchLabelFormat}
        />
      )}
      <div className="gap-y-1 py-2">
        {chartSettings.EXCLUDE_ARCHIEVE_RECORDS && (
          <OdinController
            name="excludeArchivedRecords"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                label={t('excludeArchivedRecords')}
                name="excludeArchivedRecords"
                value="true"
                initialChecked={widgetConfiguration?.widgetData?.excludeArchivedRecords ?? false}
                onChange={onChange}
              />
            )}
          />
        )}
        {chartSettings.IGNORE_DATE_FILTERS && (
          <OdinController
            name="ignoreDateFilters"
            control={control}
            render={({ field: { onChange } }) => (
              <div title={String(t('ignoreDateFiltersTooltip'))} className="contents">
                <Checkbox
                  label={t('ignoreDateFilters')}
                  name="ignoreDateFilters"
                  value="true"
                  initialChecked={widgetConfiguration?.widgetData?.ignoreDateFilters ?? false}
                  onChange={onChange}
                />
              </div>
            )}
          />
        )}
      </div>
    </>
  );
}
