import { useEffect, useState } from 'react';
import { UserType, useGetUserQuery } from '../redux/config/users-api';

export function useDashboardUser(): UserType | undefined {
  const [user, setUser] = useState<UserType>();
  const { data: userInfo } = useGetUserQuery();

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  return user;
}
