import { Key, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, DataGridColumnSettings, DataGridSettings, OverlayScrollDetectionType } from '@myosh/odin-components';

import useStartEndDates from './hooks/useStartEndDates';
import { ChartProps } from './chart.interface';
import { genericSort, SortDirection } from './generalFunctions/general-functions';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import { useAppDispatch } from '../../redux/hooks';
import { CrossTabDataColumnProps } from '../../redux/config/table-data.interfaces';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { tableDataApi, useCrossTabReportTableDataQuery } from '../../redux/config/table-data';
import { useSelectedView } from './hooks/use-selected-view';

export interface CrossTabReportDataProps {
  firstField: string;
  [field: string]: Key;
}

export default function CrossTabReport({ widgetId, layoutProps }: ChartProps) {
  const [crossTabReportTableData, setCrossTabReportTableData] = useState<Array<CrossTabReportDataProps>>();
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    isUninitialized: isDataUninitialized,
    error,
  } = useCrossTabReportTableDataQuery(
    { widgetId, from: startDate, to: endDate, viewId: view?.id },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (widgetChartData && !isLoading && !isWidgetChartDataFetching) {
      const firstKey = Object.keys(widgetChartData);

      if (firstKey.length > 0) {
        const gridColumns: Array<DataGridColumnSettings> = widgetChartData[firstKey[0]].map((column) => {
          return {
            id: column.secondField,
            title: column.secondField,
            field: column.secondField,
            visible: true,
          };
        });

        setColumns([
          { id: 'firstField', title: '', field: 'firstField', visible: true, isIdField: true },
          ...gridColumns,
        ]);
      }
    }
  }, [widgetChartData, isLoading, isWidgetChartDataFetching]);

  useEffect(() => {
    if (widgetChartData && !isLoading && !isWidgetChartDataFetching) {
      const dataGridRows: Array<CrossTabReportDataProps> = [];
      for (const key in widgetChartData) {
        const gridRowData = widgetChartData[key].reduce(
          (
            previousValue: CrossTabReportDataProps | CrossTabReportDataProps[],
            currentValue: CrossTabDataColumnProps
          ) => {
            previousValue = {
              ...previousValue,
              firstField: key,
              [currentValue.secondField]: currentValue.value,
            };
            return previousValue;
          },
          []
        );

        dataGridRows.push(gridRowData as CrossTabReportDataProps);
      }
      setCrossTabReportTableData(genericSort(dataGridRows, 'firstField', SortDirection.Ascending));
    }
  }, [widgetChartData, isLoading, isWidgetChartDataFetching]);

  const gridSettings = useMemo<DataGridSettings | undefined>(() => {
    if (columns && columns.length > 0) {
      return {
        columns,
        scrollDetection: OverlayScrollDetectionType.All,
        fullHeight: true,
      };
    }
  }, [columns]);

  const handleRefetch = () => {
    if (!isDataUninitialized && crossTabReportTableData && !isWidgetChartDataFetching) {
      dispatch(tableDataApi.util.invalidateTags([{ type: 'CrossTabReportData', id: `LIST-${widgetId}` } as const]));
    }
  };

  return (
    <ChartGenericWrapper
      widgetId={widgetId}
      isLoading={isLoading}
      isFetching={isWidgetChartDataFetching}
      refetch={handleRefetch}
      isError={isError}
      error={error}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !crossTabReportTableData?.length}
    >
      {gridSettings && crossTabReportTableData && (
        <DataGrid data={crossTabReportTableData} gridSettings={gridSettings} showSettings={false} />
      )}
    </ChartGenericWrapper>
  );
}
