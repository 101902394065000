import { IDataCommand } from '../data-transformer.interface';
import { ChartDataKPI } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { ChartColumnResponseProps } from './column-chart-interfaces';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import transformDataChartKPI from '../../components/Charts/generalFunctions/transform-data-kpi';
import { isEmpty, keyBy, merge, values } from 'lodash';
import { findSum } from '../common-commands/find-sum-value';

export class BuildColumnChartData
  implements IDataCommand<ChartDataKPI, WidgetConfigurationData, Array<ChartColumnResponseProps>>
{
  process(data: ChartDataKPI, config: WidgetConfigurationData): Array<ChartColumnResponseProps> {
    let formattedData: Array<ChartColumnResponseProps> = [];
    const basicValues = (data?.basicValue ?? []) as Array<ChartColumnResponseProps>;
    const isCalculationType = config.widgetData?.calculationType === 'sum';

    for (const basicValue of basicValues) {
      const basicValueField = getValueOrDefault(basicValue.field, '[none]');
      const itemSecondKey = basicValue.secondField;
      const value = isNaN(Number(basicValue.value)) ? 0 : Number(basicValue.value);
      const fieldObject = formattedData.find((item) => item.name === basicValueField);

      // only runs if value not in array
      if (!fieldObject && !isCalculationType) {
        const field: ChartColumnResponseProps = {
          name: basicValueField,
        };
        field[getValueOrDefault(itemSecondKey ?? 'Quantity', '[none]')] = value;
        formattedData.push(field);
      } else if (itemSecondKey && fieldObject) {
        fieldObject[itemSecondKey] = value;
      } else if (isCalculationType) {
        const field: ChartColumnResponseProps = {
          name: basicValueField,
        };
        field['Sum'] = itemSecondKey;
        formattedData.push(field);
      } else {
        const field: ChartColumnResponseProps = {
          name: basicValueField,
        };
        field['Quantity'] = value;
        formattedData.push(field);
      }
    }

    formattedData = findSum(formattedData);
    const skipKpi =
      (isEmpty(data.kpi) || config?.widgetConfig?.kpiTargetConfiguration?.hideKPI === true) &&
      isEmpty(data.rollingFR) &&
      isEmpty(data.rollingSiteTRIFR) &&
      isEmpty(data.rollingTRIFR);
    if (skipKpi) {
      return formattedData;
    }
    const kpiResponse = transformDataChartKPI(config, data);
    return values(merge(keyBy(formattedData, 'name'), keyBy(kpiResponse, 'name')));
  }
}
