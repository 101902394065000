import React, { useState } from 'react';
import { t } from 'i18next';
import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { Layout } from '../../redux/config/layout';
import PermissionsTabWindow from './tab-permissions-window';

type PermissionsTabIconProps = {
  layout: Layout;
};
export const PermissionTabIcon = ({ layout }: PermissionsTabIconProps) => {
  const [permissionWindowVisible, setPermissionWindowVisible] = useState<boolean>(false);
  return (
    <>
      <div className="flex p-1 align-middle" onClick={() => setPermissionWindowVisible(!permissionWindowVisible)}>
        <OdinIcon className="self-center" icon="UserSettings" size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} />
        <div className="ml-2">{String(t('permissions'))}</div>
      </div>
      <PermissionsTabWindow
        layout={layout}
        showWindow={permissionWindowVisible}
        onHidden={() => setPermissionWindowVisible(false)}
      />
    </>
  );
};
