import { useEffect, useState } from 'react';
import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';

import type { GroupedTableType } from '../../redux/config/table-data.interfaces';

type GenericObjectType<T = unknown> = {
  [key: string]: T;
};

const compareColors = {
  Up: '#FF9293',
  Down: '#9CD190',
  Left: '#FFE598',
};

const compareColumns = ['Current TRIFR movement from prev month', 'Current SR movement from prev month'];

/**
 * Compares two numbers and returns a predefined string
 * based on their relative values.
 * Returned string should match `compareColors` keys.
 */
const compareValues = (v1: number, v2: number) => {
  if (v1 > v2) {
    return 'Down';
  }
  if (v1 < v2) {
    return 'Up';
  }

  return 'Left';
};

const CustomGroupedColumnTable = ({
  data,
  gridCellColors,
}: {
  data: GroupedTableType;
  gridCellColors?: Map<string, string>;
}) => {
  const [columns, setColumns] = useState<GenericObjectType[]>([]);
  const [subColumns, setSubColumns] = useState<string[]>([]);
  const [rows, setRows] = useState<GenericObjectType<string>[][]>([]);

  useEffect(() => {
    if (data && data.data && data.header) {
      const compareColumnsMap = new Map(compareColumns.map((c) => [c, true]));
      const newData = structuredClone(data);
      const colArray: typeof columns = [];

      /**
       * Loops through each header object.
       * Checks if it is a special compare column and needs an extra column.
       * Pushes the header info (title, span) into the "Columns" array.
       * Returns the flattened "Sub-columns" array.
       */
      const subColsArray: string[] = newData.header?.flatMap((h: GenericObjectType) => {
        let colLength = (h.column as string[]).length;

        if (compareColumnsMap.has(h.title as string) && colLength === 2) {
          // `=` could be anything to reference as a "special compare column"
          (h.column as string[]).push('=');
          colLength += 1;
        }

        colArray.push({
          title: h.title,
          span: colLength,
        });

        return h.column as string[];
      });

      /**
       * Transform the data array into a rows array for the table.
       * For each data item, create a new row array with the row label.
       * Then loop through its columns, handling compare columns specially by
       * generating a third value. Finally flatten the column values into the row.
       * Accumulate all rows into a 2D array.
       */
      const rowArray = newData.data.reduce((acc, curr) => {
        const newRow = [
          {
            value: curr.row,
          },
        ];
        curr.columns.forEach((col) => {
          if (compareColumnsMap.has(col.title) && col.values.length === 2) {
            col.values.push({
              field: '',
              value: compareValues(+col.values[0].value, +col.values[1].value),
            });
          }

          newRow.push(
            ...col.values.flatMap((v) => ({
              value: v.value,
              secondField: v.secondField,
            }))
          );
        });
        acc.push(newRow);
        return acc;
      }, [] as typeof rows);

      setColumns(colArray);
      setSubColumns(subColsArray);
      setRows(rowArray);
    }
  }, [data]);

  return (
    <div className="relative flex h-auto w-full flex-col overflow-hidden text-lg">
      <div className="custom-scroll flex h-full w-full grow flex-wrap content-start overflow-auto">
        <table className="w-full text-sm">
          <thead className="text-xxs text-left leading-3 text-gray-2">
            <tr>
              <th rowSpan={2} className="sticky left-0 w-36 bg-clip-padding" style={{ backgroundColor: 'white' }}>
                &nbsp;
              </th>
              {columns.map((column, index) => {
                return (
                  <th
                    key={index}
                    colSpan={column?.span as number}
                    className="border-b border-l border-gray-2 py-1 pl-1"
                  >
                    {column.title as string}
                  </th>
                );
              })}
            </tr>
            <tr className="border-b border-gray-2">
              {subColumns.map((column, index) => {
                const isSpecialColumn = column === '=';
                return (
                  <th
                    key={`${column}-${index}`}
                    style={!isSpecialColumn ? { minWidth: '50px' } : undefined}
                    className="border-l border-gray-2 py-1 pl-1"
                  >
                    {!isSpecialColumn ? column : ''}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              return (
                <tr key={index} className="border-b border-gray-2">
                  {row.map((r, index) => {
                    if (index === 0) {
                      return (
                        <td
                          key={index}
                          className="sticky left-0 border-b border-gray-2 bg-clip-padding py-2"
                          style={{ backgroundColor: 'white' }}
                          role="rowheader"
                        >
                          <div className="w-36 truncate" title={r.value}>
                            {r.value}
                          </div>
                        </td>
                      );
                    }

                    return (
                      <td
                        key={index}
                        className="border-l border-gray-2 px-1"
                        style={{
                          backgroundColor: `${
                            r.secondField
                              ? gridCellColors?.get(r.secondField)
                              : compareColors[r.value as keyof typeof compareColors]
                          }`,
                        }}
                      >
                        {!(r.value in compareColors) ? (
                          r.value
                        ) : (
                          <div className="flex h-full justify-center">
                            <OdinIcon
                              size={OdinIconSize.ExtraSmall}
                              type={OdinIconType.Line}
                              icon={`Arrow${r.value}`}
                            />
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomGroupedColumnTable;
