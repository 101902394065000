import { formatDateFields } from '../../components/Charts/generalFunctions/formate-date-fields';
import { BasicDataChartResponse, ChartDataKPI, DataKPI } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { getFrequencyValues } from './get-frequency-values';

export class KpiValues implements IDataCommand<ChartDataKPI, WidgetConfigurationData, Array<DataKPI>> {
  process(data: ChartDataKPI): DataKPI[] {
    const kpiValues: DataKPI[] = [];
    const variablesResponse = getFrequencyValues(data);
    variablesResponse?.iterator?.map((item) => {
      const calculateRollingFR = () => variablesResponse.FR_Text?.find((u) => u.field === item.field);
      const calculateRollingTRIFR = () => variablesResponse.TRIFR_Text?.find((s) => s.field === item.field);
      const calculateKPI = () => data?.kpi?.find((k) => k.field === item.field);

      const kpiValue = data.kpi ? calculateKPI() : undefined;
      const trifrValue = data.rollingTRIFR ? calculateRollingTRIFR() : undefined;
      const rollingFR = data.rollingFR ? calculateRollingFR() : undefined;
      const newObj: Record<string, string | number | undefined> = {
        name: item.field,
        value_KPI: kpiValue && Number(kpiValue.value),
        value_TRIFR: trifrValue && Number(trifrValue.value),
        value_FR: rollingFR && Number(rollingFR.value),
      };
      if (data?.rollingSiteTRIFR) {
        Object.keys(data?.rollingSiteTRIFR).map((key) => {
          const parsedElement: BasicDataChartResponse[] | undefined =
            data.rollingSiteTRIFR && formatDateFields(data.rollingSiteTRIFR[key]);
          const rollingSite = parsedElement?.find((site) => site.field === item.field) || {};
          newObj[`${key}_rollingSiteTRIFR`] = rollingSite.value;
        });
      }
      kpiValues.push(newObj);
    });
    return kpiValues;
  }
}
