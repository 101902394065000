import React, { useEffect, useMemo, useState } from 'react';
import { WidgetConfigsGenericWrapper } from '../../common/chart-generic-wrapper';
import { CustomColorProps } from './config-components.interfaces';
import ConfigPaletteWindow from './config-palette.component';
import { t } from 'i18next';
import { Control } from 'react-hook-form';
import { OrderList } from 'primereact/orderlist';
import { isEmpty, sortBy } from 'lodash';
import { getStatusColor } from '../../../helpers/getStatusColor';

type ColorsSortingConfigurations = {
  widgetConfiguration: WidgetConfigsGenericWrapper;
  control: Control;
  setColors: (colors: Record<string, string> | undefined) => void;
  setOrders?: (orders?: string[]) => void;
  sortOrder?: Array<string>;
};

export default function CustomColorsSortingConfiguration({
  widgetConfiguration,
  setColors,
  setOrders,
  sortOrder,
}: ColorsSortingConfigurations) {
  const [paletteVisible, setPaletteVisible] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<CustomColorProps>();
  const [updatedColors, setUpdatedColors] = useState<CustomColorProps[]>([]);
  const [settingsColors, setSettingsColors] = useState<Record<string, string>>();

  const sortBySortOrder = useMemo(
    () => (colorsToSort: CustomColorProps[], localSort?: string[]) => {
      //First, sort aphabetically
      sortBy(colorsToSort, ['label']);
      if (localSort) {
        const sortedArray = [];
        for (let i = 0; i < localSort.length; i++) {
          const color = colorsToSort.find((color) => color.label === localSort[i]);
          if (color) {
            sortedArray.push(color);
          }
        }
        const notPresented = colorsToSort.filter((item) => !localSort.includes(item.label));
        return [...sortedArray, ...notPresented];
      }
      return colorsToSort;
    },
    []
  );

  const updateColor = (newColorSetting: CustomColorProps) => {
    setUpdatedColors(
      updatedColors.map((item) =>
        item.label === newColorSetting.label ? { ...item, color: newColorSetting.color } : item
      )
    );

    setSettingsColors({
      ...settingsColors,
      [newColorSetting.label]: newColorSetting.color,
    });
  };

  useEffect(() => {
    if (settingsColors) {
      setColors(settingsColors);
    }
  }, [settingsColors]);

  useEffect(() => {
    if (widgetConfiguration && widgetConfiguration.colors) {
      setUpdatedColors(sortBySortOrder(widgetConfiguration.colors, widgetConfiguration.widgetConfig?.sortOrder));
    } else if (sortOrder) {
      const colorsToSort = sortOrder.map((el, index) => {
        return {
          label: el,
          color: getStatusColor(index),
        };
      });
      setUpdatedColors(sortBySortOrder(colorsToSort, sortOrder));
    }
  }, [widgetConfiguration, sortOrder, sortBySortOrder]);

  const colorItemTemplate = (updatedColor: CustomColorProps) => {
    return (
      <div key={updatedColor.label} className="my-1 flex flex-row items-center">
        <svg
          width="24"
          height="24"
          style={{ minWidth: 24 }}
          onClick={() => {
            setPaletteVisible(true);
            setSelectedElement(updatedColor);
          }}
          className="cursor-pointer"
        >
          <rect
            width="24"
            height="24"
            style={{
              fill: updatedColor.color,
            }}
          />
        </svg>
        <span className="px-2">{updatedColor.label}</span>
      </div>
    );
  };

  const assignReferenceUpdateScroll = (instance: OrderList) => {
    if (instance) {
      const list = instance.getElement().getElementsByClassName('p-orderlist-list')[0];
      if (list) {
        list.classList.add('custom-scroll');
      }
    }
  };

  /**
   * If there are no possibility to set sort order, then a "read-only" div will be rendered
   */
  const optionsElement = useMemo(
    () =>
      setOrders ? (
        <OrderList
          value={updatedColors}
          onChange={(e) => {
            setUpdatedColors(e.value);
            setOrders(e.value.map((color: CustomColorProps) => color?.label));
          }}
          itemTemplate={colorItemTemplate}
          header={String(t('customiseDataSeries'))}
          dragdrop
          className="dashboard max-w-max flex-row-reverse"
          ref={assignReferenceUpdateScroll}
        />
      ) : (
        <>
          <span className="block text-base font-bold">{String(t('customiseDataSeries'))}</span>
          <div>{updatedColors.map((element) => colorItemTemplate(element))}</div>
        </>
      ),
    [updatedColors, setOrders]
  );

  return !isEmpty(updatedColors) || sortOrder ? (
    <div className="py-2">
      {optionsElement}
      <ConfigPaletteWindow
        visible={paletteVisible}
        selectedItem={selectedElement}
        updateColor={updateColor}
        onPaletteClosed={() => {
          setPaletteVisible(false);
          setSelectedElement(undefined);
        }}
      />
    </div>
  ) : null;
}
