import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';

export class SortByField<TIn extends Array<ChartResponse>> implements IDataCommand<TIn, WidgetConfigurationData, TIn> {
  process(data: TIn): TIn {
    return data?.sort((a: ChartResponse, b: ChartResponse) => {
      if (a.field && b.field) {
        return a.field.localeCompare(b.field);
      } else if (a.field) {
        return 1;
      } else if (b.field) {
        return -1;
      }
      return 0;
    });
  }
}
