import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewWithRange, selectCurrentView } from '../../../redux/slices/selected-view';

export function useSelectedView() {
  const [selectedView, setSelectedView] = useState<ViewWithRange | undefined>();

  const currentView = useSelector(selectCurrentView);

  useEffect(() => {
    setSelectedView(currentView.selectedView);
  }, [currentView]);

  return selectedView;
}
