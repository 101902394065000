import React from 'react';
import {
  DropDown,
  isDropDownResult,
  OdinController,
  JsonData,
  DropDownResult,
  isDropDownNoSelection,
} from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { t } from 'i18next';

interface WidgetGlobalFilterProps {
  data: JsonData;
  setWidgetFieldValue: (value: DropDownResult | undefined) => void;
  control: Control;
  globalFiltersValues?: DropDownResult[];
  setGlobalFilters: (value: DropDownResult[] | undefined) => void;
}

const WidgetGlobalFilterComponent = ({
  data,
  setWidgetFieldValue,
  control,
  globalFiltersValues,
  setGlobalFilters,
}: WidgetGlobalFilterProps) => {
  return (
    <div className="my-1 py-2">
      <OdinController
        name="globalFilters"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={t('globalFilters')}
            setDefaultValue={false}
            data={data}
            textField="field"
            valueField="field"
            pageSize={50}
            value={globalFiltersValues}
            onChange={(value) => {
              if (isDropDownResult(value)) {
                setWidgetFieldValue(value);
                onChange(value);
              } else if (isDropDownNoSelection(value)) {
                setWidgetFieldValue(undefined);
                setGlobalFilters(undefined);
              }
            }}
          />
        )}
      />
    </div>
  );
};

export default WidgetGlobalFilterComponent;
