import { formatDateFields } from '../../components/Charts/generalFunctions/formate-date-fields';
import { BasicDataChartResponse, ChartDataKPI } from '../../redux/config/chart-data';

export function getFrequencyValues(data: ChartDataKPI) {
  const FR_Text: BasicDataChartResponse[] | undefined = data?.rollingFR && formatDateFields(data.rollingFR[0]);
  const TRIFR_Text: BasicDataChartResponse[] | undefined = data?.rollingTRIFR && formatDateFields(data.rollingTRIFR);
  const rollingSiteTRIFR =
    data?.rollingSiteTRIFR &&
    Object.keys(data.rollingSiteTRIFR).map((key) => {
      return data?.rollingSiteTRIFR && formatDateFields(data?.rollingSiteTRIFR[key]);
    });
  const iterator: BasicDataChartResponse[] | undefined = data?.kpi
    ? data?.kpi
    : data?.rollingFR
    ? FR_Text
    : data?.rollingTRIFR
    ? TRIFR_Text
    : rollingSiteTRIFR && rollingSiteTRIFR[0];

  return {
    FR_Text,
    TRIFR_Text,
    iterator,
  };
}
