import { CHART_TYPE } from './enums';

export type ConfigurationOptions =
  | 'LEGEND'
  | 'DECIMAL_PLACES'
  | 'CUSTOM_SORTING'
  | 'ITEMS_SHOW'
  | 'CUSTOM_COLOR'
  | 'LABEL_DISPLAY'
  | 'LABEL_CONFIG_PIE'
  | 'DATA_CATEGORIES_TO_SHOW'
  | 'COLOUR_CUSTOMISATION'
  | 'DISPLAY_ROLLING_LINE_VALUES'
  | 'LABEL_PLACEMENT'
  | 'EXCLUDE_ARCHIEVE_RECORDS'
  | 'IGNORE_DATE_FILTERS';

export type SupportedWidgetSettings = {
  [K in ConfigurationOptions]?: boolean;
};

type WidgetSettingsType = Record<CHART_TYPE, SupportedWidgetSettings>;

export const widgetSettings: WidgetSettingsType = {
  MAP: {},
  MANAGEMENT_TABLE: {},
  CHART_BAR: {
    CUSTOM_COLOR: true,
    CUSTOM_SORTING: true,
    LEGEND: true,
    DATA_CATEGORIES_TO_SHOW: true,
  },
  CHART_KPI: {
    CUSTOM_COLOR: true,
    LEGEND: true,
    DISPLAY_ROLLING_LINE_VALUES: true,
    COLOUR_CUSTOMISATION: true,
  },
  CHART_COLUMN: {
    CUSTOM_SORTING: true,
    CUSTOM_COLOR: true,
    LEGEND: true,
    LABEL_DISPLAY: true,
    LABEL_PLACEMENT: true,
    DECIMAL_PLACES: true,
    DATA_CATEGORIES_TO_SHOW: true,
    COLOUR_CUSTOMISATION: true,
  },
  CHART_COLUMN_BASIC: {
    CUSTOM_COLOR: true,
    CUSTOM_SORTING: true,
    LEGEND: true,
    DATA_CATEGORIES_TO_SHOW: true,
  },
  CHART_COLUMN_RATE: {
    CUSTOM_COLOR: true,
    DATA_CATEGORIES_TO_SHOW: true,
  },
  CHART_COLUMN_LINE: {
    CUSTOM_COLOR: true,
    CUSTOM_SORTING: true,
    LEGEND: true,
    DATA_CATEGORIES_TO_SHOW: true,
  },
  CHART_DIFFERENCE: {
    LEGEND: true,
  },
  CHART_LINE: {
    LEGEND: true,
    CUSTOM_SORTING: true,
    LABEL_DISPLAY: true,
    DECIMAL_PLACES: true,
  },
  CHART_PIE: {
    LEGEND: true,
    CUSTOM_SORTING: true,
    CUSTOM_COLOR: true,
    LABEL_CONFIG_PIE: true,
  },
  TABLE: {},
  TABLE_INJURY_FREE_DAYS: {},
  CHART_ROLLINGFR: {
    LEGEND: true,
  },
  CROSS_TAB_REPORT: {},
  COMPARED_FR_LINE: {
    LEGEND: true,
  },
  TABLE_ROLLING_TRIFR: {},
  TABLE_RATE: {},
  CHART_COLUMN_KPI_PERCENTAGE: {
    DATA_CATEGORIES_TO_SHOW: true,
  },
  INJURY_NUMBER_BY_SITE_TABLE: {},
  CHART_NCR_AGING: {},
};

export const commonSettings: SupportedWidgetSettings = {
  EXCLUDE_ARCHIEVE_RECORDS: true,
  IGNORE_DATE_FILTERS: true,
};

export const getWidgetConfigurationSettings = (chartType: CHART_TYPE) => {
  return { ...commonSettings, ...widgetSettings[chartType] };
};

export const MIN_COLUMN_BAR_HEIGHT = 3;
