import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetViewExtended } from '../config/views';
import { GlobalFilterQuickDateRange } from './globalFilter/global-dates-filter-slice';
import { removeStorageItem, setStorageItem } from '../../helpers/storage';

export interface ViewWithRange extends GetViewExtended {
  quickDateRange?: GlobalFilterQuickDateRange;
}
const initialState: { selectedView?: ViewWithRange } = {
  selectedView: undefined,
};

const selectedViewSlice = createSlice({
  name: 'selectedView',
  initialState: initialState,
  reducers: {
    setSelectedView(state, action) {
      state.selectedView = action.payload;
      setStorageItem('lastUsedView', action.payload, 'sessionStorage');
    },
    clearSelectedView(state) {
      state.selectedView = undefined;
      removeStorageItem('lastUsedView', 'sessionStorage');
    },
  },
});

export default selectedViewSlice.reducer;
export const { setSelectedView, clearSelectedView } = {
  ...selectedViewSlice.actions,
};
export const selectCurrentView = (state: RootState) => state.rootReducer.selectedView;
