import React from 'react';
import ConfigurationTabContent from '../../../WidgetSettingWindow/Tabs/configuration-setting-tab';
import { WidgetShowConfigProps } from '../wizard-tab-fourth';
import useUpdateColorsData from '../../../../hooks/useUpdateColorsData';
import { WidgetConfigsGenericWrapper } from '../../../common/chart-generic-wrapper';

const WidgetConfigurationComponent = ({
  control,
  widgetConfiguration,
  watchLabelFormat,
  setSortOrder,
  setColorsSelection,
  watchWidgetType,
  sortOrder,
}: WidgetShowConfigProps) => {
  const widgetConfigurationData = useUpdateColorsData(widgetConfiguration);
  return widgetConfigurationData || watchWidgetType ? (
    <ConfigurationTabContent
      widgetConfiguration={widgetConfigurationData as Omit<WidgetConfigsGenericWrapper, 'layoutProps'>}
      control={control}
      watchLabelFormat={watchLabelFormat}
      setColors={setColorsSelection}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      watchWidgetType={watchWidgetType}
      showLineLabel={false}
      showDecimalPlaces={false}
      showCustomSorting
    />
  ) : null;
};

export default WidgetConfigurationComponent;
