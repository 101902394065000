import {
  DomElementAlignment,
  DomTargetPosition,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  OverlayPanel,
} from '@myosh/odin-components';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsEditableMode } from '../Charts/hooks/useIsEditableMode';
import { OdinCustomFilters } from '../CustomFilterComponent/CustomFilterComponent';
import WidgetSettingsDialog from '../WidgetSettingWindow/widget-settings-dialog';
import { WidgetConfigsGenericWrapper } from './chart-generic-wrapper';
import ConfirmationWindow from './confirmation-window';

import { odinIconStyle } from '../../constants/common-style-constants';
import { LayoutWidget } from '../../redux/config/layout';
import { useUpdateLayoutWidgetDataMutation } from '../../redux/config/layout-widget';
import {
  useDeleteLayoutWidgetMutation,
  useGetInsightsMutation,
  useGetWidgetInsightsEnabledQuery,
} from '../../redux/config/widgetApi';
import { selectActiveTabId } from '../../redux/slices/active-layout-tab-id-slice';
import { getTabs, getWidgets } from '../../redux/slices/layout-tabs-slice';
import { setWidgetWidthHeight } from '../../redux/slices/update-width-height-of-chart';

import SettingsMenu, { MenuItem } from './menu-item';

import toast from 'react-hot-toast';
import { CHART_TYPE } from '../../constants/enums';
import { setWidgetAiInsight } from '../../redux/slices/widgetChart/WidgetChartSlice';
import { useExportFile } from '../Charts/hooks/use-export-file';
export interface ChartBottomPanelInterface {
  widgetId: string;
  refetch: () => void;
  widgetConfiguration?: WidgetConfigsGenericWrapper;
  isTable?: boolean;
  widgetChartData: any;
}

const isAbleToAnalyze = (type?: CHART_TYPE) => {
  if (
    type === CHART_TYPE.ChartBar ||
    type === CHART_TYPE.ChartColumn ||
    type === CHART_TYPE.ChartLine ||
    type === CHART_TYPE.ChartPie
  ) {
    return true;
  }
  return false;
};

export default function ChartBottomPanel({
  widgetId,
  refetch,
  isTable,
  widgetConfiguration,
  widgetChartData,
}: ChartBottomPanelInterface) {
  const iconRef = useRef<HTMLDivElement>(null);
  const [configOverlayOpened, setConfigOverlayOpened] = useState<boolean>(false);
  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [layoutWidget, setLayoutWidget] = useState<LayoutWidget>();

  const [deleteWidget, { isLoading: isDeleting }] = useDeleteLayoutWidgetMutation();
  const [getAiInsight] = useGetInsightsMutation();
  const isEditable = useIsEditableMode();
  const [updateWidgetLayout] = useUpdateLayoutWidgetDataMutation();
  const { data: aiEnabled, isFetching: isConfigFetching } = useGetWidgetInsightsEnabledQuery();
  const dispatch = useDispatch();
  const { exportWidgetHandler } = useExportFile({ widgetId });

  const layoutTabs = useSelector(getTabs);
  const layoutTabWidgets = useSelector(getWidgets);
  const activeTabId = useSelector(selectActiveTabId);

  useEffect(() => {
    setLayoutWidget(layoutTabWidgets.find((widget) => +widget.widget.id === +widgetId));
  }, [layoutTabWidgets]);

  const layoutProps = useMemo(() => {
    if (layoutTabs) {
      return layoutTabs.find((tab) => tab.id === activeTabId);
    }
  }, [activeTabId]);

  const isAvailableToUser: boolean = useMemo(
    () => (layoutProps?.canModify || layoutProps?.isUserLayout ? isEditable : false),
    [layoutProps, isEditable]
  );

  const menuOptions: MenuItem[] = useMemo(
    () => [
      {
        title: 'AI Insight',
        isSupported: true,
        isAvailableToUser:
          !isConfigFetching &&
          Boolean(aiEnabled) &&
          isAbleToAnalyze(widgetConfiguration?.type) &&
          (widgetChartData?.basicValue?.length > 0 || widgetChartData?.length > 0),
        icon: 'Magic',
        iconType: OdinIconType.Fill,
        clickHandler: () => handleClickAiInsight(),
      },
      {
        title: t('settings'),
        isSupported: true,
        isAvailableToUser: layoutProps?.canAdmin ?? false,
        icon: 'Settings5',
        iconType: OdinIconType.Line,
        clickHandler: () => setSettingsOpened(!settingsOpened),
      },
      {
        title: t('export'),
        icon: 'Download2',
        iconType: OdinIconType.Line,
        isSupported: true,
        isAvailableToUser: true,
        //availability depends on widget's type not permissions
        clickHandler: () => setConfigOverlayOpened(!configOverlayOpened),
      },
      {
        title: layoutWidget?.width === '100%' ? t('singleWidth') : t('doubleWidth'),
        isSupported: true,
        isAvailableToUser,
        icon: 'SplitCellsHorizontal',
        iconType: OdinIconType.Editor,
        clickHandler: () => onEditDimension(false, true),
      },
      {
        title: layoutWidget?.height === '100%' ? t('singleHeight') : t('doubleHeight'),
        isSupported: true,
        isAvailableToUser,
        icon: 'SplitCellsVertical',
        iconType: OdinIconType.Editor,
        clickHandler: () => onEditDimension(true, false),
      },
      {
        title: t('delete'),
        isSupported: true,
        isAvailableToUser,
        icon: 'DeleteBin6',
        iconType: OdinIconType.Line,
        clickHandler: () => setShowSaveModal(true),
      },
    ],
    [settingsOpened, layoutProps, layoutWidget, isAvailableToUser, exportWidgetHandler, isTable]
  );

  const exportSubMenuItems = [
    {
      title: 'CSV',
      clickHandler: () => exportWidgetHandler('CSV', true),
      isSupported: false,
      isAvailableToUser: true,
    },
    {
      title: 'Excel',
      clickHandler: () => exportWidgetHandler('EXCEL', true),
      isSupported: true,
      isAvailableToUser: isTable,
    },
    {
      title: 'PDF',
      clickHandler: () => exportWidgetHandler('PDF'),
      isSupported: true,
      isAvailableToUser: true,
    },
  ];

  const onDeleteHandler = () => {
    const widgetLayoutId = layoutWidget?.id;
    if (widgetLayoutId) {
      const widgetRef = document.getElementById(widgetLayoutId);
      deleteWidget(widgetLayoutId);
      if (widgetRef) {
        widgetRef.remove();
      }
    }
  };

  const onEditDimension = useCallback(
    (updateHeight: boolean, updateWidth: boolean) => {
      if (layoutProps && layoutWidget) {
        const { height, width } = layoutWidget;
        const updatedData = {
          ...layoutWidget,
          width: updateWidth ? (width === '100%' ? '50%' : '100%') : width,
          height: updateHeight ? (height === '100%' ? '50%' : '100%') : height,
        };
        setLayoutWidget(updatedData);
        updateWidgetLayout(updatedData);

        dispatch(
          setWidgetWidthHeight({
            id: updatedData.id,
            updateHeight,
            updateWidth,
          })
        );
      }
    },
    [layoutProps, layoutWidget]
  );

  const onHideModal = () => {
    setShowSaveModal(false);
  };

  const handleClickAiInsight = async () => {
    const toastId = toast.loading('Generating AI Insight');
    try {
      const res = await getAiInsight({
        widgetId: widgetId,
        body: widgetChartData.basicValue ?? widgetChartData,
      });
      if ('data' in res) {
        const data = res.data;
        toast.success('AI Insight generated successfully', {
          id: toastId,
        });
        dispatch(
          setWidgetAiInsight({
            title: widgetConfiguration?.title,
            data: data,
          })
        );
      } else if ('error' in res) {
        toast.error('Failed to generate AI Insight, please try again', {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error('Failed to generate AI Insight, please try again', {
        id: toastId,
      });
    }
  };

  return (
    <>
      <div className="mt-auto flex flex-row justify-between">
        {layoutWidget?.widget?.widgetData?.customFilterField ? (
          <OdinCustomFilters id={widgetId} onFilterStateChanged={refetch} />
        ) : null}
        <div className="ml-auto flex flex-row justify-end gap-2">
          {menuOptions
            .filter((option) => option.isAvailableToUser)
            .map((menuOption) => {
              return (
                <div
                  key={menuOption.title}
                  onClick={menuOption.clickHandler}
                  className="m-0.5 ml-auto flex-none"
                  ref={iconRef}
                  title={menuOption.title}
                >
                  <OdinIcon
                    icon={menuOption.icon as string}
                    type={menuOption.iconType}
                    size={OdinIconSize.Small}
                    className={odinIconStyle}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <OverlayPanel
        target={iconRef.current}
        visible={configOverlayOpened}
        hidden={() => setConfigOverlayOpened(false)}
        mountOnEnter={false}
        unmountOnExit={false}
        shouldScrollCloseOverlay
        shouldCheckZIndex
        shouldUseCreatePortal={false}
        transitionDuration={0}
        elementAlignment={DomElementAlignment.BottomLeft}
        targetPosition={DomTargetPosition.BottomLeft}
        className="OverlayPanel flex w-36 rounded-md bg-gray-4 shadow-md"
      >
        <SettingsMenu items={exportSubMenuItems} />
      </OverlayPanel>
      {widgetConfiguration && (
        <WidgetSettingsDialog
          widgetConfiguration={widgetConfiguration}
          visible={settingsOpened}
          onDialogHidden={() => setSettingsOpened(false)}
        />
      )}
      <ConfirmationWindow
        onSaveHandler={onDeleteHandler}
        showWindow={showSaveModal}
        onDiscardChanges={{
          onHideModal,
          onDiscard: onHideModal,
        }}
        title={isDeleting ? t('deleting') : t('deleteWidget')}
        text={t('confirmDelete')}
        discard={t('no')}
        save={t('yes')}
      />
    </>
  );
}
