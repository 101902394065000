import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UpdateWidgetProps {
  widgetWidthHeight: {
    id: string;
    updateWidth: boolean;
    updateHeight: boolean;
  };
}

const initialState: UpdateWidgetProps = {
  widgetWidthHeight: {
    id: '',
    updateWidth: false,
    updateHeight: false,
  },
};

const updateWidthHeightSlice = createSlice({
  name: 'updateChartWidthHeight',
  initialState,
  reducers: {
    setWidgetWidthHeight(state, action) {
      state.widgetWidthHeight = action.payload;
    },
  },
});

export const { setWidgetWidthHeight } = {
  ...updateWidthHeightSlice.actions,
};

export default updateWidthHeightSlice.reducer;

export const selectWidgetChartHeight = (state: RootState) => state.rootReducer.updateWidthHeight;
