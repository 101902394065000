import { DotProps } from 'recharts';

//https://github.com/recharts/recharts/issues/4671#issuecomment-2176643791
// cx,cy and r set makes sure that clipDot is applied
export const lineDotBaseProps: DotProps = {
  clipDot: false,
  cx: 0,
  cy: 0,
  r: 2,
  strokeWidth: 5,
};

export const getDotWithColor = (color: string, additionalProps?: DotProps) => {
  return { ...lineDotBaseProps, fill: color, stroke: color, ...additionalProps };
};
