import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  DataGrid,
  DataGridColumnSettings,
  DataGridSettings,
  DynamicFormFieldType,
  JsonDataWrapper,
  ModalDialog,
  OdinDataRetrieval,
  OdinDataSender,
} from '@myosh/odin-components';
import { ModalDialogButtonSetting } from '@myosh/odin-components/dist/types/components/common-interfaces';
import useStartEndDates from '../Charts/hooks/useStartEndDates';
import { useDetailsWindowDataQuery, useDetailsWindowHeaderQuery } from '../../redux/config/details-window';
import { OdinDataRetrievalOptions } from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';
import { useRecordOdinUrl } from '../Charts/hooks/useRecordOdinUrl';
import { LoaderComponent } from '../common/loading-spinner.component';
import { DetailsWindowFiltersProps } from '../../redux/config/table-data.interfaces';
import { useTableFiltersQuery } from '../../redux/config/table-data';
import { SelectionSearchTypeProps } from '../../constants/enums';
import { ErrorDataLabel } from '../common/error-data-label.component';
import { t } from 'i18next';
import { useExportFile } from '../Charts/hooks/use-export-file';
import { setTableFilters } from '../../redux/slices/update-widget-table-sort-field';
import { useAppDispatch } from '../../redux/hooks';
import { useSelectedView } from '../Charts/hooks/use-selected-view';
import { formatDisplayDate } from '../common/date-util';

export interface CustomModalProps extends DetailsConfigurationFieldsValues {
  field?: string;
  name: string;
  tooltipPayload: Array<{
    name: string;
    dataKey: string;
  }>;
}

export interface DetailsConfigurationFields {
  field: string;
  secondField: string;
  moduleViewId: string;
}

export interface DetailsConfigurationFieldsValues {
  fieldValue?: string;
  secondFieldValue?: string | boolean;
  recordIds?: Array<string>;
}

interface DetailsWindowProps extends DetailsConfigurationFields, DetailsConfigurationFieldsValues {
  widgetId: string;
  visible: boolean;
  onDialogHidden: () => void;
}

export default function OdinCustomDetailsWindow({
  widgetId,
  field,
  fieldValue,
  secondField,
  secondFieldValue,
  visible,
  onDialogHidden,
  moduleViewId,
  recordIds,
}: DetailsWindowProps) {
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const dataSender = useRef<OdinDataSender<JsonDataWrapper>>();
  const templateSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const { startDate, endDate } = useStartEndDates();
  const columnFilters = useRef<DetailsWindowFiltersProps>({});
  const dispatch = useAppDispatch();
  const view = useSelectedView();

  const { exportWidgetHandler } = useExportFile({ widgetId });

  const { data: header, isFetching: headerFetching } = useDetailsWindowHeaderQuery(widgetId, { skip: !visible });

  const { data, isFetching, isError } = useDetailsWindowDataQuery(
    {
      widgetId,
      field,
      fieldValue,
      secondField,
      secondFieldValue: secondFieldValue as string,
      from: startDate,
      to: endDate,
      viewId: view?.id,
      page: gridOptions?.page || 1,
      pageSize: gridOptions?.pageSize || 50,
      fieldFilters: gridOptions?.fieldFilters,
      sortedFields: gridOptions?.sortedFields,
      recordIds,
    },
    { skip: !visible || header === undefined || headerFetching }
  );
  const { data: tableFilterData } = useTableFiltersQuery({ widgetId }, { skip: !visible });

  const createDetailsUrl = useRecordOdinUrl(moduleViewId);
  const closeModalButton = useRef<Array<ModalDialogButtonSetting>>([
    {
      type: 'primary',
      name: 'closeModalButton',
      text: 'Close',
    },
  ]);

  useEffect(() => {
    if (header && !headerFetching) {
      const gridColumns: Array<DataGridColumnSettings> = header.map((column) => {
        const searchType = SelectionSearchTypeProps[column.type as keyof typeof SelectionSearchTypeProps] ?? undefined;
        const dropDownText = column.type === 'COMBOBOX' || column.type === 'MULTISELECTCOMBOBOX' ? 'field' : undefined;
        return {
          id: column.field,
          title: column.field,
          field: column.field,
          visible: true,
          isIdField: column.field === 'Doc Number',
          searchType: searchType,
          dropDownText: dropDownText,
          dropDownValue: dropDownText,
        };
      });
      setColumns(gridColumns);
    }
  }, [header, headerFetching]);

  useEffect(() => {
    if (data && !isFetching) {
      if (gridOptions?.page && gridOptions?.pageSize && data.length > 0) {
        const updatedData = data.map((item) => ({
          ...item,
          'Creation Date': item['Creation Date']
            ? formatDisplayDate(new Date(Date.parse(item['Creation Date'] as string)))
            : item['Creation Date'],
        }));

        dataSender.current?.sendData({ data: updatedData });
      } else {
        dataSender.current?.sendData();
      }
    }
  }, [gridOptions, data, isFetching]);

  const dataRetrieval = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          dataSender.current = subscriber;
        } else {
          templateSubscriber.current = subscriber;
        }
      },
      getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          if (options.page === 1 && options.fieldId) {
            const fieldId = options.fieldId;
            if (columnFilters.current[fieldId]) {
              templateSubscriber.current?.sendData({
                data: columnFilters.current[fieldId],
                requestId: options.requestId,
              });
            } else if (tableFilterData) {
              const dataResult = tableFilterData.find((el) => el.fieldId === fieldId)?.values;
              if (dataResult && dataResult.length >= 0) {
                columnFilters.current = { ...columnFilters.current, [String(options.fieldId)]: dataResult };
                templateSubscriber.current?.sendData({ data: dataResult, requestId: options.requestId });
              } else {
                templateSubscriber.current?.sendData();
              }
            }
          } else {
            templateSubscriber.current?.sendData();
          }
        } else {
          setGridOptions(options);
        }
      },
    };
  }, [tableFilterData]);

  useEffect(() => {
    dispatch(
      setTableFilters({
        id: `detailsWindow-${widgetId}`,
        ...gridOptions,
        field,
        fieldValue,
        secondField,
        secondFieldValue: secondFieldValue as string,
      })
    );
  }, [gridOptions?.sortedFields, gridOptions?.fieldFilters, field, fieldValue, secondField, secondFieldValue]);

  const gridSettings = useMemo<DataGridSettings | undefined>(() => {
    if (columns && columns.length > 0) {
      return {
        columns,
        initialPageSize: 50,
        stopRequestsOnNoData: true,
        filterLocation: 1,
        maxHeight: 1000,
      };
    }
  }, [columns]);

  const onButtonClick = () => {
    exportWidgetHandler('EXCEL', true);
  };

  return (
    <ModalDialog
      visible={visible}
      buttons={closeModalButton.current}
      header="Details Window"
      hidden={onDialogHidden}
      maxDialogWidth="65vw"
      minDialogHeight="35vh"
      maxDialogHeight="65vh"
      minDialogWidth="45vw"
      transitionDuration={400}
    >
      {isError ? (
        <ErrorDataLabel />
      ) : !gridSettings ? (
        <LoaderComponent />
      ) : (
        <div className="flex flex-col">
          <div className="m-2">
            <Button onClick={onButtonClick} type="default" variant="alternative" fullWidth={false} htmlType="button">
              {String(t('exportAsExcel'))}
            </Button>
          </div>
          <DataGrid
            data={dataRetrieval}
            gridSettings={gridSettings}
            onRowSelectionChange={(_gridId, selectedRows) => {
              if (selectedRows.length > 0 && createDetailsUrl) {
                const selectedRow = selectedRows[0];
                const baseUrl = createDetailsUrl(String(selectedRow.formId));
                window.open(`${baseUrl}${selectedRow.recordId}`);
              }
            }}
            customSettingsMenuItems={[]}
          />
        </div>
      )}
    </ModalDialog>
  );
}
