import { omit, omitBy } from 'lodash';
import { PayloadChart } from '../../shared/widget-data.interfaces';
import { staticChartKeys } from '../../constants/static-chart-keys';

export const CustomValueAccessor = (payload: PayloadChart, legendProps: Record<string, unknown>) => {
  if (legendProps) {
    let sum = 0;
    /**
     * To make sure that the sum of a chart area (it could be a stacked chart that has multiple values stacked over each other)
     * return the sum of each unique key element, when an area is
     * (legend component) the sum is decreased
     * and when the legend activate the area the sum is increased based on the selected value.
     * */

    const legendPropsKeys = Object.keys(legendProps).filter((legendPropKey) => legendProps[legendPropKey]);
    const uniqueKeysObject = omitBy(omit(payload.payload, [...staticChartKeys, ...legendPropsKeys]), (value, key) => {
      return String(key).includes('_rollingSiteTRIFR');
    });
    const values = Object.values(uniqueKeysObject);
    for (let i = 0; i < values.length; i += 1) {
      sum += Number(values[i]);
    }

    return sum === payload.value[payload.value.length - 1] && sum !== 0 ? sum : '';
  }
};
