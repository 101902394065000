import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  layoutAdmin: boolean;
};
export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query<UserType, void>({
      query: () => ({
        url: `/users/current`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserQuery } = usersApi;
