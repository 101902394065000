import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  Bar,
  YAxis,
  ComposedChart,
  ResponsiveContainer,
  LabelList,
  Cell,
  Line,
} from 'recharts';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import { intervalCounter } from './generalFunctions/general-functions';
import { getHighestColumnChartValue } from './generalFunctions/get-domaincharts';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import OdinCustomDetailsWindow from '../CustomDetailsWindow/custom-details-window.component';
import type { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';

import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { CustomXAxis } from '../common/custom-X-axis.component';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { ColumnKpiPercentageResponse, useChartKPIPercentageQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';

import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';
import { useSelectedView } from './hooks/use-selected-view';
import { lineDotBaseProps } from '../common/line-dot-base-props';

export default function OdinColumnKpiPercentage({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [transformedData, setTransformedData] = useState<Array<ColumnKpiPercentageResponse>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CustomModalProps>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useChartKPIPercentageQuery(
    {
      url: configurationData?.dataURL,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    {
      skip: !configurationData || (!view?.id && startDate === '' && endDate === ''),
    }
  );

  const onShowModal = (data: CustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };

  const onDialogHidden = () => setDetailsWindowVisible(false);
  const handleRefetch = useInvalidateChartResponse(widgetId);

  useEffect(() => {
    if (widgetChartData) {
      const transformedData = widgetChartData.map((data) => {
        const newData = { ...data, color: +data.value < +data.kpi ? '#C70039' : '#50C878' };

        if (newData.field === '') {
          newData.field = '[none]';
        }

        return newData;
      });

      setTransformedData(transformedData);
    }
  }, [widgetChartData]);

  useEffect(() => {
    if (widgets && widgets.length > 0) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: [], layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && transformedData && transformedData.length > 0 && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.fieldValue || modalData?.field}
            secondFieldValue={modalData?.tooltipPayload[0]?.dataKey}
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
          />
          <ResponsiveContainer height="99%">
            <ComposedChart
              data={transformedData}
              margin={{
                top: 20,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <CartesianGrid vertical={false} />
              <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
              <XAxis {...xAxisSettings} interval={intervalCounter(transformedData)} tick={CustomXAxis} />
              <YAxis
                type="number"
                label={{
                  value: 'Target KPI',
                  angle: -90,
                  position: 'insideLeft',
                  dy: 35,
                }}
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
                domain={[0, getHighestColumnChartValue(transformedData, 'kpi')]}
                tickFormatter={compactNumberFormatter}
                interval={0}
                tick
              />
              <Bar
                name="Value"
                dataKey="value"
                onMouseEnter={() => handleMouseEnter('Value')}
                onMouseLeave={handleMouseLeave}
                onClick={(data) => onShowModal(data)}
                minPointSize={MIN_COLUMN_BAR_HEIGHT}
                cursor="pointer"
                isAnimationActive={activateAnimation}
              >
                <LabelList dataKey="percentage" position="top" fontSize={12} fill="#000" />
                {transformedData.map((data, index) => (
                  <Cell key={`cell-${index}`} fill={data.color} />
                ))}
              </Bar>
              <Line
                type="linear"
                dataKey="kpi"
                stroke="#000"
                isAnimationActive={activateAnimation}
                dot={lineDotBaseProps}
              >
                <LabelList dataKey="kpi" position="top" offset={10} fontSize={12} fill="#000" />
              </Line>
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
