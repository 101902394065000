import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type { ChartProps } from './chart.interface';
import useStartEndDates from './hooks/useStartEndDates';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import CustomGroupedColumnTable from '../CustomGroupedColumnTable/CustomGroupedColumnTable';

import { tableDataApi, useInjuryNumberSiteTableQuery } from '../../redux/config/table-data';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import type { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getRGBFromDecimal } from '../../helpers/getStatusColor';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinTableInjurySite({ widgetId, layoutProps }: ChartProps) {
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useInjuryNumberSiteTableQuery(
    {
      url: configurationData?.dataURL,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    {
      skip: !configurationData || (!view?.id && startDate === '' && endDate === ''),
    }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  const handleRefetch = useCallback(() => {
    dispatch(tableDataApi.util.invalidateTags([{ type: 'InjuryNumberSiteTable', id: widgetId }]));
  }, [widgetId]);

  /**
   * Creates a map of cell colors by converting each decimal
   * value to an RGB string using getRGBFromDecimal().
   */
  const cellColorMap: Map<string, string> = useMemo(() => {
    const colors = new Map();
    if (configurationData) {
      const gridCellBgColors = configurationData.widgetConfig?.gridCellBgColors;
      for (const key in gridCellBgColors) {
        colors.set(key, getRGBFromDecimal(Number(gridCellBgColors[key])));
      }
    }
    return colors;
  }, [configurationData]);

  return (
    <ChartGenericWrapper
      isFetching={isWidgetChartDataFetching}
      error={error}
      isError={isError}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!widgetChartData}
    >
      {widgetChartData && (
        <>
          <CustomGroupedColumnTable data={widgetChartData} gridCellColors={cellColorMap} />

          <div className="mt-4 flex w-full justify-center gap-2">
            {[...cellColorMap.entries()].map(([key, value]) => {
              // if key is multiple word, get only each first capital letter, else return whole key
              const splitKeys = key.split(' ');
              const formattedKey =
                splitKeys.length > 1 ? splitKeys.map((word: string) => word[0]).join('') : splitKeys[0];
              return (
                <div key={key} className="px-2" style={{ backgroundColor: value }} title={key}>
                  {formattedKey}
                </div>
              );
            })}
          </div>
        </>
      )}
    </ChartGenericWrapper>
  );
}
