import { IDataCommand } from '../data-transformer.interface';
import { ChartColumnResponseProps } from './column-chart-interfaces';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { Key } from 'react';
import { genericSort } from '../../components/Charts/generalFunctions/general-functions';
import { getSortDirection } from './sort-order-column-chart';

export class CustomSortOrderColumnChart
  implements IDataCommand<ChartColumnResponseProps[], WidgetConfigurationData, ChartColumnResponseProps[]>
{
  process(data: ChartColumnResponseProps[], config: WidgetConfigurationData): ChartColumnResponseProps[] {
    const sortOrder = config.widgetConfig?.sortOrder;
    const secondField = Boolean(config.widgetData?.secondField);

    // Check for the secondField, because its presence defines that a chart should be stacked or not
    // If second field is not included in the widget configuration, sort the fields as specified in the sortOrder configs
    if (!secondField && sortOrder) {
      const itemPositions: Record<string, number> = {};

      for (const [index, id] of sortOrder.entries()) {
        itemPositions[id] = index;
      }

      return data.sort((a, b) => itemPositions[a.name as Key] - itemPositions[b.name as Key]);
    } else {
      return genericSort(data, 'name', getSortDirection(config));
    }
  }
}
