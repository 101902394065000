import { WidgetConfigurationData } from '../../redux/config/widgetApi';

export const getTopAmountToDisplay = (configurationData: WidgetConfigurationData): number => {
  const top = configurationData.widgetConfig?.showTop;
  if (!top || top.toLocaleLowerCase() === 'all') {
    return -1;
  }
  switch (top) {
    case 'top3':
      return 3;
    case 'top5':
      return 5;
    case 'top10':
      return 10;
    case 'top30':
      return 30;
    default:
      return -1;
  }
};
