import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  DomElementAlignment,
  DropDown,
  DropDownMultiSelect,
  DropDownNoSelection,
  DropDownResult,
  HfDateTime,
  JsonData,
  OdinController,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  TabViewPanel,
  TabViewRef,
  isDropDownResult,
} from '@myosh/odin-components';
import { useGetHierarchyWithValueQuery } from '../../redux/config/hierarchy';
import { useForm } from 'react-hook-form';
import {
  GlobalFilterDateDetails,
  GlobalFilterDetails,
  GlobalFilterState,
  setGlobalDateFilters,
  selectSearchQuery,
} from '../../redux/slices/globalFilter/global-dates-filter-slice';
import { useDispatch, useSelector } from 'react-redux';
import { appendTimeUnit, formatDate } from '../../helpers/dateFormater';
import {
  useAddHierarchyFiltersMutation,
  useGetHierarchyFiltersQuery,
  useUpdateHierarchyFiltersMutation,
} from '../../redux/config/HierarchyFiltersApi';
import { Hierarchy, HierarchyFilterValues, HierarchyFilters, HierarchyValue } from '../../shared/Hierarchy';
import { MainLayoutProps } from './Header.interface';
import CloseableOverlay from '../common/closeable-overlay.component';
import FilterChips, { FilterChip } from '../common/filter-chips.component';
import { addHierarchyFilter } from '../../redux/slices/globalFilter/GlobalFilterHierarchySlice';
import DashboardTabView from '../DashboardTabView/dashboard-tab-view';
import { ToggleEditComponent } from './toggle-edit.component';
import { t } from 'i18next';
import { odinIconStyle } from '../../constants/common-style-constants';
import { getDropDownFilterValue } from '../../helpers/getDropDownFilterValue';
import { isEqual, orderBy } from 'lodash';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { TopMenuComponent } from '../TopMenu/top-menu-component';
import { useDashboardUser } from '../../hooks/useDashboardUser';
import { ViewHandlerComponent } from './view-components/view-handler.component';
import { quickDateRange } from './utilities';
import { useSelectedView } from '../Charts/hooks/use-selected-view';
import { useDateRangeContext } from '../../context/globalDateRange.context';
import { useConfiguredDateRanges } from '../../hooks/useConfiguredDateRanges';
import { ViewMenu } from './view-components/view-menu';
import { clearSelectedView } from '../../redux/slices/selected-view';

const formatDateToISOString = (dateStr: string | undefined): string => {
  return dateStr ? `${dateStr}T00:00:00` : '';
};

const DateDetailsGroupName = 'DateDetails';
const DateDetailsFieldNames = {
  dateStart: 'DateDetails.dateStart',
  dateEnd: 'DateDetails.dateEnd',
  quickDateRange: 'DateDetails.quickDateRange',
};

export const Header = ({ classNames }: MainLayoutProps) => {
  const { data: allExistingHierarchies, isLoading } = useGetHierarchyWithValueQuery();
  //hierachy filters that don't belong to a view
  const { data: appliedHierarchyFilters, isLoading: areHierarchyFiltersLoading } = useGetHierarchyFiltersQuery();
  const [addHierarchyFilters] = useAddHierarchyFiltersMutation();
  const [updateHierarchyFilters] = useUpdateHierarchyFiltersMutation();

  const { control, setValue, resetField } = useForm();
  const dispatch = useDispatch();

  //pre-defined ranges
  const dateRanges = useConfiguredDateRanges();
  const currentUser = useDashboardUser();
  const currentView = useSelectedView();
  //used to restore view filters
  const [currentViewCopy, setCurrentViewCopy] = useState<typeof currentView | undefined>(undefined);

  const [isHierarchyOpen, setIsHierarchyOpen] = useState<boolean>(false);
  const [dateRangeText, setDateRangeText] = useState<string>('');
  const [filterHasChanged, setFilterHasChanged] = useState(false);

  const showArchivedRef = useRef<boolean>();
  const globalFilterData = useSelector(selectSearchQuery);
  const globalDateLocalFilters = useRef<GlobalFilterDetails>();
  const globalFiltersAssigned = useRef<GlobalFilterState>();
  const [hiearchyFiltersUI, setHierarchyFiltersUI] = useState<HierarchyFilterValues>();
  const latestFiltersApplied = useRef<HierarchyFilters>();
  const hierachyFiltersLocalSelection = useRef<HierarchyFilters>();

  const dateRangeValue = useDateRangeContext();
  const tabsReference = useRef<TabViewRef>(null);
  const filterIconRef = useRef<HTMLDivElement>(null);

  const toggleHierarchyDropdown = () => {
    setIsHierarchyOpen(!isHierarchyOpen);
  };

  const closeHierarchyDropdown = () => {
    //Reset to the latest valid values
    setValue(DateDetailsGroupName, globalFiltersAssigned.current?.value?.DateDetails);
    setValue(DateDetailsFieldNames.quickDateRange, globalFiltersAssigned.current?.value?.DateDetails.quickDateRange);
    hierachyFiltersLocalSelection.current = latestFiltersApplied.current;
    globalDateLocalFilters.current = globalFiltersAssigned.current?.value;
    setIsHierarchyOpen(false);
    tabsReference.current?.setActiveTab(0);
  };

  const hierarchyFilterKeys = useMemo(() => {
    if (hiearchyFiltersUI) {
      const appliedFilterNames = Object.keys(hiearchyFiltersUI);
      if (allExistingHierarchies) {
        return orderBy(
          appliedFilterNames.map((filterName) => {
            const weight = allExistingHierarchies.find((hierarchy) => hierarchy.name === filterName)?.weight ?? 0;
            return { hierarchy: filterName, weight };
          }),
          ['weight', 'hierarchy']
        ).map((orderedFilter) => orderedFilter.hierarchy);
      }
      return appliedFilterNames;
    }
    return [];
  }, [hiearchyFiltersUI, allExistingHierarchies]);

  useEffect(() => {
    if (currentView !== undefined) {
      setCurrentViewCopy(currentView);
    }
  }, [currentView]);

  useEffect(() => {
    if (dateRangeValue?.DateDetails) {
      setValue(DateDetailsGroupName, dateRangeValue.DateDetails);
    }
  }, [dateRangeValue]);

  useEffect(() => {
    if (currentView) {
      if (currentView.hierarchyFilters) {
        setHierarchyFiltersUI(currentView.hierarchyFilters.hierarchyValues);
      } else {
        setHierarchyFiltersUI(undefined);
      }
      if (hierachyFiltersLocalSelection.current) {
        hierachyFiltersLocalSelection.current = {
          ...hierachyFiltersLocalSelection.current,
          values: currentView.hierarchyFilters?.hierarchyValues ?? {},
        };
      }
      if (currentView.dateFiltersForView) {
        const updateDateFilter: GlobalFilterDateDetails = {
          dateStart: configureWithTime(currentView.dateFiltersForView.fromDate),
          dateEnd: configureWithTime(currentView.dateFiltersForView.toDate),
          quickDateRange: currentView.quickDateRange ?? quickDateRange,
        };

        globalFiltersAssigned.current = { value: { DateDetails: updateDateFilter } };
        globalDateLocalFilters.current = { DateDetails: updateDateFilter };
        setDateRangeText(updateDateFilter.quickDateRange?.text ?? '');
        setValue(DateDetailsGroupName, updateDateFilter);
        setValue(DateDetailsFieldNames.quickDateRange, updateDateFilter.quickDateRange);
      }
    }
  }, [currentView]);

  const handleFieldChange = (name: string, value: string | DropDownResult) => {
    //make sure the latest values are read
    let formDateDetails = globalDateLocalFilters.current;
    const [groupName, fieldName] = name.split('.');
    const currentDataTab = isDropDownResult(value)
      ? dateRanges?.find((dataTab) => dataTab.id === value.value)
      : undefined;
    //Predefined selection is chosen
    if (fieldName === 'quickDateRange' && currentDataTab) {
      const dateDetails = {
        DateDetails: {
          dateStart: configureWithTime(currentDataTab.from),
          dateEnd: configureWithTime(currentDataTab.to),
          quickDateRange: {
            value: currentDataTab.id || '',
            text: currentDataTab.caption || '',
          },
        },
      };
      setValue(DateDetailsGroupName, dateDetails.DateDetails);

      formDateDetails = dateDetails;
    } else if (
      groupName === DateDetailsGroupName &&
      (fieldName === 'dateStart' || fieldName === 'dateEnd') &&
      formDateDetails
    ) {
      //quick range must be changed to custom if a user selects own range
      setValue(DateDetailsFieldNames.quickDateRange, quickDateRange);
      formDateDetails = {
        DateDetails: {
          ...(formDateDetails?.DateDetails ?? {}),
          [fieldName]: value || '',
          quickDateRange,
        },
      };
    } else if (groupName === DateDetailsGroupName && formDateDetails) {
      formDateDetails = {
        DateDetails: {
          ...formDateDetails?.DateDetails,
          [fieldName]: value || '',
        },
      };
    }
    globalDateLocalFilters.current = formDateDetails;
  };

  const convertForDropDownHierarchies = useCallback(
    (values?: HierarchyFilterValues) => {
      const hierarchiesValuesMapLocal: Record<string, DropDownResult[]> = {};
      if (values && allExistingHierarchies) {
        for (let j = 0; j < hierarchyFilterKeys.length; j++) {
          const currentFilterKey = hierarchyFilterKeys[j];
          for (let i = 0; i < allExistingHierarchies.length; i++) {
            if (currentFilterKey === allExistingHierarchies[i].name) {
              hierarchiesValuesMapLocal[currentFilterKey] = allExistingHierarchies[i].values
                .filter((data: HierarchyValue) => values[currentFilterKey].includes(data.name))
                .map((data: HierarchyValue) => {
                  return {
                    value: data.id,
                    text: data.name,
                  } as DropDownResult;
                });
            }
          }
        }
      }
      return hierarchiesValuesMapLocal;
    },
    [allExistingHierarchies, hierarchyFilterKeys]
  );
  const hierarchiesValuesMap: Record<string, DropDownResult[]> = useMemo(() => {
    return convertForDropDownHierarchies(hiearchyFiltersUI);
  }, [hiearchyFiltersUI, hierarchyFilterKeys]);

  useEffect(() => {
    // Check current applied hierarchy filters value,
    // if values are selected save those values at local-storage
    dispatch(addHierarchyFilter(appliedHierarchyFilters?.values ?? {}));
  }, [appliedHierarchyFilters?.values]);

  useEffect(() => {
    if (appliedHierarchyFilters) {
      showArchivedRef.current = appliedHierarchyFilters.showArchived;
    }
    latestFiltersApplied.current = appliedHierarchyFilters;
    hierachyFiltersLocalSelection.current = appliedHierarchyFilters;
    setHierarchyFiltersUI(appliedHierarchyFilters?.values);
  }, [appliedHierarchyFilters]);

  const handleHierarchyFiltersChange = (
    name: string,
    value: DropDownResult[] | DropDownNoSelection | DropDownResult | undefined
  ) => {
    const userId = currentUser?.id;
    if (userId) {
      const currentFilters: HierarchyFilters = {
        id: userId,
        values: {
          ...hierachyFiltersLocalSelection.current?.values,
          [name]: Array.isArray(value) ? value.map((data: DropDownResult) => data.text) : [],
        },
        showArchived: showArchivedRef.current,
      };

      Object.keys(currentFilters.values).forEach((key: string) => {
        if (currentFilters.values[key].length === 0) {
          delete currentFilters.values[key];
        }
      });
      hierachyFiltersLocalSelection.current = currentFilters;
    }
  };

  useEffect(() => {
    globalFiltersAssigned.current = globalFilterData;
    globalDateLocalFilters.current = globalFilterData.value;
    setDateRangeText(getValueOrDefault(globalFilterData.value?.DateDetails?.quickDateRange?.text, ''));
  }, [globalFilterData]);

  const quickDateRangleHandler = (value: DropDownResult) => {
    handleFieldChange(DateDetailsFieldNames.quickDateRange, value as DropDownResult);
  };

  /**
   * Unmount hierarchies when dialog is closed. Makes sure the valid values are shown as selected
   */
  const hierarchiesComponents = useMemo(() => {
    return isHierarchyOpen
      ? allExistingHierarchies?.map((hierarchy: Hierarchy) => (
          <DropDownMultiSelect
            key={hierarchy.name}
            name={hierarchy.name}
            label={hierarchy.name}
            data={hierarchy.values.filter((el) => (showArchivedRef.current ? { ...el } : !el.archived))}
            value={hierarchiesValuesMap[hierarchy.name] ?? []}
            allowClear={true}
            placeholder={`Select ${hierarchy.name}`}
            textField="name"
            valueField="id"
            onChange={(value: DropDownResult | DropDownResult[] | DropDownNoSelection | undefined) =>
              handleHierarchyFiltersChange(hierarchy.name, getDropDownFilterValue(value))
            }
            labelStyles="font-bold text-xs text-gray-1 "
            className="mb-4 inline-block overflow-hidden py-0.5 pl-1.5 text-gray-2"
            allowSelectAll={false}
          />
        ))
      : [];
  }, [allExistingHierarchies, hierarchiesValuesMap, isHierarchyOpen]);

  const applyClickHandler = useCallback(() => {
    setIsHierarchyOpen(false);
    if (currentView) {
      dispatch(clearSelectedView());
    }
    if (
      hierachyFiltersLocalSelection.current &&
      !isEqual(latestFiltersApplied.current, hierachyFiltersLocalSelection.current)
    ) {
      if (latestFiltersApplied.current?.id) {
        updateHierarchyFilters(hierachyFiltersLocalSelection.current);
      } else {
        addHierarchyFilters(hierachyFiltersLocalSelection.current);
      }
    }
    dispatch(setGlobalDateFilters(globalDateLocalFilters.current));
    setFilterHasChanged(true);
  }, [currentView]);

  const handleChipsClick = useCallback((tabIndex: number, isOpened: boolean) => {
    if (!isOpened) {
      tabsReference.current?.setActiveTab(tabIndex);
      toggleHierarchyDropdown();
    }
  }, []);
  //called from view-save-modal component to disable the restore view menu option
  const viewSaved = () => {
    setFilterHasChanged(false);
  };

  const restoreCurrentView = () => {
    const origHierarchyFilters: HierarchyFilters = {
      id: currentViewCopy?.userId ?? '',
      values: currentViewCopy?.hierarchyFilters?.hierarchyValues ?? {},
      showArchived: currentViewCopy?.hierarchyFilters?.showArchived,
    };

    updateHierarchyFilters(origHierarchyFilters);

    const origFilterDetails = currentViewCopy
      ? {
          DateDetails: {
            dateStart: formatDateToISOString(currentViewCopy.dateFiltersForView?.fromDate) ?? '',
            dateEnd: formatDateToISOString(currentViewCopy.dateFiltersForView?.toDate) ?? '',
            quickDateRange: currentViewCopy.quickDateRange,
          },
        }
      : undefined;

    resetField(DateDetailsFieldNames.quickDateRange, { defaultValue: origFilterDetails?.DateDetails.quickDateRange });
    resetField(DateDetailsFieldNames.dateStart, { defaultValue: origFilterDetails?.DateDetails.dateStart });
    resetField(DateDetailsFieldNames.dateEnd, { defaultValue: origFilterDetails?.DateDetails.dateEnd });

    dispatch(setGlobalDateFilters(origFilterDetails));
    setFilterHasChanged(false);
  };

  return (
    <>
      <div className={classNames}>
        <div className="flex flex-row">
          <ViewHandlerComponent />
          <div className="flex flex-row items-center justify-between pl-3.5 text-sm">
            <div className="flex flex-row justify-between" ref={filterIconRef} onClick={toggleHierarchyDropdown}>
              <OdinIcon icon="Filter" type={OdinIconType.Line} size={OdinIconSize.Small} className={odinIconStyle} />
            </div>
          </div>
          <CloseableOverlay
            target={filterIconRef.current}
            loading={isLoading}
            visible={isHierarchyOpen}
            alignment={DomElementAlignment.TopLeft}
            onHidden={closeHierarchyDropdown}
          >
            <DashboardTabView
              styles={{
                navigationStyles:
                  'list-none flex-grow flex flex-row overflow-x-scroll no-scrollbar fix-ul bg-transparent',
                panelStyles: 'overflow-x-hidden overflow-y-hidden p-0',
              }}
              ref={tabsReference}
            >
              <TabViewPanel header="Date Range">
                <OdinController
                  name={DateDetailsFieldNames.quickDateRange}
                  control={control}
                  onChange={quickDateRangleHandler}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DropDown
                        name={DateDetailsFieldNames.quickDateRange}
                        allowClear={true}
                        allowSearch
                        value={value}
                        data={dateRanges as JsonData}
                        label="Quick Date Range"
                        placeholder={dateRangeText}
                        setDefaultValue={false}
                        textField="caption"
                        className="mb-4 overflow-hidden py-0.5 pl-1.5 text-sm text-gray-2"
                        valueField="id"
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <div className="flex gap-x-2.5 text-sm text-mono-0">
                  <HfDateTime
                    control={control}
                    name={DateDetailsFieldNames.dateStart}
                    label="From"
                    onChange={(value) => {
                      handleFieldChange(
                        DateDetailsFieldNames.dateStart,
                        configureWithTime(formatDate(value as string))
                      );
                    }}
                  />
                  <HfDateTime
                    control={control}
                    name={DateDetailsFieldNames.dateEnd}
                    label="To"
                    onChange={(value) => {
                      handleFieldChange(DateDetailsFieldNames.dateEnd, configureWithTime(formatDate(value as string)));
                    }}
                  />
                </div>
              </TabViewPanel>
              <TabViewPanel header="Hierarchy">
                <Checkbox
                  name="showArchivedHierarchies"
                  label={t('showArchivedHierarchies')}
                  onChange={(value) => {
                    if (value) {
                      showArchivedRef.current = value.checked;
                      handleHierarchyFiltersChange('', undefined);
                    }
                  }}
                  initialChecked={appliedHierarchyFilters?.showArchived}
                />
                <div className="flex flex-col">{hierarchiesComponents}</div>
              </TabViewPanel>
            </DashboardTabView>
            <Button onClick={applyClickHandler}>{String(t('applyFilter'))}</Button>
          </CloseableOverlay>
          <div className="flex flex-1 flex-wrap">
            <div
              className="my-1 flex items-center pl-3"
              onClick={() => {
                handleChipsClick(0, isHierarchyOpen);
              }}
            >
              {dateRangeText && (
                <>
                  <h1 className="text-sm font-normal">Date Range</h1>
                  <FilterChip text={dateRangeText} />
                </>
              )}
            </div>

            <div className="flex flex-1 flex-wrap">
              <div
                className="my-1 flex flex-row items-center pl-3"
                onClick={() => {
                  handleChipsClick(1, isHierarchyOpen);
                }}
              >
                {!areHierarchyFiltersLoading && (
                  <>
                    <h1 className="text-sm font-normal">Hierarchy</h1>
                    {hierarchyFilterKeys?.length === 0 ? (
                      <FilterChips chips={['All']} />
                    ) : (
                      hiearchyFiltersUI &&
                      hierarchyFilterKeys?.map((hierarchy) => (
                        <FilterChip
                          key={hierarchy}
                          text={`${hierarchy} (${hiearchyFiltersUI[hierarchy].length ?? 0})`}
                          tooltip={[...(hiearchyFiltersUI[hierarchy] ?? [])]
                            .sort((value1, value2) => value1.localeCompare(value2))
                            .join(', ')}
                        />
                      ))
                    )}
                  </>
                )}
              </div>
              <div className="group relative">
                <div className="mb-0 pl-3.5 pt-2 text-sm">
                  <OdinIcon icon="More" type={OdinIconType.Line} size={OdinIconSize.Small} className={odinIconStyle} />
                </div>
                <div className="absolute right-0 z-50 mt-1 hidden rounded-md bg-mono-1 shadow-md group-focus-within:block group-hover:block">
                  <ViewMenu
                    restoreCurrentView={restoreCurrentView}
                    filterHasChanged={filterHasChanged}
                    viewSaved={viewSaved}
                  />
                </div>
              </div>
            </div>
          </div>
          <ToggleEditComponent />
          <TopMenuComponent />
        </div>
      </div>
    </>
  );
};

const configureWithTime = (time?: string | Date): string => {
  return appendTimeUnit(String(time));
};
