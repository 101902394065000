import { Key, useEffect, useMemo, useState } from 'react';
import { DataGrid, DataGridColumnSettings, DataGridSettings } from '@myosh/odin-components';
import { useSelector } from 'react-redux';

import useStartEndDates from './hooks/useStartEndDates';
import { ChartProps } from './chart.interface';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import { tableDataApi, useManagementTableDataQuery } from '../../redux/config/table-data';
import { ManagementDataColumnProps } from '../../redux/config/table-data.interfaces';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { useSelectedView } from './hooks/use-selected-view';

export interface ManagementTableDataProps {
  firstField: string;
  [field: string]: Key;
}

export default function OdinManagementTableChart({ widgetId, layoutProps }: ChartProps) {
  const [managementTableData, setManagementTableData] = useState<Array<ManagementTableDataProps>>();
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data,
    isLoading,
    isFetching,
    isUninitialized: isDataUninitialized,
    isError,
    error,
  } = useManagementTableDataQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const firstKey = Object.keys(data);
      if (firstKey.length > 0) {
        const gridColumns: Array<DataGridColumnSettings> = data[firstKey[0]].map((column) => {
          return {
            id: column.field,
            title: column.field,
            field: column.field,
            visible: true,
          };
        });

        setColumns([
          { id: 'firstField', title: '', field: 'firstField', visible: true, isIdField: true },
          ...gridColumns,
        ]);
      }
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const dataGridRows: Array<ManagementTableDataProps> = [];

      // Check if every key in `data` object have an empty array
      let allEmptyArray = true;
      for (const key in data) {
        if (!allEmptyArray || data[key].length !== 0) {
          allEmptyArray = false;
          break;
        }
      }

      if (!allEmptyArray) {
        for (const key in data) {
          const gridRowData = data[key].reduce(
            (
              previousValue: ManagementTableDataProps | ManagementTableDataProps[],
              currentValue: ManagementDataColumnProps
            ) => {
              previousValue = {
                ...previousValue,
                firstField: key,
                [currentValue.field]: parseFloat(currentValue.value).toFixed(1),
              };
              return previousValue;
            },
            []
          );

          dataGridRows.push(gridRowData as ManagementTableDataProps);
        }
      }

      setManagementTableData(dataGridRows);
    }
  }, [data, isLoading, isFetching]);

  const gridSettings = useMemo<DataGridSettings | undefined>(() => {
    if (columns && columns.length > 0) {
      return {
        columns,
        fullHeight: true,
      };
    }
  }, [columns]);

  const handleRefetch = () => {
    if (!isDataUninitialized && managementTableData && !isFetching) {
      dispatch(tableDataApi.util.invalidateTags([{ type: 'ManagementTableData', id: `LIST-${widgetId}` } as const]));
    }
  };

  return (
    <ChartGenericWrapper
      isLoading={isLoading}
      isFetching={isFetching}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={managementTableData}
      renderNoDataByDefault={!isFetching && !managementTableData?.length}
    >
      {gridSettings && managementTableData && (
        <DataGrid data={managementTableData} gridSettings={gridSettings} showSettings={false} />
      )}
    </ChartGenericWrapper>
  );
}
