import React, { useEffect, useRef, useState } from 'react';
import { Button, DropDownResult, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { cloneDeep, compact, size } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  CustomFiltersType,
  ExistingFilterType,
  useAddCustomFiltersMutation,
  useGetWidgetCustomFiltersQuery,
  useLazyGetCustomFiltersQuery,
  useUpdateCustomFiltersMutation,
} from '../../redux/config/CustomFiltersApi';
import CloseableOverlay, { DomElementAlignment, DomTargetPosition } from '../common/closeable-overlay.component';
import { FilterChip } from '../common/filter-chips.component';
import OdinDropDownMultiSelect from '../common/dropdown-multi-select.component';
import { BaseStackTypes, DateRangeTypes, SelectionStackTypes } from '../../constants/custom-filter.enums';
import UserDropDownMultiSelect from '../common/user-dropdown-multi-select';
import { OdinDataLabelMessage } from '../common/custom-info-message.component';
import UserOdinInput from '../common/input.component';
import { odinIconStyle } from '../../constants/common-style-constants';
import { getDropDownFilterValue } from '../../helpers/getDropDownFilterValue';
import CustomDateFieldRange from '../CustomDateFieldRange/CustomDateFieldRange';
import { getAssignedDatesFromFilter } from '../../helpers/dateFormater';
import { useDashboardUser } from '../../hooks/useDashboardUser';

export interface CustomFiltersProps {
  id: string;
  onFilterStateChanged: (widgetId: number) => void;
}
type CheckTypesProps = {
  validation: boolean;
  children: React.ReactNode;
};
const RenderCheckTypes: React.FC<CheckTypesProps> = ({ validation, children }) => <>{validation && children}</>;

export const OdinCustomFilters = ({ id, onFilterStateChanged }: CustomFiltersProps) => {
  const [isCustomFiltersOpen, setIsCustomFiltersOpen] = useState<boolean>(false);
  //need a separate state to make sure that items are sorted and ordered correctly
  //modfying original array results in errors due to read-only state
  const [customFiltersData, setCustomFiltersData] = useState<Array<CustomFiltersType>>([]);
  const targetRef = useRef<HTMLDivElement>(null);
  const user = useDashboardUser();
  const { t } = useTranslation();
  const [getCustomFilters, { data: persistedFiltersData, isLoading, isFetching }] = useLazyGetCustomFiltersQuery();
  const { data: widgetFilters } = useGetWidgetCustomFiltersQuery({ widgetId: id });
  const applicableTypes = { ...SelectionStackTypes, ...BaseStackTypes, ...DateRangeTypes };

  const [addCustomFilters] = useAddCustomFiltersMutation();
  const [updateCustomFilters] = useUpdateCustomFiltersMutation();

  useEffect(() => {
    getCustomFilters({ widgetId: id });
  }, []);

  useEffect(() => {
    if (persistedFiltersData && !isFetching) {
      setCustomFiltersData(cloneDeep(persistedFiltersData));
    }
  }, [persistedFiltersData, isFetching]);

  const toggleCustomFiltersDropdown = () => {
    setIsCustomFiltersOpen(!isCustomFiltersOpen);
  };

  const closeCustomFiltersDropdown = () => {
    setIsCustomFiltersOpen(false);
    if (persistedFiltersData && !isFetching) {
      setCustomFiltersData(cloneDeep(persistedFiltersData));
    }
  };
  const handleCustomFiltersChange = (fieldName: string, value: DropDownResult[]) => {
    const newValues = customFiltersData?.map((tab: CustomFiltersType) => {
      if (tab.field === fieldName) {
        return { ...tab, currentValues: value.map((values) => String(values.value)) };
      } else {
        return tab;
      }
    });
    setCustomFiltersData(newValues);
  };

  const applyFilters = () => {
    const userId = user?.id;

    const transformedFilters = customFiltersData
      .filter((item) => item.field !== undefined && item.currentValues && item.currentValues.length > 0)
      .map((item) => item as ExistingFilterType)
      // Aggregates filters into an object of a type {fieldName1 : ["X"], fieldName2 : ["Y"]}
      .reduce<{ [x: string]: string[] }>(
        (ac, appliedFilter) => ({ ...ac, [appliedFilter.field]: appliedFilter.currentValues }),
        {}
      );
    const currentFilters = {
      id: `${userId} ${id}`,
      userId: userId,
      widgetId: id,
      value: {
        ...transformedFilters,
      },
    };

    const currentFiltersKeys = Object.keys(currentFilters.value);
    for (let i = 0; i < currentFiltersKeys.length; i++) {
      if (
        currentFilters &&
        currentFilters.value &&
        (currentFilters.value[currentFiltersKeys[i]].length === 0 ||
          currentFilters.value[currentFiltersKeys[i]][0] === '')
      ) {
        delete currentFilters?.value[currentFiltersKeys[i]];
      }
    }
    // A separate API must be used to update & create new filter settings
    // for a user & widget combination
    if (widgetFilters && widgetFilters.value) {
      updateCustomFilters(currentFilters)
        .unwrap()
        .then(() => {
          onFilterStateChanged(Number(id));
        });
    } else {
      addCustomFilters(currentFilters)
        .unwrap()
        .then(() => {
          onFilterStateChanged(Number(id));
        });
    }
    closeCustomFiltersDropdown();
  };

  const sortedAlphaArray = (props: Array<string>) => {
    // eslint-disable-next-line react/prop-types
    return props.sort((a: string, b: string) =>
      a.localeCompare(b, undefined, {
        numeric: true,
      })
    );

    // eslint-enable react/prop-types
  };

  return (
    <>
      <div className="flex flex-row">
        <div className="flex flex-row items-center justify-between text-sm">
          <div className={'z-10 flex flex-row justify-between'} ref={targetRef} onClick={toggleCustomFiltersDropdown}>
            <OdinIcon
              icon="Filter"
              type={size(widgetFilters?.value) > 0 ? OdinIconType.Fill : OdinIconType.Line}
              size={OdinIconSize.Small}
              className={odinIconStyle}
            />
          </div>
        </div>
        <CloseableOverlay
          target={targetRef.current}
          loading={isLoading}
          visible={isCustomFiltersOpen}
          onHidden={closeCustomFiltersDropdown}
          alignment={DomElementAlignment.BottomLeft}
          position={DomTargetPosition.BottomRight}
          closeOnScroll={true}
          classNames="h-72 pt-5 justify-evenly"
        >
          <div className="flex h-full grow flex-col">
            {isCustomFiltersOpen && customFiltersData && (
              <>
                <div className="custom-scroll flex h-full grow flex-col overflow-y-auto">
                  {customFiltersData.map((el: CustomFiltersType, index: number) => (
                    <div key={el.field} className="flex flex-col">
                      <RenderCheckTypes validation={Object.keys(BaseStackTypes).includes(el.type || '')}>
                        <UserOdinInput
                          id={id}
                          field={el.field}
                          onChange={(value) => handleCustomFiltersChange(el.field || '', getDropDownFilterValue(value))}
                          currentValues={sortedAlphaArray(el?.currentValues as Array<string>) || []}
                        />
                      </RenderCheckTypes>
                      <RenderCheckTypes
                        validation={
                          Object.keys(SelectionStackTypes).includes(el.type || '') && !(el.subType === 'PERSON_FIELD')
                        }
                      >
                        {el.potentialValues && el.potentialValues.length > 0 ? (
                          <OdinDropDownMultiSelect
                            key={index}
                            data={sortedAlphaArray(el.potentialValues)}
                            currentValues={el.currentValues}
                            field={el.field}
                            onChange={(value) =>
                              handleCustomFiltersChange(el.field || '', getDropDownFilterValue(value))
                            }
                          />
                        ) : (
                          <OdinDataLabelMessage field={el.field} text={t('noData')} />
                        )}
                      </RenderCheckTypes>
                      <RenderCheckTypes validation={el.subType === 'PERSON_FIELD'}>
                        {(el.field && el.potentialValuesURL) || el.subType ? (
                          <UserDropDownMultiSelect
                            currentValues={el.currentValues}
                            field={el.field || ''}
                            onChange={(value) =>
                              handleCustomFiltersChange(el.field || '', getDropDownFilterValue(value))
                            }
                          />
                        ) : (
                          <OdinDataLabelMessage field={el.field} text={t('noData')} />
                        )}
                      </RenderCheckTypes>
                      <RenderCheckTypes validation={el.type ? Object.keys(DateRangeTypes).includes(el.type) : false}>
                        <CustomDateFieldRange
                          {...el}
                          handleCustomFiltersChange={(value) => el.field && handleCustomFiltersChange(el.field, value)}
                        />
                      </RenderCheckTypes>
                      <RenderCheckTypes validation={!Object.keys(applicableTypes).includes(el.type || '')}>
                        <OdinDataLabelMessage field={el.field} text={t('coming_soon')} />
                      </RenderCheckTypes>
                    </div>
                  ))}
                </div>
                <Button classNames="!w-fit" onClick={applyFilters}>
                  {t('apply')}
                </Button>
              </>
            )}
          </div>
        </CloseableOverlay>

        {persistedFiltersData && !isFetching && (
          <div className="flex items-center pl-3" onClick={toggleCustomFiltersDropdown}>
            {compact(
              persistedFiltersData.map(({ field, type, currentValues }) => {
                if (currentValues && currentValues.length > 0) {
                  return (
                    <FilterChip
                      key={field}
                      text={`${field} (${currentValues.length})`}
                      tooltip={getAssignedDatesFromFilter(currentValues).join(getDelimiterType(type))}
                    />
                  );
                }
              })
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const getDelimiterType = (fieldType?: string) => {
  return fieldType === 'DATETIME' ? ' - ' : ', ';
};
