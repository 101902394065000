import React from 'react';
import { Customized, Text } from 'recharts';
import { AxisProps } from '../../shared/widget-data.interfaces';

/**

CustomXAxis component for displaying the X-axis in a chart.
@param displayShortMonth - Optional flag to display a shortened month value.
@returns - The rendered component.
*/

export const CustomXAxis = <T,>(
  { x, y, payload: { offset, value } }: AxisProps,
  displayShortMonth?: boolean,
  transformData?: T[]
) => {
  const chartWidth = Number(offset);
  const rotateLabels = (chartWidth < 30 && chartWidth !== 0) || ((transformData && transformData.length > 20) ?? false);
  const textWidth = rotateLabels ? 120 : chartWidth > 100 ? chartWidth : Math.min(chartWidth * 2.5, 120);
  return (
    <CustomizedTextWithTooltip
      x={x}
      y={y}
      textWidth={textWidth}
      rotateLabel={rotateLabels}
      value={displayShortMonth ? value.substring(0, 3) : value}
    />
  );
};

export const XAxisLineChart = ({ x, y, payload, visibleTicksCount, index }: AxisProps) => {
  const rotateLabels = visibleTicksCount > 8;
  const textWidth = rotateLabels || visibleTicksCount - 1 === index ? 120 : 150;
  return (
    <CustomizedTextWithTooltip x={x} y={y} textWidth={textWidth} rotateLabel={rotateLabels} value={payload.value} />
  );
};

type TextElWithTooltipType = {
  value?: string;
  x: number;
  y: number;
  rotateLabel: boolean;
  textWidth: number;
};

const getAttributeFromCondition = <T,>(conditon: boolean, first: T, second: T): T => {
  return conditon ? first : second;
};
const CustomizedTextWithTooltip = ({ value, x, y, rotateLabel, textWidth }: TextElWithTooltipType) => {
  return value ? (
    <Customized
      component={
        <>
          <title>{value}</title>
          <Text
            x={x}
            y={y}
            fontSize={getAttributeFromCondition(rotateLabel, 8, 10.5)}
            verticalAnchor="start"
            textAnchor={getAttributeFromCondition(rotateLabel, 'end', 'middle')}
            angle={getAttributeFromCondition(rotateLabel, -40, 0)}
            width={textWidth}
            maxLines={getAttributeFromCondition(rotateLabel, 1, 2)}
          >
            {value}
          </Text>
        </>
      }
    />
  ) : (
    <></>
  );
};
