import React from 'react';
import { getDelimiterType } from '../CustomFilterComponent/CustomFilterComponent';

import { CustomFiltersType } from '../../redux/config/CustomFiltersApi';
import { getAssignedDatesFromFilter } from '../../helpers/dateFormater';

export interface ChartBottomPanelInterface {
  widgetId: string;
  data?: CustomFiltersType[];
  isFetching?: boolean;
}

export default function ExportChartBottomPanel({ data, isFetching }: ChartBottomPanelInterface) {
  return (
    <>
      {data && !isFetching && (
        <div className="flex items-center text-base">
          {data
            .filter((item) => item.currentValues && item.currentValues.length > 0)
            .map(({ field, type, currentValues }) => {
              return `${field} : ${getAssignedDatesFromFilter(currentValues ?? []).join(getDelimiterType(type))}`;
            })
            .join('; ')}
        </div>
      )}
    </>
  );
}
