import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { CombineFirstSecondFields } from '../common-commands/combine-first-second-fields';
import { FirstSecondFieldsDataType } from '../common-commands/common-data.interface';
import { DeleteUndefinedAttributes } from '../common-commands/delete-undefined-attributes';
import { FirstFields } from '../common-commands/first-fields';
import { SecondFields } from '../common-commands/second-fields';
import { SortByField } from '../common-commands/sort-by-field';
import { DataCommandResponse, IDataCommand, IDataCommandFactory } from '../data-transformer.interface';

export class LineChartCommandFactory implements IDataCommandFactory {
  private currentCommandIndex = 0;
  private readonly commands: Array<
    IDataCommand<FirstSecondFieldsDataType, WidgetConfigurationData, FirstSecondFieldsDataType>
  >;

  constructor() {
    this.commands = [
      new FirstFields(),
      new SecondFields(),
      new CombineFirstSecondFields(),
      new SortByField<ChartResponse[]>(),
      new DeleteUndefinedAttributes<ChartResponse>(),
    ];
  }

  getNextCommand<TIn, TInConfig, TOut>(): DataCommandResponse<TIn, TInConfig, TOut> | undefined {
    const command = this.commands[this.currentCommandIndex] as IDataCommand<TIn, TInConfig, TOut>;
    return (
      command && {
        command,
        commandIndex: this.currentCommandIndex++,
      }
    );
  }
}
