import React from 'react';
import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';

export interface ActionIconProps {
  icon: string;
  onClick: () => void;
  hidden?: boolean;
  iconTheme?: 'primary' | 'default';
}

const ActionIcon = ({ icon, onClick, hidden = false, iconTheme = 'primary' }: ActionIconProps) => {
  const getButtonClass = () => {
    let className = `action-icon text-primary-1 mr-2 ${iconTheme}`;
    if (hidden) {
      className += ' hidden';
    }

    return className;
  };

  return (
    <IconButton classNames={getButtonClass()} onClick={onClick}>
      <OdinIcon icon={icon} size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} className="action-icon__icon" />
    </IconButton>
  );
};

export default ActionIcon;
