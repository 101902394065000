import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Roles'],
  endpoints: (builder) => ({
    getRoles: builder.query<Array<string>, void>({
      query: () => ({
        url: `/roles`,
        method: 'GET',
      }),
      providesTags: ['Roles'],
    }),
  }),
});

export const { useGetRolesQuery } = rolesApi;
