import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Legend, PieChart, ResponsiveContainer, Tooltip, LegendProps, Cell, Pie } from 'recharts';

import { usePieChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { ChartProps } from './chart.interface';
import useStartEndDates from './hooks/useStartEndDates';
import { renderLegend } from '../common/custom-legend.component';
import { DataExecutor } from '../../data-transformer/data-executor';
import { BuildPieChartValuesByValues } from '../../data-transformer/pie-chart/build-pie-chart-values-by-values';
import { PieChartValues } from '../../shared/widget-data.interfaces';
import PieChartLabel from '../common/custom-label.component';
import { FilterEmptyText } from '../../data-transformer/common-commands/filter-empty-text';
import { DeleteUndefinedAttributes } from '../../data-transformer/common-commands/delete-undefined-attributes';
import OdinCustomDetailsWindow, { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import { LegendPayloadOnClick } from './chart-events';
import { SortByCustomSorting } from '../../data-transformer/column-line-chart/data-commands/sort-by-custom-sorting';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinPieChart({ widgetId, layoutProps }: ChartProps) {
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [transformedData, setTransformedData] = useState<Array<PieChartValues>>();
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CustomModalProps>();
  const [customColors, setCustomColors] = useState<CustomColorProps[]>();
  const { startDate, endDate } = useStartEndDates();
  const view = useSelectedView();

  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const widgets = useSelector(getWidgets);

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = usePieChartQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgetChartData && configurationData && !isWidgetChartDataFetching) {
      const commandExecutor = new DataExecutor();
      const dataOfPieChart = commandExecutor.execute(
        new BuildPieChartValuesByValues(),
        widgetChartData,
        configurationData
      );

      const colors: CustomColorProps[] = dataOfPieChart.map((element) => {
        return {
          label: element.field,
          color: element.color,
        };
      });
      setCustomColors(colors);

      const dataOfPieChartFiltered = commandExecutor.execute(
        new FilterEmptyText<PieChartValues>(),
        dataOfPieChart,
        configurationData
      );

      const removedUndefinedResponse = commandExecutor.execute(
        new DeleteUndefinedAttributes<PieChartValues>(),
        dataOfPieChartFiltered,
        configurationData
      );
      if (configurationData.widgetConfig?.sortOrder) {
        setTransformedData(
          commandExecutor.execute(new SortByCustomSorting(), removedUndefinedResponse, configurationData)
        );
      } else {
        setTransformedData(removedUndefinedResponse);
      }
    }
  }, [widgetChartData, configurationData?.widgetConfig, isWidgetChartDataFetching]);

  const legendSelection = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };
  const customPayload = transformedData?.map((data: PieChartValues) => ({
    ...data,
    value: data.field,
    color: legendProps != null && legendProps[data.field as string] ? '#808080' : data.color,
    fill: data.color,
    type: 'rect',
  })) as LegendProps['payload'];

  const pieChartData = useMemo(() => {
    if (transformedData) {
      return transformedData.filter((item) => !(item.field && legendProps[item.field]));
    }
    return [];
  }, [legendProps, transformedData]);

  const onShowModal = (data: CustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };

  const onDialogHidden = () => setDetailsWindowVisible(false);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  return (
    <ChartGenericWrapper
      widgetId={widgetId}
      isLoading={isLoading}
      isFetching={isWidgetChartDataFetching}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetConfigurationData={configurationData && { ...configurationData, colors: customColors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.field}
            secondFieldValue={modalData?.tooltipPayload && modalData.tooltipPayload[0]?.dataKey}
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
          />
          <ResponsiveContainer height="99%">
            <PieChart>
              <Legend onClick={(e) => legendSelection(e)} formatter={renderLegend} payload={customPayload} />
              {pieChartData.length > 0 ? (
                <>
                  <Tooltip content={<CustomTooltip type="pie" />} cursor={false} isAnimationActive={false} />
                  <Pie
                    name="Quantity"
                    paddingAngle={0}
                    minAngle={1}
                    data={pieChartData}
                    dataKey="value"
                    nameKey="field"
                    label={(props) => (
                      <PieChartLabel
                        {...props}
                        labelFormat={configurationData?.widgetConfig?.labelFormat}
                        labelDecimals={configurationData?.widgetConfig?.labelDecimals}
                      />
                    )}
                    labelLine={false}
                    outerRadius="90%"
                    onClick={(data) => onShowModal(data)}
                    onMouseEnter={() => handleMouseEnter('Quantity')}
                    onMouseLeave={handleMouseLeave}
                    isAnimationActive={false}
                    cursor="pointer"
                  >
                    {pieChartData.map((entry, index: number) => (
                      <Cell key={`cell-${index}`} fill={entry.color} style={{ outline: 'none' }} />
                    ))}
                  </Pie>
                </>
              ) : (
                // Show empty Pie chart
                <Pie
                  dataKey="value"
                  outerRadius="90%"
                  nameKey="field"
                  data={[{ field: '', value: 1 }]}
                  fill="#eeeeee"
                />
              )}
            </PieChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
