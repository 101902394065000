import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { HierarchyFilterValues } from '../../shared/Hierarchy';

export type ViewBaseInfo = {
  id: string;
  name: string;
  userId?: string;
  privateView?: boolean;
  defaultView?: boolean;
  hierarchyFilters?: { hierarchyValues?: HierarchyFilterValues; showArchived?: boolean };
};

export type GetViewExtended = ViewBaseInfo & {
  dateFiltersForView?: { fromDate: string; toDate: string; dateFilterRangeType?: string };
};

export type PostViewExtended = Omit<ViewBaseInfo, 'id'> & {
  dateFilterRangeType?: string;
  fromDate?: string;
  toDate?: string;
};

export type PutPatchViewInterface = Partial<PostViewExtended> & {
  id: string;
};

export const viewsApi = createApi({
  reducerPath: 'viewsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Views', 'View', 'SimplifiedView'],
  endpoints: (builder) => ({
    getView: builder.query<GetViewExtended, string>({
      query: (id) => {
        return { url: `/view-details/${id}`, method: 'GET' };
      },
      providesTags: (result) => [{ type: 'View', id: result?.id }],
    }),
    createView: builder.mutation<string, PostViewExtended>({
      query: (body) => {
        return {
          url: '/view-details',
          method: 'POST',
          body,
          responseHandler: 'text',
        };
      },
      invalidatesTags: (id) => [{ type: 'View', id: id }, { type: 'Views' }, { type: 'SimplifiedView', id: id }],
    }),
    updateView: builder.mutation<string, PutPatchViewInterface>({
      query: ({ id, ...body }) => {
        return {
          url: `/view-details/${id}`,
          method: 'PATCH',
          body,
          responseHandler: 'text',
        };
      },
      invalidatesTags: (id) => [{ type: 'View', id: id }, { type: 'Views' }, { type: 'SimplifiedView', id: id }],
    }),
    deleteView: builder.mutation<void, string>({
      query: (id) => {
        return { url: `/view-details/${id}`, method: 'DELETE' };
      },
      invalidatesTags: (_result, _error, id) => [
        { type: 'View', id: id },
        { type: 'SimplifiedView', id: id },
      ],
    }),
    getAllViews: builder.query<Array<ViewBaseInfo>, void>({
      query: () => {
        return { url: '/view-details', method: 'GET' };
      },
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'SimplifiedView' as const, id })), 'Views'] : ['Views'],
    }),
  }),
});

export const {
  useGetViewQuery,
  useLazyGetViewQuery,
  useGetAllViewsQuery,
  useCreateViewMutation,
  useDeleteViewMutation,
  useUpdateViewMutation,
} = viewsApi;
