import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { DeleteUndefinedAttributes } from '../common-commands/delete-undefined-attributes';
import { DataCommandResponse, IDataCommand, IDataCommandFactory } from '../data-transformer.interface';
import { ComparedFrLineResponse, MonthlyComparisonData } from './compared-fr-line.interface';
import { TransformComparedFrLineChartData } from './transform-compared-fr-line-data';

export type ComparedFrLineType = ComparedFrLineResponse | MonthlyComparisonData[];

export class ComparedFrLineCommandFactory implements IDataCommandFactory {
  private currentCommandIndex = 0;
  private readonly commands: Array<IDataCommand<ComparedFrLineType, WidgetConfigurationData, ComparedFrLineType>>;

  constructor() {
    this.commands = [new TransformComparedFrLineChartData(), new DeleteUndefinedAttributes<MonthlyComparisonData>()];
  }

  getNextCommand<TIn, TInConfig, TOut>(): DataCommandResponse<TIn, TInConfig, TOut> | undefined {
    const command = this.commands[this.currentCommandIndex] as IDataCommand<TIn, TInConfig, TOut>;
    return (
      command && {
        command,
        commandIndex: this.currentCommandIndex++,
      }
    );
  }
}
