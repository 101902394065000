import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModalDialog } from '@myosh/odin-components';
import { ModalDialogButtonSetting } from '@myosh/odin-components/dist/types/components/common-interfaces';
import { CustomColorProps } from './config-components.interfaces';
import { statusColor } from '../../../constants/Palette';
import { t } from 'i18next';

export type ConfigPaletteWindow = {
  selectedItem?: CustomColorProps;
  visible: boolean;
  onPaletteClosed: () => void;
  updateColor: (newColor: CustomColorProps) => void;
};

export default function ConfigPaletteWindow({
  selectedItem,
  visible,
  onPaletteClosed,
  updateColor,
}: ConfigPaletteWindow) {
  const closeModalButton = useRef<Array<ModalDialogButtonSetting>>([
    {
      type: 'primary',
      name: 'okButton',
      text: 'Ok',
    },
    {
      type: 'primary',
      name: 'cancelModalButton',
      text: 'Cancel',
      closeModalOnClick: true,
    },
  ]);

  const [currentlySelectedColor, setSelectedColor] = useState<string>();

  useEffect(() => {
    if (selectedItem) {
      setSelectedColor(selectedItem.color);
    }
  }, [selectedItem]);

  // We need to update save buttons' click handlers once selected items changes.
  // When cancel is clicked, we should not update color
  useMemo(() => {
    if (selectedItem && currentlySelectedColor && selectedItem.color !== currentlySelectedColor) {
      closeModalButton.current[0].onClick = () =>
        updateColor({ label: selectedItem.label, color: currentlySelectedColor });
    }
  }, [currentlySelectedColor, selectedItem]);

  return (
    <ModalDialog
      visible={selectedItem ? visible : false}
      buttons={closeModalButton.current}
      header={t('colors')}
      hidden={onPaletteClosed}
      maxDialogWidth="300px"
      minDialogHeight="35vh"
      maxDialogHeight="85vh"
      minDialogWidth="20wh"
      transitionDuration={200}
      shouldCheckZIndex
    >
      {selectedItem && (
        <div className="flex flex-col items-center justify-center">
          <div>{selectedItem.label}</div>
          <input
            type="color"
            className="w-32"
            id={selectedItem.label}
            name={selectedItem.label}
            value={currentlySelectedColor}
            onChange={(event) => {
              setSelectedColor(event.target.value);
            }}
          />
          <div className="text-lg">{String(t('myoshColors'))}</div>
          <div className="flex flex-shrink flex-wrap">
            {statusColor.map((color) => (
              <svg
                width="64"
                height="64"
                key={color}
                className="cursor-pointer"
                onClick={() => setSelectedColor(color)}
              >
                <rect
                  width="64"
                  height="64"
                  style={{
                    fill: color,
                  }}
                />
              </svg>
            ))}
          </div>
        </div>
      )}
    </ModalDialog>
  );
}
