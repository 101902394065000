import React from 'react';
import { AuthService } from '@myosh/myosh-login';
import { AboutInfoModal } from '@myosh/odin-components';
import { useAppDispatch } from '../../redux/hooks';
import { removeGlobalDateFilters } from '../../redux/slices/globalFilter/global-dates-filter-slice';
import { removeHierarchyFilters } from '../../redux/slices/globalFilter/GlobalFilterHierarchySlice';
import { useGetCurrentSchemaInfoQuery } from '../../redux/config/current-schema-api';
import { t } from 'i18next';

type AboutMenuOptionType = {
  setShowAboutInfo: (visible: boolean) => void;
  showAboutInfo: boolean;
};
export const AboutMenuOption = ({ showAboutInfo, setShowAboutInfo }: AboutMenuOptionType) => {
  const dispatch = useAppDispatch();
  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();
  const handleLogout = () => {
    const authService = new AuthService();
    authService.logout();
    dispatch(removeGlobalDateFilters());
    dispatch(removeHierarchyFilters());
  };
  const closeAboutInfo = () => {
    setShowAboutInfo(false);
  };

  return (
    <AboutInfoModal
      build={process.env.REACT_APP_VERSION}
      currentSchema={{ text: schemaInfo?.schema || 'n/a' }}
      isAboutInfoOpen={showAboutInfo}
      showLogoutButton
      onClose={closeAboutInfo}
      onLogout={handleLogout}
      name={t('softwareName')}
      userTimeZone={schemaInfo?.['time-zone']}
      {...schemaInfo}
    />
  );
};
