import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ContinuousScrollerSort,
  DataGrid,
  DataGridCellRenderer,
  DataGridCellRendererProps,
  DataGridColumnSettings,
  DataGridRef,
  JsonData,
  OverlayScrollDetectionType,
} from '@myosh/odin-components';
import { isEmpty, isNil } from 'lodash';
import { compareAsc, compareDesc } from 'date-fns';
import { useSelector } from 'react-redux';

import { BaseChartProps } from './chart.interface';
import useStartEndDates from './hooks/useStartEndDates';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { BasicDataChartResponse } from '../../redux/config/chart-data';
import { tableDataApi, useInjuryFreeTableQuery } from '../../redux/config/table-data';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { setTableFilters } from '../../redux/slices/update-widget-table-sort-field';
import { equalsToday } from '../../helpers/dateFormater';
import { useSelectedView } from './hooks/use-selected-view';

export type TableInjuryFreeDaysChartProps = Omit<BaseChartProps, 'type' | 'title'>;

const replaceZeroesWithText: DataGridCellRenderer = {
  CellComponent: ({ rowData }: DataGridCellRendererProps) => {
    const value = rowData.value ?? '';
    const occurence = (rowData.field ?? '') as string;
    const showZero = occurence && equalsToday(occurence);
    return <div>{value === '0' && !showZero ? 'No Injuries' : String(value)}</div>;
  },
};
export default function OdinTableInjuryFreeDaysChart({ widgetId, layoutProps }: TableInjuryFreeDaysChartProps) {
  const [transformedData, setTransformedData] = useState<BasicDataChartResponse[]>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const gridApi = useRef<DataGridRef>();
  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    isUninitialized: isDataUninitialized,
    error,
  } = useInjuryFreeTableQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (widgetChartData) {
      setTransformedData(widgetChartData);
    }
  }, [widgetChartData]);

  const columns: Array<DataGridColumnSettings> | undefined = useMemo(() => {
    if (configurationData?.widgetData) {
      const { secondField, field } = configurationData.widgetData;
      return [
        {
          id: 'secondField',
          field: 'secondField',
          title: secondField,
          isIdField: true,
          visible: true,
          customDataProperties: {
            field: secondField,
          },
        },
        {
          id: 'field',
          field: 'field',
          title: field,
          visible: true,
          width: 233,
          customDataProperties: {
            field: field,
          },
        },
        {
          id: 'value',
          field: 'value',
          title: 'Injury Free Days',
          visible: true,
          width: 233,
          cellRenderer: 'replaceZeroesWithText',
          type: 6,
        },
      ];
    }
  }, [configurationData?.widgetData]);

  const extractSortedField = useCallback(() => {
    const sortedFields = gridApi.current?.api.sortedFields.getSortedFields();
    const result: Record<string, unknown> = {};
    if (sortedFields) {
      for (let i = 0; i < sortedFields.length; i++) {
        const fieldRule = sortedFields[i];
        const key = fieldRule.customDataProperties?.field ?? fieldRule.field;
        result[`${key}`] = { direction: fieldRule.sortDirection === 0 ? 'asc' : 'desc', order: fieldRule.order };
      }
    }
    return result;
  }, []);

  const handleRefetch = () => {
    if (!isDataUninitialized && widgetChartData && !isWidgetChartDataFetching) {
      dispatch(tableDataApi.util.invalidateTags([{ type: 'InjuryFreeDaysData', id: `LIST-${widgetId}` } as const]));
    }
  };

  const gridSettings = useMemo(() => {
    return columns
      ? {
          components: { replaceZeroesWithText },
          columns,
          filterLocation: 1,
          fullHeight: true,
          initialPageSize: 50,
          sortFunction: customSortFunction,
          autoSizeColumns: true,
          scrollDetection: OverlayScrollDetectionType.All,
        }
      : undefined;
  }, [columns]);

  return (
    <ChartGenericWrapper
      isLoading={isLoading}
      isFetching={isWidgetChartDataFetching}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {gridSettings && transformedData && transformedData?.length > 0 && (
        <DataGrid
          data={transformedData}
          gridSettings={gridSettings}
          showSettings={false}
          customSettingsMenuItems={[]}
          ref={(ref) => {
            if (ref) {
              gridApi.current = ref;
              dispatch(setTableFilters({ id: widgetId, getSortedFields: extractSortedField }));
            }
          }}
        />
      )}
    </ChartGenericWrapper>
  );
}

/**
 * Injury Free Days Table consists of array of objects, where an object's attribute of
 * - `field`  is a date
 * - `seconField` is a site
 * - `value` is a number of dates
 *
 * It's possible to apply customSortFunction because we have in **memory data**.
 *
 * @param data displayed in Injury Free Table
 * @param sorts applied by a user sort order
 * @returns sorted data
 */
const customSortFunction = (data: JsonData, sorts: Array<ContinuousScrollerSort>): JsonData => {
  return [...data].sort((first, second) => {
    for (let i = 0; i < sorts.length; i++) {
      const sortRule = sorts[i];
      const sortedField = sortRule.field;
      const fieldValueFirst = first[sortedField];
      const fieldValueSecond = second[sortedField];
      let firstValue: Date | string | undefined | number =
        isNil(fieldValueFirst) || isEmpty(fieldValueFirst) ? undefined : (fieldValueFirst as string);
      let secondValue: Date | string | undefined | number =
        isNil(fieldValueSecond) || isEmpty(fieldValueSecond) ? undefined : (fieldValueSecond as string);
      if (firstValue === secondValue) {
        // Rely on other fields to sort
        continue;
      }

      const isAscSort = sortRule.sortDirection === 0;
      if (firstValue === undefined) {
        return secondValue === undefined ? 0 : isAscSort ? -1 : 1;
      }
      if (secondValue === undefined) {
        return isAscSort ? 1 : -1;
      }

      if (sortedField === 'field') {
        firstValue = new Date(firstValue);
        secondValue = new Date(secondValue);
        return isAscSort ? compareAsc(firstValue, secondValue) : compareDesc(firstValue, secondValue);
      }
      if (sortedField === 'value') {
        firstValue = Number(firstValue);
        secondValue = Number(secondValue);
        return isAscSort ? firstValue - secondValue : secondValue - firstValue;
      }

      return isAscSort ? firstValue.localeCompare(secondValue) : secondValue.localeCompare(firstValue);
    }
    return 0;
  });
};
