import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LayoutWeight, selectNewTabsOrder } from '../../../redux/slices/new-tab-order';

export function useUpdatedTabsOrder() {
  const [updatedTabOrder, setUpdatedTabOrder] = useState<Array<LayoutWeight>>([]);
  const newTabState = useSelector(selectNewTabsOrder);
  useEffect(() => {
    setUpdatedTabOrder(newTabState.newOrderTabs);
  }, [newTabState]);
  return updatedTabOrder;
}
