import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { HierarchyFilters } from '../../shared/Hierarchy';

export const hierarchyFiltersApi = createApi({
  reducerPath: 'hierarchyFiltersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['HierarchyFilter'],

  endpoints: (build) => ({
    getHierarchyFilters: build.query<HierarchyFilters, void>({
      query: () => {
        return {
          url: `/hierarchy-filters`,
          method: 'GET',
        };
      },
      providesTags: ['HierarchyFilter'],
    }),
    addHierarchyFilters: build.mutation<HierarchyFilters, Partial<HierarchyFilters>>({
      query: (body) => ({
        url: `/hierarchy-filters`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['HierarchyFilter'],
    }),
    updateHierarchyFilters: build.mutation<void, HierarchyFilters>({
      query: (body) => ({
        url: `/hierarchy-filters`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['HierarchyFilter'],
    }),
  }),
});

export const { useGetHierarchyFiltersQuery, useAddHierarchyFiltersMutation, useUpdateHierarchyFiltersMutation } =
  hierarchyFiltersApi;
