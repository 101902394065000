import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const regexWidget = /\/widget\/[0-9]+/;
const regexLayout = /\/layout\/.+/;

export function useIsExportView() {
  const location = useLocation();
  const [isExportView, setIsExportView] = useState<boolean>(false);

  useEffect(() => {
    const stateExport = location?.state?.export;
    const isIndividualWidget = location.pathname?.match(regexWidget)?.length === 1;
    const isIndividualTab = location.pathname?.match(regexLayout)?.length === 1;
    const isExport = stateExport ? Boolean(stateExport) : isIndividualWidget || isIndividualTab;
    setIsExportView(isExport);
  }, [location]);

  return isExportView;
}
