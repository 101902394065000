import { combineReducers } from '@reduxjs/toolkit';
import globalFilterReducer from './slices/globalFilter/global-dates-filter-slice';
import globalHierarchyReducer from './slices/globalFilter/GlobalFilterHierarchySlice';
import appEditableStateReducer from './slices/editable-view-slice';
import selectNewTabState from './slices/new-tab-slice';
import widgetChartsStateReducer from './slices/widgetChart/WidgetChartSlice';
import activeLayoutTabIdReducer from './slices/active-layout-tab-id-slice';
import layoutTabsReducer from './slices/layout-tabs-slice';
import saveEditChangesReducer from './slices/editability/save-edit-changes';
import updateWidthHeightOfChartReducer from './slices/update-width-height-of-chart';
import changesInTabOrderReducer from './slices/new-tab-order';
import updateWidgetTableSortFieldReducer from './slices/update-widget-table-sort-field';
import selectedViewReducer from './slices/selected-view';

const rootReducer = combineReducers({
  filter: globalFilterReducer,
  filterHierarchy: globalHierarchyReducer,
  appEditability: appEditableStateReducer,
  newTabState: selectNewTabState,
  widgetChart: widgetChartsStateReducer,
  layoutTabs: layoutTabsReducer,
  layoutActiveTab: activeLayoutTabIdReducer,
  editChangesSave: saveEditChangesReducer,
  updateWidthHeight: updateWidthHeightOfChartReducer,
  tabsWeight: changesInTabOrderReducer,
  updateTableDataSettings: updateWidgetTableSortFieldReducer,
  selectedView: selectedViewReducer,
});

export default rootReducer;
