import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import type { ChartQueryParameters } from './chart-data';
import { getFilters } from './get-filters';
import { FieldFiltersProps } from './table-data.interfaces';
import { OdinDataSort } from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';
import {
  DetailsConfigurationFields,
  DetailsConfigurationFieldsValues,
} from '../../components/CustomDetailsWindow/custom-details-window.component';
import { dateValueFieldFormatter } from './details-window';

export interface ExportQueryParameters
  extends Omit<ChartQueryParameters, 'url'>,
    DetailsConfigurationFieldsValues,
    Partial<DetailsConfigurationFields> {
  exportFileType: string;
  timeZoneId: string;
  detailsWindow: boolean;
  layoutId?: string;
  fieldFilters?: FieldFiltersProps;
  sortedFields?: Record<string, OdinDataSort>;
  languageTag: string;
}

export interface ExportQueryLayoutparams {
  layoutId: string;
  timeZoneId: string;
  languageTag: string;
  from?: string;
  to?: string;
  viewId?: string;
}

export const exportFileApi = createApi({
  reducerPath: 'export-api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [],
  endpoints: (builder) => ({
    getExportWidgetJobId: builder.mutation<string, ExportQueryParameters>({
      query: (args) => {
        let url = `/background-jobs/widget/${args.widgetId}/export?timeZoneId=${args.timeZoneId}&autostart=true&exportFileType=${args.exportFileType}&detailsWindow=${args.detailsWindow}`;
        url = appendCommonExportURLParams(url, args);
        if (args.layoutId) {
          url += `&layoutId=${args.layoutId}`;
        }
        if (args.sortedFields) {
          const sortField = Object.keys(args.sortedFields);
          if (sortField.length >= 1) {
            const sortOrder = args.sortedFields[sortField[0]].direction.toUpperCase();
            // the data can only be sorted by one field at a time
            url += `&sort=${sortField[0]}&orderBy=${sortOrder}`;
          }
        }
        if (args.recordIds) {
          const ids = args.recordIds.length > 0 ? args.recordIds.join(',') : '-1';
          url = url + `&filter=recordIds:in:${ids}`;
        } else {
          if (args.field && args.fieldValue) {
            url += dateValueFieldFormatter(args.field, args.fieldValue);
          }
          if (args.secondField && args.secondFieldValue) {
            url += dateValueFieldFormatter(args.secondField, String(args.secondFieldValue));
          }
        }
        if (args.fieldFilters) {
          url = `${url}${getFilters(args.fieldFilters)}`;
        }
        return {
          url: url,
          method: 'POST',
          responseHandler: 'text',
          body: { ...args, autostart: true },
        };
      },
    }),
    getExportLayoutJobId: builder.mutation<string, ExportQueryLayoutparams>({
      query: (args) => {
        let url = `/background-jobs/layout/${args.layoutId}/export?timeZoneId=${args.timeZoneId}&autostart=true`;
        url = appendCommonExportURLParams(url, args);

        return {
          url: url,
          method: 'POST',
          responseHandler: 'text',
          body: { ...args, autostart: true },
        };
      },
    }),

    exportJobState: builder.query<ExportJobDetailsResult, string>({
      query: (jobId) => ({
        url: `/background-jobs/${jobId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useExportJobStateQuery, useGetExportWidgetJobIdMutation, useGetExportLayoutJobIdMutation } =
  exportFileApi;

export interface ExportJobDetailsResult {
  id: string;
  creationDate: string;
  startDate: string;
  finishDate: string;
  status: string;
  objectId: string;
  s3FileUrl: string;
  exportFileType: string;
  type: string;
  widgetFilters: Record<string, string>;
}

const appendCommonExportURLParams = (baseUrl: string, args?: Omit<ExportQueryLayoutparams, 'layoutId'>): string => {
  let url = baseUrl;
  if (args) {
    if (args.viewId) {
      url += `&viewId=${args.viewId}`;
    } else if (args.from && args.to) {
      url += `&from=${args.from}&to=${args.to}`;
    }
    if (args.languageTag) {
      url += `&languageTag=${args.languageTag}`;
    }
    return url;
  }
  return url;
};
