import React, { useState } from 'react';

import { DeleteLayoutConfirmation } from '../common/delete-layout-confirmation';
import { DeleteTabHeaderProps } from './tab-header-element.component';
import { t } from 'i18next';
import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';

export const DeleteTabIcon = ({ updateLayout, title, onlyIcon = true }: DeleteTabHeaderProps) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  return (
    <>
      <div
        className="flex p-1 align-middle"
        onClick={() => {
          setDeleteConfirmation(true);
        }}
      >
        <OdinIcon className="self-center" icon="DeleteBin6" size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} />
        {!onlyIcon && <div className="ml-2">{String(t('delete'))}</div>}
      </div>
      <DeleteLayoutConfirmation
        showConfirm={deleteConfirmation}
        setIsVisible={setDeleteConfirmation}
        onDeleteConfirmation={() => updateLayout({}, true)}
        tabName={title}
      />
    </>
  );
};
