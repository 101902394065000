export enum CHART_TYPE {
  ChartBar = 'CHART_BAR',
  ChartColumn = 'CHART_COLUMN',
  ChartLine = 'CHART_LINE',
  ChartPie = 'CHART_PIE',
  Table = 'TABLE',
  ChartColumnLine = 'CHART_COLUMN_LINE',
  TableInjuryFreeDays = 'TABLE_INJURY_FREE_DAYS',
  ChartKpi = 'CHART_KPI',
  ChartColumnRate = 'CHART_COLUMN_RATE',
  ChartColumnBasic = 'CHART_COLUMN_BASIC',
  MapWidget = 'MAP',
  ManagementTable = 'MANAGEMENT_TABLE',
  ChartDifference = 'CHART_DIFFERENCE',
  ChartRollingFR = 'CHART_ROLLINGFR',
  ComparedFrLine = 'COMPARED_FR_LINE',
  CrossTabReport = 'CROSS_TAB_REPORT',
  TableRollingTRIFR = 'TABLE_ROLLING_TRIFR',
  TableRate = 'TABLE_RATE',
  ColumnKpiPercentage = 'CHART_COLUMN_KPI_PERCENTAGE',
  TableInjurySite = 'INJURY_NUMBER_BY_SITE_TABLE',
  NCRAgingLineChart = 'CHART_NCR_AGING',
}

export enum TIME_SORT {
  MONTHLY = 'MONTHLY',
}

export enum SelectionSearchTypeProps {
  COMBOBOX = 'MultiSelect',
  RISKRATING = 'TextSearch',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MULTISELECTCOMBOBOX = 'MultiSelect',
  DATETIME = 'DateRange',
  NUMBER = 'Integer',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  TEXT = 'TextSearch',
}
