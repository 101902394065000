import { useMemo, useState } from 'react';
import { CustomColorProps } from '../components/WidgetSettingWindow/components/config-components.interfaces';
import { WidgetConfigurationData } from '../redux/config/widgetApi';
import { WidgetConfigsGenericWrapper } from '../components/common/chart-generic-wrapper';

function useUpdateColorsData(widgetConfiguration: WidgetConfigurationData | undefined) {
  const [widgetConfigData, setWidgetConfigData] = useState<Omit<WidgetConfigsGenericWrapper, 'layoutProps'>>();

  useMemo(() => {
    if (widgetConfiguration && widgetConfiguration.widgetConfig) {
      const widgetConfigColors = widgetConfiguration.widgetConfig.colors;
      const colorData: CustomColorProps[] = [];

      for (const key in widgetConfigColors) {
        colorData.push({
          label: key,
          color: widgetConfigColors[key],
        });
      }
      setWidgetConfigData({ ...widgetConfiguration, colors: colorData });
    }
  }, [widgetConfiguration]);
  return widgetConfigData;
}

export default useUpdateColorsData;
