import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const usePageTracking = () => {
  const location = useLocation();
  const { initialize, isInitialized, send } = ReactGA;
  const googleAnalyticsID = `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;

  useEffect(() => {
    if (!isInitialized && googleAnalyticsID && googleAnalyticsID.length > 0) {
      initialize(googleAnalyticsID);
    }
  }, []);

  useEffect(() => {
    if (isInitialized) {
      send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);
};
