import { ChartQueryParameters } from './chart-data';

/** If a view is in use by a user, we must supply its id to fetch data correctly.
 *  If a user has changed filters locally and hasn't saved them into a view,
 *  from and to attributes must be used instead */
export const appendQueryParameters = (path: string, args: ChartQueryParameters, firstParam = true) => {
  let url = path;
  if (args) {
    let firstCharacter = firstParam ? `?` : `&`;
    if (args.viewId) {
      url += `${firstCharacter}viewId=${args.viewId}`;
    } else {
      if (args.from) {
        url += `${firstCharacter}from=${args.from}`;
      }
      if (args.to) {
        firstCharacter = args.from ? `&` : firstCharacter;
        url += `${firstCharacter}to=${args.to}`;
      }
    }
  }
  return url;
};
