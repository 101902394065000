import { useMemo, useState } from 'react';

export function useTicksBasedOnScale(domainScale?: [number, number]) {
  const [ticks, setTicks] = useState<Array<number>>();

  const generateTicks = (maxValue: number, minValue: number) => {
    const ticksArr = [0];
    const rangeLength = Math.round(maxValue / minValue);
    const interval = maxValue / rangeLength;

    while (ticksArr.length < rangeLength + 1) {
      const lastTick = ticksArr[ticksArr.length - 1];
      ticksArr.push(lastTick + interval);
    }
    setTicks(ticksArr);
  };

  const calculateForNegativeTicks = (maxValue: number, minValue: number) => {
    //distance between min and max values
    const rangeLength = Math.round(
      maxValue > 0 ? Math.abs(minValue) + maxValue : Math.abs(minValue) - Math.abs(maxValue)
    );
    if (rangeLength === 2) {
      setTicks([minValue, rangeLength / 2, maxValue]);
    } else if (rangeLength % 20 === 0 || rangeLength % 2 === 0) {
      const step = rangeLength / 4;
      setTicks([minValue, minValue + step, minValue + 2 * step, minValue + 3 * step, maxValue]);
    } else if (rangeLength % 15 === 0 /*|| rangeLength % 3 === 0*/) {
      const step = rangeLength / 3;
      setTicks([minValue, minValue + step, minValue + 2 * step, maxValue]);
    } else if (rangeLength % 5 === 0) {
      const step = rangeLength / 5;
      setTicks([minValue, minValue + step, minValue + 2 * step, minValue + 3 * step, minValue + 4 * step, maxValue]);
    } else {
      //Rely on rechart's default behaviour
      setTicks(undefined);
    }
  };

  useMemo(() => {
    if (domainScale && domainScale.length === 2) {
      const [min, max] = domainScale;

      if (min < 0) {
        calculateForNegativeTicks(max, min);
      } else if (Number.isSafeInteger(max)) {
        if (max === 2) {
          setTicks([0, max / 2, max]);
        } else if (max % 4 === 0) {
          setTicks([0, max * 0.25, max * 0.5, max * 0.75, max]);
        } else if (max % 15 === 0 || max % 3 === 0) {
          setTicks([0, max / 3, (max * 2) / 3, max]);
        } else if (max % 2 === 0) {
          setTicks([0, max * 0.5, max]);
        } else if (max % 5 === 0) {
          setTicks([0, max * 0.2, max * 0.4, max * 0.6, max * 0.8, max]);
        } else {
          generateTicks(max, 2);
        }
      } else if (Number.isSafeInteger(min)) {
        generateTicks(max, 2);
      } else {
        generateTicks(max, min);
      }
    } else {
      setTicks(undefined);
    }
  }, [domainScale]);

  return ticks;
}
