import { createApi } from '@reduxjs/toolkit/query/react';
import { DateRange } from '../../components/Header/Header.interface';
import { baseQueryWithReAuth } from './api';

interface MetadataConfigType {
  dateRange: DateRange[];
  fieldMapping: Record<string, string>;
}
interface MetadataType {
  defaultDateRange: string;
  [p: string]: string;
}
export const metadataConfigApi = createApi({
  reducerPath: 'metadataConfigApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['MetadataConfig', 'Metadata'],

  endpoints: (builder) => ({
    metadataConfig: builder.query<MetadataConfigType, Record<string, unknown>>({
      query: (args: { timeZoneId?: string }) => {
        return {
          url: `/metadata/config?timeZoneId=${args?.timeZoneId}`,
          method: 'GET',
        };
      },
      providesTags: (_response, _error, args) => {
        return args?.timeZoneId ? [{ type: 'MetadataConfig', id: String(args.timeZoneId) }] : ['MetadataConfig'];
      },
    }),
    getMetadata: builder.query<MetadataType, unknown>({
      query: () => {
        return {
          url: '/metadata',
          method: 'GET',
        };
      },
      providesTags: ['Metadata'],
    }),
  }),
});

export const { useMetadataConfigQuery, useGetMetadataQuery } = metadataConfigApi;
