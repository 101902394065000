import React, { useCallback } from 'react';
import ConfirmationWindow from './confirmation-window';
import { t } from 'i18next';

type DeleteLayoutConfirmationType = {
  showConfirm: boolean;
  setIsVisible: (arg: boolean) => void;
  onDeleteConfirmation: () => void;
  tabName: string;
};
export const DeleteLayoutConfirmation = ({
  showConfirm,
  setIsVisible,
  onDeleteConfirmation,
  tabName,
}: DeleteLayoutConfirmationType) => {
  const onHideModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <ConfirmationWindow
      onSaveHandler={onDeleteConfirmation}
      showWindow={showConfirm}
      onDiscardChanges={{
        onHideModal: onHideModal,
        onDiscard: onHideModal,
      }}
      title={t('deleteTab')}
      text={t('confirmDeleteTab', { tabName: tabName })}
      discard={t('no')}
      save={t('yes')}
    />
  );
};
