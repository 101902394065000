import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { ChartResponse } from '../../redux/config/chart-data';
import { PieChartValues } from '../../shared/widget-data.interfaces';
import { getStatusColor } from '../../helpers/getStatusColor';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';

export class BuildPieChartValuesByValues
  implements IDataCommand<Array<ChartResponse>, WidgetConfigurationData, Array<PieChartValues>>
{
  process(data: Array<ChartResponse>, config: WidgetConfigurationData): Array<PieChartValues> {
    const widgetConfigValues = config.widgetConfig;
    return data.map((item, index) => {
      const field = getValueOrDefault(item.field, '[none]');
      let color = widgetConfigValues && widgetConfigValues.colors && widgetConfigValues.colors[field];
      if (!color) {
        color = getStatusColor(index);
      }
      return {
        field,
        value: Number(item.value),
        color,
      };
    });
  }
}
