import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { setStorageItem } from '../../helpers/storage';

export interface ToggleEditProps {
  activeLayoutTabId: string;
  activeLayoutTabType: string;
}

const initialState: ToggleEditProps = {
  activeLayoutTabId: '',
  activeLayoutTabType: 'public',
};

const activeLayoutTabIdSlice = createSlice({
  name: 'activeLayoutTabId',
  initialState,
  reducers: {
    setActiveLayoutTabId(state, action) {
      state.activeLayoutTabId = action.payload;
      setStorageItem('activeLayoutTabId', action.payload, 'sessionStorage');
    },
    setActiveLayoutTabType(state, action) {
      state.activeLayoutTabType = action.payload;
    },
  },
});

export const { setActiveLayoutTabId, setActiveLayoutTabType } = {
  ...activeLayoutTabIdSlice.actions,
};

export default activeLayoutTabIdSlice.reducer;

export const selectActiveTabId = (state: RootState) => state.rootReducer.layoutActiveTab.activeLayoutTabId;
export const selectActiveTabType = (state: RootState) => state.rootReducer.layoutActiveTab.activeLayoutTabType;
