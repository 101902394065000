import { Loader, TabViewPanel, TabViewRef } from '@myosh/odin-components';
import { cloneDeep, differenceWith, isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import dragula from 'react-dragula';
import { useSelector } from 'react-redux';

import { resetChildrenPosition } from '../../helpers/reset-children-position';
import { usePrevious } from '../../hooks/UsePreviousHook';
import { useIsEditableMode } from '../Charts/hooks/useIsEditableMode';
import { useEditSaveDiscard } from '../Charts/hooks/useSaveEdit';
import { useUpdatedTabsOrder } from '../Charts/hooks/useUpdatedTabsOrder';
import DashboardTabView from '../DashboardTabView/dashboard-tab-view';
import { getLayoutsNewPosition } from './layout-tab.functions';
import { TabContent } from './tab-content.component';
import { TabViewHeaderElement } from './tab-header-element.component';

import { useLayoutAdmin } from '../../hooks/useIsLayoutAdmin';
import {
  Layout,
  useDeleteLayoutMutation,
  useGetLayoutsQuery,
  useUpdateLayoutMutation,
} from '../../redux/config/layout';
import { useGetLayoutWidgetQuery } from '../../redux/config/layout-widget';
import { useAppDispatch } from '../../redux/hooks';
import {
  selectActiveTabId,
  selectActiveTabType,
  setActiveLayoutTabId,
  setActiveLayoutTabType,
} from '../../redux/slices/active-layout-tab-id-slice';
import { setLayoutTabs, setLayoutWidgets } from '../../redux/slices/layout-tabs-slice';
import { LayoutWeight, changedLayoutsWeight, discardNewOrder } from '../../redux/slices/new-tab-order';
import { removeWidgetAiInsight, selectWidgetAiInsight } from '../../redux/slices/widgetChart/WidgetChartSlice';
import WidgetAiInsightPanel from '../common/widget-ai-insight-panel.component';

const LayoutTab = () => {
  const [localLayouts, setLocalLayout] = useState<Layout[]>([]);
  const [originalTabPositions, setOriginalTabsPosition] = useState<Array<LayoutWeight>>();
  const [updatedTabPositions, setUpdatedTabPositions] = useState<Array<LayoutWeight>>();
  const [tabInEditMode, setTabinEditMode] = useState(false);

  const dispatch = useAppDispatch();
  const [updateLayout] = useUpdateLayoutMutation();
  const [deleteLayout] = useDeleteLayoutMutation();
  const isEditable = useIsEditableMode();
  const activeTabId = useSelector(selectActiveTabId);
  const activeTabType = useSelector(selectActiveTabType);
  const widgetAiInsight = useSelector(selectWidgetAiInsight);
  const prevCount: string | undefined = usePrevious<string>(activeTabId);
  const saveChangesEdit = useEditSaveDiscard();
  const globalTabReorder = useUpdatedTabsOrder();
  const tabsReference = useRef<TabViewRef>(null);
  const forceChangeTab = useRef<boolean>();

  const drakeRef = useRef<dragula.Drake>();
  const headerId = useRef<string>('mainTabsLayout');
  const isAdminLayout = useLayoutAdmin();

  const { data: layouts, isFetching } = useGetLayoutsQuery({});
  const {
    data: widgets,
    refetch: refetchWidgets,
    isFetching: refetchingWidgets,
  } = useGetLayoutWidgetQuery(activeTabId, {
    skip: activeTabId === '',
  });

  useEffect(() => {
    drakeRef.current = dragula([], {
      direction: 'horizontal',
      invalid: (el?: Element) => {
        return el?.classList.contains('noDrop') || false;
      },
      accepts(el, target, source, sibling) {
        const draggableElement = el?.getElementsByClassName('tabTitle')[0];
        const dropTarget = sibling?.getElementsByClassName('tabTitle')[0];
        // sibling is null, if moved to last position
        // we can move there if all layouts are private or a private layout is in question
        if (!dropTarget) {
          return (
            draggableElement?.classList.contains('userLayout') ??
            target?.getElementsByClassName('publicLayout').length === 0
          );
        }

        const draggableElementClasses = draggableElement?.classList;
        const dropTargetClasses = dropTarget.classList;
        let isPrivateBeforePublic = false;
        let isPublicAfterPrivate = false;
        if (dropTargetClasses && draggableElementClasses) {
          isPrivateBeforePublic =
            draggableElementClasses.contains('userLayout') && dropTargetClasses.contains('publicLayout');
          isPublicAfterPrivate =
            draggableElementClasses.contains('publicLayout') && dropTargetClasses.contains('userLayout');
        }
        return !(isPrivateBeforePublic || isPublicAfterPrivate);
      },
    });
    drakeRef.current.on('drop', () => {
      const childNodes = drakeRef.current?.containers[0];
      if (childNodes) {
        setUpdatedTabPositions(getLayoutsNewPosition(childNodes.children));
      }
    });
    () => drakeRef.current?.remove();
  }, []);

  useEffect(() => {
    if (originalTabPositions && updatedTabPositions) {
      dispatch(changedLayoutsWeight(differenceWith(updatedTabPositions, originalTabPositions, isEqual)));
    }
  }, [originalTabPositions, updatedTabPositions]);

  useEffect(() => {
    if (widgets && !refetchingWidgets) {
      dispatch(setLayoutWidgets(widgets));
    }
  }, [widgets, refetchingWidgets]);

  useEffect(() => {
    if (layouts && !isEmpty(layouts)) {
      // set the default active layout tab as the first element of layout api response
      dispatch(setActiveLayoutTabId(layouts[0].id));
      dispatch(setActiveLayoutTabType('public'));
      dispatch(setLayoutTabs(layouts));

      const newLayouts = cloneDeep(layouts);
      setLocalLayout(newLayouts);
      let publicLayoutCounter = 0;
      let privateLayoutCounter = 0;
      setOriginalTabsPosition(
        newLayouts.map((layout) => {
          return {
            weight: layout.isUserLayout ? ++publicLayoutCounter : ++privateLayoutCounter,
            id: layout.id,
          };
        })
      );
    }
    if (prevCount) {
      // If the user refetch the data for /layouts/all, navigate back to the same tab he was before
      refetchWidgets();
      forceChangeTab.current = true;
      document.getElementById(`headerTab-${prevCount}`)?.click();
    }
  }, [layouts]);

  const assignChildDraggableContainer = useCallback(
    (
      editEnabled: boolean,
      drakeRef: React.MutableRefObject<dragula.Drake | undefined>,
      childContainer: HTMLElement | null
    ) => {
      if (drakeRef.current) {
        if (childContainer && editEnabled) {
          drakeRef.current.containers.length = 0;
          drakeRef.current.containers.push(childContainer);
          childContainer.style.cursor = 'all-scroll';
        } else {
          drakeRef.current.containers.length = 0;
          drakeRef.current.containers = [];
          if (childContainer) {
            childContainer.style.cursor = 'context-menu';
          }
        }
      }
    },
    []
  );

  const findHeaderRow = useCallback(() => {
    const innerHtmlRow = document.getElementById(headerId.current)?.getElementsByClassName('elementNavigatorStyles');
    return innerHtmlRow && innerHtmlRow.length > 0 ? (innerHtmlRow[0] as HTMLDivElement) : null;
  }, []);

  useEffect(() => {
    const header = findHeaderRow();
    if (header) {
      const editEnabled: boolean = isEditable && !tabInEditMode;
      assignChildDraggableContainer(editEnabled, drakeRef, header);
    }
  }, [isEditable, tabInEditMode]);

  useEffect(() => {
    if (saveChangesEdit !== undefined && !isEmpty(globalTabReorder)) {
      if (!saveChangesEdit) {
        dispatch(discardNewOrder());
        resetChildrenPosition(findHeaderRow());
      } else {
        globalTabReorder.forEach((newOrder) => updateLayout({ ...newOrder, keepOldTags: true }));
      }
    }
  }, [saveChangesEdit, globalTabReorder]);

  const handleSelect = useCallback(
    (index: number) => {
      const localLayout = layouts && layouts[index];
      if (localLayout) {
        dispatch(setActiveLayoutTabId(localLayout.id));
        dispatch(setActiveLayoutTabType(isAdminLayout ? 'admin' : localLayout.isUserLayout ? 'private' : 'public'));
      }
      if (forceChangeTab.current && tabsReference.current) {
        tabsReference.current?.setScrollTab(index);
      }
      forceChangeTab.current = false;
      dispatch(removeWidgetAiInsight());
    },
    [isAdminLayout, layouts]
  );

  return (
    <div className="flex h-full w-full flex-row gap-4 overflow-hidden">
      <div className="tab-view h-full w-full overflow-hidden">
        {isFetching && (
          <div className="flex h-full w-full items-center justify-center">
            <Loader title="Loading" />
          </div>
        )}
        {!isFetching && (
          <DashboardTabView
            id={headerId.current}
            isPublicTab={activeTabType === 'public'}
            isEditing={isEditable}
            onTabChange={handleSelect}
            ref={tabsReference}
          >
            {localLayouts.map((layout, index) => (
              <TabViewPanel
                header={
                  <TabViewHeaderElement
                    layoutId={layout.id}
                    isEditable={(layout.canModify || layout.isUserLayout) && isEditable}
                    layout={layout}
                    setTabInEditMode={setTabinEditMode}
                    updateLayout={(data: Partial<Layout>, removeLayout) => {
                      let newLocalLayouts: Array<Layout> = [];
                      if (removeLayout) {
                        newLocalLayouts = localLayouts.filter((local) => local.id !== layout.id);
                        deleteLayout({ id: layout.id, keepOldTags: true });
                      } else {
                        newLocalLayouts = localLayouts.map((tab) => {
                          if (tab.id === layout.id) {
                            return { ...tab, ...data };
                          }
                          return tab;
                        });
                        updateLayout({ ...data, id: layout.id, keepOldTags: true });
                      }
                      setLocalLayout(newLocalLayouts);
                    }}
                    localIndex={index}
                  />
                }
                key={layout.id}
              >
                <TabContent
                  layout={layout}
                  isEditable={layout.canModify && isEditable}
                  assignChildDraggableContainer={assignChildDraggableContainer}
                />
              </TabViewPanel>
            ))}
          </DashboardTabView>
        )}
      </div>

      <WidgetAiInsightPanel widgetAiInsight={widgetAiInsight} />
    </div>
  );
};

export default LayoutTab;
