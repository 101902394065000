import { t } from 'i18next';

export const getLabelOptions = () => {
  return [
    {
      value: 'number',
      text: t('values'),
    },
    {
      value: 'percentage',
      text: t('percentage'),
    },
  ];
};
