import { useState, useEffect } from 'react';
import { DateRange } from '../components/Header/Header.interface';
import { useMetadataConfigQuery } from '../redux/config/metadataConfig';

export const useConfiguredDateRanges = () => {
  const { data: metadataConfig, isLoading: metaConfigLoading } = useMetadataConfigQuery({
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [dateRanges, setDateRanges] = useState<Array<DateRange>>();

  useEffect(() => {
    if (metadataConfig && !metaConfigLoading) {
      setDateRanges(metadataConfig.dateRange);
    }
  }, [metadataConfig, metaConfigLoading]);

  return dateRanges;
};
