import { CheckboxGroupResult } from '@myosh/odin-components/dist/types/components/fields/checkbox-group/checkbox-group.component';
import { RadioGroupResult } from '@myosh/odin-components';

export const labelFormatter = (labelFormat: CheckboxGroupResult[]) => {
  if (!labelFormat || labelFormat.length === 0) {
    return false;
  } else if (labelFormat.length === 2) {
    return 'both';
  } else {
    return labelFormat[0].value as string;
  }
};
export const labelDecimalFormatter = (labelDecimals?: RadioGroupResult, labelFormat?: CheckboxGroupResult[]) => {
  if (!labelDecimals || !labelFormat || labelFormat.length === 0) {
    return undefined;
  } else if (labelDecimals.value === 'one') {
    return 1;
  } else if (labelDecimals.value === 'two') {
    return 2;
  } else {
    return 0;
  }
};
