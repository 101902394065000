import React, { useRef, useState } from 'react';
import { OdinIcon, OdinIconSize, OdinIconType, OverlayPanel } from '@myosh/odin-components';
import { odinIconStyle } from '../../constants/common-style-constants';
import { DomElementAlignment, DomTargetPosition } from '../common/closeable-overlay.component';
import { MenuOptionsComponent } from './MenuOptionsComponent';

export const TopMenuComponent = () => {
  const [configOverlayOpened, setConfigOverlayOpened] = useState<boolean>(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleConfigOverlay = () => {
    setConfigOverlayOpened(!configOverlayOpened);
  };
  const closeConfigOverlay = () => {
    setConfigOverlayOpened(false);
  };

  return (
    <>
      <div
        className={`mx-2 p-1 ${
          configOverlayOpened ? 'rounded-md ring-2 ring-primary-2' : 'flex flex-row justify-between'
        }`}
        onClick={handleConfigOverlay}
        ref={iconRef}
      >
        <OdinIcon icon="Menu" type={OdinIconType.Line} size={OdinIconSize.Small} className={odinIconStyle} />
      </div>

      <OverlayPanel
        target={iconRef.current}
        visible={configOverlayOpened}
        hidden={closeConfigOverlay}
        mountOnEnter={false}
        unmountOnExit={false}
        shouldScrollCloseOverlay
        shouldCheckZIndex
        shouldUseCreatePortal={false}
        transitionDuration={0}
        elementAlignment={DomElementAlignment.TopRight}
        targetPosition={DomTargetPosition.BottomLeft}
        className="w-250 mt-4 flex rounded-md bg-gray-4 shadow-md"
      >
        <MenuOptionsComponent closeConfigOverlay={closeConfigOverlay} />
      </OverlayPanel>
    </>
  );
};
