import { useEffect, useMemo, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Props } from 'recharts/types/component/Label';
import { useSelector } from 'react-redux';

import { LegendPayloadOnClick } from './chart-events';
import { ChartProps } from './chart.interface';
import { getHighestColumnChartValue } from './generalFunctions/get-domaincharts';
import { findCustomColor } from './generalFunctions/general-functions';

import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';

import { DataExecutor } from '../../data-transformer/data-executor';
import {
  ColumnLineCommandFactory,
  ColumnLineType,
} from '../../data-transformer/column-line-chart/column-line-command-factory';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';

import { labelListStyle } from '../../constants/line-label-styles';
import { statusColor } from '../../constants/Palette';
import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';

import { getColorsArray } from '../../helpers/get-colors-arrays';
import { ColumnLineChartValues, PayloadChart } from '../../shared/widget-data.interfaces';

import { XAxisLineChart } from '../common/custom-X-axis.component';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { CustomMiddleLabelList } from '../common/custom-middle-label.component';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { useColumnLineChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';

import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { t } from 'i18next';
import { useSelectedView } from './hooks/use-selected-view';
import { getDotWithColor } from '../common/line-dot-base-props';

export default function OdinColumnLineChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [transformedData, setTransformedData] = useState<Array<ColumnLineChartValues>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings('field', 'category', false);
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useColumnLineChartQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (widgetChartData && configurationData && !isLoading && !isWidgetChartDataFetching) {
      const commandExecutor = new DataExecutor();
      const commandFactory = new ColumnLineCommandFactory(configurationData);
      let commandResponse:
        | DataCommandResponse<ColumnLineType, WidgetConfigurationData, ColumnLineChartValues[]>
        | undefined;
      let outputData: ColumnLineChartValues[] | undefined;
      while ((commandResponse = commandFactory.getNextCommand())) {
        outputData = commandExecutor.execute(commandResponse.command, outputData ?? widgetChartData, configurationData);
      }
      setTransformedData(outputData);
    }
  }, [widgetChartData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };

  const toPercent = (decimal: number) => {
    return `${decimal.toFixed(0)}%`;
  };

  const columnName = useMemo(() => {
    if (widgetChartData) {
      if (widgetChartData?.calculationType) {
        return Object.keys(widgetChartData)[1];
      }
      return Object.keys(widgetChartData?.columnData)[0];
    }
    return '[none]';
  }, [widgetChartData?.calculationType, widgetChartData?.columnData]);

  const renderConfigs: {
    lineName: string;
    hideBlankLine: boolean;
    hideBlankColumn: boolean;
    colors: CustomColorProps[];
  } = useMemo(() => {
    let lineName = '[none]';
    let hideBlankLine = false;
    let hideBlankColumn = false;
    if (configurationData) {
      lineName = configurationData.widgetData?.linePercentage?.seriesName ?? t('nameNotConfigured');
      const hideBlankCategory = configurationData.widgetConfig?.hideBlankCategory === true;
      hideBlankColumn = hideBlankCategory && (columnName === '[blank]' || columnName === '[none]');
      hideBlankLine = hideBlankCategory && (lineName === '[blank]' || lineName === '[none]');
    }
    return {
      lineName,
      hideBlankLine,
      hideBlankColumn,
      colors: getColorsArray([columnName], configurationData),
    };
  }, [columnName, configurationData]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: renderConfigs.colors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <ResponsiveContainer height="99%">
          <ComposedChart
            data={transformedData} // need to potentially sort this data by time or alpha
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
            <CartesianGrid vertical={false} horizontal />
            <XAxis {...xAxisSettings} interval={0} tick={XAxisLineChart} />
            <YAxis
              type="number"
              domain={[0, getHighestColumnChartValue(transformedData)]}
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              yAxisId="left"
              orientation="left"
              tickFormatter={compactNumberFormatter}
              interval={0}
              tick
              label={{
                value: 'Values',
                angle: -90,
                dx: -40,
                position: 'center',
              }}
            />
            <YAxis
              type="number"
              tickFormatter={configurationData.widgetData.lineType === 'percentage' ? toPercent : undefined}
              domain={
                configurationData.widgetData.lineType === 'percentage'
                  ? [0, 100]
                  : [0, getHighestColumnChartValue(transformedData)]
              }
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              yAxisId="right"
              orientation="right"
              interval={0}
              tick
              label={{
                value: 'Values',
                angle: 90,
                dx: 40,
                position: 'center',
              }}
            />
            <Legend formatter={renderLegend} wrapperStyle={defaultLegendWrapperStyle} onClick={selectItem} />
            {!renderConfigs.hideBlankColumn && (
              <Bar
                name={columnName}
                dataKey="value_column"
                yAxisId="left"
                barSize={80}
                fill={findCustomColor(columnName, renderConfigs.colors)}
                hide={legendProps.value_column}
                onMouseEnter={() => handleMouseEnter(columnName)}
                onMouseLeave={handleMouseLeave}
                minPointSize={MIN_COLUMN_BAR_HEIGHT}
                isAnimationActive={activateAnimation}
              >
                <LabelList dataKey="value_column" position="top" style={labelListStyle} />
                <LabelList
                  dataKey="value_column"
                  position="center"
                  style={{ ...labelListStyle, fontWeight: 'normal' }}
                  content={(props: unknown) => <CustomMiddleLabelList {...(props as PayloadChart)} />}
                />
              </Bar>
            )}
            {!renderConfigs.hideBlankLine && (
              <Line
                name={renderConfigs.lineName}
                type="linear"
                yAxisId="right"
                dataKey="value_line"
                strokeWidth={2}
                stroke={statusColor[10]}
                fill={statusColor[10]}
                hide={legendProps.value_line}
                isAnimationActive={activateAnimation}
                activeDot={{
                  onMouseMove: () => handleMouseEnter(renderConfigs.lineName),
                  onMouseLeave: handleMouseLeave,
                }}
                dot={getDotWithColor(statusColor[10])}
              >
                {configurationData.widgetConfig?.lineDisplayValue && (
                  <LabelList
                    dataKey={configurationData.widgetData.lineType === 'percentage' ? 'value_percentage' : 'value_line'}
                    content={LabelListContent}
                    position="top"
                    style={labelListStyle}
                  />
                )}
              </Line>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}

const LabelListContent = ({ x, y, value, style }: Props) => {
  const percentageNumber = parseFloat(value as string);
  const position = percentageNumber >= 99 ? 'bottom' : 'top';
  const dy = position === 'bottom' ? 15 : -5;
  return (
    <text x={x} y={y} dy={dy} style={style}>
      {value}
    </text>
  );
};
