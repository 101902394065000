import React, { useEffect, useState } from 'react';
import { DropDownResult, OdinDateTime } from '@myosh/odin-components';
import { CustomFiltersType } from '../../redux/config/CustomFiltersApi';
import { isArray } from 'lodash';
import {
  apiDateFormat,
  formatDateAppendComparision,
  formatFromDate,
  getAssignedDatesFromFilter,
} from '../../helpers/dateFormater';
import { dropDownLabelStyle } from '../../constants/common-style-constants';

type CustomDateFieldRangeType = CustomFiltersType & {
  handleCustomFiltersChange: (value: DropDownResult[]) => void;
};

export default function CustomDateFieldRange({
  field,
  currentValues,
  handleCustomFiltersChange,
}: CustomDateFieldRangeType) {
  const [dates, setDates] = useState<Date[] | undefined>();

  /**
   * `currentValues` contain values returned from the backend.
   */
  useEffect(() => {
    if (currentValues && currentValues.length === 2) {
      const assignedValues = getAssignedDatesFromFilter(currentValues);
      const start = assignedValues[0];
      const end = assignedValues[1];
      setDates([formatFromDate(start, apiDateFormat), formatFromDate(end, apiDateFormat)]);
    } else {
      setDates(undefined);
    }
  }, [currentValues]);

  const sendValidDates = (selectedValue?: string | Date | Date[]) => {
    if (field) {
      const newFilterValues = [];
      if (selectedValue && isArray(selectedValue) && selectedValue.length === 2) {
        const endDate = formatDateAppendComparision(selectedValue[1], false);
        newFilterValues.push({ value: endDate, text: endDate });
        const startDate = formatDateAppendComparision(selectedValue[0], true);
        newFilterValues.push({ value: startDate, text: startDate });

        handleCustomFiltersChange(newFilterValues);
      } else {
        handleCustomFiltersChange([]);
      }
    }
  };

  return (
    <div className="my-1">
      <OdinDateTime
        labelStyles={dropDownLabelStyle}
        value={dates}
        label={field}
        dateSelectionType={3}
        onChange={sendValidDates}
        allowClear
        useFormattedInput={false}
      />
    </div>
  );
}
