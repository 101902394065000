export enum DateTimeStyle {
  Short = 'short',
  Medium = 'medium',
  Long = 'long',
  Full = 'full',
}

// User's time zone i.e "Europe/Helsinki"
export const USER_TIME_ZONE = Intl.DateTimeFormat(navigator.language).resolvedOptions().timeZone;

/* Date formatters (user's time zone) */
const DATE_FORMAT_SHORT = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Short,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_MEDIUM = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Medium,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_LONG = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Long,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_FULL = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Full,
  timeZone: USER_TIME_ZONE,
});

/**
 * Format a date for display.
 *
 * @param date the date to format
 * @param dateStyle the date style, 'short' by default
 *
 * @returns a date string formatted based on the users's locale and user's time zone
 */
export function formatDisplayDate(date: Date, dateStyle: DateTimeStyle = DateTimeStyle.Short) {
  if (DateTimeStyle.Short === dateStyle) {
    return DATE_FORMAT_SHORT.format(date);
  } else if (DateTimeStyle.Medium === dateStyle) {
    return DATE_FORMAT_MEDIUM.format(date);
  } else if (DateTimeStyle.Long === dateStyle) {
    return DATE_FORMAT_LONG.format(date);
  } else if (DateTimeStyle.Full === dateStyle) {
    return DATE_FORMAT_FULL.format(date);
  }

  return DATE_FORMAT_SHORT.format(date);
}
