import { difference, values } from 'lodash';
import { CustomColorProps } from '../components/WidgetSettingWindow/components/config-components.interfaces';
import { WidgetConfigurationData } from '../redux/config/widgetApi';
import { getStatusColor } from './getStatusColor';

/**
 * Method to get default colors' array to be used in a chart for the series contained in {@param labels}.
 *
 * The array returned contains, at least, colors for the series found in the {@param labels},
 *  but also all the colors that are preconfigured and returned in the configuration data.
 * If no color is specified from prior for a series, a random color from the predefined MyOSH palette is supplied.
 *
 * @param labels series that must have colors assigned to them
 * @param configurationData configuration data returned from the API for a widget
 * @returns an array consisting of objects of type `CustomColorProps`
 */
export function getColorsArray(labels: string[] | CustomColorProps[], configurationData?: WidgetConfigurationData) {
  const defaultColors: CustomColorProps[] = [];
  let missingSeriesColors: string[] | CustomColorProps[] = labels;

  if (configurationData) {
    const colorsAvailable = configurationData?.widgetConfig?.colors ?? {};
    const nameMappings = configurationData?.widgetConfig?.names ?? {};
    const preconfiguredColorSeries = Object.keys(colorsAvailable);

    if (configurationData.widgetData?.calculationType === 'sum') {
      (labels as CustomColorProps[]).forEach((value) =>
        defaultColors.push({
          label: value.label,
          color: value.color,
        })
      );
    } else {
      preconfiguredColorSeries.forEach((record) =>
        defaultColors.push({
          label: nameMappings[record] ?? record,
          color: colorsAvailable[record],
        })
      );
      missingSeriesColors = difference(labels, [
        ...(preconfiguredColorSeries as string[]),
        ...values(nameMappings as unknown),
      ]);

      for (let i = 0; i < missingSeriesColors.length; i++) {
        defaultColors.push({ label: missingSeriesColors[i] as string, color: getStatusColor(i) });
      }
    }
  }
  return defaultColors;
}
