/**
 The purpose on the function is to display only values that are fully divided by index if we have a lot of categories 
@param data - An array of keys.
@param currentValue - The value of a single category.
@param index - The position of the chart in the current category.
@returns The currentValue if it meets the display criteria, otherwise null.
*/
export function labelOrNone<T>(data: T[], currentValue: number, index: number) {
  const len = data.length;
  // The value is displayed if it is greater than 100, or if there are fewer than or equal to 5 categories.
  // Additional display rules are applied based on the number of categories.
  return currentValue > 100 ||
    len <= 5 ||
    (len > 5 && len <= 15 && index % 2 === 0) ||
    (len > 15 && len <= 20 && index % 3 === 0) ||
    (len > 20 && len <= 30 && index % 4 === 0) ||
    (len > 30 && len <= 40 && index % 5 === 0) ||
    (len > 40 && index % 8 === 0) ||
    (len > 100 && index % (len / 10) === 0)
    ? currentValue
    : null;
}
