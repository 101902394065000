import { Key } from 'react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { cloneDeep } from 'lodash';

import { baseQueryWithReAuth } from './api';
import { CHART_TYPE } from '../../constants/enums';
import { DataUpdatedProps } from '../../components/WidgetSettingWindow/widget-settings-dialog';
import { layoutWidgetApi } from './layout-widget';
import type { LayoutWidget } from './layout';
import { getStorageItem } from '../../helpers/storage';
import { tableDataApi } from './table-data';
import { detailsWindowApi } from './details-window';

//TODO: Determine which fields are required and if these are all of the settings

export type Colors = Record<string, string>;

export interface KpiTargetConfiguration extends Record<string, Key | boolean | undefined | Record<string, number>> {
  hideKPI: boolean;
  lineColor?: string;
  caption: string;
  onFRAxis?: boolean;
  id: string;
  janValue?: number;
  febValue?: number;
  marValue?: number;
  aprValue?: number;
  mayValue?: number;
  julValue?: number;
  junValue?: number;
  augValue?: number;
  sepValue?: number;
  octValue?: number;
  novValue?: number;
  decValue?: number;
  value?: Record<string, number>;
}
export interface WidgetConfiguration {
  caseInsensitive: boolean;
  colors?: Colors;
  disableFilterOutNoHoursBranches: boolean;
  filterOutNoDataBranches: boolean;
  filterOutNoTargetPeople: boolean;
  gridCellBgColors: Colors;
  hideBlankCategory: boolean;
  ignoreDateRangeFilter: boolean;
  labelDecimals: number;
  labelFormat?: string;
  labelPlacement?: string;
  secondColorEnabled: boolean;
  sortOrder: Array<string>;
  showTRIFR: boolean;
  showTop?: string;
  toggle?: boolean;
  lineDisplayValue?: boolean;
  displayDecimalPlaces?: number;
  yScaleMin?: number;
  yScaleMax?: number;
  kpiTargetConfiguration?: KpiTargetConfiguration;
  calculationType?: string;
  names?: Record<string, string>;
  defaultColor?: string;
  secondColor?: string;
  threshold?: string;
  kpiTargetForColumnKpiPercentage: Record<string, string>;
}

export interface RollingFR {
  title: string;
  module: string;
  form: string;
  field: string;
  values: string[];
  factor: number;
  displayValues: boolean;
  colors?: string[];
  ignoreOuterFilter: boolean;
  hours: {
    module: string;
    form: string;
    field: string;
  };
  legend?: string[];
}

export interface WidgetData {
  module: string;
  moduleViewId: string;
  form: string[];
  field: string;
  includeAverageColumn: boolean;
  fetchParent: boolean;
  fetchLinkedRecord: boolean;
  applyYTDColumn: boolean;
  threatColumnExist: boolean;
  displayLegend: boolean;
  ascendingSort: boolean;
  disableAutoSort: boolean;
  lineField: string;
  linePercentage?: {
    fieldValues: string[];
    seriesName: string;
  };
  lineType?: string;
  forms: string[];
  timeFrequency: string;
  secondField: string;
  customFilterField: string[];
  rollingTRIFR: RollingFR;
  rollingFR: RollingFR;
  kpiFieldValue: string;
  lineValues: number;
  visibleColumns?: Array<string>;
  pureVisibleColumns?: Array<string>;
  calculationType?: string;
  globalFilters?: Array<GlobalFiltersConfigs>;
  excludeArchivedRecords?: boolean;
  ignoreDateFilters?: boolean;
}

export interface GlobalFiltersConfigs {
  id: string;
  notBlank: boolean;
  values: Array<string>;
}

export interface WidgetConfigurationData {
  calculatedVisibleColumns: Array<string>;
  dataURL: string;
  id: string;
  title: string;
  type: CHART_TYPE;
  widgetConfig?: WidgetConfiguration;
  widgetData: WidgetData;
  fieldMapping: Record<string, unknown>;
}

export interface ColumnLineWidgetConfiguration {
  widgetConfig: WidgetConfiguration;
  widgetData: WidgetData;
}

export interface WidgetSimplifiedData {
  id: number;
  title: string;
  type: string;
  dataURL: string;
}

export type GetWidgetInsightsEnabledApiResponse = /** status 200 OK */ boolean;
export type GetWidgetInsightsEnabledApiArg = void;
export type GetInsightsApiResponse = /** status 200 OK */ WidgetInsightsResponseDto;
export type GetInsightsApiArg = {
  widgetId: string;
  body: BasicChartDataDtoObject[];
};
export type WidgetInsightsResponseDto = {
  data_analysis?: string;
  potential_causes?: string;
  recommendations?: string;
  future_trends?: string;
  missing_values_analysis?: string;
  summary?: string;
};
export type BasicChartDataDtoObject = {
  field?: string;
  secondField?: string;
  value?: object;
};

export const widgetConfigurationApi = createApi({
  reducerPath: 'widget',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['widgetConfig', 'widgetConfigSimplified'],
  endpoints: (builder) => ({
    widgetConfiguration: builder.query<WidgetConfigurationData, Key>({
      query: (widgetId) => ({ url: `/widgets/${widgetId}`, method: 'GET' }),
      providesTags: (result, error, id) =>
        result ? [{ type: 'widgetConfig' as const, id }, 'widgetConfig'] : ['widgetConfig'],
    }),
    updateWidgetConfigurationData: builder.mutation<void, Partial<DataUpdatedProps>>({
      query: ({ id, ...body }) => ({
        url: `/widgets/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (_result, _error, { id }) =>
        id
          ? [
              { type: 'widgetConfig', id: id },
              { type: 'widgetConfigSimplified', id: id },
            ]
          : [],
      async onQueryStarted({ id, ...body }, { dispatch, queryFulfilled }) {
        const activeTabId = getStorageItem('activeLayoutTabId', 'sessionStorage');
        try {
          dispatch(
            layoutWidgetApi.util.updateQueryData('getLayoutWidget', activeTabId, (draft) => {
              draft.forEach((widget, index) => {
                if (widget.widget.id === id) {
                  const thisWidget = cloneDeep(widget);
                  const newWidget = {
                    widget: {
                      ...thisWidget.widget,
                      widgetData: {
                        ...thisWidget.widget.widgetData,
                        ...body.widgetData,
                      },
                      widgetConfig: {
                        ...thisWidget.widget.widgetConfig,
                        ...body.widgetConfig,
                        kpiTargetConfiguration: {
                          ...thisWidget.widget.widgetConfig?.kpiTargetConfiguration,
                          ...body.widgetConfig?.kpiTargetConfiguration,
                        },
                      },
                    },
                  };
                  draft[index] = { ...thisWidget, ...newWidget } as LayoutWidget;
                }
              });
            })
          );

          await queryFulfilled;
          //Table header settings must be refetched
          //as configuration for columns is stored there
          if (body.isTable) {
            dispatch(tableDataApi.util.invalidateTags([{ type: 'TableHeader', id: id } as const]));
            dispatch(tableDataApi.util.invalidateTags([{ type: 'RegularTableData', id: `LIST-${id}` } as const]));
          }
          //invalidate details' window header and data
          dispatch(detailsWindowApi.util.invalidateTags([{ type: 'DetailsHeader', id: id } as const]));
          dispatch(detailsWindowApi.util.invalidateTags([{ type: 'DetailsData', id: `LIST-${id}` } as const]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    addWidgetConfiguration: builder.mutation<void, Partial<WidgetConfigurationData>>({
      query: (body) => {
        return {
          url: `/widgets`,
          method: 'POST',
          body,
        };
      },
    }),
    widgetSimplifiedConfigurationData: builder.query<Array<WidgetSimplifiedData>, unknown>({
      query: () => ({
        url: `/widgets/simplified/all`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'widgetConfigSimplified' as const, id })), 'widgetConfigSimplified']
          : ['widgetConfigSimplified'],
      transformResponse: (response: Array<WidgetSimplifiedData>) => {
        return response.map((el) => ({
          ...el,
          text: `${el.title} (${el.type})`,
        }));
      },
    }),
    widgetsAllConfigurationData: builder.query<Array<WidgetConfigurationData>, unknown>({
      query: () => ({
        url: `/widgets/all`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'widgetConfig' as const, id })), 'widgetConfig']
          : ['widgetConfig'],
    }),
    deleteWidget: builder.mutation<void, number>({
      query: (widgetId) => ({ url: `/widgets/${widgetId}`, method: 'DELETE' }),
      invalidatesTags: (result, error, widgetId) => [{ type: 'widgetConfig', id: widgetId }],
    }),

    deleteLayoutWidget: builder.mutation<void, string>({
      query: (id) => ({
        url: `/layout-widgets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'widgetConfig', id: id }],
    }),
    getWidgetInsightsEnabled: builder.query<GetWidgetInsightsEnabledApiResponse, GetWidgetInsightsEnabledApiArg>({
      query: () => ({ url: `/metadata/ai-insights-enabled` }),
    }),
    getInsights: builder.mutation<GetInsightsApiResponse, GetInsightsApiArg>({
      query: (queryArg) => ({
        url: `/widgets/data-analysis/${queryArg.widgetId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
});

export const {
  useWidgetConfigurationQuery,
  useUpdateWidgetConfigurationDataMutation,
  useAddWidgetConfigurationMutation,
  useWidgetSimplifiedConfigurationDataQuery,
  useWidgetsAllConfigurationDataQuery,
  useDeleteLayoutWidgetMutation,
  useDeleteWidgetMutation,
  useGetWidgetInsightsEnabledQuery,
  useGetInsightsMutation,
} = widgetConfigurationApi;
