import React, { useEffect, useState } from 'react';
import { Checkbox, DynamicFormRequiredType, HfInput, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { KpiTargetConfiguration, WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { CustomColorProps } from '../components/config-components.interfaces';
import { t } from 'i18next';
import SingleColorSelectorComponent from '../../common/single-color-selector.component';

interface KpiConfigurationProps {
  control: Control;
  widgetConfiguration?: WidgetConfigurationData;
  kpiTargetConfiguration: KpiTargetConfiguration;
  setLineColorSelection: (color: string) => void;
}

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const KpiTargetSettingTab = ({ control, kpiTargetConfiguration, setLineColorSelection }: KpiConfigurationProps) => {
  const [paletteVisible, setPaletteVisible] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<CustomColorProps>();
  const [updatedColor, setUpdatedColor] = useState<CustomColorProps>();
  const [settingsColors, setSettingsColors] = useState<Record<string, string>>();
  const [hideKpiValue, setHideKpiValue] = useState<boolean>(kpiTargetConfiguration?.hideKPI);
  const [inputValue, setInputValue] = useState<Array<{ field: string; value: number }>>();

  const updateColor = (newColorSetting: CustomColorProps) => {
    setUpdatedColor(
      updatedColor?.label === newColorSetting.label ? { ...updatedColor, color: newColorSetting.color } : updatedColor
    );

    setSettingsColors({
      ...settingsColors,
      [newColorSetting.label]: newColorSetting.color,
    });

    setLineColorSelection(newColorSetting.color);
  };

  useEffect(() => {
    const randomValues = [];
    const kpiValues = kpiTargetConfiguration?.value;
    if (kpiValues) {
      for (const field in kpiValues) {
        const inputValue = {
          field: field,
          value: Number(kpiValues[field].toFixed(1)),
        };
        randomValues.push(inputValue);
      }

      setInputValue(randomValues);
    }
  }, []);

  useEffect(() => {
    if (kpiTargetConfiguration?.lineColor) {
      setUpdatedColor({
        label: 'color',
        color: kpiTargetConfiguration.lineColor,
      });
    }
  }, [kpiTargetConfiguration?.lineColor]);

  const onColorItemClickHandler = () => {
    if (hideKpiValue) {
      setPaletteVisible(false);
    } else {
      setPaletteVisible(true);
      setSelectedElement({
        label: 'color',
        color: updatedColor?.color || '',
      });
    }
  };

  return (
    <div className="flex flex-col gap-3.5">
      <span className="box-border inline-block whitespace-normal align-top text-sm font-light text-error">
        {String(t('kpiMessage'))}
      </span>

      <div className="flex flex-col gap-2.5">
        <OdinController
          name="hideKPI"
          control={control}
          render={({ field: { onChange } }) => (
            <Checkbox
              label={t('hideKpi')}
              initialChecked={kpiTargetConfiguration?.hideKPI}
              onChange={(value) => {
                value && setHideKpiValue(value.checked);
                return onChange(value?.checked);
              }}
            />
          )}
        />

        <OdinController
          name="onFRAxis"
          control={control}
          render={({ field: { onChange } }) => (
            <Checkbox
              label={t('onFRAxis')}
              readOnly={hideKpiValue}
              initialChecked={kpiTargetConfiguration?.onFRAxis}
              onChange={(value) => onChange(value?.checked)}
            />
          )}
        />
      </div>
      <div className="w-40 pt-4">
        <HfInput
          control={control}
          name="caption"
          label={String(t('legendLabel'))}
          inputStyles={'h-8'}
          readOnly={hideKpiValue}
          value={kpiTargetConfiguration?.caption}
        />
      </div>

      <div className="py-2">
        <h4>{String(t('lineColour'))}</h4>
        <div className="my-1 flex">
          <SingleColorSelectorComponent
            onClick={onColorItemClickHandler}
            className={hideKpiValue ? 'cursor-none' : 'cursor-pointer'}
            fillColor={hideKpiValue ? 'darkgray' : updatedColor?.color}
            paletteVisible={paletteVisible}
            selectedItem={selectedElement}
            updateColor={updateColor}
            onPaletteClosed={() => {
              setPaletteVisible(false);
              setSelectedElement(undefined);
            }}
          />
        </div>
      </div>

      <div className="flex h-80 flex-col gap-3.5">
        <span className="text-sm font-light">{String(t('targetValue'))}</span>
        <div className="grid h-full  grid-cols-3 gap-1">
          {inputValue &&
            inputValue
              .sort((a, b) => months.indexOf(a.field) - months.indexOf(b.field))
              .map((value, index: number) => (
                <HfInput
                  key={index}
                  control={control}
                  name={value.field}
                  readOnly={hideKpiValue}
                  required={value.value > 0 ? DynamicFormRequiredType.False : DynamicFormRequiredType.True}
                  label={value.field}
                  value={String(value.value.toFixed(1))}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default KpiTargetSettingTab;
