import React, { useCallback } from 'react';
import {
  DropDown,
  DropDownNoSelection,
  DropDownResult,
  DynamicFormRequiredType,
  isDropDownNoSelection,
  OdinController,
} from '@myosh/odin-components';
import { isArray } from 'lodash';
import { t } from 'i18next';
import { widgetTypes, WidgetType } from '../../types/widget-type';
import { WidgetValueAndSetter } from '../../types/widget-value-and-setter';

export const WidgetTypeComponent = ({ value, setValue, control }: WidgetValueAndSetter<WidgetType>) => {
  const typeChangeListener = useCallback((selectedType?: DropDownResult | DropDownNoSelection | DropDownResult[]) => {
    if (selectedType && !isDropDownNoSelection(selectedType) && !isArray(selectedType)) {
      setValue(widgetTypes.find((el) => el.name === selectedType.text));
    } else {
      setValue(undefined);
    }
  }, []);

  return (
    <div className="my-1">
      <OdinController
        name="type"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={t('widgetType')}
            required={DynamicFormRequiredType.True}
            data={widgetTypes}
            textField="name"
            valueField="type"
            onChange={(value) => {
              onChange(value);
              typeChangeListener(value);
            }}
            value={{ value: value?.type, text: value?.name ?? '' }}
          />
        )}
      />
    </div>
  );
};
