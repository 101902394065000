import type { PayloadChart } from '../../shared/widget-data.interfaces';

export const CustomMiddleLabelList = ({ x, y, width, height, value, position }: PayloadChart) => {
  /** position the text in the center of a bar piece, x-scale */
  const xAxisTextPosition = x + width / 2;

  /**
   *  Calculate the text size for a bar chart piece,
   *  if height will be smaller than 12, the size of the text will be reduced to that height
   */
  const textSize = Math.ceil(height > 12 ? 12 : height);

  /** position the text in the center of a bar piece, y-scale */
  let yAxisTextPosition = y + height / 2 + textSize / 2.5;

  if (position === 'top' && height > textSize) {
    yAxisTextPosition = y + 12;
  }

  return height > 10 ? (
    <g>
      <text
        x={xAxisTextPosition}
        y={yAxisTextPosition}
        fill="#ffffff"
        fontSize={`${textSize}px`}
        textAnchor="middle"
        dominantBaseline="top"
        className="zIndex"
      >
        {value}
      </text>
    </g>
  ) : null;
};
