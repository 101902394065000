import React, { useEffect, useMemo, useState } from 'react';
import { ToolTipElement } from '../../shared/widget-data.interfaces';

const radians = Math.PI / 180;
export default function PieChartLabel({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  color,
  value,
  endAngle,
  startAngle,
  tooltipPayload,
  tooltipPosition,
  labelFormat,
  labelDecimals,
}: ToolTipElement) {
  const filterData = useMemo(() => tooltipPayload[0], [tooltipPayload]);
  const [substringText, setSubstringText] = useState<string>('');

  const coordinates = useMemo(() => {
    const sin = Math.sin(-radians * midAngle);
    const cos = Math.cos(-radians * midAngle);
    // (cx,cy) - center coordinates
    //(mx,my) - point on the outer radius. Positioned in the middle of the arc
    const mx = cx + outerRadius * cos;
    const my = cy + outerRadius * sin;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return {
      sin,
      cos,
      sx,
      sy,
      mx,
      my,
      ex,
      ey,
      textAnchor,
    };
  }, [midAngle, outerRadius, tooltipPosition, endAngle, startAngle]);

  useEffect(() => {
    setSubstringText((filterData.name || '').length > 20 ? `${filterData.name.substring(0, 20)}...` : filterData.name);
  }, [filterData.name]);

  if (Math.abs(endAngle - startAngle) < 5) {
    return null;
  }
  return (
    <g>
      <path
        d={`M${coordinates.sx},${coordinates.sy}L${coordinates.mx},${coordinates.my}L${coordinates.ex},${coordinates.ey}`}
        stroke={color}
        fill="none"
      />
      <circle cx={coordinates.ex} cy={coordinates.ey} r={2} fill={color} stroke="none" />
      <text
        className="flex rounded-md text-xs font-bold"
        x={coordinates.ex + (coordinates.cos >= 0 ? 1 : -1) * 5}
        y={coordinates.ey}
        fill={color}
        textAnchor={coordinates.textAnchor}
        dominantBaseline="central"
      >
        {substringText === '' ? '[none]' : substringText}
        {labelFormat === 'number' || labelFormat === 'both' ? '(' + value + '):' : null}{' '}
        {labelFormat === 'percentage' || labelFormat === 'both' ? `${(percent * 100).toFixed(labelDecimals)}%` : null}
      </text>
    </g>
  );
}
