import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { t } from 'i18next';
import { AboutMenuOption } from './about-menu-component';
import { useIsEditableMode } from '../Charts/hooks/useIsEditableMode';
import { useExportFile } from '../Charts/hooks/use-export-file';

type MenuItem = {
  title: string;
  isSupported: boolean;
  clickHandler?: () => void;
  className: string;
  icon: string;
  iconType: OdinIconType;
  iconSize: OdinIconSize;
  component: ReactNode;
};

interface MenuOptionsProps {
  closeConfigOverlay: () => void;
}

export const MenuOptionsComponent = ({ closeConfigOverlay }: MenuOptionsProps) => {
  const itemStyle = useRef<string>(
    `text-sm flex items-center whitespace-nowrap px-3 leading-5 cursor-pointer hover:bg-primary-4 text-mono-0`
  );
  const isEditable = useIsEditableMode();
  const [aboutModelVisible, setAboutModelVisible] = useState<boolean>(false);
  const { exportLayoutHandler, exportLayoutJobActive } = useExportFile({});

  const menuOptions: MenuItem[] = useMemo(
    () => [
      {
        title: t('about'),
        isSupported: true,
        icon: 'Information',
        iconType: OdinIconType.Line,
        iconSize: OdinIconSize.ExtraSmall,
        clickHandler: () => setAboutModelVisible(true),
        className: itemStyle.current,
        component: <AboutMenuOption showAboutInfo={aboutModelVisible} setShowAboutInfo={setAboutModelVisible} />,
      },
    ],
    [aboutModelVisible, isEditable, exportLayoutHandler, exportLayoutJobActive]
  );

  return (
    <div className="w-42 flex flex-col flex-wrap bg-mono-1 py-2 shadow group-hover:flex">
      {menuOptions.map(
        (option) =>
          option.isSupported && (
            <div
              key={option.title}
              className={option.className}
              onClick={() => {
                if (option.clickHandler) {
                  option.clickHandler();
                }
                closeConfigOverlay();
              }}
            >
              <IconButton classNames="mr-2">
                <OdinIcon size={option.iconSize} type={option.iconType} icon={option.icon} />
              </IconButton>
              {option.title}
              {option.component}
            </div>
          )
      )}
    </div>
  );
};
