import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface LayoutWeight {
  id: string;
  weight: number;
}
const initialState = {
  newOrderTabs: [] as Array<LayoutWeight>,
};
const changesInTabOrder = createSlice({
  name: 'tabsWeight',
  initialState: initialState,
  reducers: {
    changedLayoutsWeight(state, action) {
      state.newOrderTabs = action.payload;
    },
    discardNewOrder(state) {
      state.newOrderTabs = [];
    },
  },
});

export default changesInTabOrder.reducer;
export const { changedLayoutsWeight, discardNewOrder } = {
  ...changesInTabOrder.actions,
};
export const selectNewTabsOrder = (state: RootState) => state.rootReducer.tabsWeight;
