import { DropDownNoSelection, DropDownResult, isDropDownNoSelection } from '@myosh/odin-components';

export function getDropDownFilterValue(
  value: DropDownResult | DropDownResult[] | DropDownNoSelection | undefined
): Array<DropDownResult> {
  let newValue: Array<DropDownResult> = [];

  if (value && !isDropDownNoSelection(value)) {
    newValue = Array.isArray(value) ? value : [value];
  }

  return newValue;
}
