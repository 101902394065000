import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSaveChangesEdit } from '../../../redux/slices/editability/save-edit-changes';

export function useEditSaveDiscard() {
  const [saveChanges, setSaveChanges] = useState<undefined | boolean>();

  const saveChangesSelector = useSelector(selectSaveChangesEdit);

  useEffect(() => {
    setSaveChanges(saveChangesSelector.saveChanges);
  }, [saveChangesSelector]);

  return saveChanges;
}
