import { getFilters } from './get-filters';
import { createApi } from '@reduxjs/toolkit/query/react';
import { parse, isValid, format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { baseQueryWithReAuth } from './api';
import { TableQueryParameters } from './table-data';
import { OdinDataSort } from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';
import { FieldFiltersProps, TableDataColumn } from './table-data.interfaces';
import { appendQueryParameters } from './utils';

export interface DetailsWindowQueryParameters extends TableQueryParameters {
  field?: string;
  fieldValue?: string;
  secondField?: string;
  secondFieldValue?: string;
  sortedFields?: Record<string, OdinDataSort>;
  fieldFilters?: FieldFiltersProps;
  recordIds?: Array<string>;
}

export const detailsWindowApi = createApi({
  reducerPath: 'details-window',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['DetailsHeader', 'DetailsData'],
  endpoints: (builder) => ({
    detailsWindowData: builder.query<Array<Record<string, unknown>>, DetailsWindowQueryParameters>({
      query: (args) => {
        let url = `/data/${args.widgetId}/details`;
        url = appendQueryParameters(url, args);

        if (args.page) {
          url += `&page=${args.page}`;
        }
        if (args.pageSize) {
          url += `&pageSize=${args.pageSize}`;
        }
        //column chart rate
        if (args.recordIds) {
          const ids = args.recordIds.length > 0 ? args.recordIds.join(',') : '-1';
          url = url + `&filter=recordIds:in:${ids}`;
        } else {
          if (args.field && args.fieldValue) {
            url += dateValueFieldFormatter(args.field, args.fieldValue);
          }
          if (args.secondField && args.secondFieldValue) {
            url += dateValueFieldFormatter(args.secondField, args.secondFieldValue);
          }
        }
        if (args.sortedFields) {
          const sortField = Object.keys(args.sortedFields);
          if (sortField.length >= 1) {
            const sortOrder = args.sortedFields[sortField[0]].direction.toUpperCase();
            // the data can only be sorted by one field at a time
            url += `&sort=${sortField[0]}&orderBy=${sortOrder}`;
          }
        }
        if (args.fieldFilters) {
          url += getFilters(args.fieldFilters);
        }
        return { url, method: 'GET' };
      },
      providesTags: (result, _error, { widgetId }) =>
        Array.isArray(result)
          ? [
              ...result?.map((item) => ({ type: 'DetailsData', id: item.recordId as string } as const)),
              { type: 'DetailsData', id: `LIST-${widgetId}` } as const,
            ]
          : [{ type: 'DetailsData', id: `LIST-${widgetId}` } as const],
    }),
    detailsWindowHeader: builder.query<Array<TableDataColumn>, string>({
      query: (widgetId) => {
        return {
          url: `/data/${widgetId}/details/header`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, widgetId) => (result ? [{ type: 'DetailsHeader', id: widgetId }] : []),
    }),
  }),
});

export const { useDetailsWindowDataQuery, useDetailsWindowHeaderQuery } = detailsWindowApi;

const isEmptyValue = (value: string) => {
  return value === '[blank]' || value === '[none]';
};

const getEmptyFilterValue = () => {
  return '%5Bblanks%5D';
};

export const dateValueFieldFormatter = (field: string, fieldValue: string): string => {
  const parsedDate = parse(fieldValue, 'P', new Date(), { locale: enGB });

  //MAP widget only supports `eq`
  if (field === 'lat' || field === 'lon') {
    return `&filter=${field}:eq:${fieldValue}`;
  }
  if (isValid(parsedDate)) {
    // if parseDate is a valid date that include year, month and day, the format should be given as yyyy-mm-dd
    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
    return `&filter=${field}:eq:${formattedDate}`;
  } else if (isNaN(Number(fieldValue)) && isValid(new Date(fieldValue))) {
    // if filed value is a valid date that contains only year and month, the format should be given as yyyy-mm
    const formattedDate = format(new Date(fieldValue), 'yyyy-MM');
    return `&filter=${field}:like:${formattedDate}`;
  } else {
    const isBlank = isEmptyValue(fieldValue);
    return `&filter=${field}:eq:${isBlank ? getEmptyFilterValue() : fieldValue}`;
  }
};
