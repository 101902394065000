import { useEffect, useState } from 'react';
import { CartesianGrid, Tooltip, XAxis, Bar, YAxis, BarChart, ResponsiveContainer, LabelList, Cell } from 'recharts';
import { useSelector } from 'react-redux';

import { statusColor } from '../../constants/Palette';
import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';

import { TransformColumnRateData } from '../../data-transformer/column-rate/transform-column-rate-data';
import { DataExecutor } from '../../data-transformer/data-executor';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { ChartProps } from './chart.interface';
import { intervalCounter } from './generalFunctions/general-functions';
import { getDomainValue } from './generalFunctions/get-domaincharts';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';

import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { CustomXAxis } from '../common/custom-X-axis.component';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { ColumnRateResponse, useColumnRateQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import OdinCustomDetailsWindow, { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';
import { useSelectedView } from './hooks/use-selected-view';

export type EnhancedColumnRateResponse = ColumnRateResponse & {
  color: string;
};

export default function OdinColumnRate({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [transformedData, setTransformedData] = useState<Array<EnhancedColumnRateResponse>>();
  const [customColors, setCustomColors] = useState<CustomColorProps[]>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const xAxisSettings = useXAxisTopSettings();

  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CustomModalProps>();
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useColumnRateQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    const commandExecutor = new DataExecutor();
    if (widgetChartData && configurationData) {
      const columnRateResponseValue = commandExecutor.execute(
        new TransformColumnRateData(),
        widgetChartData,
        configurationData
      );
      setTransformedData(columnRateResponseValue);
      setCustomColors(
        columnRateResponseValue.map((item) => {
          return {
            label: item.field,
            color: item.color,
          };
        })
      );
    }
  }, [widgetChartData, configurationData]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const onShowModal = (data: CustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };
  const onDialogHidden = () => setDetailsWindowVisible(false);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: customColors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.field}
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
            recordIds={modalData?.recordIds}
          />
          <ResponsiveContainer height="99%">
            <BarChart
              data={transformedData}
              margin={{
                top: 20,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <CartesianGrid vertical={false} />
              <Tooltip content={<CustomTooltip color={statusColor[0]} />} cursor={false} isAnimationActive={false} />
              <XAxis
                {...xAxisSettings}
                interval={intervalCounter<ColumnRateResponse>(transformedData)}
                tick={CustomXAxis}
              />
              <YAxis
                type="number"
                label={{
                  value: 'Values',
                  angle: -90,
                  dx: -30,
                  position: 'center',
                }}
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
                domain={[0, getDomainValue(transformedData)]}
                tickFormatter={compactNumberFormatter}
                interval={0}
                tick
              />
              <Bar
                name="Quantity"
                dataKey="value"
                onMouseEnter={() => handleMouseEnter('Quantity')}
                onMouseLeave={handleMouseLeave}
                cursor="pointer"
                minPointSize={MIN_COLUMN_BAR_HEIGHT}
                onClick={(data) => onShowModal(data)}
                isAnimationActive={activateAnimation}
              >
                <LabelList dataKey="percentageValue" position="top" fontSize={12} fill="#000" />
                {transformedData.map((data, index) => (
                  <Cell key={`cell-${index}`} fill={data.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
