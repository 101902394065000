import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import {
  DropDownResult,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
  TabView,
  TabViewPanel,
} from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, omitBy } from 'lodash';
import { FieldValues, FormProvider, useForm, useFormState } from 'react-hook-form';
import { WizardTabFirst } from './wizard-tabs/wizard-tab-first';
import { WizardTabSecond } from './wizard-tabs/wizard-tab-second';
import { WizardTabThird } from './wizard-tabs/wizard-tab-third';
import { WizardTabFourth } from './wizard-tabs/wizard-tab-fourth';
import { labelDecimalFormatter, labelFormatter } from '../../helpers/getLabelFormatter';
import {
  WidgetConfigurationData,
  useUpdateWidgetConfigurationDataMutation,
  useWidgetConfigurationQuery,
  useAddWidgetConfigurationMutation,
  widgetConfigurationApi,
} from '../../redux/config/widgetApi';
import { DataUpdatedProps } from '../WidgetSettingWindow/widget-settings-dialog';
import { VisibleFieldType } from '../../redux/config/field-types-api';
import { useAppDispatch } from '../../redux/hooks';
import toast from 'react-hot-toast';
import { useWidgetConfigs } from '../WidgetSettingWindow/hooks/useWidgetConfigs';
import { layoutApi } from '../../redux/config/layout';

type WidgetShowConfigType = {
  setShowWidgetConfig: (visible: boolean) => void;
  showWidgetConfig: boolean;
};

export const WidgetConfigComponent = ({ showWidgetConfig, setShowWidgetConfig }: WidgetShowConfigType) => {
  const [finishClicked, setFinishClicked] = useState<boolean>(false);
  const [enableFinishButton, setEnableFinishButton] = useState<boolean>(false);
  const mainWindowConfigRef = useRef<ModalDialogRef>();
  const [selectedChart, setSelectedChart] = useState<DropDownResult>();
  const [activeIndex, setActiveTabIndex] = useState<number>(0);
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [widgetConfiguration, setSelectedConfigs] = useState<WidgetConfigurationData>();
  const [fieldTypeData, setFieldTypeData] = useState<Array<VisibleFieldType>>();

  const { t } = useTranslation();

  const idWidget: number = selectedChart ? Number(selectedChart.value) : -1;
  const { data: latestConfigs } = useWidgetConfigurationQuery(idWidget, {
    skip: idWidget === -1,
  });

  const defaultValues = useWidgetConfigs(latestConfigs);
  const methods = useForm({ defaultValues });
  const { dirtyFields } = useFormState({
    control: methods.control,
  });
  const formValue = methods.watch('form');
  const moduleValue = methods.watch('module');
  const fieldValue = methods.watch('field');

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const [addWidgetConfigurationData] = useAddWidgetConfigurationMutation();
  const [updateWidgetConfigurationData] = useUpdateWidgetConfigurationDataMutation();

  useEffect(() => {
    if (!selectedChart) {
      setSelectedConfigs(undefined);
    } else {
      setSelectedConfigs(latestConfigs);
    }
  }, [latestConfigs, selectedChart]);
  const dispatch = useAppDispatch();

  const buttons: Array<ModalDialogButtonSetting> = useMemo(
    () => [
      {
        isPrimary: false,
        name: 'cancelModalButton',
        text: t('cancelCAPS'),
        closeModalOnClick: true,
      },
      {
        isPrimary: false,
        name: 'back',
        text: t('backCAPS'),
        onClick: () => {
          setActiveTabIndex(activeIndex - 1);
          setEnableNextButton(true);
        },
        disabled: activeIndex === 0,
        closeModalOnClick: false,
      },
      {
        isPrimary: false,
        name: 'next',
        text: t('nextCAPS'),
        onClick: () => setActiveTabIndex(activeIndex + 1),
        disabled: activeIndex === 3 || !enableNextButton,
        closeModalOnClick: false,
      },
      {
        isPrimary: false,
        name: 'finish',
        text: t('finishCAPS'),
        onClick: () => {
          onSubmit();
          methods.reset();
          setFinishClicked(true);
          dispatch(widgetConfigurationApi.util.invalidateTags(['widgetConfigSimplified']));
        },
        disabled: !enableFinishButton && activeIndex < 2,
        closeModalOnClick: true,
      },
    ],
    [enableFinishButton, enableNextButton, activeIndex]
  );

  const onSubmit = methods.handleSubmit((data: FieldValues) => {
    if (data && !isEmpty(dirtyFields)) {
      const dataUpdated: DataUpdatedProps = {
        id: selectedChart?.value ? String(selectedChart?.value) : undefined,
        title: data.title,
        type: data.type?.value.typeValue,
      };

      dataUpdated.widgetData = omitBy(
        {
          module: data.module?.value,
          form: data.form?.value ? [data.form.value] : undefined,
          field: data.field?.value,
          secondField: data.secondField?.value,
          customFilterField: data.customFilterField,
          globalFilters: data.globalFilters?.value && [
            {
              id: data.globalFilters.value,
              notBlank: data.notBlank === true,
              values: data.globalFiltersValues ?? [],
            },
          ],
          ascendingSort: data.ascendingSort ? data.ascendingSort?.value === 'ascending' : undefined,
          visibleColumns: data.visibleColumns,
          excludeArchivedRecords: data.excludeArchivedRecords?.checked,
          ignoreDateFilters: data.ignoreDateFilters?.checked,
          displayLegend: data.displayLegend,
          disableAutoSort: data.customSorting?.checked,
        },
        isNil
      );

      dataUpdated.widgetConfig = omitBy(
        {
          labelFormat: labelFormatter(data.labelFormat),
          labelDecimals: labelDecimalFormatter(data.labelDecimals, data.labelFormat),
          sortOrder: data.sortOrder,
          colors: data.colors,
          showTop: data.showTop?.value,
          kpiTargetForColumnKpiPercentage: { ...data.kpiTargetForColumnKpiPercentage },
        },
        isNil
      );

      selectedChart
        ? updateWidgetConfigurationData(omitBy(dataUpdated, isEmpty))
            .unwrap()
            .then(() => {
              toast.success(t('updateWidgetSuccess'));
              dispatch(layoutApi.util.invalidateTags(['layoutInfo']));
            })
            .catch((error) => {
              toast.error(t('updateWidgetFailed', { details: error }));
            })
        : addWidgetConfigurationData(omitBy(dataUpdated, isEmpty));
    }
  });

  //Reset all inner values
  const closeWidgetConfig = useCallback(() => {
    setActiveTabIndex(0);
    setSelectedChart(undefined);
    setFinishClicked(false);
    setEnableNextButton(false);
    setEnableFinishButton(false);
    setShowWidgetConfig(false);
    mainWindowConfigRef.current?.close();
  }, []);

  return (
    <ModalDialog
      visible={showWidgetConfig}
      header={t('widgetWizard')}
      hidden={closeWidgetConfig}
      maxDialogWidth="55vw"
      minDialogHeight="55vh"
      maxDialogHeight="80vh"
      minDialogWidth="55vw"
      buttons={buttons}
      ref={(el) => {
        if (el) {
          mainWindowConfigRef.current = el;
        }
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <TabView id="widgetWizardContent" activeIndex={activeIndex} onTabChange={setActiveTabIndex}>
            <TabViewPanel header={<div className="widgetWizardTab">{t('wizardStep1')}</div>}>
              <WizardTabFirst
                finishClicked={finishClicked}
                setFinishClicked={setFinishClicked}
                closeOverlays={closeWidgetConfig}
                setEnableFinishButton={setEnableFinishButton}
                selectedChart={selectedChart}
                setSelectedChart={setSelectedChart}
                setEnableNextButton={setEnableNextButton}
              />
            </TabViewPanel>
            <TabViewPanel header={<div className="widgetWizardTab">{t('wizardStep2')}</div>}>
              <WizardTabSecond
                selectedChart={selectedChart}
                setEnableFinishButton={setEnableFinishButton}
                setEnableNextButton={setEnableNextButton}
                finishClicked={finishClicked}
                closeOverlays={closeWidgetConfig}
                isActive={activeIndex === 1}
                widgetConfigs={widgetConfiguration}
                setFieldTypeData={setFieldTypeData}
              />
            </TabViewPanel>
            <TabViewPanel header={<div className="widgetWizardTab">{t('wizardStep3')}</div>}>
              <WizardTabThird
                selectedChart={selectedChart}
                setEnableNextButton={setEnableNextButton}
                closeOverlays={closeWidgetConfig}
                isActive={activeIndex === 2}
                widgetConfigs={widgetConfiguration}
                fieldTypeData={fieldTypeData}
                formValue={formValue}
              />
            </TabViewPanel>
            <TabViewPanel header={<div className="widgetWizardTab">{t('wizardStep4')}</div>} disabled={true}>
              <WizardTabFourth
                control={methods.control}
                selectedChart={selectedChart}
                setEnableNextButton={setEnableNextButton}
                closeOverlays={closeWidgetConfig}
                widgetConfiguration={widgetConfiguration}
                setSortOrder={(sortOrder) => {
                  methods.setValue('sortOrder', sortOrder, { shouldDirty: true });
                }}
                setColorsSelection={(colors) => {
                  methods.setValue('colors', colors, { shouldDirty: true });
                }}
                formValue={formValue}
                fieldValue={fieldValue}
                moduleValue={moduleValue}
              />
            </TabViewPanel>
          </TabView>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};
