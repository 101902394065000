import { t } from 'i18next';
import { ViewManageModal } from './view-manage-modal';
import { ViewSaveModal } from './view-save-modal';
import { useState } from 'react';
import { useExportFile } from '../../Charts/hooks/use-export-file';
import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';

export interface ViewMenuProps {
  restoreCurrentView: () => void;
  viewSaved?: () => void;
  filterHasChanged?: boolean;
}
export const ViewMenu = ({ restoreCurrentView, filterHasChanged, viewSaved }: ViewMenuProps) => {
  const [showManageViewsModal, setShowManageViewsModal] = useState<boolean>(false);
  const [showSaveViewsModal, setShowSaveViewsModal] = useState<boolean>(false);

  const { exportLayoutHandler } = useExportFile({});

  const closeManageViewsModal = () => {
    setShowManageViewsModal(false);
  };

  const closeSaveViewsModal = () => {
    setShowSaveViewsModal(false);
  };

  const onMenuItemClick = (event: string) => {
    switch (event) {
      case 'restore':
        restoreCurrentView();
        break;
      case 'manage':
        setShowManageViewsModal(true);
        break;
      case 'save':
        setShowSaveViewsModal(true);
        break;
      case 'export':
        exportLayoutHandler();
        break;
      default:
      //no action
    }
  };
  const allDefaultItems = [
    {
      label: t('restoreCurrentView', 'Restore Current View'),
      key: 'restore',
      icon: 'ArrowGoBack',
      disabled: !filterHasChanged,
    },
    {
      label: t('saveCurrentView', 'Save Current View'),
      key: 'save',
      icon: 'Save',
      disabled: false,
    },
    {
      label: t('manageViews', 'Manage Views'),
      key: 'manage',
      icon: 'ListSettings',
      disabled: false,
    },
    {
      key: 'separator 2',
      isSeparator: true,
      disabled: false,
    },
    {
      label: t('exportTab', 'Export Tab'),
      key: 'export',
      icon: 'Download',
      disabled: false,
    },
  ];

  return (
    <>
      <div className="relative w-48 rounded-md bg-mono-1 py-1 text-sm group-hover:flex-col">
        {allDefaultItems.map((item) => (
          <div
            key={item.key}
            className={`hover:bg-gray-100 relative px-2 py-1 leading-6 ${
              item.isSeparator ? 'py-1' : 'px-3 py-px hover:bg-primary-4'
            } ${item.disabled ? 'pointer-events-none !cursor-default text-gray-3' : 'cursor-pointer'}`}
            onClick={() => onMenuItemClick(item.key)}
          >
            {!item.isSeparator ? (
              <div className="flex items-center space-x-2">
                <OdinIcon icon={item.icon || 'Filter'} type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} />
                {<span className="flex-1">{item.label}</span>}
              </div>
            ) : (
              <hr className="border-gray-3" />
            )}
          </div>
        ))}
      </div>
      <ViewManageModal visible={showManageViewsModal} onClose={closeManageViewsModal} />
      <ViewSaveModal visible={showSaveViewsModal} onClose={closeSaveViewsModal} viewSaved={viewSaved} />
    </>
  );
};
