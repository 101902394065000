import { Key } from 'react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';
import { OdinDataFilter } from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';
import { JsonDataItem } from '@myosh/odin-components';
import { values } from 'lodash';
import { JsonData } from '@myosh/odin-components/dist/types/components/generic-types';
import { removeComparisionCharacters } from '../../helpers/dateFormater';

export interface WidgetCustomFiltersType {
  id?: string;
  userId?: string;
  widgetId?: Key;
  value: {
    [additionalProp: string]: Array<string>;
  };
}

export interface CustomFiltersType {
  subType?: string;
  field?: string;
  type?: string;
  potentialValuesURL?: string;
  potentialValues?: Array<string>;
  currentValues?: Array<string>;
}

export interface ExistingFilterType {
  field: string;
  currentValues: Array<string>;
}

type QueryParameters = {
  [key: string]: string;
};

export const customFiltersApi = createApi({
  reducerPath: 'customFiltersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Filters'],
  endpoints: (build) => ({
    getCustomFilters: build.query<CustomFiltersType[], QueryParameters>({
      query: (args) => {
        return {
          url: `/widgets/${args.widgetId}/custom-filters`,
          method: 'GET',
        };
      },
      transformResponse: (response: CustomFiltersType[]) => {
        return response.map((filterType) => {
          return { ...filterType, currentValues: filterType.currentValues?.map(removeComparisionCharacters) };
        });
      },

      providesTags: (result, error, { widgetId }) => [{ type: 'Filters', id: widgetId }],
    }),
    getWidgetCustomFilters: build.query<WidgetCustomFiltersType, QueryParameters>({
      query: (args) => ({
        url: `/custom-filters/${args.widgetId}`,
        method: 'GET',
      }),
      transformResponse: (response: WidgetCustomFiltersType) => {
        return response;
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'Filters', id: widgetId }],
    }),
    addCustomFilters: build.mutation<WidgetCustomFiltersType, Partial<WidgetCustomFiltersType>>({
      query: (data) => {
        return {
          url: `/custom-filters`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, { widgetId }) => [{ type: 'Filters', id: widgetId }],
    }),
    updateCustomFilters: build.mutation<WidgetCustomFiltersType, Partial<WidgetCustomFiltersType>>({
      query: (data) => {
        return {
          url: `/custom-filters`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: (result, error, { widgetId }) => [{ type: 'Filters', id: widgetId }],
    }),
    userController: build.query<Array<JsonDataItem>, string | Record<string, unknown>>({
      query: (args: { page: number; pageSize: number; fieldFilters?: Record<string, OdinDataFilter> }) => {
        let url = `/users/name-list`;

        if ((args.page === 0 || args.page) && args.pageSize) {
          url += `?page=${args.page}&pageSize=${args.pageSize}`;
        }

        if (args.fieldFilters) {
          url += `&fullName=${args.fieldFilters.field.value}`;
        }

        return { url, method: 'GET' };
      },
      transformResponse: (response: Array<string>) => {
        return response.map((field) => {
          return {
            id: field,
            title: field,
            field: field,
          };
        });
      },
    }),
    getTextSearchCustomFilters: build.query<JsonData, string | Record<string, unknown>>({
      query: (args: { widgetId: number; field?: string; filterValue?: string }) => {
        let url = `/widgets/${args.widgetId}/custom-filters/text-search`;

        if (args.field && args.filterValue) {
          url += `?filter=${args.field}:like:${args.filterValue}`;
        }

        return { url, method: 'GET' };
      },
      transformResponse: (response: { [key: string]: JsonData }) => {
        return values(response)[0].map((field) => {
          return { id: field, field: field, text: field };
        });
      },
    }),
  }),
});

export const {
  useGetCustomFiltersQuery,
  useLazyGetCustomFiltersQuery,
  useGetWidgetCustomFiltersQuery,
  useAddCustomFiltersMutation,
  useUpdateCustomFiltersMutation,
  useUserControllerQuery,
  useGetTextSearchCustomFiltersQuery,
} = customFiltersApi;
