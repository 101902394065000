import { BasicDataChartResponse, ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { flatMap, groupBy } from 'lodash';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';

type SecondField = {
  secondField: string;
  value: number;
};

/**
 * This is the first command to be used when transforming data received for the "COLUMN_BASIC" chart.
 * The `process` composes and returns a `ChartResponse[]`,
 * where each item contains the `field` X, secondFields related to that field X with their respective values
 * and an accumulated value for the field X.
 */
export class ParseChartBasicResponse
  implements IDataCommand<ChartResponse[], WidgetConfigurationData, ChartResponse[]>
{
  process(data: BasicDataChartResponse[], conf: WidgetConfigurationData): ChartResponse[] {
    if (!data || !conf) {
      return data;
    }
    // Returns a dictionary, where K is a unique `field` value and V is an array
    //containing objects of type `BasicDataChartResponse`. The dictionary's key K matches the `field` of an object
    const transposedData: Record<string, Array<BasicDataChartResponse>> = groupBy(data, 'field');
    const finalResponse: ChartResponse[] = [];
    for (const key in transposedData) {
      const secondFields: SecondField[] = flatMap(transposedData[key], (response) => {
        return {
          secondField: response.secondField ?? '',
          value: Number(response.value),
        };
      });

      //Compose a final response item of type
      // {field: 'X', "secondField1":"value1", "secondField2":"value2", "valueColumn": "value1+value2"}
      const item: ChartResponse = { field: getValueOrDefault(key, '[none]') };
      let seriesFieldTotalCount = 0;
      for (let i = 0; i < secondFields.length; i++) {
        item[secondFields[i].secondField] = secondFields[i].value;
        seriesFieldTotalCount = Math.max(seriesFieldTotalCount, secondFields[i].value);
      }
      item['value_column'] = seriesFieldTotalCount;
      finalResponse.push(item);
    }

    return finalResponse;
  }
}
