import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface TabId {
  tabId?: string;
}

const newTabState = createSlice({
  name: 'newTab',
  initialState: {} as TabId,
  reducers: {
    setNewTabId(state, action) {
      state.tabId = action.payload;
    },
  },
});

export default newTabState.reducer;
export const newTabStateActions = {
  ...newTabState.actions,
};
export const selectNewTabState = (state: RootState) => state.rootReducer.newTabState;
