import React from 'react';
import { DropDown, isDropDownResult, JsonData, OdinController } from '@myosh/odin-components';
import { t } from 'i18next';
import { WidgetValueAndSetter } from '../../types/widget-value-and-setter';

export const WidgetSecondFieldComponent = ({
  widgetType,
  value,
  setValue,
  secondFieldData,
  control,
}: WidgetValueAndSetter<string>) => {
  return (
    <div className="my-1">
      <OdinController
        name="secondField"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={widgetType?.type.typeValue === 'CROSS_TAB_REPORT' ? t('fieldXAxis') : t('secondField')}
            data={secondFieldData as unknown as JsonData}
            textField="field"
            valueField="field"
            onChange={(value) => {
              if (isDropDownResult(value)) {
                setValue(value.text);
                onChange(value);
              }
            }}
            value={value ? { text: value, value: value } : undefined}
          />
        )}
      />
    </div>
  );
};
