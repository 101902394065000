import React, { Ref, forwardRef, useCallback, useId, useImperativeHandle, useState } from 'react';
import { t } from 'i18next';
import '../../../styles/view-dropdown-selection.css';
import { DropdownChangeEvent, Dropdown as PrimeReactDropdown } from 'primereact/dropdown';
import { PutPatchViewInterface } from '../../../redux/config/views';

export type ViewNameOption = PutPatchViewInterface & { value: string; text: string; defaultView?: boolean };
export interface ViewDropdownRef {
  setSelectedView: (viewId: string) => void;
}
type ViewDropdownprops = {
  viewOptions: Array<ViewNameOption>;
  isFetching?: boolean;
  updateSelectedView: (id?: string) => void;
};
function ViewDropdown(props: ViewDropdownprops, ref: Ref<ViewDropdownRef>) {
  const { viewOptions, isFetching, updateSelectedView } = props;
  const id = useId();
  const [currentView, setCurrentView] = useState<string>();

  const itemTemplate = (option: ViewNameOption) => {
    if (isFetching) return <></>;

    return <>{option.text}</>;
  };

  const onViewSelected = (event: DropdownChangeEvent) => {
    setCurrentView(event.value);
    updateSelectedView(event.value);
  };

  const setSelectedView = useCallback((viewId: string) => {
    setCurrentView(viewId);
  }, []);

  useImperativeHandle(ref, () => ({
    setSelectedView,
  }));
  return (
    <div className="prime-dropdown mr-2 pt-1">
      <PrimeReactDropdown
        options={viewOptions}
        optionLabel="text"
        optionValue="value"
        onChange={onViewSelected}
        value={currentView}
        placeholder={t('selectView')}
        inputId={id}
        filter
        filterPlaceholder={t('typeToSearch')}
        itemTemplate={itemTemplate}
        emptyMessage={<>{t('noViews')}</>}
        virtualScrollerOptions={{ itemSize: 32, className: 'custom-scroll' }}
      />
      <label htmlFor={id} className="dropdown-label">
        {String(t('view'))}
      </label>
    </div>
  );
}

export default forwardRef(ViewDropdown);
