import { IDataCommand } from '../data-transformer.interface';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

type FieldNameOptional = {
  field?: string;
  name?: string;
};

export class FilterEmptyText<T extends FieldNameOptional>
  implements IDataCommand<Array<T>, WidgetConfigurationData, Array<T>>
{
  process(data: Array<T>, config: WidgetConfigurationData): Array<T> {
    if (config?.widgetConfig?.hideBlankCategory === true) {
      return data.filter((cellLabel) => {
        const fieldEmpty = 'field' in cellLabel ? checkEmptyOrNone(cellLabel.field) : false;
        const nameEmpty = 'name' in cellLabel ? checkEmptyOrNone(cellLabel.field) : false;
        return !fieldEmpty && !nameEmpty;
      });
    } else {
      return data;
    }
  }
}

const checkEmptyOrNone = (value: string | undefined) => {
  return !value || value === '' || value === '[none]';
};
