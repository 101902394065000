import { useEffect, useMemo, useState } from 'react';
import { CartesianGrid, Tooltip, XAxis, Bar, Legend, YAxis, BarChart, ResponsiveContainer, LabelList } from 'recharts';
import { useSelector } from 'react-redux';

import { LegendPayloadOnClick } from './chart-events';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { ChartProps } from './chart.interface';
import { findCustomColor, includeLegend, intervalCounter } from './generalFunctions/general-functions';
import { getHighestColumnChartValue } from './generalFunctions/get-domaincharts';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import { getColorsArray } from '../../helpers/get-colors-arrays';
import OdinCustomDetailsWindow, { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';

import { DataExecutor } from '../../data-transformer/data-executor';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';
import { ColumnBasicCommandFactory } from '../../data-transformer/column-basic/column-basic-command.factory';

import { renderLegend } from '../common/custom-legend.component';
import { labelOrNone } from '../common/formatter-label';
import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { sortOrderStrings } from '../common/sort-order-strings';
import { CustomXAxis } from '../common/custom-X-axis.component';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { ChartResponse, useColumnBasicQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';

import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinColumnBasic({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [legendProps, setLegendProps] = useState<Record<string, unknown>>({});
  const [transformedData, setTransformedData] = useState<Array<ChartResponse>>();
  const [secondFieldKeys, setSecondFieldKeys] = useState<Array<string>>();
  const [customColors, setCustomColors] = useState<Array<CustomColorProps>>([]);
  const [modalData, setModalData] = useState<CustomModalProps>();
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useColumnBasicQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets && widgets.length > 0) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (transformedData && transformedData.length > 0 && configurationData) {
      const secondFields = sortOrderStrings(transformedData, configurationData);
      setSecondFieldKeys(secondFields);
      setCustomColors(getColorsArray(secondFields, configurationData));
    }
  }, [transformedData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) =>
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });

  useEffect(() => {
    if (configurationData && widgetChartData) {
      const commandExecutor = new DataExecutor();
      const commandFactory = new ColumnBasicCommandFactory(configurationData);
      let commandResponse: DataCommandResponse<ChartResponse[], unknown, ChartResponse[]> | undefined;
      let outputData: ChartResponse[] = widgetChartData;
      while ((commandResponse = commandFactory.getNextCommand()) !== undefined) {
        const inputData = outputData;
        outputData = commandExecutor.execute(commandResponse.command, inputData, configurationData);
      }

      setTransformedData(outputData);
    }
  }, [widgetChartData, configurationData]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const onShowModal = (data: CustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };

  const onDialogHidden = () => setDetailsWindowVisible(false);

  const yAxisDomain: [number, number] | undefined = useMemo(() => {
    if (transformedData) {
      return [0, getHighestColumnChartValue(transformedData)];
    }
    return undefined;
  }, [transformedData]);

  const ticks = useTicksBasedOnScale(yAxisDomain);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: customColors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.field}
            secondFieldValue={
              modalData?.tooltipPayload && modalData.tooltipPayload.length > 0 && modalData.tooltipPayload[0].dataKey
            }
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
          />
          <ResponsiveContainer height="99%">
            <BarChart
              data={transformedData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 30,
              }}
              layout="horizontal"
            >
              <CartesianGrid vertical={false} horizontal strokeDasharray={undefined} />
              <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
              <XAxis {...xAxisSettings} interval={intervalCounter<ChartResponse>(transformedData)} tick={CustomXAxis} />
              <YAxis
                type="number"
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
                domain={yAxisDomain}
                tickFormatter={compactNumberFormatter}
                interval={0}
                tick
                ticks={ticks}
              />
              {includeLegend(configurationData) && (
                <Legend
                  formatter={renderLegend}
                  verticalAlign="bottom"
                  wrapperStyle={{
                    bottom: 0,
                    paddingTop: 0,
                    paddingBottom: 7,
                    maxHeight: '80px',
                    overflow: 'auto',
                    zIndex: 1,
                    background: 'white',
                  }}
                  onClick={(data) => selectItem(data)}
                />
              )}
              {secondFieldKeys?.map((item: string, index: number) => {
                return (
                  <Bar
                    key={index}
                    name={item}
                    dataKey={item}
                    barSize={80}
                    fill={findCustomColor(item, customColors)}
                    hide={Boolean(legendProps && legendProps[item])}
                    onMouseEnter={() => handleMouseEnter(item)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(data) => onShowModal(data)}
                    cursor="pointer"
                    minPointSize={MIN_COLUMN_BAR_HEIGHT}
                    isAnimationActive={activateAnimation}
                  >
                    <LabelList
                      dataKey={item}
                      position="top"
                      fontSize={12}
                      formatter={(label: number) => labelOrNone(secondFieldKeys, label, index)}
                    />
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
