import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState: { saveChanges?: boolean } = {
  saveChanges: undefined,
};

export const saveEditChangesSlice = createSlice({
  name: 'editChanges',
  initialState,
  reducers: {
    saveChanges: (state, action) => {
      state.saveChanges = action.payload;
    },
  },
});

export const { saveChanges } = saveEditChangesSlice.actions;

export default saveEditChangesSlice.reducer;

export const selectSaveChangesEdit = (state: RootState) => state.rootReducer.editChangesSave;
