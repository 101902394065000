import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { isNil, omitBy, reject } from 'lodash';

export class DeleteUndefinedAttributes<T extends Record<string, unknown>>
  implements IDataCommand<Array<T>, WidgetConfigurationData, Array<T>>
{
  process(data: Array<T>): Array<T> {
    return reject(data, isNil).map((item) => {
      return omitBy(item, isNil) as T;
    });
  }
}
