import { ChartColumnLineResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { ColumnLineChartValues } from '../../shared/widget-data.interfaces';
import { DeleteUndefinedAttributes } from '../common-commands/delete-undefined-attributes';
import { MoveAnElemenToTheEnd } from '../common-commands/move-an-element-to-the-end-of-array';
import { SortByField } from '../common-commands/sort-by-field';
import { DataCommandResponse, IDataCommand, IDataCommandFactory } from '../data-transformer.interface';
import { BuildColumnLineChartValues } from './data-commands/build-column-line-chart-values-by-values';
import { SortByCustomSorting } from './data-commands/sort-by-custom-sorting';

export type ColumnLineType = ChartColumnLineResponse | ColumnLineChartValues[];
export class ColumnLineCommandFactory implements IDataCommandFactory {
  private currentCommandIndex = 0;
  private readonly commands: Array<IDataCommand<ColumnLineType, WidgetConfigurationData, ColumnLineChartValues[]>>;

  constructor(configurationData: WidgetConfigurationData) {
    this.commands = [new BuildColumnLineChartValues()];

    if (configurationData.widgetConfig?.sortOrder) {
      this.commands.push(new SortByCustomSorting<ColumnLineChartValues>());
    } else {
      this.commands.push(new SortByField<ColumnLineChartValues[]>(), new MoveAnElemenToTheEnd());
    }
    this.commands.push(new DeleteUndefinedAttributes<ColumnLineChartValues>());
  }

  getNextCommand<TIn, TInConfig, TOut>(): DataCommandResponse<TIn, TInConfig, TOut> | undefined {
    const command = this.commands[this.currentCommandIndex] as IDataCommand<TIn, TInConfig, TOut>;
    return (
      command && {
        command,
        commandIndex: this.currentCommandIndex++,
      }
    );
  }
}
