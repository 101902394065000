import { difference, has, sortBy } from 'lodash';
import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';

/**
 * This sort class is used to rearrange **attributes** in each object to match `sortOrder`
 * returned from the API.
 * This is needed because sort order is,sometimes, based on the `secondField`
 * value and these values are included in the final transformation as attributes.
 */
export class SortOrderKeys
  implements IDataCommand<Array<ChartResponse>, WidgetConfigurationData, Array<ChartResponse>>
{
  process(data: Record<string, unknown>[], configurationData: WidgetConfigurationData): ChartResponse[] {
    const sortOrder = configurationData?.widgetConfig?.sortOrder;
    if (sortOrder && data) {
      const sortedKeys = data.map((item) => {
        const obj: Record<string, unknown> = {};
        //Apend first all the existing sorted keys
        for (let i = 0; i < sortOrder.length; i++) {
          const sortKey = sortOrder[i];
          const result = item[sortKey];
          if (has(item, sortKey)) {
            obj[sortKey] = result;
          }
        }

        //Append the rest of the attributes in alphabetical order
        const keysNotExistingInSortOrder = sortBy(difference(Object.keys(item), sortOrder));
        for (let i = 0; i < keysNotExistingInSortOrder.length; i++) {
          const key = keysNotExistingInSortOrder[i];
          obj[key] = item[key];
        }
        return obj;
      });
      return sortedKeys;
    }
    return data;
  }
}
