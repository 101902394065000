import { compareAsc, compareDesc, isValid } from 'date-fns';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { CustomColorProps } from '../../WidgetSettingWindow/components/config-components.interfaces';
import { dateStringFormatter } from './formate-date-fields';

export enum SortDirection {
  Ascending,
  Descending,
}

export function intervalCounter<T>(transformedData: T[]) {
  if (transformedData.length > 130) {
    return 3;
  } else if (transformedData.length > 90) {
    return 2;
  } else if (transformedData.length > 65) {
    return 1;
  } else {
    return 0;
  }
}

export function genericSort<T extends Record<string, any>, K extends keyof T>(
  data: Array<T>,
  sortKey: K,
  direction: SortDirection = SortDirection.Ascending
): Array<T> {
  return data.sort((a, b) => {
    const sortType = typeof a[sortKey];
    const isValidDate = isValid(new Date(dateStringFormatter(a[sortKey], true, true)));

    if (sortType === 'string' && !isValidDate) {
      return direction === SortDirection.Ascending
        ? a[sortKey].localeCompare(b[sortKey])
        : b[sortKey].localeCompare(a[sortKey]);
    }
    if (isValidDate) {
      const firstDate = new Date(a[sortKey] || '');
      const secondDate = new Date(b[sortKey] || '');
      return direction === SortDirection.Ascending
        ? compareAsc(firstDate, secondDate)
        : compareDesc(firstDate, secondDate);
    }
    return direction === SortDirection.Ascending ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
  });
}
/**
 * A helper method to indicate if a legend is to be shown for a chart.
 * Missing `displayLegend` attribute must be treated as `true`.
 *
 * @param configurationData of a widget which legend visibility is to be defined
 * @returns boolean
 */
export const includeLegend = (configurationData: WidgetConfigurationData | undefined): boolean => {
  const widgetData = configurationData?.widgetData;

  if (!!widgetData?.secondField) {
    if (widgetData?.displayLegend !== undefined) {
      // If the widget has a secondary field and displayLegend property exists then use
      // Legend visibility explicitly set by the user
      return widgetData?.displayLegend;
    } else {
      // If displayLegend property does not exists, set to true
      return true;
    }
  } else {
    // If the widget has no secondary field, set to false
    return false;
  }
};

export const findCustomColor = (label: string | undefined, customColors: Array<CustomColorProps>) => {
  if (customColors && label) {
    return customColors.find((item) => item.label === label)?.color;
  }
};
