import React, { ReactElement, isValidElement } from 'react';
import { ModalDialog } from '@myosh/odin-components';

type ButtonProps = {
  onHideModal: () => void;
  onDiscard: () => void;
};
interface ConfirmationWindowProps {
  onSaveHandler: () => void;
  showWindow: boolean;
  title: string;
  text: string | ReactElement;
  save: string;
  discard: string;
  onDiscardChanges: ButtonProps;
}

const ConfirmationWindow = ({
  onSaveHandler,
  showWindow: showSaveModal,
  text,
  title,
  discard,
  save,
  onDiscardChanges,
}: ConfirmationWindowProps) => {
  return (
    <ModalDialog
      buttons={[
        {
          type: 'primary',
          name: save,
          text: save,
          onClick: onSaveHandler,
        },
        {
          type: 'default',
          variant: 'regular',
          name: discard,
          text: discard,
          onClick: onDiscardChanges.onDiscard,
        },
      ]}
      displayPosition={4}
      header={title}
      showCloseIcon
      transitionDuration={400}
      visible={showSaveModal}
      hidden={onDiscardChanges.onHideModal}
    >
      {isValidElement(text) ? text : <div>{text}</div>}
    </ModalDialog>
  );
};

export default ConfirmationWindow;
