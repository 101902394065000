import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { ChartResponse } from '../../redux/config/chart-data';

export const transformBarChartData = (data: ChartResponse[]) => {
  return data?.map((item) => {
    return {
      field: getValueOrDefault(item.field, '[none]'),
      value_column: Number(item.value),
    };
  });
};
