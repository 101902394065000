import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '@myosh/myosh-login';
import { useDispatch } from 'react-redux';
import { setSchemaInfo } from '../../redux/slices/globalFilter/GlobalFilterHierarchySlice';
import { useLoginQueryParams } from '../../hooks/useLoginQueryParams';

export const PrivateRoute = () => {
  const { state } = useUser();
  const isAuthenticated = state.auth;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { username } = useLoginQueryParams();

  useEffect(() => {
    if (state?.user?.schemas) {
      dispatch(setSchemaInfo(state.user.currentSchema));
    }
  }, [state]);

  if (isAuthenticated) {
    return <Outlet />;
  } else if (!isAuthenticated && !pathname.includes('/login')) {
    return <Navigate to={username ? `/login?username=${username}` : '/login'} />;
  }

  return null;
};
