import { useCallback } from 'react';
import { useGetCurrentSchemaInfoQuery } from '../../../redux/config/current-schema-api';

export function useRecordOdinUrl(moduleId?: string | number) {
  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();

  const createDetailsUrl = useCallback(
    (formId?: string | number) => {
      if (schemaInfo && moduleId) {
        if (formId) {
          return `${process.env.REACT_APP_ODIN_HOST_URL}/${schemaInfo.schema}/modules/${moduleId}/forms/${formId}/records/`;
        }
        return `${process.env.REACT_APP_ODIN_HOST_URL}/${schemaInfo.schema}/modules/${moduleId}/records/`;
      }
      return undefined;
    },
    [schemaInfo, moduleId]
  );

  return createDetailsUrl;
}
