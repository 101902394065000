import { createSlice } from '@reduxjs/toolkit';
import { HierarchyFilterValues } from '../../../shared/Hierarchy';
import { RootState } from '../../store';

export interface HierarchyFiltersState {
  schema: string | undefined;
  values: HierarchyFilterValues;
}

const getHierarchyStorageKey = (schema: string | undefined) => {
  if (schema) {
    return `${schema}-global-filters-hierarchy`;
  }
  return `global-filters-hierarchy`;
};

const initialState: HierarchyFiltersState = {
  schema: undefined,
  values: {},
};

export const globalFilterHierarchySlice = createSlice({
  name: 'globalFilterHierarchy',
  initialState,
  reducers: {
    setSchemaInfo: (state, action) => {
      state.schema = action.payload;
    },
    addHierarchyFilter: (state, action) => {
      state.values = action.payload;
      localStorage.setItem(getHierarchyStorageKey(state.schema), JSON.stringify(action.payload));
    },
    removeHierarchyFilters: (state) => {
      localStorage.removeItem(getHierarchyStorageKey(state.schema));
    },
  },
});

export const { addHierarchyFilter, removeHierarchyFilters, setSchemaInfo } = globalFilterHierarchySlice.actions;

export default globalFilterHierarchySlice.reducer;

export const selectHierarchySearchQuery = (state: RootState) => state.rootReducer.filterHierarchy;
