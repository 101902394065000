import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Layout, LayoutWidget } from '../config/layout';

type TabsType = {
  tabs: Layout[];
  widgets: LayoutWidget[];
};

const initialState: TabsType = {
  tabs: [],
  widgets: [],
};

const layoutTabsSlice = createSlice({
  name: 'layoutTabs',
  initialState,
  reducers: {
    setLayoutTabs(state, action) {
      state.tabs = action.payload;
    },
    setLayoutWidgets(state, action) {
      state.widgets = action.payload;
    },
  },
});

export const { setLayoutTabs, setLayoutWidgets } = {
  ...layoutTabsSlice.actions,
};

export default layoutTabsSlice.reducer;

export const getTabs = (state: RootState) => state.rootReducer.layoutTabs.tabs;
export const getWidgets = (state: RootState) => state.rootReducer.layoutTabs.widgets;
