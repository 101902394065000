import { MouseEventHandler, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  Cell,
} from 'recharts';
import { compact, flatten, has, isEmpty, uniq } from 'lodash';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import OdinCustomDetailsWindow, { CustomModalProps } from '../CustomDetailsWindow/custom-details-window.component';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import { PayloadChart, ToolTipElement } from '../../shared/widget-data.interfaces';
import { findCustomColor, includeLegend, intervalCounter } from './generalFunctions/general-functions';
import { getHighestColumnChartValue } from './generalFunctions/get-domaincharts';

import { getStatusColor } from '../../helpers/getStatusColor';
import { getColorsArray } from '../../helpers/get-colors-arrays';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';

import { ColumnChartCommandFactory } from '../../data-transformer/column-chart/column-chart-command.factory';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';
import { DataExecutor } from '../../data-transformer/data-executor';
import { FieldDataProps } from '../../data-transformer/column-chart/column-chart-interfaces';

import { CustomLegendWithCustomScroll, defaultLegendWrapperStyle } from '../common/custom-legend.component';
import { CustomValueAccessor } from '../common/custom-value-accessor';
import { CustomMiddleLabelList } from '../common/custom-middle-label.component';
import { CustomXAxis, XAxisLineChart } from '../common/custom-X-axis.component';
import { yAxisSettings } from '../common/y-axis-properties';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { sortOrderStrings } from '../common/sort-order-strings';
import CustomizedLabel from '../common/column-chart-custom-label.component';

import useStartEndDates from './hooks/useStartEndDates';
import { useKpiConfigs } from './hooks/useKPIConfigs';
import { useTRIFRConfigs } from './hooks/useRollingTRIFRConfigs';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import { useKPIDomainValue } from './hooks/useKPIDomainValue';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';
import { useFRConfigs } from './hooks/useRollingFRConfigs';

import { BasicDataChartResponse, useColumnChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { renderCustomizedLineLabel } from '../common/custom-label-list';
import { LabelPosition } from 'recharts/types/component/Label';
import { useSelectedView } from './hooks/use-selected-view';
import { getDotWithColor } from '../common/line-dot-base-props';

export interface ColumnChartCustomModalProps extends CustomModalProps {
  Sum?: string;
}

export default function OdinColumnChart({ widgetId, layoutProps }: ChartProps) {
  const [transformedData, setTransformedData] = useState<Array<FieldDataProps>>();
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [modalData, setModalData] = useState<ColumnChartCustomModalProps>();
  const [barChartKeys, setBarChartKeys] = useState<Array<string>>([]);
  const [rollingSiteTRIFRKeys, setRollingSiteTRIFRKeys] = useState<Array<string>>([]);
  const [rollingFRKeys, setRollingFRKeys] = useState<Array<string>>([]);
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [xLabelsHeight, setXLabelsHeight] = useState<number>(30);
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const chartParentRef = useRef<HTMLDivElement>(null);

  const { startDate, endDate } = useStartEndDates();
  const view = useSelectedView();
  const widgets = useSelector(getWidgets);

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    error,
    isError,
  } = useColumnChartQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  const recalculateLabelsHeight = () => {
    const targetNode = chartParentRef.current;
    if (targetNode) {
      const xAxis = targetNode.getElementsByClassName(`recharts-xAxis`)[0];
      if (xAxis) {
        const height = Math.max(30, Math.ceil((xAxis as SVGSVGElement).getBBox().height));
        setXLabelsHeight(height);
      }
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    recalculateLabelsHeight();
  });

  const xAxisSettings = useXAxisTopSettings('name', 'category');
  const yAxisLineName = useRef<string>('lineYAxis');

  const kpiConfigs = useKpiConfigs(configurationData);
  const renderKPILine = useMemo(
    () => has(widgetChartData, 'kpi') && kpiConfigs.available,
    [kpiConfigs, widgetChartData]
  );
  const trifrConfig = useTRIFRConfigs(configurationData);
  const renderTrifrLine = useMemo(
    () => has(widgetChartData, 'rollingTRIFR') && trifrConfig.available,
    [trifrConfig, widgetChartData]
  );
  const frConfig = useFRConfigs(configurationData);
  const renderRollingFR = useMemo(
    () => has(widgetChartData, 'rollingFR') && frConfig.available,
    [widgetChartData, frConfig]
  );
  const renderRollingSiteTRIFR: boolean = useMemo(
    () => has(widgetChartData, 'rollingSiteTRIFR') && configurationData?.widgetData?.rollingTRIFR !== undefined,
    [widgetChartData, configurationData]
  );

  const basicValue = useMemo(() => {
    if (widgetChartData) {
      return widgetChartData.basicValue;
    }
    return [];
  }, [widgetChartData]);

  const isSumCalculationType = useMemo(() => {
    return configurationData && configurationData.widgetData?.calculationType === 'sum';
  }, [configurationData]);

  const colors = useMemo(() => {
    let allValidKeys: string[] | CustomColorProps[] = [];
    if (basicValue) {
      if (isSumCalculationType) {
        const widgetConfiguration = configurationData?.widgetConfig;

        allValidKeys = uniq(
          basicValue.map((item) => {
            const { field, secondField } = item;
            const isSecondColorActive =
              widgetConfiguration?.secondColorEnabled && Number(widgetConfiguration?.threshold) <= Number(secondField);
            const color =
              widgetConfiguration &&
              (isSecondColorActive ? widgetConfiguration.secondColor : widgetConfiguration.defaultColor);
            const property: CustomColorProps = {
              label: getValueOrDefault(field, '[none]'),
              color: getValueOrDefault(color, getStatusColor(0)),
            };

            return property;
          })
        );
      } else if (!isSumCalculationType) {
        allValidKeys = uniq(
          basicValue.map((item) => {
            const { field, secondField } = item;
            return getValueOrDefault(secondField ?? field, '[none]');
          })
        );
      }
    }
    return getColorsArray(allValidKeys, configurationData);
  }, [configurationData, basicValue]);

  useEffect(() => {
    if (widgetChartData && configurationData && !isWidgetChartDataFetching) {
      const commandExecutor = new DataExecutor();
      const commandFactory = new ColumnChartCommandFactory(configurationData);
      let ColumnChartCommandResponse: DataCommandResponse<unknown, unknown, Array<FieldDataProps>> | undefined;
      let outputData: undefined | Array<FieldDataProps>;

      while ((ColumnChartCommandResponse = commandFactory.getNextCommand())) {
        const inputData = outputData || widgetChartData;
        outputData = commandExecutor.execute(ColumnChartCommandResponse.command, inputData, configurationData);
      }

      if (outputData) {
        if (basicValue) {
          // Second field is present when chart is stacked and must be used then
          const getMappedValue = (item: BasicDataChartResponse) => {
            if ('secondField' in item && !isSumCalculationType) {
              return getValueOrDefault(item.secondField, '[none]');
            }
            if (isSumCalculationType) {
              return 'Sum';
            }
            return 'Quantity';
          };
          const basicValueKeys = uniq(flatten(compact(basicValue.map(getMappedValue))));
          setBarChartKeys(sortOrderStrings(outputData, configurationData, basicValueKeys));
        }
        if (widgetChartData.rollingSiteTRIFR) {
          setRollingSiteTRIFRKeys(Object.keys(widgetChartData.rollingSiteTRIFR));
        }
        if (widgetChartData.rollingFR) {
          setRollingFRKeys(Object.keys(widgetChartData.rollingFR));
        }
        setTransformedData(outputData);
      }
    }
  }, [widgetChartData, configurationData, isSumCalculationType, basicValue, isWidgetChartDataFetching]);

  const onShowModal = (data: ColumnChartCustomModalProps) => {
    setModalData(data);
    setDetailsWindowVisible(true);
  };

  const onDialogHidden = () => setDetailsWindowVisible(false);

  const selectItem = (dataKey: string) =>
    setLegendProps({
      ...legendProps,
      [dataKey || '']: !legendProps[dataKey || ''],
    });

  const hasAnyDataKey = useMemo(() => {
    if (widgetChartData) {
      return (
        'rollingFR' in widgetChartData ||
        'rollingTRIFR' in widgetChartData ||
        'rollingSiteTRIFR' in widgetChartData ||
        (basicValue?.length > 0 && 'basicValue' in widgetChartData) ||
        'kpi' in widgetChartData
      );
    }
  }, [widgetChartData, basicValue]);

  useLayoutEffect(() => {
    if (chartParentRef.current) {
      const targetNode = chartParentRef.current;
      resizeObserver.observe(targetNode);
    }
    return () => resizeObserver.disconnect();
  }, [chartParentRef.current]);

  const linesYDomainScale: [number, number] | undefined = useKPIDomainValue(configurationData, transformedData);

  const renderYAxisKPIs: boolean = useMemo(() => {
    return renderKPILine || renderTrifrLine || renderRollingFR || renderRollingSiteTRIFR;
  }, [renderKPILine, renderTrifrLine, renderRollingFR, renderRollingSiteTRIFR]);

  const shouldSkipRenderingChart =
    !transformedData || transformedData.length === 0 || !hasAnyDataKey || (!renderYAxisKPIs && isEmpty(basicValue));

  const barChartYDomainValue: [number, number] | undefined = useMemo(() => {
    if (transformedData && configurationData) {
      const barChartMax = getHighestColumnChartValue(transformedData);
      return [0, barChartMax];
    }
    return undefined;
  }, [transformedData, configurationData]);

  const barChartTicks = useTicksBasedOnScale(barChartYDomainValue);
  const lineChartTicks = useTicksBasedOnScale(linesYDomainScale);

  const onClickLabelListHandler: MouseEventHandler<SVGElement> = useCallback((props) => {
    const target = props.target as Element;

    if (target?.parentElement) {
      onShowModal({ name: target.parentElement.getAttribute('name') ?? '', tooltipPayload: [] });
    }
  }, []);

  const columnLabelPlacement: LabelPosition =
    (configurationData?.widgetConfig?.labelPlacement as LabelPosition) || 'middle';

  const valueAccessor = useCallback((props: PayloadChart) => CustomValueAccessor(props, legendProps), [legendProps]);

  const renderBarChartsWithYAxis = useMemo(() => {
    if (!isEmpty(barChartKeys)) {
      const minPointSize = barChartKeys.length === 1 ? MIN_COLUMN_BAR_HEIGHT : 0;
      return (
        <>
          <YAxis {...yAxisSettings(barChartYDomainValue, 'Quantity', -90, -40, 'left')} ticks={barChartTicks} />
          {barChartKeys.map((uniqueDataKey, index) => (
            <Bar
              key={uniqueDataKey}
              name={uniqueDataKey}
              dataKey={uniqueDataKey}
              stackId="left"
              fill={findCustomColor(uniqueDataKey, colors) ?? getStatusColor(index)}
              hide={legendProps[uniqueDataKey]}
              isAnimationActive={false}
              onClick={(data) => onShowModal(data)}
              onMouseEnter={() => handleMouseEnter(uniqueDataKey)}
              onMouseLeave={handleMouseLeave}
              yAxisId="left"
              cursor="pointer"
              minPointSize={minPointSize}
            >
              <LabelList
                position="top"
                fill="#000"
                fontSize={11}
                fontWeight={500}
                accumulate="sum"
                additive="replace"
                cursor="pointer"
                onClick={onClickLabelListHandler}
                valueAccessor={valueAccessor}
              />
              {transformedData &&
                transformedData.map((entry, index) => {
                  const predefinedColor = colors && entry.name && findCustomColor(entry.name, colors);
                  return predefinedColor && <Cell key={`cell-${index}`} fill={predefinedColor} />;
                })}
              <LabelList
                dataKey={uniqueDataKey}
                position={columnLabelPlacement}
                content={(props) => CustomMiddleLabelList(props as unknown as PayloadChart)}
              />
            </Bar>
          ))}
        </>
      );
    }
    return null;
  }, [barChartKeys, transformedData, barChartTicks, colors, legendProps, valueAccessor]);

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const ColumChartCustomizedLabel = useCallback(
    (props: unknown) => CustomizedLabel(transformedData as FieldDataProps[], props as ToolTipElement),
    [transformedData]
  );

  return (
    <ChartGenericWrapper
      isError={isError}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={shouldSkipRenderingChart}
      error={error}
    >
      {!shouldSkipRenderingChart &&
        configurationData &&
        widgetChartData &&
        transformedData &&
        transformedData.length > 0 && (
          <div ref={chartParentRef} className="h-full">
            <OdinCustomDetailsWindow
              {...configurationData.widgetData}
              widgetId={widgetId}
              fieldValue={modalData?.name}
              secondFieldValue={
                modalData?.tooltipPayload[0]?.dataKey !== 'Sum'
                  ? modalData?.tooltipPayload &&
                    modalData.tooltipPayload.length > 0 &&
                    modalData.tooltipPayload[0].dataKey
                  : modalData.Sum
              }
              visible={detailsWindowVisible}
              onDialogHidden={onDialogHidden}
            />

            <ResponsiveContainer height="99%">
              <ComposedChart
                data={transformedData}
                margin={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: xLabelsHeight,
                }}
                layout="horizontal"
                id={widgetId}
              >
                <CartesianGrid vertical={false} horizontal strokeDasharray={undefined} />

                <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />

                <XAxis
                  {...xAxisSettings}
                  interval={intervalCounter(transformedData)}
                  tick={(props) => {
                    return basicValue.length > 0 ? (
                      CustomXAxis(props, false, transformedData)
                    ) : (
                      <XAxisLineChart {...props} />
                    );
                  }}
                />
                {renderYAxisKPIs && (
                  <YAxis
                    {...yAxisSettings(
                      linesYDomainScale,
                      'Frequency Rate',
                      90,
                      40,
                      'right',
                      'number',
                      yAxisLineName.current
                    )}
                    allowDataOverflow
                    ticks={lineChartTicks}
                  />
                )}
                {includeLegend(configurationData) && (
                  <Legend
                    wrapperStyle={defaultLegendWrapperStyle}
                    content={(props) => (
                      <CustomLegendWithCustomScroll
                        {...props}
                        clickListener={selectItem}
                        recalculateLabelsHeight={recalculateLabelsHeight}
                      />
                    )}
                  />
                )}
                {renderBarChartsWithYAxis}
                {/* KPI */}
                {renderKPILine && (
                  <Line
                    {...{ stackId: 'right' }}
                    name={kpiConfigs.caption}
                    connectNulls
                    type="linear"
                    dataKey="value_KPI"
                    yAxisId={yAxisLineName.current}
                    strokeWidth={2}
                    stroke={kpiConfigs.lineColor}
                    fill={kpiConfigs.lineColor}
                    hide={legendProps.value_KPI}
                    dot={getDotWithColor(kpiConfigs.lineColor)}
                    onMouseEnter={() => handleMouseEnter(kpiConfigs.caption)}
                    onMouseLeave={handleMouseLeave}
                    isAnimationActive={false}
                  >
                    <LabelList dataKey="value_KPI" position="top" content={ColumChartCustomizedLabel} />
                  </Line>
                )}
                {/* rollingTRIFR */}
                {renderTrifrLine && (
                  <Line
                    {...{ stackId: 'right' }}
                    name={trifrConfig.caption}
                    type="linear"
                    dataKey="value_TRIFR"
                    yAxisId={yAxisLineName.current}
                    strokeWidth={2}
                    stroke={trifrConfig.lineColor}
                    fill={trifrConfig.lineColor}
                    hide={legendProps.value_TRIFR}
                    isAnimationActive={false}
                    dot={getDotWithColor(trifrConfig.lineColor)}
                    onMouseEnter={() => handleMouseEnter(trifrConfig.caption)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {trifrConfig.displayValues && (
                      <LabelList
                        dataKey="value_TRIFR"
                        position="top"
                        content={(props) => renderCustomizedLineLabel(props as unknown as ToolTipElement)}
                      />
                    )}
                  </Line>
                )}
                {/* rollingFR */}
                {renderRollingFR &&
                  rollingFRKeys.map((item, index: number) => {
                    return (
                      <Line
                        {...{ stackId: 'right' }}
                        key={index}
                        name={frConfig.legend[index]}
                        type="linear"
                        dataKey="value_FR"
                        yAxisId={yAxisLineName.current}
                        strokeWidth={2}
                        stroke={frConfig.colors[index]}
                        fill={frConfig.colors[index]}
                        hide={legendProps.value_FR}
                        isAnimationActive={false}
                        dot={getDotWithColor(frConfig.colors[index])}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {frConfig.displayValues && (
                          <LabelList
                            dataKey="value_FR"
                            position="top"
                            content={(props) => renderCustomizedLineLabel(props as unknown as ToolTipElement)}
                          />
                        )}
                      </Line>
                    );
                  })}
                {/* rollingSiteTRIFR */}
                {renderRollingSiteTRIFR &&
                  rollingSiteTRIFRKeys.map((item, index: number) => {
                    return (
                      <Line
                        {...{ stackId: 'right' }}
                        key={`${item}_rollingSiteTRIFR`} // must be unique from the Bar keys
                        name={item || 'TRIFR'}
                        dataKey={`${item}_rollingSiteTRIFR`}
                        yAxisId={yAxisLineName.current}
                        strokeWidth={2}
                        type="linear"
                        stroke={getStatusColor(index)}
                        fill={getStatusColor(index)}
                        hide={legendProps[`${item}_rollingSiteTRIFR`]}
                        dot={getDotWithColor(getStatusColor(index))}
                        onMouseEnter={() => handleMouseEnter(`${item}_rollingSiteTRIFR`)}
                        onMouseLeave={handleMouseLeave}
                        isAnimationActive={false}
                      >
                        <LabelList
                          dataKey={`${item}_rollingSiteTRIFR`}
                          position="top"
                          content={(props) => renderCustomizedLineLabel(props as unknown as ToolTipElement)}
                        />
                      </Line>
                    );
                  })}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
    </ChartGenericWrapper>
  );
}
