import { OdinIcon } from '@myosh/odin-components';
import { WidgetInsightsResponseDto } from '../../redux/config/widgetApi';
import { useDispatch } from 'react-redux';
import { removeWidgetAiInsight } from '../../redux/slices/widgetChart/WidgetChartSlice';
import classNames from 'classnames';

interface WidgetAiInsightPanelProps {
  widgetAiInsight: {
    title: string;
    data: WidgetInsightsResponseDto | undefined;
  };
}

function WidgetAiInsightPanel({ widgetAiInsight }: WidgetAiInsightPanelProps) {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    dispatch(removeWidgetAiInsight());
  };

  return (
    <div
      className={classNames('flex w-[600px] flex-col gap-4 overflow-y-auto border-s-[1px] p-4', {
        hidden: !widgetAiInsight.title,
      })}
    >
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold">AI Insight</p>
        <div title="Close" className="cursor-pointer" onClick={handleClickClose}>
          <OdinIcon icon="Close" />
        </div>
      </div>

      <p className="text-lg font-semibold">{widgetAiInsight.title}</p>
      <hr />

      <p>
        <p className="font-bold">Data Analysis: </p>
        {widgetAiInsight?.data?.data_analysis ?? '-'}
      </p>
      <p>
        <p className="font-bold">Potential Causes: </p>
        {widgetAiInsight?.data?.potential_causes ?? '-'}
      </p>
      <p>
        <p className="font-bold">Recommendations: </p>
        {widgetAiInsight?.data?.recommendations ?? '-'}
      </p>
      <p>
        <p className="font-bold">Future Trends: </p>
        {widgetAiInsight?.data?.future_trends ?? '-'}
      </p>
      <p>
        <p className="font-bold">Missing Values Analysis: </p>
        {widgetAiInsight?.data?.missing_values_analysis ?? '-'}
      </p>
      <p>
        <p className="font-bold">Summary: </p>
        {widgetAiInsight?.data?.summary ?? '-'}
      </p>
    </div>
  );
}

export default WidgetAiInsightPanel;
