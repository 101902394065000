import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, DataGridColumnSettings, DataGridSettings, OverlayScrollDetectionType } from '@myosh/odin-components';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import useStartEndDates from './hooks/useStartEndDates';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import { tableDataApi, useTableRateQuery } from '../../redux/config/table-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinRateTable({ widgetId, layoutProps }: ChartProps) {
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const { data, isLoading, isFetching, isError, error } = useTableRateQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (data && !isLoading && !isFetching && !columns) {
      const gridColumns = data.header.map((column) => {
        return {
          id: column.field,
          title: column.caption,
          field: column.field,
          visible: true,
        };
      });
      setColumns(gridColumns);
    }
  }, [data, isLoading, isFetching, columns]);

  const gridSettings = useMemo<DataGridSettings | undefined>(() => {
    if (columns && columns.length > 0) {
      return {
        columns,
        fullHeight: true,
        stopRequestsOnNoData: true,
        filterLocation: 0,
        scrollDetection: OverlayScrollDetectionType.All,
      };
    }
  }, [columns]);

  const handleRefetch = useCallback(() => {
    dispatch(tableDataApi.util.invalidateTags([{ type: 'TableRate', id: `LIST-${widgetId}` } as const]));
  }, [widgetId]);

  return (
    <ChartGenericWrapper
      isLoading={isLoading}
      isFetching={false}
      isError={isError}
      error={error}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={data}
    >
      {gridSettings && data && <DataGrid data={data.row} gridSettings={gridSettings} customSettingsMenuItems={[]} />}
    </ChartGenericWrapper>
  );
}
