import { useCallback, useEffect, useState } from 'react';
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  CartesianGrid,
  YAxis,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Dot,
} from 'recharts';
import { useSelector } from 'react-redux';

import { LegendPayloadOnClick } from './chart-events';
import { intervalCounter } from './generalFunctions/general-functions';
import { ChartProps } from './chart.interface';

import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { XAxisLineChart } from '../common/custom-X-axis.component';
import { yAxisSettings } from '../common/y-axis-properties';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import { useKPIDomainValue } from './hooks/useKPIDomainValue';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { getStatusColor } from '../../helpers/getStatusColor';

import { useNcrLineChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

import { labelListStyle } from '../../constants/line-label-styles';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { DotPayloadProps } from './line-chart.component';
import { useSelectedView } from './hooks/use-selected-view';

type LineChartValues = Record<string, string | number>;

export default function OdinNcrLineChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [transformedData, setTransformedData] = useState<LineChartValues[]>([]);
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [lines, setLines] = useState<Record<string, string>[]>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings();
  const domainScale = useKPIDomainValue(configurationData, transformedData);
  const ticks = useTicksBasedOnScale(domainScale);
  const handleRefetch = useInvalidateChartResponse(widgetId);
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useNcrLineChartQuery(
    {
      url: configurationData?.dataURL,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    {
      skip: !configurationData || (!view?.id && startDate === '' && endDate === ''),
    }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (widgetChartData && configurationData) {
      const transformedData: LineChartValues[] = [];
      const lines: Record<string, string>[] = [];

      Object.entries(widgetChartData).forEach((data, index) => {
        lines.push({
          color: getStatusColor(index),
          label: data[0],
        });

        data[1].forEach((values) => {
          const existingData = transformedData.find((item) => item.field === values.field);
          if (existingData) {
            existingData[data[0]] = values.value;
          } else {
            const newData: LineChartValues = {
              field: values.field,
              [data[0]]: values.value,
            };
            transformedData.push(newData);
          }
        });
      });

      setTransformedData(transformedData);
      setLines(lines);
    }
  }, [widgetChartData, configurationData]);

  const selectItem = useCallback(
    (e: LegendPayloadOnClick) => {
      const key = e.dataKey || e.value || '';
      setLegendProps({
        ...legendProps,
        [key]: !legendProps[key],
      });
    },
    [legendProps]
  );

  const dotLineChart = useCallback(
    (color: string) => ({
      fill: color,
      stroke: color,
      strokeWidth: 5,
    }),
    []
  );

  const ActiveDot = ({ cy, cx, fill, item }: DotPayloadProps) => {
    return (
      <Dot
        r={2}
        cy={cy}
        cx={cx}
        fill={fill}
        stroke={fill}
        strokeWidth={5}
        onMouseEnter={() => handleMouseEnter(item as string)}
        onMouseLeave={handleMouseLeave}
      />
    );
  };

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData.length > 0 && (
        <ResponsiveContainer height="99%">
          <LineChart
            data={transformedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10,
            }}
            layout="horizontal"
          >
            <Tooltip content={<CustomToolTip />} cursor={false} isAnimationActive={false} />

            <>
              <CartesianGrid vertical={false} horizontal strokeDasharray={undefined} />
              <XAxis
                {...xAxisSettings}
                interval={intervalCounter(transformedData)}
                tick={(props) => <XAxisLineChart {...props} />}
                allowDuplicatedCategory
              />

              <YAxis
                {...yAxisSettings(undefined, 'Days Open', -90, -40)}
                ticks={ticks}
                allowDataOverflow={
                  !!(configurationData.widgetConfig?.yScaleMin && configurationData.widgetConfig?.yScaleMin !== 0)
                }
              />

              <Legend
                formatter={renderLegend}
                verticalAlign="bottom"
                wrapperStyle={defaultLegendWrapperStyle}
                onClick={selectItem}
              />

              {lines &&
                lines.map((line) => {
                  return (
                    <Line
                      key={line.label}
                      name={line.label}
                      dataKey={line.label}
                      strokeWidth={2}
                      stroke={line.color}
                      fill={line.color}
                      hide={legendProps[line.label]}
                      dot={dotLineChart(line.color)}
                      activeDot={<ActiveDot item={line.label} />}
                      onMouseEnter={() => handleMouseEnter(line.label)}
                      onMouseLeave={handleMouseLeave}
                      isAnimationActive={activateAnimation}
                    >
                      <LabelList dataKey={line.label} position="top" dy={-5} style={labelListStyle} />
                    </Line>
                  );
                })}
            </>
          </LineChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}
