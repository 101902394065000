import React, { useEffect, useState } from 'react';
import { DropDownMultiSelect, DropDownNoSelection, DropDownResult, JsonData } from '@myosh/odin-components';
import { CustomFiltersType } from '../../redux/config/CustomFiltersApi';
import { dropDownInputStyle, dropDownLabelStyle } from '../../constants/common-style-constants';

export interface OdinDropDownMultiSelectProps extends CustomFiltersType {
  data: Array<string>;
  currentValues?: Array<string>;
  onChange?: (value?: DropDownResult | DropDownResult[] | DropDownNoSelection) => void;
}

/**
 * A DropDown component to be used dynamically over the custom filter component, when no pagination or lazy loading is included
 */

function OdinDropDownMultiSelect({ data, currentValues, onChange, field }: OdinDropDownMultiSelectProps) {
  const [checkboxData, setCheckboxData] = useState<JsonData>();
  const [valueField, setValueField] = useState<DropDownResult[]>();

  useEffect(() => {
    if (data) {
      setCheckboxData(
        data.map((value: string, index: number) => {
          return {
            value: value,
            text: value,
            position: index,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (currentValues) {
      setValueField(
        currentValues?.map((data: string) => {
          return { value: data, text: data };
        })
      );
    }
  }, [currentValues]);

  return (
    <DropDownMultiSelect
      name="checkbox_columns"
      textField="text"
      valueField="value"
      data={checkboxData}
      value={valueField}
      allowSelectAll={false}
      onChange={onChange}
      label={field}
      allowClear={true}
      placeholder={`Select ${field}`}
      labelStyles={dropDownLabelStyle}
      className={dropDownInputStyle}
    />
  );
}

export default OdinDropDownMultiSelect;
