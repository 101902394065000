import { getColorsArray } from '../../helpers/get-colors-arrays';
import { getStatusColor } from '../../helpers/getStatusColor';
import { formatIntoPercentage } from '../../helpers/number-formatters';
import { ColumnRateResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';

export class TransformColumnRateData
  implements IDataCommand<Array<ColumnRateResponse>, WidgetConfigurationData, Array<ColumnRateResponse>>
{
  process(
    data: Array<ColumnRateResponse>,
    configurationData: WidgetConfigurationData
  ): Array<
    ColumnRateResponse & {
      color: string;
    }
  > {
    const colors = getColorsArray(
      data.map((item) => item.field),
      configurationData
    );
    const findColor = (label: string | undefined, index: number) => {
      const defaultColor = getStatusColor(index);
      if (colors && label) {
        return colors.find((color) => color.label === label)?.color ?? defaultColor;
      }
      return defaultColor;
    };

    return data.map((item, index) => {
      const percentageValue = Number(item.percentageValue);
      return {
        ...item,
        percentageValue: percentageValue > 0 ? formatIntoPercentage(percentageValue) : '',
        color: findColor(item.field, index),
      };
    });
  }
}
