import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  OdinDataSort,
  OdinDataFilter,
} from '@myosh/odin-components/dist/types/common/data-retrieval/data-retrieval-interfaces';

export interface TableProperties {
  id: string;
  fieldFilters?: Record<string, OdinDataFilter>;
  //for lazy loading grids
  sortedFields?: Record<string, OdinDataSort>;
  //for in memory sorting grids
  getSortedFields?: () => Record<string, OdinDataSort>;
}
interface UpdateTableProps {
  tables: Array<TableProperties>;
}

const initialState: UpdateTableProps = {
  tables: [],
};

const updateTableSettings = createSlice({
  name: 'updateTableDataSettings',
  initialState,
  reducers: {
    setTableFilters(state, action) {
      const tableId = action.payload.id;
      const existingSettings = state.tables.find((table) => table.id === tableId);
      if (existingSettings) {
        state.tables = state.tables.map((table) => (table.id === tableId ? action.payload : table));
      } else {
        state.tables = state.tables.concat([action.payload]);
      }
    },
  },
});

export const { setTableFilters } = {
  ...updateTableSettings.actions,
};

export default updateTableSettings.reducer;

export const selectTableDataSettings = (state: RootState) => state.rootReducer.updateTableDataSettings;
