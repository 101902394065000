import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, OdinIcon, OdinIconSize, OdinIconType, ToggleButton } from '@myosh/odin-components';
import { difference, isEmpty, isEqual, union } from 'lodash';
import { t } from 'i18next';
import { Layout, layoutApi, useGetLayoutRolesQuery, useUpdateLayoutMutation } from '../../redux/config/layout';
import { useGetRolesQuery } from '../../redux/config/roles-api';
import { useAppDispatch } from '../../redux/hooks';
import { PickList } from 'primereact/picklist';
import '../../styles/permissions-list.css';
import { Dialog } from 'primereact/dialog';
import { LoaderComponent } from '../common/loading-spinner.component';

interface PermissionTabProps {
  showWindow: boolean;
  layout: Layout;
  onHidden: () => void;
}
const linkStyle = 'mr-4 cursor-pointer text-gray-3 hover:text-primary-1 font-medium is-editing font-medium text-base';
const linkStyleSelected = `${linkStyle} text-primary-2 border-b-2 border-solid border-primary-2 is-active`;
type PermissionTab = 'READ' | 'EDIT';

export const PermissionsTabWindow = ({ showWindow, layout, onHidden }: PermissionTabProps) => {
  const { data: roles } = useGetRolesQuery();
  const { data: assignedRoles, isFetching } = useGetLayoutRolesQuery(layout.id, {
    skip: showWindow === false,
  });
  const [updateLayout] = useUpdateLayoutMutation();
  const [isShared, setIsShared] = useState<boolean>(!layout.isUserLayout);
  const [allRolesRead, setAllRolesRead] = useState<Array<string>>([]);
  const [allRolesEdit, setAllRolesEdit] = useState<Array<string>>([]);
  const [modifyRoles, setModifyRoles] = useState<Array<string>>([]);
  const [readRoles, setReadRoles] = useState<Array<string>>([]);
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<PermissionTab>('READ');

  const resetToDefaultValues = useCallback(
    (isPublic: boolean) => {
      if (isPublic) {
        const assignedReadRoles = assignedRoles?.rolesView ?? [];
        const assignedEditRoles = assignedRoles?.rolesModify ?? [];
        setReadRoles(assignedReadRoles);
        setAllRolesRead(difference(roles, assignedReadRoles));
        setModifyRoles(assignedEditRoles);
        setAllRolesEdit(difference(roles, assignedEditRoles));
      }
      setIsShared(isPublic);
    },
    [assignedRoles, roles]
  );

  useEffect(() => {
    if (layout) {
      resetToDefaultValues(!layout?.isUserLayout);
    }
  }, [layout, assignedRoles]);

  const onSaveHandler = useCallback(() => {
    const updatedPayload: Record<string, string | Array<string>> = {};
    if (isShared === layout.isUserLayout) {
      updatedPayload.privacy = isShared ? 'public' : 'private';
    }
    // We should not be able to update roles if layout is `private`
    if (isShared) {
      if (!isEqual(assignedRoles?.rolesModify, modifyRoles)) {
        updatedPayload.rolesModify = modifyRoles;
      }
      if (!isEqual(assignedRoles?.rolesView, readRoles)) {
        updatedPayload.rolesView = readRoles;
      }
    }

    if (!isEmpty(updatedPayload)) {
      updateLayout({ id: layout.id, ...updatedPayload }).then(() => {
        dispatch(layoutApi.util.invalidateTags([{ type: 'LayoutRoles', id: layout.id }]));
      });
    }
  }, [layout, modifyRoles, readRoles, isShared, assignedRoles]);

  /**
   * - Save is always disabled if tab is in private state as we can't update permissions for private tab nor make any tab private
   * - Save is disabled if there are no readRoles selected
   * - Save is disabled if no changed are done in the UI
   */
  const disableSaveButton = useMemo(() => {
    const hasRolesChanged = !(
      isEqual(assignedRoles?.rolesView, readRoles) && isEqual(assignedRoles?.rolesModify, modifyRoles)
    );
    return !hasRolesChanged || !isShared || isEmpty(readRoles);
  }, [readRoles, modifyRoles, isShared, assignedRoles]);

  const footer = useMemo(() => {
    return (
      <div>
        <Button type="primary" name={String(t('save'))} onClick={onSaveHandler} disabled={disableSaveButton}>
          {String(t('save'))}
        </Button>

        <Button
          type="default"
          name={String(t('cancel'))}
          onClick={() => {
            onHidden();
            resetToDefaultValues(!layout?.isUserLayout);
          }}
          disabled={disableSaveButton}
        >
          {String(t('cancel'))}
        </Button>
      </div>
    );
  }, [layout, onSaveHandler, disableSaveButton]);

  return (
    <Dialog
      onHide={onHidden}
      visible={showWindow}
      closeOnEscape={false}
      header={
        <>
          <h1>{String(t('permissionsForTab', { title: layout.title }))}</h1>
          <div className="my-3 ml-1">
            <div className="mb-1 block text-sm font-bold">{String(t('sharedTab'))}</div>
            <ToggleButton
              checked={isShared}
              disabled={!layout.isUserLayout}
              onChange={() => resetToDefaultValues(!isShared)}
            />
            {isShared && !isFetching && (
              <div className="overflow-hidden">
                <div className="flex flex-row">
                  <button
                    className={selectedTab === 'READ' ? linkStyleSelected : linkStyle}
                    onClick={() => setSelectedTab('READ')}
                  >
                    {String(t('readRoles'))}
                  </button>
                  <button
                    className={selectedTab === 'EDIT' ? linkStyleSelected : linkStyle}
                    onClick={() => setSelectedTab('EDIT')}
                  >
                    <div className="flex items-end gap-1">
                      {String(t('modifyRoles'))}
                      <div className="mr-1 flex h-5 " title={String(t('readPermissionsHint'))}>
                        <OdinIcon size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} icon="Information" />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      }
      headerClassName="permissions-header"
      footer={footer}
    >
      {isShared && isFetching && <LoaderComponent />}
      {isShared && !isFetching && (
        <>
          {selectedTab === 'READ' && (
            <PickList
              source={allRolesRead}
              target={readRoles}
              sourceHeader={String(t('availablePermissions'))}
              targetHeader={String(t('selectedPermissions'))}
              sourceStyle={{ width: '20rem', height: '15rem' }}
              targetStyle={{ width: '20rem', height: '15rem' }}
              className="picklist permissions-list"
              filterBy="name"
              onChange={(event) => {
                setReadRoles(event.target);
                setAllRolesRead(event.source);
              }}
              showSourceControls={false}
              showTargetControls={false}
            />
          )}

          {selectedTab === 'EDIT' && (
            <PickList
              source={allRolesEdit}
              target={modifyRoles}
              sourceStyle={{ width: '20rem', height: '15rem' }}
              targetStyle={{ width: '20rem', height: '15rem' }}
              className="picklist permissions-list"
              filterBy="name"
              sourceHeader={String(t('availablePermissions'))}
              targetHeader={String(t('selectedPermissions'))}
              showSourceControls={false}
              showTargetControls={false}
              onChange={(result) => {
                setAllRolesEdit(result.source);
                setModifyRoles(result.target);
                setReadRoles(union(readRoles, result.target));
              }}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default PermissionsTabWindow;
