import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Layout, LayoutWidget } from '../../redux/config/layout';
import dragula from 'dragula';
import { useDispatch } from 'react-redux';
import { getWidgetLayoutIds, updateDataPosition } from './layout-tab.functions';
import { addWidgetsReorder } from '../../redux/slices/widgetChart/WidgetChartSlice';
import { useUpdateLayoutWidgetMutation } from '../../redux/config/layout-widget';
import { useEditSaveDiscard } from '../Charts/hooks/useSaveEdit';
import { TabWidget } from './tab-widget.component';
import { resetChildrenPosition } from '../../helpers/reset-children-position';

type TabElementProps = {
  layout: Layout;
  assignChildDraggableContainer: (
    editEnabled: boolean,
    drakeRef: React.MutableRefObject<dragula.Drake | undefined>,
    childContainer: HTMLElement | null
  ) => void;
  isEditable: boolean;
};

export const TabContent = ({ layout, assignChildDraggableContainer, isEditable }: TabElementProps) => {
  const dispatch = useDispatch();
  const [updateLayoutWidget] = useUpdateLayoutWidgetMutation();
  const tabId = useRef<string>(`mainWidgetCard-${layout.id}`);
  const drakeWidgetRef = useRef<dragula.Drake>();
  const [currentTabRef, setCurrentTabRef] = useState<HTMLDivElement>();
  const saveChangesEdit = useEditSaveDiscard();
  const widgetsReorderd = useRef(false);

  useEffect(() => {
    if (saveChangesEdit !== undefined && currentTabRef && widgetsReorderd.current) {
      if (saveChangesEdit) {
        const widgetIds = getWidgetLayoutIds(currentTabRef.children);
        updateLayoutWidget(widgetIds);
        updateDataPosition(currentTabRef.children);
      } else {
        resetChildrenPosition(currentTabRef);
      }
    }
  }, [saveChangesEdit]);

  useEffect(() => {
    if (currentTabRef) {
      assignChildDraggableContainer(isEditable, drakeWidgetRef, currentTabRef);
    }
  }, [currentTabRef, isEditable]);

  useEffect(() => {
    drakeWidgetRef.current = dragula([], {
      direction: 'vertical',
    });

    drakeWidgetRef.current.on('drop', (element: Element, target: Element) => {
      const widgetIds = getWidgetLayoutIds(target.children);
      widgetsReorderd.current = true;

      if (widgetIds) {
        dispatch(addWidgetsReorder(widgetIds));
      }
    });

    //Wheel events are registed in drag mode on the mirrored element.
    //To catch these events, once cloned element is appended to the DOM
    //we listen for wheel events and modify scroll position of the parent
    drakeWidgetRef.current.on('cloned', (clone: Element) => {
      const parentElement = document.getElementById(tabId.current)?.parentElement;
      if (parentElement) {
        (clone as HTMLDivElement).addEventListener('wheel', (e) => {
          parentElement.scrollBy({
            top: e.deltaY,
          });
        });
      }
    });

    return () => drakeWidgetRef.current?.remove();
  }, []);

  const sortByPosition = useCallback((arr: LayoutWidget[]) => {
    return Array.from(arr).sort((a, b) => a.position - b.position);
  }, []);

  return (
    <div
      className="flex h-full flex-wrap"
      data-tab={layout.title}
      id={tabId.current}
      key={layout.id}
      ref={(el) => el && setCurrentTabRef(el)}
    >
      {sortByPosition(layout.layoutWidgets)?.map((layoutWidget) => (
        <TabWidget layoutWidget={layoutWidget} layout={layout} key={layoutWidget.id} />
      ))}
    </div>
  );
};
