import { t } from 'i18next';

import { CustomPayload, CustomToolTipProps, Payload } from './custom-tooltip.interfaces';
import { getStorageItem } from '../../helpers/storage';
import { dateStringFormatter, decrementYear } from '../Charts/generalFunctions/formate-date-fields';
import GeneralToolTip from './general-tooltip';

const getTooltipValue = (name: string, payload: Payload) => {
  if (name === 'Current') {
    return payload.trifrCurrent || payload.frCurrent;
  }

  if (name === 'Previous') {
    return payload.trifrPrevious || payload.frPrevious;
  }

  return payload.value_KPI;
};

const CustomTooltip = ({ active, payload, type, customDataKey, chartData }: CustomToolTipProps) => {
  if (active && payload) {
    const tooltipDataKey = getStorageItem('tooltipDataKey') || customDataKey;
    let activePayload = tooltipDataKey ? payload.find((data) => data.name === tooltipDataKey) : null;

    if (type === 'pie') {
      activePayload = payload[0].payload as CustomPayload;
    }

    if (activePayload) {
      const { payload } = activePayload;
      const colorValue = activePayload.color || activePayload.fill || activePayload.stroke || payload?.color;
      let nameValue = payload?.name || payload?.field;
      let fieldValue = `${activePayload.name} : ${activePayload.value}`;
      let index;

      if (type === 'comparedFRLine') {
        const previousField = decrementYear(payload as Payload).field;
        const changedPreviousValue = dateStringFormatter(previousField, true);
        const hasPreviousValue = tooltipDataKey === 'Previous' && changedPreviousValue;
        const hasCurrentValue = tooltipDataKey === 'Current' && dateStringFormatter(payload?.field as string, true);
        const kpiValue = payload?.field.substring(0, 3);
        nameValue = hasPreviousValue || hasCurrentValue || kpiValue;
        fieldValue = `${tooltipDataKey}: ${getTooltipValue(tooltipDataKey, payload as Payload)}`;
      }

      if (type === 'difference' && activePayload.name === t('riskReduction')) {
        nameValue = activePayload.name;
        fieldValue = activePayload.value.toString();
        index =
          (chartData &&
            Array.isArray(chartData) &&
            chartData.findIndex((data: Record<string, unknown>) => data.field === payload?.field)) ||
          0;
      }

      return <GeneralToolTip color={colorValue} name={nameValue} field={fieldValue} type={type} index={index} />;
    }
  }

  return null;
};

export default CustomTooltip;
