import { difference, sortBy } from 'lodash';
import filteredKeys from '../../components/Charts/generalFunctions/filter-keys';
import { ChartResponse, DataKPI } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

/**
 * @param keys An optional array of strings representing keys from the data. If provided, the sort order will only include these keys.
 * @returns An array of strings sorted based on the provided sort order and keys.
 */

export const sortOrderStrings = <T extends DataKPI | ChartResponse>(
  data: T[],
  configurationData: WidgetConfigurationData,
  keys?: string[]
) => {
  const sortOrder = configurationData.widgetConfig?.sortOrder || [];
  // If keys are provided, filter the sort order to include only those keys
  const chartKeys = sortBy(keys ?? filteredKeys(data));
  const commonStrings: string[] = [];
  // Append items from sortOrder that exist in chartKeys
  for (const item of sortOrder) {
    if (chartKeys.includes(item)) {
      commonStrings.push(item);
    }
  }
  // Find the difference between sortOrder and chartKeys
  const differenceKeys = difference(chartKeys, sortOrder);
  // Sort the remaining items alphabetically and append to commonStrings
  commonStrings.push(...sortBy(differenceKeys));
  return sortOrder.length > 0 ? commonStrings : chartKeys;
};
