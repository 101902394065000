import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClusterProvider, LoginCallback, LogoutCallback, useUser } from '@myosh/myosh-login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import LoginPage from './pages/LoginPage';
import { ThemeProvider } from './context/theme.context';
import Home from './pages/Home';
import { IndividualWidgetPage } from './pages/individual-widget-page';
import { IndividualTabPage } from './pages/individual-tab-page';
import { GlobalDateRangeProvider } from './context/globalDateRange.context';
import { removeStorageItem } from './helpers/storage';
import { usePageTracking } from './hooks/use-page-tracking';

function App() {
  usePageTracking();
  const { state } = useUser();
  const isAuthenticated = state.auth;

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.clear();
    }
    return () => {
      removeStorageItem('forced-date-api', 'sessionStorage');
    };
  }, []);

  return (
    <Routes>
      <Route path="/auth/cb/logout" element={<LogoutCallback />} />
      <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
      <Route path="/auth/cb" element={<LoginCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/widget/:widgetId" element={<PrivateRoute />}>
        <Route
          index
          element={
            <ClusterProvider>
              <ThemeProvider>
                <GlobalDateRangeProvider>
                  <IndividualWidgetPage />
                </GlobalDateRangeProvider>
              </ThemeProvider>
            </ClusterProvider>
          }
        />
      </Route>
      <Route path="/layout/:layoutId" element={<PrivateRoute />}>
        <Route
          index
          element={
            <ClusterProvider>
              <ThemeProvider>
                <GlobalDateRangeProvider>
                  <IndividualTabPage />
                </GlobalDateRangeProvider>
              </ThemeProvider>
            </ClusterProvider>
          }
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          index
          element={
            <ClusterProvider>
              <ThemeProvider>
                <GlobalDateRangeProvider>
                  <Home />
                </GlobalDateRangeProvider>
              </ThemeProvider>
            </ClusterProvider>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
