import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { getTopAmountToDisplay } from './get-top-items-display';

/**
 * When widget is configured to return only top specified amount of items,
 *  we must first sort its categories by total amount of values.
 * This command takes care of, first, sort data by the `value_column` and then return
 *  only the series applicable
 */

export class SortByValueReturnTop<T extends ChartResponse>
  implements IDataCommand<Array<T>, WidgetConfigurationData, Array<T>>
{
  process(data: Array<T>, config: WidgetConfigurationData): Array<T> {
    const amountToDisplay = getTopAmountToDisplay(config);
    if (amountToDisplay === -1 || !data) {
      return data;
    }

    return data
      .sort((a, b) => Number(b.value_column) - Number(a.value_column))
      .slice(0, Math.min(data.length, amountToDisplay));
  }
}
