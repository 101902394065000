import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  CartesianGrid,
  YAxis,
  XAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { LegendPayloadOnClick } from './chart-events';
import filteredKeys from './generalFunctions/filter-keys';
import { intervalCounter } from './generalFunctions/general-functions';

import { ChartProps } from './chart.interface';
import { CustomColorProps, TrifrProps } from '../WidgetSettingWindow/components/config-components.interfaces';
import { MonthlyComparisonData } from '../../data-transformer/compared-fr-line-chart/compared-fr-line.interface';

import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { CustomXAxis } from '../common/custom-X-axis.component';
import { yAxisSettings } from '../common/y-axis-properties';
import { renderCustomizedLineLabel } from '../common/custom-label-list';

import { DataExecutor } from '../../data-transformer/data-executor';
import {
  ComparedFrLineCommandFactory,
  ComparedFrLineType,
} from '../../data-transformer/compared-fr-line-chart/compared-fr-line-command-factory';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import { useKPIDomainValue } from './hooks/useKPIDomainValue';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import { useKpiConfigs } from './hooks/useKPIConfigs';
import { useTRIFRConfigs } from './hooks/useRollingTRIFRConfigs';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { getColorsArray } from '../../helpers/get-colors-arrays';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { getStatusColor } from '../../helpers/getStatusColor';

import { useComparedFrLineQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

import { labelListStyle } from '../../constants/line-label-styles';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { ToolTipElement } from '../../shared/widget-data.interfaces';
import { useSelectedView } from './hooks/use-selected-view';
import { getDotWithColor } from '../common/line-dot-base-props';

export default function OdinComparedFrLineChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [transformedData, setTransformedData] = useState<MonthlyComparisonData[]>([]);
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [customColors, setCustomColors] = useState<Array<CustomColorProps>>([]);
  const [frProps, setFrProps] = useState<TrifrProps[]>();
  const [trifrProps, setTrifrProps] = useState<TrifrProps[]>();
  const [isKPI, setIsKPI] = useState<boolean>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const kpiConfigs = useKpiConfigs(configurationData);
  const xAxisSettings = useXAxisTopSettings();
  const domainScale = useKPIDomainValue(configurationData, transformedData);
  const ticks = useTicksBasedOnScale(domainScale);
  const trifrConfig = useTRIFRConfigs(configurationData);
  const handleRefetch = useInvalidateChartResponse(widgetId);
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useComparedFrLineQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  const assignColors = useCallback((keys: string[]) => {
    return keys.map((item, index) => {
      return {
        color: getStatusColor(index),
        label: item,
      };
    });
  }, []);

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (widgetChartData && configurationData) {
      const commandExecutor = new DataExecutor();
      const commandFactory = new ComparedFrLineCommandFactory();
      let commandResponse: DataCommandResponse<ComparedFrLineType, unknown, ComparedFrLineType> | undefined;
      let outputData: ComparedFrLineType = widgetChartData;
      while ((commandResponse = commandFactory.getNextCommand()) !== undefined) {
        const inputData = outputData as MonthlyComparisonData[];
        outputData = commandExecutor.execute(commandResponse.command, inputData, configurationData);
      }

      setTransformedData(outputData as MonthlyComparisonData[]);

      if (widgetChartData.fr) {
        const keys = Object.keys(widgetChartData.fr).reverse();
        setFrProps(assignColors(keys));
      }
      if (trifrConfig.available) {
        const keys = Object.keys(widgetChartData.trifr).reverse();
        setTrifrProps(assignColors(keys));
      }
    }
  }, [widgetChartData, configurationData, trifrConfig.available, assignColors]);

  useEffect(() => {
    if (transformedData.length) {
      setIsKPI(transformedData.some((obj) => obj.hasOwnProperty('value_KPI')) && kpiConfigs.available);
      const secondFields = filteredKeys(transformedData);
      setCustomColors(getColorsArray(secondFields, configurationData));
    }
  }, [configurationData, kpiConfigs.available, transformedData]);

  const selectItem = useCallback(
    (e: LegendPayloadOnClick) => {
      const key = e.dataKey || e.value || '';
      setLegendProps({
        ...legendProps,
        [key]: !legendProps[key],
      });
    },
    [legendProps]
  );

  const labelList = useCallback((dataKey: string) => {
    return (
      <LabelList
        dataKey={dataKey}
        position="top"
        content={(props) => renderCustomizedLineLabel(props as unknown as ToolTipElement)}
      />
    );
  }, []);

  const frComponent = useMemo(() => {
    if (configurationData && frProps) {
      return (
        configurationData.widgetData.rollingFR &&
        frProps.map((item) => (
          <Line
            key={item.label}
            name={item.label}
            type="linear"
            dataKey={`fr${item.label}`}
            strokeWidth={2}
            stroke={item.color}
            fill={item.color}
            hide={legendProps[`fr${item.label}`]}
            dot={getDotWithColor(item.color)}
            onMouseEnter={() => handleMouseEnter(item.label)}
            onMouseLeave={handleMouseLeave}
            isAnimationActive={activateAnimation}
          >
            {(configurationData.widgetConfig?.lineDisplayValue ||
              configurationData.widgetConfig?.lineDisplayValue === undefined) &&
              item.label === 'Current' &&
              labelList(`fr${item.label}`)}
          </Line>
        ))
      );
    }
  }, [configurationData, frProps, handleMouseEnter, handleMouseLeave, labelList, legendProps]);

  const trifrComponent = useMemo(() => {
    const hideValues = trifrConfig?.displayValues === false;

    if (trifrProps && !isEmpty(trifrProps)) {
      return trifrProps.map((item) => (
        <Line
          key={item.label}
          name={item.label}
          type="linear"
          dataKey={`trifr${item.label}`}
          strokeWidth={2}
          stroke={item.color}
          fill={item.color}
          hide={legendProps[`trifr${item.label}`]}
          dot={getDotWithColor(item.color)}
          onMouseEnter={() => handleMouseEnter(item.label)}
          onMouseLeave={handleMouseLeave}
          isAnimationActive={activateAnimation}
        >
          {!hideValues && item.label === 'Current' && labelList(`trifr${item.label}`)}
        </Line>
      ));
    }
  }, [trifrProps, trifrConfig, legendProps]);

  const kpiComponent = useMemo(() => {
    if (kpiConfigs.available && isKPI) {
      const name = getValueOrDefault(kpiConfigs.caption, 'KPI');
      return (
        <Line
          name={name}
          type="linear"
          dataKey="value_KPI"
          strokeWidth={2}
          stroke={kpiConfigs.lineColor}
          fill={kpiConfigs.lineColor}
          hide={legendProps.value_KPI}
          dot={getDotWithColor(kpiConfigs.lineColor)}
          onMouseEnter={() => handleMouseEnter(name)}
          onMouseLeave={handleMouseLeave}
          isAnimationActive={activateAnimation}
        >
          <LabelList dataKey="value_KPI" position="top" dy={-5} style={labelListStyle} />
        </Line>
      );
    }
  }, [
    kpiConfigs.available,
    kpiConfigs.caption,
    kpiConfigs.lineColor,
    isKPI,
    legendProps.value_KPI,
    handleMouseLeave,
    handleMouseEnter,
  ]);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, colors: customColors, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData.length > 0 && (
        <ResponsiveContainer height="99%">
          <LineChart
            data={transformedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10,
            }}
            layout="horizontal"
          >
            <Tooltip content={<CustomToolTip type="comparedFRLine" />} cursor={false} isAnimationActive={false} />

            <>
              <CartesianGrid vertical={false} horizontal strokeDasharray={undefined} />
              <XAxis
                {...xAxisSettings}
                interval={intervalCounter(transformedData)}
                tick={(props) => CustomXAxis(props, true, transformedData)}
                allowDuplicatedCategory
              />

              <YAxis {...yAxisSettings(domainScale, 'Frequency Rate', -90, -40)} ticks={ticks} />

              <Legend
                formatter={renderLegend}
                verticalAlign="bottom"
                wrapperStyle={defaultLegendWrapperStyle}
                onClick={selectItem}
              />

              {/* KPI */}
              {kpiComponent}

              {/* TRIFR */}
              {trifrComponent}

              {/* FR */}
              {frComponent}
            </>
          </LineChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}
