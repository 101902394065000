import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';

export class HideBlankCategory
  implements IDataCommand<Array<ChartResponse>, WidgetConfigurationData, Array<ChartResponse>>
{
  process(data: Record<string, unknown>[]): ChartResponse[] {
    data?.map((item) => {
      Object.keys(item).map((k) => {
        if (k === '[none]') {
          delete item[k];
        }
      });
    });
    return data;
  }
}
