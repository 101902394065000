import React, { useEffect, useMemo, useState } from 'react';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { DropDown, OdinController, RadioGroup, DropDownResult, RadioGroupResult } from '@myosh/odin-components';
import { isBoolean } from 'lodash';
import { Control, useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import { WidgetShowConfigType } from './widget-config-type';
import { getWidgetConfigurationSettings, SupportedWidgetSettings } from '../../../constants/widget-configuration';
import WidgetConfigurationComponent from './step-fourth/widget-configuration.component';
import DataColumnsSettingTab from '../../WidgetSettingWindow/Tabs/data-columns-setting-tab';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { useGetHierarchyWithValueQuery } from '../../../redux/config/hierarchy';
import { useGetFiltersQuery } from '../../../redux/config/filters-api';
import { useGetFieldTypesQuery } from '../../../redux/config/field-types-api';
import { LabelFormatType } from '../../WidgetSettingWindow/widget-settings-dialog';

export interface WidgetShowConfigProps extends Partial<WidgetShowConfigType> {
  widgetConfiguration?: WidgetConfigurationData;
  control: Control;
  watchLabelFormat?: LabelFormatType[];
  setSortOrder: (sortOrder?: string[]) => void;
  setColorsSelection: (data: Record<string, string> | undefined) => void;
  watchWidgetType?: DropDownResult;
  moduleValue?: string;
  fieldValue?: string;
  sortOrder?: Array<string>;
}

export const WizardTabFourth = ({
  widgetConfiguration,
  setSortOrder,
  setColorsSelection,
  formValue,
  moduleValue,
  fieldValue,
  control,
}: Omit<WidgetShowConfigProps, 'watchLabelFormat'>) => {
  const [dropdownValue, setDropdownValue] = useState<DynamicFieldDataTypes>();
  const [columnSortOrder, setColumnSortOrder] = useState<RadioGroupResult>();
  const { watch } = useFormContext();

  const watchLabelFormat = watch('labelFormat');
  const watchWidgetType = watch('type');

  const chartSettings: SupportedWidgetSettings | undefined = useMemo(() => {
    if (watchWidgetType?.value.typeValue) {
      return getWidgetConfigurationSettings(watchWidgetType?.value.typeValue);
    } else if (!watchWidgetType?.value.typeValue && widgetConfiguration?.type) {
      return getWidgetConfigurationSettings(widgetConfiguration.type);
    }
  }, [widgetConfiguration?.type, watchWidgetType?.value.typeValue]);

  const { data: allExistingHierarchies } = useGetHierarchyWithValueQuery();
  const { data: filtersData } = useGetFiltersQuery(
    {
      field: fieldValue as string,
      form: formValue as string[],
    },
    { skip: !fieldValue || !formValue }
  );

  const { data: fieldTypeData } = useGetFieldTypesQuery(
    {
      module: String(moduleValue),
      form: formValue,
    },
    {
      skip: !moduleValue || !formValue,
    }
  );
  const radioGroupData = [
    {
      position: 1,
      text: t('ascending'),
      value: 'ascending',
    },
    {
      position: 2,
      text: t('descending'),
      value: 'descending',
    },
  ];

  useEffect(() => {
    if (
      (watchWidgetType?.value.typeValue === 'MAP' ||
        (watchWidgetType?.value.typeValue === undefined && widgetConfiguration?.type === 'MAP')) &&
      allExistingHierarchies
    ) {
      setDropdownValue(
        allExistingHierarchies.map(({ name, id }) => {
          return {
            value: name,
            text: id,
          };
        })
      );
    }
  }, [allExistingHierarchies, widgetConfiguration?.type, watchWidgetType?.value?.typeValue]);

  useEffect(() => {
    if (widgetConfiguration) {
      const widgetSortOrder = widgetConfiguration.widgetData?.ascendingSort;

      if (isBoolean(widgetSortOrder)) {
        setColumnSortOrder(
          widgetSortOrder
            ? { text: t('ascending'), value: 'ascending' }
            : { text: t('descending'), value: 'descending' }
        );
      }
    }
  }, [widgetConfiguration]);

  return (
    <div className="flex flex-col justify-center">
      {(watchWidgetType?.value.typeValue === 'MAP' ||
        (watchWidgetType?.value.typeValue === undefined && widgetConfiguration?.type === 'MAP')) && (
        <div>{String(t('mapAttribution'))}</div>
      )}
      <div className="my-2">{String(t('wizardStep4Description'))}</div>
      {(widgetConfiguration || chartSettings) && (
        <WidgetConfigurationComponent
          control={control}
          watchLabelFormat={watchLabelFormat}
          widgetConfiguration={widgetConfiguration}
          setSortOrder={setSortOrder}
          setColorsSelection={setColorsSelection}
          watchWidgetType={watchWidgetType}
          sortOrder={filtersData?.values}
        />
      )}
      {chartSettings && (
        <>
          {(widgetConfiguration?.calculatedVisibleColumns || fieldTypeData) &&
            (watchWidgetType?.value.typeValue === 'TABLE' ||
              (watchWidgetType?.value.typeValue === undefined && widgetConfiguration?.type === 'TABLE')) && (
              <>
                <DataColumnsSettingTab
                  visibleColumns={widgetConfiguration?.widgetData.visibleColumns}
                  pureVisibleColumns={widgetConfiguration?.widgetData.customFilterField}
                  module={widgetConfiguration?.widgetData.module || (moduleValue as string)}
                  form={widgetConfiguration?.widgetData.forms || formValue}
                  showDescription={false}
                  control={control}
                />

                <div className="flex flex-col py-2">
                  <OdinController
                    name="ascendingSort"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <RadioGroup
                        id="ascendingSortField"
                        name="ascendingSortField"
                        data={radioGroupData}
                        label={t('firstColumnSortOrder')}
                        orientation="VERTICAL"
                        textField="text"
                        valueField="value"
                        onChange={onChange}
                        value={columnSortOrder}
                      />
                    )}
                  />
                </div>
              </>
            )}

          {(watchWidgetType?.value.typeValue === 'MAP' ||
            (watchWidgetType?.value.typeValue === undefined && widgetConfiguration?.type === 'MAP')) && (
            <OdinController
              name="hierarchyForLocation"
              control={control}
              render={({ field: { onChange } }) => (
                <DropDown
                  textField="text"
                  valueField="value"
                  data={dropdownValue}
                  label={t('hierarchyLocationForMap')}
                  onChange={onChange}
                />
              )}
            />
          )}
        </>
      )}
    </div>
  );
};
