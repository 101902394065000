import { useCallback } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { chartApi } from '../../../redux/config/chart-data';

export default function useInvalidateChartResponse(widgetId: number | string) {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(chartApi.util.invalidateTags([{ type: 'ChartResponse', id: widgetId } as const]));
  }, [widgetId]);
}
