import { useEffect, useMemo, useState } from 'react';
import { CartesianGrid, ComposedChart, Dot, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSelector } from 'react-redux';

import { ChartResponse, useLineChartQuery } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { statusColor } from '../../constants/Palette';
import { LegendPayloadOnClick } from './chart-events';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { genericSort, includeLegend, SortDirection } from './generalFunctions/general-functions';
import useStartEndDates from './hooks/useStartEndDates';
import { transformBiDimensionalLineChart } from './generalFunctions/transformLineChart';
import { DataExecutor } from '../../data-transformer/data-executor';
import { ChartProps } from './chart.interface';
import OdinCustomDetailsWindow from '../CustomDetailsWindow/custom-details-window.component';
import { XAxisLineChart } from '../common/custom-X-axis.component';
import { compactNumberFormatter } from '../common/tick-formatter';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { roundHighest } from './generalFunctions/get-domaincharts';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import { LineChartCommandFactory } from '../../data-transformer/line-chart/line-chart-component.factory';
import { DataCommandResponse } from '../../data-transformer/data-transformer.interface';
import { FirstSecondFieldsDataType } from '../../data-transformer/common-commands/common-data.interface';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';

import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import { useSelectedView } from './hooks/use-selected-view';
import { getDotWithColor } from '../common/line-dot-base-props';

export interface DotPayloadProps {
  cy?: number;
  cx?: number;
  fill?: string;
  payload?: ModalDataProps;
  dataKey?: string;
  item?: string;
}

export interface ModalDataProps {
  fieldValue?: string;
  field?: string;
}

export default function OdinLineChart({ widgetId, layoutProps, activateAnimation }: ChartProps) {
  const [legendProps, setLegendProps] = useState<ChartResponse & Record<string, unknown>>({});
  const [transformedData, setTransformedData] = useState<ChartResponse[]>();
  const [secondFieldKeys, setSecondFieldKeys] = useState<Array<string>>();
  const [detailsWindowVisible, setDetailsWindowVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalDataProps>();
  const [secondFieldValue, setSecondFieldValue] = useState<string | undefined>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings();
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useLineChartQuery({
    widgetId,
    from: startDate,
    to: endDate,
    viewId: view?.id,
  });

  const isSecondFieldUndefined = useMemo(() => {
    return (
      widgetChartData !== undefined &&
      widgetChartData.every((item) => {
        return item.secondField === undefined;
      })
    );
  }, [widgetChartData]);

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    if (transformedData) {
      setSecondFieldKeys(
        !isSecondFieldUndefined
          ? Object.keys(transformedData[0]).filter((key) => key !== 'field' && key !== 'value')
          : ['2']
      );
    }
  }, [transformedData]);

  useEffect(() => {
    const commandExecutor = new DataExecutor();
    if (widgetChartData && configurationData) {
      if (isSecondFieldUndefined) {
        setTransformedData(transformBiDimensionalLineChart(widgetChartData));
      } else {
        const commandFactory = new LineChartCommandFactory();
        let commandResponse:
          | DataCommandResponse<FirstSecondFieldsDataType, WidgetConfigurationData, FirstSecondFieldsDataType>
          | undefined;
        let outputData: FirstSecondFieldsDataType = widgetChartData;
        while ((commandResponse = commandFactory.getNextCommand())) {
          const inputData: FirstSecondFieldsDataType = outputData;
          outputData = commandExecutor.execute(commandResponse.command, inputData, configurationData);
        }

        setTransformedData(outputData as ChartResponse[]);
      }
    }
  }, [widgetChartData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };

  /** The active dot(the dot the user is clicking),
   Documentation link: https://recharts.org/en-US/api/Line
   this method returns an active dot positioned at X and Y with a radius of R
   cx: The x-coordinate of center.
   cy: The y-coordinate of center.
   r: The radius of dot.
   * */
  const RepositoryGraphActiveDot = ({ cy, cx, fill, payload, dataKey, item }: DotPayloadProps) => {
    const dotOnClick = () => {
      setModalData(payload);
      setSecondFieldValue(dataKey !== 'Quantity' ? dataKey : undefined);
      setDetailsWindowVisible(true);
    };
    return (
      <Dot
        r={5}
        cy={cy}
        cx={cx}
        fill={fill}
        onClick={dotOnClick}
        onMouseEnter={() => handleMouseEnter(item as string)}
        onMouseLeave={handleMouseLeave}
      />
    );
  };

  const onDialogHidden = () => {
    setModalData(undefined);
    setDetailsWindowVisible(false);
    setSecondFieldValue(undefined);
  };

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const largestDomainValue: number = useMemo(() => {
    let largest = 0;
    if (widgetChartData) {
      largest = Math.max(
        ...widgetChartData.map((item) => {
          if (item.value) {
            const parsedNumber = Number(item.value);
            if (!Number.isNaN(parsedNumber)) {
              return parsedNumber;
            }
          }
          return 0;
        })
      );
    }
    return roundHighest(largest);
  }, [widgetChartData]);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching}
      isLoading={isLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && !transformedData?.length}
    >
      {configurationData && widgetChartData && transformedData && transformedData.length > 0 && (
        <>
          <OdinCustomDetailsWindow
            {...configurationData.widgetData}
            widgetId={widgetId}
            fieldValue={modalData?.fieldValue || modalData?.field}
            secondFieldValue={secondFieldValue}
            visible={detailsWindowVisible}
            onDialogHidden={onDialogHidden}
          />
          <ResponsiveContainer height="99%">
            <ComposedChart
              data={
                isSecondFieldUndefined
                  ? transformedData
                  : genericSort(transformedData, 'field', SortDirection.Ascending)
              }
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 30,
              }}
              layout="horizontal"
            >
              <Tooltip content={<CustomToolTip />} cursor={false} isAnimationActive={false} />
              <>
                <CartesianGrid strokeDasharray={undefined} vertical={false} />
                <XAxis {...xAxisSettings} interval={0} tick={XAxisLineChart} />
                <YAxis
                  type="number"
                  domain={[0, largestDomainValue]}
                  allowDecimals={false}
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value: 'Quantity',
                    angle: -90,
                    dx: -40,
                    position: 'center',
                  }}
                  tickFormatter={compactNumberFormatter}
                  width={undefined}
                  tick
                />
                {includeLegend(configurationData) && (
                  <Legend
                    formatter={renderLegend}
                    verticalAlign="bottom"
                    wrapperStyle={defaultLegendWrapperStyle}
                    onClick={selectItem}
                  />
                )}
                {secondFieldKeys?.map((item, index: number) => {
                  const name = isSecondFieldUndefined ? 'Quantity' : item;
                  return (
                    <Line
                      key={item}
                      name={name}
                      dataKey={isSecondFieldUndefined ? 'value' : item}
                      strokeWidth={2}
                      type="linear"
                      stroke={statusColor[index]}
                      fill={statusColor[index]}
                      hide={Boolean(legendProps && legendProps[isSecondFieldUndefined ? 'value' : item])}
                      dot={getDotWithColor(statusColor[index])}
                      activeDot={<RepositoryGraphActiveDot item={name} />}
                      onMouseEnter={() => handleMouseEnter(name)}
                      onMouseLeave={handleMouseLeave}
                      isAnimationActive={activateAnimation}
                    />
                  );
                })}
              </>
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartGenericWrapper>
  );
}
