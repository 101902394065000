import { CustomToolTipProps } from './custom-tooltip.interfaces';

const GeneralToolTip = ({ name, color, field, type, index }: CustomToolTipProps) => {
  return name ? (
    <div className="tooltip-body">
      <div className="tooltip-content p-2.5 text-xs">
        {type === 'difference' && index !== undefined ? (
          <>
            <ul>
              <li style={{ color }} className="font-bold">
                {name}
              </li>
            </ul>
            <div className="flex gap-1">
              <p>x:</p> <b>{index}</b>
            </div>
            <div className="flex gap-1">
              <p>y:</p> <b>{field}</b>
            </div>
          </>
        ) : (
          <>
            <p>{name}</p>
            <ul>
              <li style={{ color }}>
                <p>{field}</p>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default GeneralToolTip;
