import { useCallback, useEffect, useState } from 'react';
import { KpiTargetConfiguration, WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { DefaultValueProps, LabelFormatType } from '../widget-settings-dialog';
import { includeLegend } from '../../Charts/generalFunctions/general-functions';
import { getLabelOptions } from '../../../constants/label-configuration-options';
import { CheckboxGroupResult } from '@myosh/odin-components/dist/types/components/fields/checkbox-group/checkbox-group.component';

const valueAsString = (item: CheckboxGroupResult): LabelFormatType => {
  return { text: item.text, value: item.value as string };
};
export function useWidgetConfigs(widgetConfiguration?: WidgetConfigurationData) {
  const [defaultValueConfig, setDefaultValueConfig] = useState<DefaultValueProps>({});

  useEffect(() => {
    if (widgetConfiguration) {
      const displayLegend = includeLegend(widgetConfiguration);
      const widgetConfig = widgetConfiguration.widgetConfig;
      const widgetData = widgetConfiguration.widgetData;
      const singleLabelFormat = getLabelOptions().find((item) => item.value === widgetConfig?.labelFormat);
      const labelFormat =
        widgetConfig?.labelFormat === 'both'
          ? getLabelOptions().map((item) => valueAsString(item))
          : singleLabelFormat
          ? [valueAsString(singleLabelFormat)]
          : [];

      const labelPlacement = widgetConfig?.labelPlacement;
      const labelDecimals = widgetConfig?.labelDecimals;
      const showTop = widgetConfig?.showTop;
      const kpiTargetConfiguration = getKpiTargets(widgetConfig?.kpiTargetConfiguration);
      const secondColorEnabled = widgetConfig?.secondColorEnabled;
      const displayDecimalPlaces = widgetConfig?.displayDecimalPlaces;
      const kpiTargetForColumnKpiPercentage = widgetConfig?.kpiTargetForColumnKpiPercentage;
      const colors = widgetConfig?.colors;
      const sortOrder = widgetConfig?.sortOrder;
      const globalFilter =
        widgetData.globalFilters && widgetData.globalFilters?.length > 0 ? widgetData?.globalFilters[0] : undefined;
      const notBlank = globalFilter?.notBlank === undefined ? false : globalFilter.notBlank;
      const globalFilters = globalFilter ? { value: globalFilter.id, ...globalFilter } : undefined;

      setDefaultValueConfig({
        ...defaultValueConfig,
        form: widgetData?.form,
        field: widgetData?.field,
        secondField: widgetData?.secondField,
        module: widgetData?.module,
        notBlank,
        globalFilters,
        displayLegend,
        labelFormat,
        labelPlacement,
        labelDecimals,
        showTop,
        kpiTargetConfiguration,
        secondColorEnabled,
        displayDecimalPlaces,
        kpiTargetForColumnKpiPercentage,
        colors,
        sortOrder,
      });
    }
  }, [widgetConfiguration]);

  const getKpiTargets = useCallback((configKPI?: KpiTargetConfiguration) => {
    if (configKPI) {
      return { ...configKPI };
    }
  }, []);

  return defaultValueConfig;
}
