import 'primereact/resources/themes/lara-light-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, UserProvider } from '@myosh/myosh-login';
import './i18n/i18n';

import Notifications from './components/common/notifications.component';

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <AuthProvider>
          <UserProvider>
            <App />
            <Notifications />
          </UserProvider>
        </AuthProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);
