import { formatDateFields } from '../../components/Charts/generalFunctions/formate-date-fields';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { ComparedFrLineResponse, MonthlyComparisonData } from './compared-fr-line.interface';
import { frData, trifrData } from './fr-response';

export class TransformComparedFrLineChartData
  implements IDataCommand<ComparedFrLineResponse, WidgetConfigurationData, MonthlyComparisonData[]>
{
  /**
   * Processes the ComparedFrLineResponse data and transforms it into an array of MonthlyComparisonData objects.
   * @param data - The ComparedFrLineResponse data to be transformed.
   * @returns An array of MonthlyComparisonData objects.
   */
  process(data: ComparedFrLineResponse) {
    const finalArray = () => {
      if (data.fr && Object.keys(data.fr).length > 0) {
        return frData(data.fr);
      } else if (Object.keys(data.trifr).length > 0) {
        return trifrData(data.trifr);
      } else {
        return [];
      }
    };

    const formattedArray = formatDateFields(finalArray());

    if (data.kpi) {
      const min = Math.min(12, formattedArray.length);
      for (let index = 0; index < min; index++) {
        const item = formattedArray[index];
        const KPI = data.kpi.find((kpi) => kpi.field === item.field);
        KPI &&
          Object.assign(item, {
            value_KPI: KPI.value,
          });
      }
    }

    return formattedArray;
  }
}
