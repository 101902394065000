import React, { useEffect, useState } from 'react';
import { PickList } from 'primereact/picklist';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTypesQueryParams, useGetFieldTypesQuery } from '../../../redux/config/field-types-api';
import { OdinController } from '@myosh/odin-components';

interface DataColumnsProps extends FieldTypesQueryParams {
  control: Control;
  setVisibleColumn?: (key: Array<string>) => void;
  visibleColumns?: Array<string>;
  pureVisibleColumns?: Array<string>;
  showDescription?: boolean;
}

export interface PickListChangeEvent {
  source: Array<string>;
  target: Array<string>;
}

export default function DataColumnsSettingTab({
  visibleColumns,
  setVisibleColumn,
  module,
  form,
  showDescription = true,
  control,
}: DataColumnsProps) {
  const [source, setSource] = useState<Array<string>>([]);
  const [target, setTarget] = useState<Array<string>>([]);
  const { t } = useTranslation();
  const { data } = useGetFieldTypesQuery({
    module: module,
    form: form,
  });

  useEffect(() => {
    if (visibleColumns) {
      const targetColumns = Array.from(new Set(visibleColumns));
      setTarget(targetColumns);
    }
  }, [visibleColumns]);

  useEffect(() => {
    if (data) {
      const currentUniqueData = Array.from(
        new Set(data.map((field) => field.field).filter((val) => !visibleColumns?.includes(val)))
      );

      if (currentUniqueData) {
        currentUniqueData.sort((value1, value2) => value1.trim().localeCompare(value2.trim()));
        setSource(currentUniqueData);
      }
    }
  }, [data, visibleColumns]);

  const onChangeEventHandler = (event: PickListChangeEvent) => {
    setSource(event.source);
    setTarget(event.target);
    if (setVisibleColumn) {
      setVisibleColumn(event.target);
    }
  };

  return (
    <div className="h-full justify-center">
      {showDescription && (
        <span className="whitespace-pre-wrap text-sm font-normal italic">{t('dataColumnsMessage')}</span>
      )}
      <div className="pt-8">
        <OdinController
          name="visibleColumns"
          control={control}
          render={({ field: { onChange } }) => (
            <PickList
              source={source}
              target={target}
              onChange={(event) => {
                onChangeEventHandler(event);
                onChange(event.target);
              }}
              targetHeader={t('visibleColumns')}
              sourceStyle={{ height: '50rem' }}
              targetStyle={{ height: '50rem' }}
              className="picklist"
              filterBy="name"
              sourceHeader={t('availableDataColumn')}
              showSourceControls={false}
              showTargetControls={target.length > 1}
              sourceFilterPlaceholder={t('search')}
              targetFilterPlaceholder={t('search')}
            />
          )}
        />
      </div>
    </div>
  );
}
