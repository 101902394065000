import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useWidgetConfigurationQuery } from '../redux/config/widgetApi';
import { LoaderComponent } from '../components/common/loading-spinner.component';
import { setLayoutWidgets } from '../redux/slices/layout-tabs-slice';
import { useAppDispatch } from '../redux/hooks';
import { TabWidget } from '../components/LayoutTab/tab-widget.component';
import { t } from 'i18next';

export const IndividualWidgetPage = () => {
  const { widgetId } = useParams();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useWidgetConfigurationQuery(widgetId ?? -1, { skip: !widgetId });
  useEffect(() => {
    if (data) {
      dispatch(setLayoutWidgets([{ id: widgetId, widget: data }]));
    }
  }, [data]);

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <LoaderComponent />
    </div>
  ) : data && widgetId ? (
    <TabWidget
      layout={{ canAdmin: false, canModify: false, canView: true, isUserLayout: false, id: '' }}
      layoutWidget={{
        id: widgetId,
        widget: data,
        position: 1,
        height: '100%',
        width: '100%',
      }}
    />
  ) : (
    <div className="flex h-full w-full flex-auto items-center justify-center text-xl text-info">
      {String(t('noWidgetForId', { widgetId: widgetId }))}
    </div>
  );
};
