export const labelListStyle: React.CSSProperties = {
  textAnchor: 'middle',
  fontSize: '12px',
  fontWeight: 'bold',
  textShadow: '0 0 2px black',
  fill: 'white',
  stroke: 'black',
  strokeWidth: '2',
  paintOrder: 'stroke',
};
