import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  widgetsOrder: [],
  widgetAiInsight: {
    title: '',
    data: undefined,
  },
};

export const widgetChartsSlice = createSlice({
  name: 'widgetChart',
  initialState,
  reducers: {
    addWidgetsReorder: (state, action) => {
      state.widgetsOrder = action.payload;
    },
    removeWidgetsReorder: (state) => {
      state.widgetsOrder = [];
    },
    setWidgetAiInsight: (state, action) => {
      state.widgetAiInsight = {
        title: action.payload.title,
        data: action.payload.data,
      };
    },
    removeWidgetAiInsight: (state) => {
      state.widgetAiInsight = {
        title: '',
        data: undefined,
      };
    },
  },
});

export const { addWidgetsReorder, removeWidgetsReorder, setWidgetAiInsight, removeWidgetAiInsight } =
  widgetChartsSlice.actions;

export default widgetChartsSlice.reducer;

export const selectWidgetChart = (state: RootState) => state.rootReducer.widgetChart;
export const selectWidgetAiInsight = (state: RootState) => state.rootReducer.widgetChart.widgetAiInsight;
