import { useEffect, useState } from 'react';
import { OdinController, RadioGroup, RadioGroupResult } from '@myosh/odin-components';
import { t } from 'i18next';
import { Control } from 'react-hook-form';

interface LabelConfigProps {
  option?: string;
  control: Control;
}

const LabelPlacement = ({ option, control }: LabelConfigProps) => {
  const labelPlacementOptions = [
    {
      text: t('top'),
      value: 'top',
    },
    {
      text: `${t('middleDefault')}`,
      value: 'middle',
    },
  ];

  const [labelFormatValue, setLabelFormatValue] = useState<RadioGroupResult>(labelPlacementOptions[1]);

  useEffect(() => {
    if (option) {
      setLabelFormatValue(option === 'middle' ? labelPlacementOptions[1] : labelPlacementOptions[0]);
    }
  }, [option]);

  return (
    <div className="my-2 flex flex-col pt-2">
      <OdinController
        name="labelPlacement"
        control={control}
        render={({ field: { onChange } }) => (
          <RadioGroup
            id="labelPlacementOption"
            name="labelPlacementOption"
            data={labelPlacementOptions}
            label={t('labelPlacement', { type: 'Column' })}
            value={labelFormatValue}
            textField="text"
            valueField="value"
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};

export default LabelPlacement;
