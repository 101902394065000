import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { OdinController, RadioGroup } from '@myosh/odin-components';
import { Control } from 'react-hook-form';

interface DataCategoriesProps {
  showTop?: string | number | undefined;
  control: Control;
}

const DataCategoriesToShowConfiguration = ({ showTop, control }: DataCategoriesProps) => {
  const [showSelectedOptions, setSelectedOptions] = useState<{ position: number; text: string; value: string }>();

  const showTopOptions = useMemo(() => {
    return [
      {
        position: 1,
        text: t('all'),
        value: 'all',
      },
      {
        position: 2,
        text: t('top5'),
        value: 'top5',
      },
      {
        position: 3,
        text: t('top10'),
        value: 'top10',
      },
      {
        position: 4,
        text: t('top30'),
        value: 'top30',
      },
    ];
  }, []);

  useEffect(() => {
    setSelectedOptions(
      showTopOptions.find((el) => {
        if (el.value === showTop) {
          return {
            value: el.value,
            text: el.value,
          };
        }
      })
    );
  }, [showTop]);

  return (
    <div className="my-2 flex flex-col pt-2">
      <OdinController
        name="showTop"
        control={control}
        render={({ field: { onChange } }) => (
          <RadioGroup
            id="showTopOption"
            name="showTopOption"
            data={showTopOptions}
            label={t('dataCategoriesToShow')}
            value={showSelectedOptions}
            orientation="VERTICAL"
            textField="text"
            valueField="value"
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};

export default DataCategoriesToShowConfiguration;
