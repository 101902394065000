import { ChartResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';
import { CombinedData } from './common-data.interface';

export class FirstFields implements IDataCommand<Array<ChartResponse>, WidgetConfigurationData, CombinedData> {
  process(data: Array<ChartResponse>) {
    const firstFields = new Map<string, Map<string, number>>();
    for (let i = 0, length = data?.length; i < length; i++) {
      const item = data[i];
      if (item.secondField && (item.field === '' || item.field)) {
        const retrievedItem = firstFields.get(item.field);
        if (retrievedItem) {
          retrievedItem.set(item.secondField, Number(item.value));
        } else {
          firstFields.set(item.field, new Map<string, number>().set(item.secondField, Number(item.value)));
        }
      }
    }
    return {
      data,
      firstFields,
    };
  }
}
