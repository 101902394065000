import { useRef } from 'react';
import { LegendPayloadOnClick } from '../Charts/chart-events';

type LegendProps = {
  payload?: Array<LegendPayloadOnClick>;
  clickListener: (dataKey: string) => void;
  recalculateLabelsHeight?: () => void;
};

export const defaultLegendWrapperStyle = {
  bottom: 0,
  paddingTop: 0,
  paddingBottom: 4,
  maxHeight: '100px',
  overflow: 'auto',
  zIndex: 1,
  background: 'white',
};

export const renderLegend = (payload: LegendPayloadOnClick) => {
  const color = payload?.color;
  return (
    <span style={{ color }} className="cursor-pointer text-sm text-mono-0">
      <>{payload || '[none]'}</>
    </span>
  );
};

const indicator = (item: LegendPayloadOnClick) => {
  if (item.type === 'line') {
    return (
      <g transform="translate(-1,-7)">
        <path
          strokeWidth="2"
          fill="none"
          stroke={item.inactive ? `rgb(204, 204, 204)` : `${item.color}`}
          d="M 0 13 L 15 13"
        ></path>
        <path
          fill={item.inactive ? `rgb(204, 204, 204)` : `${item.color}`}
          d="M 8 9 C 13.328 9 13.328 17 8 17 C 2.6719999999999997 17 2.6719999999999997 9 8 9 Z"
        ></path>
      </g>
    );
  }
  // default to `rect` implementation
  return (
    <rect
      x="0"
      y="0"
      rx="0"
      ry="0"
      width="14"
      height="14"
      strokeWidth="0"
      stroke="none"
      fill={item.inactive ? `rgb(204, 204, 204)` : `${item.color}`}
      className="recharts-legend-icon"
    />
  );
};

export const CustomLegendWithCustomScroll = ({ payload, clickListener, recalculateLabelsHeight }: LegendProps) => {
  const divRef = useRef<HTMLDivElement>();

  const assignStyleAndReference = (el: HTMLDivElement) => {
    divRef.current = el;
    const legendElement = divRef.current.parentElement;
    if (legendElement && recalculateLabelsHeight) {
      recalculateLabelsHeight();
    }
  };

  return (
    <div
      className="ml-5px flex flex-1 flex-row flex-wrap justify-center gap-1"
      ref={(el) => el && assignStyleAndReference(el)}
    >
      {payload?.map((item) => (
        <button
          type="button"
          onClick={() => clickListener(item.dataKey ?? item.value)}
          key={item.value}
          className="mr-3 flex cursor-pointer flex-row content-center items-start text-xs text-mono-0"
        >
          <div style={{ flexBasis: '14px' }} className="flex items-start">
            <svg
              style={{
                width: '14px',
                height: '14px',
                display: 'inline-block',
                verticalAlign: 'end',
                marginRight: '4px',
              }}
              viewBox="0 0 14 14"
            >
              {indicator(item)}
            </svg>
          </div>
          <div style={{ lineHeight: '14px' }}>{item.value || '[none]'}</div>
        </button>
      ))}
    </div>
  );
};
