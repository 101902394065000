import { LayoutWidget } from '../../redux/config/layout';
import { LayoutWeight } from '../../redux/slices/new-tab-order';

export function getWidgetLayoutIds(children: HTMLCollection): Array<Partial<LayoutWidget>> {
  const widgetIds: Array<Partial<LayoutWidget>> = [];
  for (let i = 0, length = children.length; i < length; i++) {
    const element = children[i] as HTMLElement;
    const dataId = element?.getAttribute('data-id');

    if (dataId) {
      widgetIds.push({
        position: i,
        id: dataId,
      });
    }
  }

  return widgetIds;
}

export function updateDataPosition(children: HTMLCollection) {
  for (let i = 0, length = children.length; i < length; i++) {
    const element = children[i] as HTMLElement;
    element.setAttribute('data-position', String(i));
  }
}

export function getLayoutsNewPosition(children: HTMLCollection): Array<LayoutWeight> {
  const widgetIds: Array<LayoutWeight> = [];
  let publicLayoutCounter = 0;
  let privateLayoutCounter = 0;
  for (let i = 0, length = children.length; i < length; i++) {
    const element = children[i] as HTMLElement;
    const title = element.getElementsByClassName(`tabTitle`)[0];
    const dataId = title.getAttribute('data-id');
    const isPublic = title.classList.contains('publicLayout');

    if (dataId) {
      widgetIds.push({
        weight: isPublic ? ++publicLayoutCounter : ++privateLayoutCounter,
        id: dataId,
      });
    }
  }

  return widgetIds;
}
