import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DropDown,
  DropDownNoSelection,
  DropDownResult,
  JsonData,
  RadioGroup,
  isDropDownNoSelection,
} from '@myosh/odin-components';
import { t } from 'i18next';
import { isArray } from 'lodash';
import { DeleteWidgetConfirmation } from '../delete-widget-confirmation';
import { WidgetSimplifiedData, useWidgetSimplifiedConfigurationDataQuery } from '../../../redux/config/widgetApi';
import { WidgetShowConfigType } from './widget-config-type';

type WidgetShowConfigTypeFirstProps = Omit<WidgetShowConfigType, 'idWidget' | 'control'> & {
  setFinishClicked: (finishClicked: boolean) => void;
  setEnableFinishButton: (enabledFinishButton: boolean) => void;
  setSelectedChart: (newChart?: DropDownResult) => void;
};

export const WizardTabFirst = ({
  setFinishClicked,
  closeOverlays,
  setEnableFinishButton,
  selectedChart,
  setSelectedChart,
  setEnableNextButton,
}: WidgetShowConfigTypeFirstProps) => {
  const [chartSelectionVisible, setChartSelectionVisible] = useState<boolean>(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false);
  const [dropDownItemsRemove, setDropDownItemsRemove] = useState<Array<WidgetSimplifiedData>>();
  const [dropDownItemsEdit, setDropDownItemsEdit] = useState<Array<WidgetSimplifiedData>>();
  const [selectedAction, setSelectedAction] = useState<string>();

  useEffect(() => {
    if (selectedChart && selectedAction === 'delete') {
      setDeleteConfirmationVisible(true);
    }
  }, [selectedChart]);

  const radioOptions = useMemo(() => {
    return [
      {
        position: 1,
        text: t('deleteAWidget'),
        value: 'delete',
        onSelect: () => {
          setSelectedChart(undefined);
          setEnableFinishButton(false);
          setChartSelectionVisible(true);
          setEnableNextButton(false);
        },
      },
      {
        position: 2,
        text: t('createAWidget'),
        value: 'create',
        onSelect: () => {
          setSelectedChart(undefined);
          setChartSelectionVisible(false);
          setEnableFinishButton(false);
          setEnableNextButton(true);
        },
      },
      {
        position: 2,
        text: t('editAWidget'),
        value: 'edit',
        onSelect: () => {
          setSelectedChart(undefined);
          setChartSelectionVisible(true);
          setEnableNextButton(false);
          setEnableFinishButton(false);
        },
      },
    ];
  }, []);

  const { data: availableCharts } = useWidgetSimplifiedConfigurationDataQuery(
    {},
    {
      skip: !chartSelectionVisible,
    }
  );

  useEffect(() => {
    if (availableCharts) {
      //Only charts with id (499, 999] can be allowed for deletion
      setDropDownItemsRemove(availableCharts.filter((item) => item.id > 499 && item.id < 1000));
      //Only charts with id >=500 can be edited
      setDropDownItemsEdit(availableCharts.filter((item) => item.id >= 500));
    }
  }, [availableCharts]);

  const chartSelectionData: JsonData | undefined = useMemo(() => {
    if (selectedAction === 'delete') {
      return dropDownItemsRemove as unknown as JsonData;
    } else if (selectedAction === 'edit') {
      return dropDownItemsEdit as unknown as JsonData;
    }
    return undefined;
  }, [selectedAction, dropDownItemsEdit, dropDownItemsRemove]);

  const clickHandlerChartSelection = useCallback(
    (value?: DropDownResult | DropDownNoSelection | DropDownResult[]) => {
      //On no selection both next and finish are disabled
      if (isDropDownNoSelection(value)) {
        setEnableFinishButton(false);
        setEnableNextButton(false);
      } else if (value && !isArray(value)) {
        setSelectedChart(value as DropDownResult);
        if (selectedAction === 'delete') {
          setEnableFinishButton(true);
          setEnableNextButton(false);
        } else if (selectedAction === 'edit') {
          setEnableFinishButton(false);
          setEnableNextButton(true);
        }
      } else {
        setSelectedChart(undefined);
        setEnableFinishButton(false);
        setEnableNextButton(false);
      }
    },
    [selectedAction]
  );

  return (
    <div className="flex flex-col justify-center">
      <div className="my-2">{String(t('wizardStep1Description'))}</div>
      <RadioGroup
        data={radioOptions}
        id="preferableActionOption"
        name="preferableActionOption"
        label={t('preferableAction')}
        orientation="VERTICAL"
        textField="text"
        valueField="value"
        onChange={(event) => {
          const selectedOption = radioOptions.find((option) => option.value === event?.value);
          if (selectedOption) {
            selectedOption.onSelect();
          }
          setSelectedAction(selectedOption?.value ?? undefined);
        }}
      />
      {chartSelectionVisible && (
        <div className="py-2">
          <DropDown
            data={chartSelectionData}
            label={t('chooseChart')}
            placeholder={`${t('selectWidget')}...`}
            setDefaultValue={false}
            textField="text"
            valueField="id"
            pageSize={50}
            onChange={clickHandlerChartSelection}
            value={{ value: selectedChart?.value, text: selectedChart?.text ?? '' }}
          />
        </div>
      )}
      {selectedChart && (
        <DeleteWidgetConfirmation
          setIsVisible={(show, closeAllOverlays) => {
            setDeleteConfirmationVisible(show);
            if (!show) {
              if (closeAllOverlays) {
                closeOverlays();
              } else {
                setFinishClicked(false);
              }
            }
          }}
          isVisible={deleteConfirmationVisible}
          chart={selectedChart}
        />
      )}
    </div>
  );
};
