import { useEffect, useRef } from 'react';

/**
 *  A custom hook to be used to track a previous value of active tab
 *
 *  @return the previous value (happens before update in useEffect)
 * */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
