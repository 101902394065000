import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { api } from './config/api';
import { hierarchyApi } from './config/hierarchy';
import { widgetConfigurationApi } from './config/widgetApi';
import { metadataConfigApi } from './config/metadataConfig';
import { hierarchyFiltersApi } from './config/HierarchyFiltersApi';
import { customFiltersApi } from './config/CustomFiltersApi';
import { chartApi } from './config/chart-data';
import { tableDataApi } from './config/table-data';
import { themeApi } from './config/theme';
import { layoutApi } from './config/layout';
import { detailsWindowApi } from './config/details-window';
import { workFlowStepsApi } from './config/workflowStepsApi';
import { layoutWidgetApi } from './config/layout-widget';
import { fieldTypesApi } from './config/field-types-api';
import { usersApi } from './config/users-api';
import { rolesApi } from './config/roles-api';
import { filtersApi } from './config/filters-api';
import { modulesApi } from './config/get-modules-api';
import { exportFileApi } from './config/export-api';
import { viewsApi } from './config/views';
import { currentSchemaApi } from './config/current-schema-api';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [widgetConfigurationApi.reducerPath]: widgetConfigurationApi.reducer,
    [hierarchyApi.reducerPath]: hierarchyApi.reducer,
    [metadataConfigApi.reducerPath]: metadataConfigApi.reducer,
    [hierarchyFiltersApi.reducerPath]: hierarchyFiltersApi.reducer,
    [customFiltersApi.reducerPath]: customFiltersApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [tableDataApi.reducerPath]: tableDataApi.reducer,
    [themeApi.reducerPath]: themeApi.reducer,
    [layoutApi.reducerPath]: layoutApi.reducer,
    [detailsWindowApi.reducerPath]: detailsWindowApi.reducer,
    [workFlowStepsApi.reducerPath]: workFlowStepsApi.reducer,
    [layoutWidgetApi.reducerPath]: layoutWidgetApi.reducer,
    [fieldTypesApi.reducerPath]: fieldTypesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [filtersApi.reducerPath]: filtersApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
    [exportFileApi.reducerPath]: exportFileApi.reducer,
    [viewsApi.reducerPath]: viewsApi.reducer,
    [currentSchemaApi.reducerPath]: currentSchemaApi.reducer,
    rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      api.middleware,
      widgetConfigurationApi.middleware,
      hierarchyApi.middleware,
      metadataConfigApi.middleware,
      hierarchyFiltersApi.middleware,
      customFiltersApi.middleware,
      chartApi.middleware,
      tableDataApi.middleware,
      themeApi.middleware,
      layoutApi.middleware,
      detailsWindowApi.middleware,
      workFlowStepsApi.middleware,
      layoutWidgetApi.middleware,
      fieldTypesApi.middleware,
      usersApi.middleware,
      rolesApi.middleware,
      filtersApi.middleware,
      modulesApi.middleware,
      exportFileApi.middleware,
      viewsApi.middleware,
      currentSchemaApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
