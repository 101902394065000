import React, { useCallback, useEffect, useRef } from 'react';
import { Login, UserActionTypes, useUser } from '@myosh/myosh-login';
import { Navigate } from 'react-router-dom';
import { useLoginQueryParams } from '../hooks/useLoginQueryParams';
import jwt_decode from 'jwt-decode';
import { clearSelectedView, setSelectedView } from '../redux/slices/selected-view';
import { useAppDispatch } from '../redux/hooks';
import { removeGlobalDateFilters } from '../redux/slices/globalFilter/global-dates-filter-slice';
import { appendTimeUnit } from '../helpers/dateFormater';

export default function LoginPage() {
  const { username, token, schema, isExport, widget, layout, to, from, viewId } = useLoginQueryParams();
  const hasTokenAndSchema = token && schema;

  const { state, dispatch } = useUser();
  const appDispatch = useAppDispatch();
  const isAuthenticated = state.auth;
  const widgetId = useRef<string>();
  const layoutId = useRef<string>();

  useEffect(() => {
    if (!isAuthenticated && hasTokenAndSchema) {
      const decodedToken: Record<string, unknown> = jwt_decode(token);

      dispatch({
        type: UserActionTypes.UpdateUser,
        payload: {
          auth: true,
          user: {
            currentSchema: schema,
            schemas: decodedToken['custom:schema'] as string,
            prefered_username: decodedToken['prefered_username'] as string,
            superuser: decodedToken['custom:superuser'] as string,
          },
          tokens: {
            id_token: token,
            expires_at: decodedToken['exp'] as number,
            access_token: '', // we don't have an access token
          },
        },
      });
      dispatch({ type: UserActionTypes.UpdateLoginStatus, payload: 'done' });

      updateViewDates();

      widgetId.current = widget;
      layoutId.current = layout;
    }
  }, []);

  const setForcedDateRange = useCallback((toDate?: string, fromDate?: string) => {
    if (toDate && fromDate) {
      const forcedDateRange = JSON.stringify({
        dateStart: appendTimeUnit(fromDate),
        dateEnd: appendTimeUnit(toDate),
        quickDateRange: { value: 'CUSTOM_FILTER_VALUE', text: 'Custom' },
      });
      sessionStorage.setItem('forced-date-api', forcedDateRange);
    }
  }, []);

  //if user is already logged in trying to access the login page,
  // propagate the path attributes
  const updatePathVariables = () => {
    updateViewDates();
    if (widget || layout) {
      widgetId.current = widget;
      layoutId.current = layout;
    }
  };

  const updateViewDates = () => {
    if (viewId) {
      appDispatch(removeGlobalDateFilters());
      appDispatch(setSelectedView({ id: viewId }));
    } else {
      appDispatch(clearSelectedView());
      setForcedDateRange(to, from);
    }
  };

  if (isAuthenticated && state.loginStatus === 'done') {
    updatePathVariables();
    let redirectPath = widgetId.current ? `/widget/${widgetId.current}` : '/';
    redirectPath = layoutId.current ? `/layout/${layoutId.current}` : redirectPath;
    return <Navigate to={redirectPath} state={{ export: isExport, widget: widget, layout: layout }} />;
  } else {
    const loginQueryParams = isExport ? { export: 'true' } : undefined;
    return <Login redirectPath="/" username={username} loginQueryParams={loginQueryParams} />;
  }
}
