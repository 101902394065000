import { compactNumberFormatter } from './tick-formatter';

export const yAxisSettings = (
  domain: [number, number] | undefined,
  labelValue: string,
  angle: number,
  dx: number,
  orientation?: 'right' | 'left',
  type: 'number' | 'category' = 'number',
  yAxisId?: string
) => {
  let formatTick = true;

  // if highest domain is not an integer and divisible by 0.5,
  // set false to show decimal values
  if (domain && !Number.isSafeInteger(domain[1]) && domain[1] % 0.5 === 0) {
    formatTick = false;
  }

  return {
    type: type,
    allowDecimals: false,
    axisLine: false,
    tickLine: false,
    orientation: orientation,
    domain: domain,
    label: {
      value: labelValue,
      angle: angle,
      dx: dx,
      position: 'center',
    },
    yAxisId: yAxisId ?? orientation,
    tickFormatter: formatTick ? compactNumberFormatter : undefined,
    interval: 0,
    tick: true,
  };
};
