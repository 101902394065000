import { useSelector } from 'react-redux';
import { selectSearchQuery } from '../../../redux/slices/globalFilter/global-dates-filter-slice';

export default function useStartEndDates() {
  const globalFilter = useSelector(selectSearchQuery);

  const dates = {
    startDate: '',
    endDate: '',
    quickDateRange: '',
  };

  const dateDetails = globalFilter.value?.DateDetails;
  if (dateDetails) {
    if (dateDetails?.dateStart) {
      dates.startDate = dateDetails.dateStart;
    }

    if (dateDetails.dateEnd) {
      dates.endDate = dateDetails.dateEnd;
    }
    if (dateDetails.quickDateRange?.value) {
      dates.quickDateRange = String(dateDetails.quickDateRange.value);
    }
  }

  return dates;
}
