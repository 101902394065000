import React from 'react';
import { t } from 'i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit';

type ErrorDataLabelType = {
  error?: FetchBaseQueryError | SerializedError;
  retry?: () => void;
};
export const ErrorDataLabel = ({ error, retry }: ErrorDataLabelType) => {
  return error && 'data' in error ? (
    <div
      role="alert"
      className="no-data rendered rendered-error flex h-full grow flex-col items-center justify-center gap-4 overflow-hidden"
    >
      <p className="custom-scroll overflow-auto px-5" style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
        {String(getErrorLabel(error.data))}
      </p>
      {retry && (
        <button onClick={retry}>
          <u>{`${t('tryAgain')}`}</u>
        </button>
      )}
    </div>
  ) : (
    <div className="no-data rendered rendered-error w-full font-bold">
      <div className="flex h-full flex-col items-center justify-center">{String(t('anErrorOccurred'))}</div>
    </div>
  );
};

const getErrorLabel = (data: unknown) => {
  if (data === undefined || data === null || typeof data !== 'object') {
    return data;
  }
  return 'message' in data ? data.message : data;
};
