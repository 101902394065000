import { formatIntoPercentage } from '../../helpers/number-formatters';
import { ChartColumnLineResponse } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

export class ColumnLineChartValueBuilder {
  private readonly columnDataFields: Array<string>;
  private readonly columnDataFirstField: string;

  constructor(private widgetData: ChartColumnLineResponse, private widgetConfig: WidgetConfigurationData) {
    this.columnDataFields = Object.keys(widgetData.columnData);
    this.columnDataFirstField = this.columnDataFields[0];
  }

  public buildColumnLineChartValue(fieldValue: string) {
    if (Object.keys(this.widgetData?.columnData).length > 0) {
      const value_column = this.widgetConfig.widgetConfig?.calculationType
        ? this.widgetData.columnDataSum[fieldValue]
        : this.widgetData.columnData[this.columnDataFirstField][fieldValue];
      const value_line = this.getLineNumberValue(fieldValue);
      const value_percentage = this.getLinePercentageValue(fieldValue);
      const color = this.widgetConfig.widgetConfig?.colors
        ? this.widgetConfig.widgetConfig?.colors[fieldValue]
        : undefined;

      return {
        field: getFieldValueToDisplay(fieldValue),
        value_column,
        value_line,
        value_percentage,
        color,
      };
    } else {
      return {};
    }
  }

  private getLineNumberValue(fieldValue: string) {
    switch (this.widgetConfig.widgetData.lineType) {
      case 'percentage':
        return Number(
          (
            ((this.widgetData.lineNumerate[fieldValue] / this.widgetData.lineDataDenom[fieldValue]) * 1000) /
            10
          ).toFixed(1)
        );
      case 'average':
        return this.widgetData.lineData[fieldValue] / this.widgetData.lineDataCount[fieldValue];
      case 'count':
        if (isNaN(this.widgetConfig.widgetData.lineValues)) {
          return this.widgetData.lineDataCount[fieldValue];
        } else {
          return this.widgetData.lineNumerate[fieldValue];
        }
      default:
        return this.widgetData.lineData[fieldValue];
    }
  }

  private getLinePercentageValue(fieldValue: string) {
    if (this.widgetConfig.widgetData.lineType === 'percentage') {
      const percentageNumber = this.widgetData.lineNumerate[fieldValue] / this.widgetData.lineDataDenom[fieldValue];
      return percentageNumber > 0 ? `${formatIntoPercentage(percentageNumber, 1)}` : '';
    } else {
      return this.widgetData.lineData[fieldValue].toString();
    }
  }
}

const getFieldValueToDisplay = (fieldValue: string) => {
  return fieldValue !== 'no values' && fieldValue !== '[none]' ? fieldValue : '[blank]';
};
