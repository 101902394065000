import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ModalDialog, TabViewPanel, Tooltip } from '@myosh/odin-components';
import { isBoolean, isEmpty, isEqual, isNil, isUndefined, omitBy } from 'lodash';
import { t } from 'i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import ConfigurationTabContent from './Tabs/configuration-setting-tab';
import DashboardTabView from '../DashboardTabView/dashboard-tab-view';
import DataColumnsSettingTab from './Tabs/data-columns-setting-tab';
import KpiTargetSettingTab, { months } from './Tabs/kpi-target-setting-tab';
import { WidgetConfigsGenericWrapper } from '../common/chart-generic-wrapper';
import { useWidgetConfigs } from './hooks/useWidgetConfigs';
import useInvalidateChartResponse from '../Charts/hooks/useInvalidateChartResponse';
import { labelDecimalFormatter, labelFormatter } from '../../helpers/getLabelFormatter';
import { detailsWindowApi } from '../../redux/config/details-window';
import {
  Colors,
  KpiTargetConfiguration,
  useUpdateWidgetConfigurationDataMutation,
  WidgetConfiguration,
  WidgetData,
} from '../../redux/config/widgetApi';
import KpiTargetPercentageSettingTab from './Tabs/kpi-target-percentage-setting-tab';
import { isTableType } from '../common/is-table-type';

interface SettingsWindowProperties {
  visible: boolean;
  onDialogHidden: () => void;
  widgetConfiguration: WidgetConfigsGenericWrapper;
}

type TabContentProperties = {
  header: string;
  key?: string;
  children?: ReactNode | ReactElement;
};

export type kpiTargetPercentage = {
  [key: string]: string;
};

export interface LabelFormatType {
  text: string;
  value: string;
}

export interface DefaultValueProps {
  displayLegend?: boolean;
  labelFormat?: LabelFormatType[];
  labelPlacement?: string;
  labelDecimals?: number;
  displayDecimalPlaces?: number;
  showTop?: string;
  kpiTargetConfiguration?: Partial<KpiTargetConfiguration>;
  secondColorEnabled?: boolean;
  kpiTargetForColumnKpiPercentage?: kpiTargetPercentage;
  colors?: Colors;
  sortOrder?: Array<string>;
  form?: string[];
  field?: string;
  secondField?: string;
  module?: string;
  notBlank?: boolean;
  globalFilters?: { id?: string; value?: string; values?: string[] };
}
export interface DataUpdatedProps {
  id: string | undefined;
  title?: string;
  type?: string;
  widgetConfig?: Partial<WidgetConfiguration>;
  widgetData?: Partial<WidgetData>;
  isTable?: boolean;
}

const TabContent = ({ header, key = header, children }: TabContentProperties) => {
  return children ? (
    <TabViewPanel header={header} key={key}>
      <div className="mx-0 w-auto flex-shrink px-0">{children}</div>
    </TabViewPanel>
  ) : null;
};

export default function WidgetSettingsDialog({
  visible,
  onDialogHidden,
  widgetConfiguration,
}: SettingsWindowProperties) {
  const [colorsSelection, setColorsSelection] = useState<Record<string, string> | undefined>();
  const [defaultColor, setDefaultColor] = useState<string>();
  const [secondColor, setSecondColor] = useState<string>();
  const [lineColorSelection, setLineColorSelection] = useState<string>();
  const [sortOrder, setSortOrder] = useState<string[] | undefined>(widgetConfiguration.widgetConfig?.sortOrder);
  const [visibleColumns, setVisibleColumns] = useState<string[] | undefined>(
    widgetConfiguration.widgetData.visibleColumns
  );
  const [kpiTargetConfiguration, setKpiTargetConfiguration] = useState<KpiTargetConfiguration | undefined>();
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(true);

  // If CHART TYPE - CHART_COLUMN_KPI_PERCENTAGE
  const [kpiTargetPercentage, setKpiTargetPercentage] = useState<kpiTargetPercentage | undefined>();

  const dispatch = useDispatch();
  const [updateWidgetConfigurationData] = useUpdateWidgetConfigurationDataMutation();
  const defaultValues = useWidgetConfigs(widgetConfiguration);
  const invalidateChartData = useInvalidateChartResponse(widgetConfiguration.id);

  const {
    handleSubmit,
    formState: { dirtyFields },
    control,
    watch,
    reset,
  } = useForm({ defaultValues });
  const watchLabelFormat = watch('labelFormat');

  const getUpdateWidgetData = useCallback(
    (data: FieldValues) => {
      const finalResult: Record<string, unknown> = {
        displayLegend: data.displayLegend,
        excludeArchivedRecords: data.excludeArchivedRecords?.checked,
        ignoreDateFilters: data.ignoreDateFilters?.checked,
        globalFilters: data.globalFilters ? [data.globalFilters] : undefined,
      };
      if (!isEqual(visibleColumns, widgetConfiguration.widgetData.visibleColumns)) {
        finalResult.visibleColumns = visibleColumns;
      }
      if (widgetConfiguration && widgetConfiguration.widgetData) {
        const rollingFR = widgetConfiguration.widgetData.rollingFR;
        const rollingTRIFR = widgetConfiguration.widgetData.rollingTRIFR;

        if (rollingFR) {
          finalResult.rollingFR = {
            ...rollingFR,
            displayValues: data.displayRollingLine,
          };
        }
        if (rollingTRIFR) {
          finalResult.rollingTRIFR = {
            ...rollingTRIFR,
            displayValues: data.displayRollingLine,
          };
        }
      }

      return omitBy(finalResult, isUndefined);
    },
    [visibleColumns, widgetConfiguration]
  );

  const onSubmit = handleSubmit((data: FieldValues) => {
    const kpiDefaultValues = defaultValues?.kpiTargetConfiguration;

    if ((data && Object.keys(dirtyFields).length > 0) || hasInnerStateChanged()) {
      const dataUpdated: DataUpdatedProps = {
        id: widgetConfiguration.id,
        isTable: isTableType(widgetConfiguration.type),
      };
      const updatedWidgetData = getUpdateWidgetData(data);
      if (!isEmpty(updatedWidgetData)) {
        dataUpdated.widgetData = updatedWidgetData;
      }
      dataUpdated.widgetConfig = omitBy(
        {
          labelFormat: data.labelFormat ? labelFormatter(data.labelFormat) : undefined,
          labelPlacement: data.labelPlacement?.value,
          labelDecimals: labelDecimalFormatter(data.labelDecimals),
          showTop: data.showTop?.value,
          threshold: data.threshold,
          kpiTargetConfiguration:
            'hideKPI' in data || isBoolean(data.onFRAxis)
              ? getKpiTargetsConfig(omitBy(data, isNil), kpiDefaultValues)
              : undefined,
          secondColorEnabled: isBoolean(data.secondColorEnabled)
            ? data.secondColorEnabled
            : defaultValues.secondColorEnabled,
          displayDecimalPlaces: labelDecimalFormatter(data.displayDecimalPlaces),
          colors: colorsSelection ? { ...widgetConfiguration.widgetConfig?.colors, ...colorsSelection } : undefined,
          sortOrder,
          defaultColor,
          secondColor,
          kpiTargetForColumnKpiPercentage: { ...kpiTargetPercentage, ...data.kpiTargetForColumnKpiPercentage },
        },
        isUndefined
      );

      updateWidgetConfigurationData(omitBy(dataUpdated, isEmpty)).then(() => {
        if (kpiTargetConfiguration || kpiTargetPercentage) {
          invalidateChartData();
        }
      });
    }
  });

  const getKpiTargetsConfig = useCallback(
    (configKPI: FieldValues, defaultConfigKpi?: Partial<KpiTargetConfiguration>) => {
      if (configKPI && defaultConfigKpi) {
        const hideKPI = 'hideKPI' in configKPI ? configKPI.hideKPI : defaultConfigKpi.hideKPI;
        return {
          hideKPI: hideKPI,
          caption: configKPI.caption ?? defaultConfigKpi.caption,
          onFRAxis: configKPI.onFRAxis ?? defaultConfigKpi.onFRAxis,
          id: widgetConfiguration.widgetConfig?.kpiTargetConfiguration?.id as string,
          lineColor: lineColorSelection ?? defaultConfigKpi.lineColor,
          ...monthsValueConfiguration(configKPI, defaultConfigKpi),
        };
      }
    },
    [lineColorSelection]
  );

  const monthsValueConfiguration = useCallback(
    (configKPI: FieldValues, defaultConfigKpi?: Partial<KpiTargetConfiguration>) => {
      const monthsValues = {};
      if (configKPI && defaultConfigKpi) {
        for (let i = 0; i < months.length; i++) {
          const currentMonth = months[i];
          const currMonthValue = `${[currentMonth.toLowerCase()]}Value`;

          Object.assign(monthsValues, {
            [currMonthValue]: !isNaN(Number(configKPI[currentMonth]))
              ? Number(configKPI[currentMonth])
              : defaultConfigKpi[currMonthValue],
          });
        }
      }

      return monthsValues;
    },
    []
  );

  const hasInnerStateChanged = () => {
    let stateChanged = false;
    if (colorsSelection || lineColorSelection || defaultColor || secondColor) {
      stateChanged = true;
    } else if (sortOrder !== widgetConfiguration.widgetConfig?.sortOrder) {
      stateChanged = true;
    } else if (visibleColumns) {
      stateChanged = true;

      // This will immediately remove all existing cache entries from details-window api when visible columns properties are updated
      dispatch(detailsWindowApi.util.resetApiState());
    }
    return stateChanged;
  };

  // Once we receive a new value from the BE, reset local values to defaults
  useEffect(() => {
    const { widgetConfig } = widgetConfiguration;

    setColorsSelection(undefined);

    if (widgetConfig) {
      setSortOrder(widgetConfig.sortOrder);
      setKpiTargetConfiguration(widgetConfig.kpiTargetConfiguration);

      if (!isEmpty(widgetConfig.kpiTargetForColumnKpiPercentage)) {
        setKpiTargetPercentage(widgetConfig.kpiTargetForColumnKpiPercentage);
      }
    }
    reset(defaultValues);
  }, [widgetConfiguration]);

  const errorMessage = useMemo(() => {
    if (!saveButtonEnabled) {
      return t('columnMustBeSelected');
    }
  }, [saveButtonEnabled]);

  const footer = (
    <div className="content-en flex items-end justify-end gap-2 pt-1">
      <Tooltip tooltipZIndexCheck disabled={saveButtonEnabled} description={errorMessage}>
        <Button
          type="primary"
          variant="alternative"
          fullWidth={false}
          htmlType="submit"
          onSubmit={onSubmit}
          onClick={onDialogHidden}
          form="widgetSettingForm"
          disabled={!saveButtonEnabled}
        >
          {String(t('save'))}
        </Button>
      </Tooltip>

      <Button type="default" variant="alternative" fullWidth={false} htmlType="button" onClick={onDialogHidden}>
        {String(t('close'))}
      </Button>
    </div>
  );

  const getDialogTitle = useMemo(() => {
    if (widgetConfiguration) {
      return `${widgetConfiguration.title} (${widgetConfiguration.type}) ${t('settings')}`;
    }
    return t('widgetConfiguration');
  }, [widgetConfiguration]);

  const handleDialogHidden = () => {
    reset();
    onDialogHidden();
    setSaveButtonEnabled(true);
  };

  return (
    <ModalDialog
      visible={visible}
      closeOnEscape={true}
      footer={footer}
      buttonsCloseModal={true}
      header={getDialogTitle}
      hidden={handleDialogHidden}
      maxDialogWidth="55vw"
      minDialogHeight="85vh"
      maxDialogHeight="95vh"
      minDialogWidth="35vw"
      transitionDuration={0}
    >
      <form id="widgetSettingForm" onSubmit={onSubmit} className="flex h-full w-full flex-col">
        <DashboardTabView>
          <TabContent header={t('configuration')}>
            <ConfigurationTabContent
              control={control}
              widgetConfiguration={widgetConfiguration}
              watchLabelFormat={watchLabelFormat}
              setColors={(colors) => setColorsSelection(colors)}
              setDefaultColor={(color) => setDefaultColor(color)}
              setSecondColor={(color) => setSecondColor(color)}
              setSortOrder={(sortOrder) => setSortOrder(sortOrder)}
            />
          </TabContent>
          {widgetConfiguration.calculatedVisibleColumns && (
            <TabContent header={t('dataColumns')}>
              <DataColumnsSettingTab
                setVisibleColumn={(visible) => {
                  setVisibleColumns(visible);
                  setSaveButtonEnabled(visible.length > 0);
                }}
                visibleColumns={widgetConfiguration.widgetData.visibleColumns}
                pureVisibleColumns={widgetConfiguration.widgetData.pureVisibleColumns}
                module={widgetConfiguration.widgetData.module}
                form={widgetConfiguration.widgetData.forms}
                control={control}
              />
            </TabContent>
          )}
          {kpiTargetConfiguration ? (
            <TabContent header={t('kpiTargets')}>
              <KpiTargetSettingTab
                control={control}
                kpiTargetConfiguration={kpiTargetConfiguration}
                setLineColorSelection={(color) => setLineColorSelection(color)}
              />
            </TabContent>
          ) : (
            <></>
          )}

          {kpiTargetPercentage ? (
            <TabContent header={t('kpiTargets')}>
              <KpiTargetPercentageSettingTab control={control} kpiTargetConfiguration={kpiTargetPercentage} />
            </TabContent>
          ) : (
            <></>
          )}
        </DashboardTabView>
      </form>
    </ModalDialog>
  );
}
