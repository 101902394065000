import React, { useState } from 'react';
import { HfToggleButton } from '@myosh/odin-components';
import { t } from 'i18next';
import { Control } from 'react-hook-form';
import DecimalConfiguration from './DecimalConfiguration';
import { LabelFormatType } from '../widget-settings-dialog';

interface DisplayRollingProps {
  displayRollingLine: boolean;
  control: Control;
  labelDecimals?: number;
  watchLabelFormat?: LabelFormatType[];
}

export default function DisplayRollingLineValues({
  displayRollingLine,
  control,
  labelDecimals,
  watchLabelFormat,
}: DisplayRollingProps) {
  const [rollingToggle, setRollingToggle] = useState<boolean>(displayRollingLine);

  return (
    <>
      <label className="block pb-1 text-sm font-bold">{String(t('displayRollingLine'))}</label>
      <HfToggleButton name="displayRollingLine" control={control} checked={rollingToggle} onChange={setRollingToggle} />

      {rollingToggle && (
        <DecimalConfiguration
          control={control}
          label={`${t('display')} ${t('decimalPlaces')}`}
          labelDecimals={labelDecimals}
          labelFormat={watchLabelFormat}
          name="displayDecimalPlaces"
        />
      )}
    </>
  );
}
