import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  fallbackLng: 'en_au',
  resources: {
    en_au: {
      translation: {
        apply: 'Apply',
        applyFilter: 'Apply Filter',
        title: '{{- title}}',
        coming_soon: 'Coming soon',
        noData: 'No data to display',
        loading: 'Loading...',
        anErrorOccurred: 'An error occurred. Please try again later!',
        fetchError: `FETCH ERROR There was error fetching your data! Please try again latter!`,
        settings: 'Settings',
        export: 'Export',
        exportAsExcel: 'Export as Excel',
        exportAsPDF: 'Export as PDF',
        exportRecords: 'Export Records',
        exportTab: 'Export Tab',
        configuration: 'Configuration',
        close: 'Close',
        widgetConfiguration: 'Widget Configuration',
        widgetTitle: 'Widget Title',
        widgetType: 'Widget Type',
        globalFilters: 'Global Filters',
        timeFrequency: 'Time frequency',
        visibleColumns: 'Visible Columns',
        dataColumns: 'Data Columns',
        kpiTargets: 'KPI Targets',
        search: 'Search...',
        typeToSearch: 'Type to search...',
        riskReduction: 'Risk Reduction %',
        displayLegend: 'Display Legend',
        yes: 'Yes',
        no: 'No',
        showArchivedHierarchies: 'Show Archived Hierarchies',
        colors: 'Colors',
        colours: 'Colours',
        myoshColors: 'MyOSH Colors',
        customiseDataSeries: 'Customise data series:',
        newTab: 'New Tab',
        labelFormatting: 'Label Formatting',
        labelPlacement: '{{- type}} Label Placement',
        lineLabelFormatting: 'Line Label Formatting',
        percentage: 'Percentage',
        values: 'Values',
        decimalPlaces: 'Decimal Places',
        roundedToWholeNumber: 'Round to whole number',
        oneDecimalPlace: 'One decimal place',
        twoDecimalPlace: 'Two decimal places',
        dataCategoriesToShow: 'Data categories to show',
        all: 'all',
        top5: 'top 5',
        top10: 'top 10',
        top30: 'top 30',
        save: 'Save',
        addWidget: 'Add widget',
        discard: 'Discard',
        edit: 'Edit',
        hideKpi: 'Hide KPI',
        onFRAxis: 'Same Axis',
        legendLabel: 'Legend Label',
        targetValue: 'Target value',
        lineColour: 'Line colour',
        kpiMessage:
          'Please note that if you use a KPI module then adding values here will take precedence over values configured in your KPI Module',
        saveChangesMessage: 'Do you want to save your changes?',
        headerChangesMessage: 'This record has unsaved changes',
        editTab: 'Edit Tab',
        delete: 'Delete',
        deleting: 'Deleting...',
        deleteWidget: 'Delete Widget',
        confirmDelete: 'Are you sure you want to delete this widget?',
        deleteTab: 'Delete tab?',
        confirmDeleteTab: 'Are you sure you want to delete tab {{tabName}}?',
        doubleWidth: 'Double Width',
        singleWidth: 'Single Width',
        doubleHeight: 'Double Height',
        singleHeight: 'Single Height',
        error401: 'Your session has expired, please sign in again.',
        dashboardConfig: 'DASHBOARD CONFIGURATION',
        availableWidgets: 'Available widgets',
        availableDataColumn: 'Available Data Columns',
        dataColumnsMessage:
          'Columns selected will be displayed based on actual value. If there`s no such value for selected records, then it will not display the corresponding column.',

        about: 'About',
        confirmDeleteWidgetGlobal: 'Are you sure you want to delete widget',
        preferableAction: 'What would you like to do?',
        deleteAWidget: 'Delete a widget',
        manageWidgets: 'Manage widgets',
        finishCAPS: 'FINISH',
        cancelCAPS: 'CANCEL',
        chooseChart: 'Choose chart',
        wizardStep1: 'Choose Widget',
        wizardStep1Description:
          'Use this wizard to create your own widget, and edit or delete widgets previously created.',
        widgetWizard: 'Widget Wizard',
        wizardStep2: 'Configure',
        wizardStep2Description:
          'Enter a title and choose the type of widget, then select the module and form to use. If you choose a chart you must choose which field should be used to generate the data series items. A second field can be specified for some chart types, which will result in a stacked chart.',
        wizardStep3: 'Filters',
        wizardStep3Description:
          'This step is optional. Select one field to set as a custom filter field. It will be available on the filter icon popup. You may also set a global filter if you wish to permanently restrict the records displayed in the widget, for eg: only "active" records. * The limit of selected custom or global filters is 6.',
        wizardStep4: 'Settings',
        wizardStep4Description:
          "Add any special configuration required for the widget. You can return to this step later by choosing 'configure' from the widget settings menu.",
        backCAPS: 'BACK',
        nextCAPS: 'NEXT',
        selectWidget: 'Select a widget',
        displayRollingLine: 'Display Rolling Line Values',
        display: 'Display',
        dynamicColours: 'Dynamic Colours:',
        defaultColumnColour: 'Default column colour',
        changeColourIfThresholdIsExceeded: 'Change colour if threshold is exceeded',
        thresholdValue: 'Threshold value',
        permissions: 'Permissions',
        permissionsForTab: 'Permissions for {{title}} tab',
        sharedTab: 'Shared Tab',
        readPermissions: 'Read Permissions',
        readPermissionsHint: 'Read permission is automatically granted if modify permission is selected',
        modifyPermissions: 'Modify Permissions',
        cancel: 'Cancel',
        module: 'Module',
        form: 'Form',
        field: 'Field',
        fieldYAxis: 'Field for y-axis',
        secondField: 'Second Field',
        fieldXAxis: 'Field for x-axis',
        enterTitleWidget: 'Enter title for the widget',
        createAWidget: 'Create a widget',
        editAWidget: 'Edit a widget',
        customFilter: 'Custom Filter',
        notBlank: 'Not Blank',
        privateDropOnly: 'Selected tab is private and can only be moved within the private tabs group',
        publicDropOnly: 'Selected tab is shared and can only be moved within the shared tabs group',
        monthly: 'Monthly',
        next12Months: 'Forthcoming 12 months',
        yearly: 'Yearly',
        firstColumnSortOrder: 'First Column Sort Order:',
        ascending: 'Ascending',
        descending: 'Descending',
        hierarchyLocationForMap: 'The hierarchy location used for the map:',
        enableCustomSorting: 'Enable custom sorting',
        globalFilter: 'Global Filter',
        top: 'Top',
        middle: 'Middle',
        middleDefault: 'Middle (Default)',
        tryAgain: 'Click here to reload this widget.',
        exportFailed: 'Export Failed',
        exportInProgress: 'Export In Progress',
        exportComplete: 'Export Complete',
        excludeArchivedRecords: 'Exclude archived records',
        ignoreDateFilters: 'Ignore date range filter',
        ignoreDateFiltersTooltip:
          'Enabling this option will mean that filtering by date using the overall dashboard date filter will not be applied for this particular widget',
        maxWidgetsTabError: 'You have reached the maximum number of widgets (8) allowed on a tab.',
        noWidgetForId: 'No widget with the id of {{widgetId}} found!',
        nameNotConfigured: 'NAME NOT CONFIGURED',
        noLayoutForId: 'No layout with the id of {{layoutId}} found!',
        addView: 'Add View',
        deleteView: 'Delete View',
        manageView: 'Manage View',
        manageViews: 'Manage Views',
        view: 'View',
        restoreCurrentView: 'Restore Current View',
        saveCurrentView: 'Save Current View',
        selectView: 'Select View',
        deleteViewSuccess: 'View was deleted',
        manageExistingViews: 'Manage existing views',
        selectAction: 'Select action',
        globalView: 'Global view',
        viewCreated: 'View was created',
        nameRequired: 'Name must be specified',
        columnMustBeSelected: 'At least one column must be selected',
        availablePermissions: 'Available permissions',
        readRoles: 'Read Roles',
        modifyRoles: 'Modify Roles',
        selectedPermissions: 'Selected Permissions',
        updateWidgetFailed: "Widget's update failed! {{details}}",
        updateWidgetSuccess: 'Widget was updated',
        updateViewSuccess: 'View was updated!',
        updateViewFailure: 'Updating view failed!',
        defaultErrorUpdate: 'Default view is read-only!',
        configure: 'Configure',
        done: 'Done',
        deleteWidgetSuccess: 'Widget was deleted',
        deleteWidgetFailure: 'Deleting widget failed!',
        thisWidgetWillBeDeleted: 'This widget will be deleted',
        doYouWantToContinue: 'Do you want to continue?',
        softwareName: 'myosh Safety Management Software',
        mapAttribution: "'Map widget is powered by Leaflet.'",
      },
    },
  },
});
