import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/view-management.css';
import { useCreateViewMutation, useLazyGetViewQuery } from '../../../redux/config/views';
import { useUser } from '@myosh/myosh-login';
import { useGetAllViewsQuery } from '../../../redux/config/views';
import ViewDropdown, { ViewDropdownRef, ViewNameOption } from './view-dropdown';
import { useAppDispatch } from '../../../redux/hooks';
import { setSelectedView } from '../../../redux/slices/selected-view';
import { useDefaultDateRange } from '../../../hooks/useDefaultDateRange';
import { convertToGlobalFilterDetails } from '../../../context/globalDateRange.context';
import { customDateRangeFlag, quickDateRange } from '../utilities';
import { useConfiguredDateRanges } from '../../../hooks/useConfiguredDateRanges';
import { getStorageItem } from '../../../helpers/storage';
import { removeGlobalDateFilters } from '../../../redux/slices/globalFilter/global-dates-filter-slice';

export interface ManageViewsModalProps {
  visible: boolean;
  onClose: () => void;
  viewSaved?: () => void;
}

export const ViewHandlerComponent = () => {
  const { data, isFetching, isLoading } = useGetAllViewsQuery();
  const [viewsOptions, setViewOptions] = useState<Array<ViewNameOption>>([]);
  const ref = useRef<ViewDropdownRef>(null);
  const navigateToView = useRef<boolean>(true);
  const user = useUser();
  const defaultDateRange = useDefaultDateRange();
  const dateRanges = useConfiguredDateRanges();

  const [addView] = useCreateViewMutation();
  const [getView] = useLazyGetViewQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data && !isFetching) {
      const viewOptions = data.map((view) => {
        return {
          text: view.name,
          value: view.id,
          defaultView: view.defaultView,
          ...view,
        };
      });
      setViewOptions(viewOptions);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (viewsOptions && dateRanges) {
      if (navigateToView.current) {
        const lastelyUsedView = getStorageItem('lastUsedView', 'sessionStorage');
        const defaultView = viewsOptions.find((view) => view.defaultView);
        const lastUsedView = lastelyUsedView && viewsOptions.find((view) => view.value === lastelyUsedView['id']);
        const id = lastUsedView?.value ?? defaultView?.value;

        if (id) {
          updateSelectedView(id);
        }
      }
      navigateToView.current = true;
    }
  }, [viewsOptions, dateRanges]);

  /** Adds a default view for a user, if there are none exist in its views. */
  useEffect(() => {
    if (
      !isLoading &&
      defaultDateRange &&
      (data?.length === 0 || (data && data.find((view) => view.defaultView) === undefined))
    ) {
      const dates = convertToGlobalFilterDetails(defaultDateRange);
      addView({
        name: 'Default',
        privateView: false,
        defaultView: true,
        hierarchyFilters: {},
        userId: user.state.user?.prefered_username ?? '',
        dateFilterRangeType: String(dates?.quickDateRange.value),
      });
    }
  }, [data, isLoading, defaultDateRange]);

  const newViewName = useRef<string>();

  const updateSelectedView = (viewId?: string) => {
    viewId && ref.current?.setSelectedView(viewId);
    const view = data?.find((view) => view.id === viewId);
    newViewName.current = view?.name ?? '';
    if (view) {
      getView(view.id)
        .unwrap()
        .then((result) => {
          dispatch(removeGlobalDateFilters());
          const predefinedRange = result?.dateFiltersForView
            ? dateRanges?.find((range) => range.id === result.dateFiltersForView?.dateFilterRangeType)
            : //if no date filters are supplied, use explicitly the dault ones
              defaultDateRange;
          if (predefinedRange) {
            const convertedDate = convertToGlobalFilterDetails(predefinedRange);
            dispatch(
              setSelectedView({
                ...result,
                quickDateRange: convertedDate?.quickDateRange,
                dateFiltersForView: {
                  fromDate: convertedDate?.dateStart,
                  toDate: convertedDate?.dateEnd,
                  dateFilterRangeType: predefinedRange.id,
                },
              })
            );
            //there are some dates returned for a view
          } else {
            dispatch(
              setSelectedView({
                ...result,
                quickDateRange: quickDateRange,
                dateFiltersForView: {
                  ...result.dateFiltersForView,
                  dateFilterRangeType: customDateRangeFlag,
                },
              })
            );
          }
        });
    }
  };

  return (
    <>
      <div className="ml-2 flex flex-row items-end gap-2">
        <ViewDropdown viewOptions={viewsOptions} updateSelectedView={updateSelectedView} ref={ref} />
      </div>
    </>
  );
};
