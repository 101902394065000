export enum SelectionStackTypes {
  COMBOBOX = 'COMBOBOX',
  MULTISELECTCHECKBOX = 'MULTISELECTCHECKBOX',
  CHECKBOX = 'CHECKBOX',
  RISKRATING = 'RISKRATING',
  MULTISELECTCOMBOBOX = 'MULTISELECTCOMBOBOX',
}

export enum BaseStackTypes {
  TEXT = 'TEXT',
}
export enum DateRangeTypes {
  DATETIME = 'DATETIME',
}
