import React from 'react';
import { labelListStyle } from '../../constants/line-label-styles';
import { ToolTipElement } from '../../shared/widget-data.interfaces';

export const renderCustomizedLineLabel = ({ x, y, value }: ToolTipElement) => {
  if (value) {
    const roundedValue = isNaN(Number(value)) ? value : Number(value).toFixed(1);
    return (
      <text x={x} y={y - 10} style={labelListStyle}>
        <title>{value}</title>
        {roundedValue}
      </text>
    );
  }
};
