// Helper function to set an item in storage
const setStorageItem = (key: string, value: string, storageType = 'localStorage') => {
  try {
    const storage = window[storageType as keyof typeof window];
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error setting item '${key}' in ${storageType}:`, error);
  }
};

// Helper function to get an item from storage
const getStorageItem = (key: string, storageType = 'localStorage') => {
  try {
    const storage = window[storageType as keyof typeof window];
    const item = storage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error getting item '${key}' from ${storageType}:`, error);
    return null;
  }
};

// Helper function to remove an item from storage
const removeStorageItem = (key: string, storageType = 'localStorage') => {
  try {
    const storage = window[storageType as keyof typeof window];
    storage.removeItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error removing item '${key}' from ${storageType}:`, error);
  }
};

export { setStorageItem, getStorageItem, removeStorageItem };
