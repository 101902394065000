import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNewTabState } from '../../../redux/slices/new-tab-slice';

export function useNewTabId() {
  const [tabId, setNewTabId] = useState<string | undefined>();

  const newTabState = useSelector(selectNewTabState);

  useEffect(() => {
    setNewTabId(newTabState.tabId);
  }, [newTabState]);

  return tabId;
}
