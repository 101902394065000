import { useEffect, useState } from 'react';
import { useGetMetadataQuery } from '../redux/config/metadataConfig';
import { DateRange } from '../components/Header/Header.interface';
import { useConfiguredDateRanges } from './useConfiguredDateRanges';

export const useDefaultDateRange = () => {
  const { data: metadataInfo, isLoading: metaInfoLoading } = useGetMetadataQuery(undefined);
  const dateRanges = useConfiguredDateRanges();
  const [defaultDateRange, setDefaultDateRange] = useState<DateRange>();

  useEffect(() => {
    if (metadataInfo && dateRanges && !metaInfoLoading) {
      const defaultGlobalFilter = metadataInfo.defaultDateRange;
      setDefaultDateRange(dateRanges.find((dateTab) => dateTab.caption === defaultGlobalFilter));
    }
  }, [dateRanges, metadataInfo, metaInfoLoading]);

  return defaultDateRange;
};
