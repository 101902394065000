import { useEffect, useState } from 'react';
import { useGetUserQuery } from '../redux/config/users-api';

export function useLayoutAdmin(): boolean {
  const [isLayoutAdmin, setIsLayoutAdmin] = useState<boolean>(false);
  const { data: userInfo } = useGetUserQuery();
  useEffect(() => {
    setIsLayoutAdmin(userInfo?.layoutAdmin ?? false);
  }, [userInfo]);

  return isLayoutAdmin;
}
