import React from 'react';
import ConfigPaletteWindow from '../WidgetSettingWindow/components/config-palette.component';
import { CustomColorProps } from '../WidgetSettingWindow/components/config-components.interfaces';

interface SingleColorProps {
  text?: string;
  className?: string;
  fillColor: string | undefined;
  onClick: () => void;
  paletteVisible: boolean;
  selectedItem?: CustomColorProps;
  updateColor: (newColor: CustomColorProps) => void;
  onPaletteClosed: () => void;
}

const SingleColorSelectorComponent = ({
  text,
  onClick,
  className,
  fillColor,
  paletteVisible,
  selectedItem,
  updateColor,
  onPaletteClosed,
}: SingleColorProps) => {
  return (
    <>
      <span className="pb-1 text-sm font-normal">{text}</span>
      <div className="pl-2">
        <svg
          width="25"
          height="25"
          style={{ minWidth: 24 }}
          onClick={onClick}
          className={className ?? 'cursor-pointer'}
        >
          <rect
            width="24"
            height="24"
            style={{
              fill: fillColor,
            }}
          />
        </svg>
      </div>

      <ConfigPaletteWindow
        visible={paletteVisible}
        selectedItem={selectedItem}
        updateColor={updateColor}
        onPaletteClosed={onPaletteClosed}
      />
    </>
  );
};

export default SingleColorSelectorComponent;
