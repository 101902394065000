import React from 'react';
import {
  DropDown,
  DynamicFormRequiredType,
  isDropDownNoSelection,
  isDropDownResult,
  OdinController,
} from '@myosh/odin-components';
import { t } from 'i18next';
import { WidgetValueAndSetter } from '../../types/widget-value-and-setter';
import { ModuleFormType } from '../../../../redux/config/field-types-api';

export const WidgetModuleComponent = ({
  value,
  setValue,
  modulesData,
  control,
}: WidgetValueAndSetter<ModuleFormType>) => {
  return (
    <div className="my-1">
      <OdinController
        name="module"
        control={control}
        render={({ field: { onChange } }) => (
          <DropDown
            label={t('module')}
            required={DynamicFormRequiredType.True}
            data={modulesData}
            textField="caption"
            valueField="id"
            onChange={(value) => {
              if (isDropDownResult(value)) {
                setValue({
                  id: String(value.value),
                  name: value.text,
                  caption: value.text,
                });
                onChange(value);
              } else if (isDropDownNoSelection(value)) {
                setValue(undefined);
                onChange(value);
              }
            }}
            value={{ text: value?.caption ?? '', value: value?.id }}
          />
        )}
      />
    </div>
  );
};
