import React from 'react';
import { Loader } from '@myosh/odin-components';
import { t } from 'i18next';

export const LoaderComponent = () => {
  return (
    <div className="loaderComponent no-data w-full text-center ">
      <div className=" flex h-full flex-col justify-center ">
        <Loader size="sm" title={t('loading')} />
      </div>
    </div>
  );
};
