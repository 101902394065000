import { useMemo, useState } from 'react';
import { DataKPI } from '../../../redux/config/chart-data';

import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { getKpiDomainValue } from '../generalFunctions/get-domaincharts';

export function useKPIDomainValue(configurationData?: WidgetConfigurationData, transformedData?: DataKPI[]) {
  const [kpiDomain, setKPIDomain] = useState<[number, number] | undefined>();

  useMemo(() => {
    if (transformedData && configurationData) {
      //max value is already rounded
      const { max: maxValue, min: minValue } = getKpiDomainValue(transformedData, configurationData);
      const widgetConfig = configurationData.widgetConfig;
      if (widgetConfig) {
        const maxScale = widgetConfig.yScaleMax ? Math.min(widgetConfig.yScaleMax, maxValue) : maxValue;
        setKPIDomain([minValue, maxScale]);
      } else {
        setKPIDomain([minValue, maxValue]);
      }
    }
  }, [transformedData, configurationData]);

  return kpiDomain;
}
