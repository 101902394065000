import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LoaderComponent } from '../components/common/loading-spinner.component';
import { t } from 'i18next';
import { LayoutBasicProps, LayoutWidget } from '../redux/config/layout';
import { setLayoutWidgets } from '../redux/slices/layout-tabs-slice';
import { useAppDispatch } from '../redux/hooks';
import { TabWidget } from '../components/LayoutTab/tab-widget.component';
import { useGetLayoutWidgetQuery } from '../redux/config/layout-widget';

export const IndividualTabPage = () => {
  const { layoutId } = useParams();
  const { data, isLoading } = useGetLayoutWidgetQuery(layoutId ?? '', { skip: !layoutId });

  const layout = useMemo<LayoutBasicProps | undefined>(() => {
    if (layoutId) {
      return {
        id: layoutId,
        canAdmin: false,
        canModify: false,
        isUserLayout: false,
        canView: true,
      };
    }
  }, [layoutId]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setLayoutWidgets(data));
    }
  }, [data]);

  const sortByPosition = useCallback((arr: LayoutWidget[]) => {
    return Array.from(arr).sort((a, b) => a.position - b.position);
  }, []);

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <LoaderComponent />
    </div>
  ) : data && layout ? (
    <div className="custom-scroll flex h-full flex-wrap overflow-y-auto" data-tab={layout.id} key={layout.id}>
      {sortByPosition(data)?.map((layoutWidget) => (
        <TabWidget layoutWidget={layoutWidget} layout={layout} key={layoutWidget.id} />
      ))}
    </div>
  ) : (
    <div className="flex h-full w-full flex-auto items-center justify-center text-xl text-info">
      {String(t('noLayoutForId', { layoutId: layoutId }))}
    </div>
  );
};
