import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type EditableType = {
  isEditable: false;
};

const initialState: EditableType = {
  isEditable: false,
};

const appEditableState = createSlice({
  name: 'editableState',
  initialState,
  reducers: {
    setEditState(state, action) {
      state.isEditable = action.payload;
    },
  },
});

export default appEditableState.reducer;
export const appEditableActions = {
  ...appEditableState.actions,
};
export const selectEditableState = (state: RootState) => state.rootReducer.appEditability;
