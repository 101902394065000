import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './api';

export interface WorkflowStepsProps {
  result: Array<DynamicFormWorkflowStep>;
  validation?: Record<string, unknown>;
}

export interface DynamicFormWorkflowStep {
  id: number;
  label: DataCaption;
  backgroundColor?: string;
}

export interface ExtendedDynamicFormWorkflowStep {
  id: number;
  label: string;
  backgroundColor?: string;
}
export interface DataCaption {
  translations: Array<DataTranslation>;
}
export interface DataTranslation {
  localeId: number;
  value: string;
}

export const workFlowStepsApi = createApi({
  reducerPath: 'workFlowStepsApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, { ...extraOptions, apiType: 'apiV4Url' }),
  endpoints: (builder) => ({
    getWorkflowSteps: builder.query<Array<ExtendedDynamicFormWorkflowStep>, string | Record<string, unknown>>({
      query: (args: { formId: string }) => ({
        url: `/forms/${args.formId}/workflow-steps`,
        method: 'GET',
      }),
      transformResponse: (response: WorkflowStepsProps) => {
        return processWorkflowSteps(response.result);
      },
    }),
  }),
});

export const { useGetWorkflowStepsQuery } = workFlowStepsApi;

export function processWorkflowSteps(steps: Array<DynamicFormWorkflowStep>) {
  const convertedWorkflowSteps: Array<ExtendedDynamicFormWorkflowStep> = [];

  for (let i = 0, length = steps.length; i < length; i++) {
    const currentStep = steps[i];

    convertedWorkflowSteps.push({
      id: currentStep.id,
      label: currentStep.label.translations[0].value,
      backgroundColor: currentStep.backgroundColor,
    });
  }

  return convertedWorkflowSteps;
}
