export const resetChildrenPosition = (parentElement: HTMLElement | null, attribute = 'data-position') => {
  if (!parentElement) {
    return;
  }
  const children = Array.from(parentElement.children);
  children.sort((a, b) => {
    return Number(a.getAttribute(attribute)) - Number(b.getAttribute(attribute));
  });
  const fragment = document.createDocumentFragment();
  children.forEach((node) => fragment.appendChild(node));
  parentElement.appendChild(fragment);
};
