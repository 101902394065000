import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@myosh/odin-components';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { appEditableActions } from '../../redux/slices/editable-view-slice';
import { useCreateLayoutMutation } from '../../redux/config/layout';
import { newTabStateActions } from '../../redux/slices/new-tab-slice';
import { removeWidgetsReorder, selectWidgetChart } from '../../redux/slices/widgetChart/WidgetChartSlice';
import ConfirmationWindow from '../common/confirmation-window';
import { saveChanges } from '../../redux/slices/editability/save-edit-changes';
import CustomWidgetsAdderComponent from '../common/custom-widgets-adder.component';
import { headerButtonsStyle } from '../../constants/common-style-constants';
import { useUpdatedTabsOrder } from '../Charts/hooks/useUpdatedTabsOrder';
import { useLayoutAdmin } from '../../hooks/useIsLayoutAdmin';
import { WidgetConfigComponent } from '../TopMenu/widget-config-component';

export const ToggleEditComponent = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showAddWidget, setShowAddWidget] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showWidgetConfig, setShowWidgetConfig] = useState<boolean>(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);

  const [createLayout] = useCreateLayoutMutation();
  const widgetsOrder = useSelector(selectWidgetChart).widgetsOrder;
  const globalTabReorder = useUpdatedTabsOrder();
  const isLayoutAdmin = useLayoutAdmin();

  useEffect(() => {
    setSaveButtonEnabled(isEdit && (!isEmpty(globalTabReorder) || !isEmpty(widgetsOrder)));
  }, [globalTabReorder, widgetsOrder, isEdit]);
  const dispatch = useDispatch();

  const onResetPosition = () => {
    if (!isEmpty(widgetsOrder)) {
      dispatch(removeWidgetsReorder());
    }
    dispatch(saveChanges(false));
  };

  const handleEditModeChange = () => {
    const isEditing = !isEdit;
    setIsEdit(isEditing);
    dispatch(appEditableActions.setEditState(isEditing));
    if (!isEditing) {
      onResetPosition();
    } else {
      dispatch(saveChanges(undefined));
    }
  };

  const createNewTab = useCallback(async () => {
    createLayout({
      title: 'New Tab',
    })
      .unwrap()
      .then((id) => {
        dispatch(newTabStateActions.setNewTabId(id));
      });
  }, []);

  function onSaveHandler() {
    if (!isEmpty(widgetsOrder)) {
      setIsEdit(false);
      dispatch(appEditableActions.setEditState(false));
    }
    dispatch(saveChanges(true));
    setShowSaveModal(false);
  }

  function saveClicked() {
    const confirmationRequired = !isEmpty(widgetsOrder);
    setShowSaveModal(confirmationRequired);
    if (!confirmationRequired && !isEmpty(globalTabReorder)) {
      dispatch(saveChanges(true));
      onHideModal();
    }
  }

  function onHideModal() {
    setShowSaveModal(false);
    if (isEdit) {
      setIsEdit(false);
      dispatch(appEditableActions.setEditState(false));
    }
    if (!isEmpty(widgetsOrder)) {
      dispatch(removeWidgetsReorder());
    }
  }

  const saveButtonStyles = classNames(
    'h-7 flex-none rounded px-3 font-medium leading-3 text-mono-1 transition-colors',
    { 'bg-primary-2 duration-200 hover:bg-primary-3': saveButtonEnabled },
    { hidden: !saveButtonEnabled }
  );

  return (
    <div className="flex flex-row items-center">
      {isEdit && (
        <div className="flex gap-1">
          <button className={saveButtonStyles} onClick={saveClicked}>
            {String(t('save'))}
          </button>

          <button className={headerButtonsStyle} onClick={() => setShowAddWidget(true)}>
            {String(t('addWidget'))}
          </button>

          <button onClick={createNewTab} className={`${headerButtonsStyle} ${!isLayoutAdmin && 'mr-3'}`}>
            {String(t('newTab'))}
          </button>
          {isLayoutAdmin && (
            <button onClick={() => setShowWidgetConfig(true)} className={`${headerButtonsStyle} mr-3`}>
              {String(t('manageWidgets'))}
            </button>
          )}
        </div>
      )}
      <Button onClick={handleEditModeChange} type={isEdit ? 'primary' : 'default'} fullWidth={false} htmlType="button">
        {!isEdit ? String(t('configure')) : String(t('done'))}
      </Button>

      <CustomWidgetsAdderComponent
        hidden={() => setShowAddWidget(false)}
        setShowAddWidget={(visible) => setShowAddWidget(visible)}
        visible={showAddWidget}
      />
      <WidgetConfigComponent showWidgetConfig={showWidgetConfig} setShowWidgetConfig={setShowWidgetConfig} />
      <ConfirmationWindow
        onSaveHandler={onSaveHandler}
        showWindow={showSaveModal}
        discard={t('discard')}
        save={t('save')}
        text={t('saveChangesMessage')}
        title={t('headerChangesMessage')}
        onDiscardChanges={{
          onHideModal: onHideModal,
          onDiscard: onResetPosition,
        }}
      />
    </div>
  );
};
