import { format, isValid, parse } from 'date-fns';
import { isEqual, trim } from 'lodash';

export const apiDateFormat = 'yyyy-MM-dd';

export function formatDate(date: string | Date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

/**
 * Helper method to extact date value from a string that contains comparision characters.
 * It's expected that date passed in a string is also enclosed in brackets. For instance,
 * when {@code `$gte(2021-12-03)`} is received, the expected output is {@code `2021-12-03`}.
 * If expectations not met, return the original string.
 *
 * @param filterText a date string represention containing comparision characters ($gte and $lte)
 * @returns sanitized date string or original string if not a date and no comparision charactes found
 */
export function removeComparisionCharacters(filterText: string): string {
  const greaterThan = '$gte(';
  const lessThan = '$lte(';
  const dateRegex = /(\d{4}-\d{2}-\d{2})/;
  const matches = isValidDate(filterText, dateRegex) ? filterText.match(dateRegex) : undefined;
  if ((filterText.includes(greaterThan) || filterText.includes(lessThan)) && matches) {
    return matches[1];
  }
  return filterText;
}

export const getAssignedDatesFromFilter = (filterValues: Array<string>) => {
  return [...filterValues]
    .map((value) => removeComparisionCharacters(value))
    .sort((value1, value2) => value1.localeCompare(value2));
};

export function isValidDate(date: string, format: RegExp) {
  return format.test(date);
}

/**
 *
 * @param date value to be formatted
 * @param greaterThan defines if greater than comparision charactes are to be appended
 * @param formatType to format with
 * @returns formatted string containing date wrapped in the cells and currounded by comparision characters
 */
export const formatDateAppendComparision = (date: Date, greaterThan: boolean, formatType = apiDateFormat): string => {
  if (greaterThan) {
    return `$gte(${format(date, formatType)})`;
  }
  return `$lte(${format(date, formatType)})`;
};

/**
 *
 * @param date string value containing a date
 * @param formatType of the date in the `date` parameter
 * @returns a date object extracted from the string
 */
export const formatFromDate = (date: string, formatType: string): Date => {
  return parse(trim(date), formatType, new Date());
};

export const equalsToday = (date: string): boolean => {
  const formatType = 'PP';
  const convertedDate = new Date(date);
  const now = format(new Date(), formatType);
  return isValid(convertedDate) ? isEqual(format(convertedDate, formatType), now) : false;
};

export const appendTimeUnit = (date: string): string => {
  return `${date}T00:00:00`;
};
