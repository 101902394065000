import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WidgetShowConfigType } from './widget-config-type';
import { isEmpty, pickBy } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import {
  DynamicFormRequiredType,
  Input,
  JsonDataItem,
  OdinController,
  DropDownResult,
  HfInput,
} from '@myosh/odin-components';
import { getTranslatedWidgetFrequencyTypes, WidgetType, widgetTypes } from '../types/widget-type';
import { WidgetTypeComponent } from './step-two/widget-type-component';
import { WidgetModuleComponent } from './step-two/widget-module-component';
import { WidgetFieldComponent } from './step-two/widget-field-component';
import { WidgetFormComponent } from './step-two/widget-form-component';
import { WidgetSecondFieldComponent } from './step-two/widget-second-field-component';
import {
  ModuleFormType,
  useGetFieldTypesQuery,
  useGetModuleFormsQuery,
  VisibleFieldType,
} from '../../../redux/config/field-types-api';
import { useGetModulesQuery } from '../../../redux/config/get-modules-api';
import WidgetTimeFrequency from './step-two/widget-time-frequency';
import { kpiTargetPercentage } from '../../WidgetSettingWindow/widget-settings-dialog';

type FORM_SETTING = 'WIDGET_TYPE' | 'MODULE' | 'FORM' | 'FIRST_FIELD' | 'SECOND_FIELD';

interface SecondTabConfigProps extends WidgetShowConfigType {
  setFieldTypeData: (data: Array<VisibleFieldType>) => void;
}
export const WizardTabSecond = ({
  selectedChart,
  setEnableFinishButton,
  setEnableNextButton,
  closeOverlays,
  isActive,
  finishClicked = false,
  widgetConfigs,
  setFieldTypeData,
}: SecondTabConfigProps) => {
  const [widgetTitle, setWidgetTitle] = useState<string>();
  const [widgetType, setWidgetType] = useState<WidgetType>();
  const [module, setModule] = useState<ModuleFormType>();
  const [form, setForm] = useState<ModuleFormType>();
  const [field, setField] = useState<string>();
  const [secondField, setSecondField] = useState<string>();
  const [timeFrequency, setTimeFrequency] = useState<DropDownResult>();
  const [kpiTargetForColumnKpiPercentage, setKpiTargetForColumnKpiPercentage] = useState<kpiTargetPercentage>();
  const widgetTimeFrequencyTypesRef = useRef<Array<JsonDataItem>>(getTranslatedWidgetFrequencyTypes());
  const { control } = useFormContext();
  const { data: modulesData } = useGetModulesQuery();

  const { data: moduleFormsData } = useGetModuleFormsQuery(Number(module?.id), { skip: !module });

  const formValue = useMemo(() => {
    if (moduleFormsData) {
      const formDataInfo: string | undefined = moduleFormsData.find((el) => el?.caption === form?.caption)?.id;
      if (formDataInfo) {
        return [formDataInfo];
      }
    }
    return undefined;
  }, [moduleFormsData, form]);
  const { data: fieldTypeData } = useGetFieldTypesQuery(
    {
      module: String(module?.id),
      form: formValue,
    },
    {
      skip: !module || !form || !formValue,
    }
  );

  useEffect(() => {
    if (fieldTypeData) {
      setFieldTypeData(fieldTypeData);
    }
  }, [fieldTypeData]);

  const requiredFieldsFilled = useMemo(() => {
    const isChartTypeSupported = widgetType?.type.typeValue === 'TABLE' || widgetType?.type.typeValue === 'MAP';
    const isChartFormTypeSupport = widgetTitle && widgetType && module && form;

    if (isChartTypeSupported) {
      return !!isChartFormTypeSupport;
    }
    return !!(isChartFormTypeSupport && field);
  }, [widgetTitle, widgetType, module, form, field]);

  //finish button can be enabled if all required fields are filled
  useEffect(() => {
    if (setEnableFinishButton && isActive) {
      setEnableFinishButton(requiredFieldsFilled);
    }
  }, [requiredFieldsFilled, isActive]);

  useEffect(() => {
    if (isActive) {
      if (requiredFieldsFilled) {
        setEnableNextButton(true);
        if (finishClicked) {
          if (!selectedChart) {
            // TODO create new widget type
          } else {
            //TODO update of the existing chart
          }
          closeOverlays();
        }
      } else {
        setEnableNextButton(false);
      }
    }
  }, [finishClicked, requiredFieldsFilled, isActive]);

  useEffect(() => {
    // Used when a new widget is to be created
    if (!selectedChart) {
      setWidgetTitle(undefined);
      setWidgetType(undefined);
      setModule(undefined);
      setForm(undefined);
      setField(undefined);
      setSecondField(undefined);
      setTimeFrequency(undefined);
      setKpiTargetForColumnKpiPercentage(undefined);
    } else if (widgetConfigs) {
      const widgetDataConfig = widgetConfigs.widgetData;

      setWidgetTitle(widgetConfigs.title);
      setWidgetType(widgetTypes.find((type) => type.type.typeValue === widgetConfigs.type));
      setModule(modulesData?.find((el) => el.id === widgetDataConfig.module));

      setField(widgetDataConfig.field);
      setSecondField(widgetDataConfig.secondField);

      setTimeFrequency(
        widgetTimeFrequencyTypesRef.current.find((el) => {
          if (el.type === widgetConfigs?.widgetData?.timeFrequency) {
            return {
              value: el.type,
              text: el.name,
            };
          }
        }) as unknown as DropDownResult
      );
      setKpiTargetForColumnKpiPercentage({
        ...kpiTargetForColumnKpiPercentage,
        ...widgetConfigs?.widgetConfig?.kpiTargetForColumnKpiPercentage,
      });
    }
  }, [widgetConfigs, modulesData]);

  useEffect(() => {
    const widgetDataConfig = widgetConfigs?.widgetData;
    if (widgetDataConfig) {
      const { forms, module: widgetModule } = widgetDataConfig;
      const isModuleMatch = module?.id === widgetModule;
      if (isModuleMatch && moduleFormsData && forms && !isEmpty(moduleFormsData) && !isEmpty(forms)) {
        setForm(moduleFormsData.find((el) => el.id === forms[0]));
      } else {
        setForm(undefined);
      }
    } else {
      setForm(undefined);
    }
  }, [moduleFormsData, widgetConfigs]);

  const resetFields = useCallback((updatedField: FORM_SETTING) => {
    let updateFollowing = false;
    if (updatedField === 'WIDGET_TYPE') {
      setModule(undefined);
      setKpiTargetForColumnKpiPercentage(undefined);
      updateFollowing = true;
    }
    if (updateFollowing || updatedField === 'MODULE') {
      setForm(undefined);
      setKpiTargetForColumnKpiPercentage(undefined);
      updateFollowing = true;
    }
    if (updateFollowing || updatedField === 'FORM') {
      setField(undefined);
      setSecondField(undefined);
      setTimeFrequency(undefined);
      setKpiTargetForColumnKpiPercentage(undefined);
    }
  }, []);

  const defaultKpi = pickBy(kpiTargetForColumnKpiPercentage, (v, k) => k === 'default' || k === '');
  const defaultValue = !isEmpty(defaultKpi)
    ? defaultKpi.default
      ? String(defaultKpi.default)
      : String(defaultKpi[''])
    : '0';

  return (
    <div className="flex flex-col justify-center">
      <div className="my-2">{String(t('wizardStep2Description'))}</div>
      <div className="widgetStepTwoFields my-2 justify-evenly">
        <OdinController
          name="title"
          control={control}
          render={({ field: { onChange } }) => (
            <Input
              id="widgetWizardTitleField"
              name="widgetWizardTitleField"
              value={widgetTitle}
              placeholder={t('enterTitleWidget')}
              label={t('widgetTitle')}
              onChange={(value) => {
                onChange(value);
                setWidgetTitle(value);
              }}
              required={DynamicFormRequiredType.True}
            />
          )}
        />

        <WidgetTypeComponent
          value={widgetType}
          setValue={(widgetType) => {
            setWidgetType(widgetType);
            resetFields('WIDGET_TYPE');
          }}
          control={control}
        />
        <WidgetModuleComponent
          value={module}
          setValue={(module) => {
            setModule(module);
            resetFields('MODULE');
          }}
          modulesData={modulesData}
          control={control}
        />
        <WidgetFormComponent
          value={form}
          setValue={(form) => {
            setForm(form);
            resetFields('FORM');
          }}
          moduleFormsData={moduleFormsData}
          control={control}
        />
        {widgetType?.field && (
          <WidgetFieldComponent
            widgetType={widgetType}
            value={field}
            setValue={setField}
            fieldData={fieldTypeData}
            control={control}
          />
        )}
        {widgetType?.secondField && (
          <WidgetSecondFieldComponent
            widgetType={widgetType}
            value={secondField}
            setValue={setSecondField}
            secondFieldData={fieldTypeData}
            control={control}
          />
        )}
        {widgetType?.timeFrequency && (
          <WidgetTimeFrequency value={timeFrequency} setValue={setTimeFrequency} control={control} />
        )}
        {widgetType?.type?.typeValue === 'CHART_COLUMN_KPI_PERCENTAGE' && (
          <HfInput
            control={control}
            name="kpiTargetForColumnKpiPercentage.default"
            label="Default"
            value={defaultValue}
            validations={[{ pattern: '^[0-9]*$', errorLabel: '! Integers only.' }]}
          />
        )}
      </div>
    </div>
  );
};
