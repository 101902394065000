import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getValueOrDefault } from '../helpers/getvalueOrDefault';

interface LoginQueryParams {
  username?: string;
  token?: string;
  schema?: string;
  isExport?: string;
  widget?: string;
  layout?: string;
  //yyyy-MM-dd
  to?: string;
  //yyyy-MM-dd
  from?: string;
  viewId?: string;
}

export const useLoginQueryParams = (): LoginQueryParams => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const username = useMemo(() => {
    return getValueOrDefault(params.get('username'), '');
  }, [params]);

  const token = useMemo(() => {
    return getValueOrDefault(params.get('token'), '');
  }, [params]);

  const schema = useMemo(() => {
    return getValueOrDefault(params.get('schema'), '');
  }, [params]);

  const isExport = useMemo(() => {
    return getValueOrDefault(params.get('export'), '');
  }, [params]);

  const layout = useMemo(() => {
    return getValueOrDefault(params.get('layout'), '');
  }, [params]);

  const widget = useMemo(() => {
    return getValueOrDefault(params.get('widget'), '');
  }, [params]);

  const to = useMemo(() => {
    return getValueOrDefault(params.get('to'), '');
  }, [params]);
  const from = useMemo(() => {
    return getValueOrDefault(params.get('from'), '');
  }, [params]);
  const viewId = useMemo(() => {
    return getValueOrDefault(params.get('viewId'), '');
  }, [params]);

  return {
    username,
    token,
    schema,
    isExport,
    widget,
    layout,
    to,
    from,
    viewId,
  };
};
