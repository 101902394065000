import { staticChartKeys } from './../../constants/static-chart-keys';

export function findSum(data: Record<string, unknown>[]) {
  const sumValues = (obj: Record<string, any>) => {
    const values = Object.entries(obj).filter((item) => !staticChartKeys.includes(item[0]));
    return values.reduce((a, b) => a + b[1], 0);
  };

  const result = data.map((obj) => {
    return {
      ...obj,
      value_column: sumValues(obj),
    };
  });

  return result;
}
