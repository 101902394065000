import { HfToggleButton } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { includeLegend } from '../../Charts/generalFunctions/general-functions';
import type { WidgetConfigsGenericWrapper } from '../../common/chart-generic-wrapper';

interface DisplayLegendProps {
  config: WidgetConfigsGenericWrapper;
  control: Control;
}

export default function LegendConfigurationElement({ config, control }: DisplayLegendProps) {
  const { t } = useTranslation();
  const widgetData = config?.widgetData;

  if (!!widgetData?.secondField) {
    return (
      <>
        <label className="block pb-1 text-sm font-bold">{t('displayLegend')}</label>
        <HfToggleButton name="displayLegend" control={control} checked={includeLegend(config)} />
      </>
    );
  }

  return null;
}
