import { HfInput } from '@myosh/odin-components';
import { t } from 'i18next';
import { isEmpty, pickBy } from 'lodash';

const KpiTargetPercentageSettingTab = ({ control, kpiTargetConfiguration }: any) => {
  const defaultKpi = pickBy(kpiTargetConfiguration, (v, k) => k === 'default' || k === '');
  // const otherKpis = omitBy(kpiTargetConfiguration, (v, k) => k === '' || k === 'default');

  const defaultValue = !isEmpty(defaultKpi)
    ? defaultKpi.default
      ? String(defaultKpi.default)
      : String(defaultKpi[''])
    : '0';

  return (
    <div className="flex flex-col gap-3.5">
      <span className="box-border inline-block whitespace-normal align-top text-sm font-light text-error">
        {String(t('kpiMessage'))}
      </span>

      <div className="flex flex-col gap-3.5">
        <span className="text-sm font-light">{String(t('targetValue'))}</span>
        {kpiTargetConfiguration && (
          <HfInput
            control={control}
            name="kpiTargetForColumnKpiPercentage.default"
            label="Default"
            value={defaultValue}
            validations={[{ pattern: '^[0-9]*$', errorLabel: '! Integers only.' }]}
          />

          // Commenting out temporarily for later requirement.
          // For now, only the default field above should be visible.
          // <div className="grid h-full grid-cols-3 gap-2">
          //   {Object.entries(otherKpis).map((value, index) => {
          //     return (
          //       <HfInput
          //         key={index}
          //         control={control}
          //         name={`kpiTargetForColumnKpiPercentage.${value[0]}`}
          //         label={value[0]}
          //         value={String(value[1]) || '0'}
          //         pattern="[0-9.]+"
          //       />
          //     );
          //   })}
          // </div>
        )}
      </div>
    </div>
  );
};

export default KpiTargetPercentageSettingTab;
