import { Key, useEffect, useMemo, useState } from 'react';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Scatter,
} from 'recharts';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { ChartProps, ColumnSeries } from './chart.interface';
import { LegendPayloadOnClick } from './chart-events';

import { roundHighest } from './generalFunctions/get-domaincharts';
import { includeLegend, intervalCounter } from './generalFunctions/general-functions';

import { CustomXAxis } from '../common/custom-X-axis.component';
import ChartGenericWrapper from '../common/chart-generic-wrapper';
import { labelOrNone } from '../common/formatter-label';
import { yAxisSettings } from '../common/y-axis-properties';
import { defaultLegendWrapperStyle, renderLegend } from '../common/custom-legend.component';
import { sortOrderStrings } from '../common/sort-order-strings';

import { DataExecutor } from '../../data-transformer/data-executor';
import { TransformDifferenceChartData } from '../../data-transformer/difference-chart/transform-difference-chart-data';

import useStartEndDates from './hooks/useStartEndDates';
import { useXAxisTopSettings } from './hooks/use-x-axis-top-settings';
import useInvalidateChartResponse from './hooks/useInvalidateChartResponse';

import { useChartDifferenceQuery } from '../../redux/config/chart-data';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { handleMouseEnter, handleMouseLeave } from '../../helpers/tooltip-functions';
import CustomTooltip from '../CustomToolTip/CustomToolTip';

import { MIN_COLUMN_BAR_HEIGHT } from '../../constants/widget-configuration';
import { getStatusColor } from '../../helpers/getStatusColor';
import { useTicksBasedOnScale } from './hooks/useTicksBasedOnScale';
import { useSelectedView } from './hooks/use-selected-view';

export default function OdinChartDifference({ widgetId, layoutProps }: ChartProps) {
  const [transformedData, setTransformedData] = useState<ColumnSeries[]>();
  const [legendProps, setLegendProps] = useState<Record<string, boolean>>({});
  const [secondFieldKeys, setSecondFieldKeys] = useState<Array<string>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();

  const { startDate, endDate } = useStartEndDates();
  const xAxisSettings = useXAxisTopSettings('field', 'category', false);
  const widgets = useSelector(getWidgets);
  const view = useSelectedView();

  const {
    data: widgetChartData,
    isLoading: isWidgetChartDataLoading,
    isFetching: isWidgetChartDataFetching,
    isError,
    error,
  } = useChartDifferenceQuery(
    {
      widgetId,
      from: startDate,
      to: endDate,
      viewId: view?.id,
    },
    { skip: !view?.id && startDate === '' && endDate === '' }
  );

  useEffect(() => {
    if (widgets) {
      setConfigurationData(widgets.find((widget) => +widget.widget.id === +widgetId)?.widget);
    }
  }, [widgets]);

  useEffect(() => {
    const commandExecutor = new DataExecutor();
    if (configurationData && !isWidgetChartDataFetching) {
      if (widgetChartData) {
        const dataResult = commandExecutor.execute(
          new TransformDifferenceChartData(),
          widgetChartData,
          configurationData
        );
        setTransformedData(dataResult);
      } else {
        setTransformedData([]);
      }
    }
  }, [widgetChartData, configurationData, isWidgetChartDataFetching]);

  useEffect(() => {
    if (transformedData && configurationData) {
      setSecondFieldKeys(sortOrderStrings(transformedData, configurationData));
    }
  }, [transformedData, configurationData]);

  const selectItem = (e: LegendPayloadOnClick) => {
    setLegendProps({
      ...legendProps,
      [e.dataKey || e.value]: !legendProps[e.dataKey || e.value],
    });
  };

  const renderBarChart = () => {
    if (secondFieldKeys) {
      return secondFieldKeys.map((item, index) => (
        <Bar
          key={index}
          name={item}
          dataKey={item}
          barSize={80}
          yAxisId="left"
          fill={getStatusColor(index)}
          hide={legendProps[item]}
          isAnimationActive={false}
          onMouseEnter={() => handleMouseEnter(item)}
          onMouseLeave={handleMouseLeave}
          minPointSize={MIN_COLUMN_BAR_HEIGHT}
        >
          (
          <LabelList
            dataKey={item}
            position="top"
            fontSize={12}
            formatter={(label: number) => labelOrNone(secondFieldKeys, label, index)}
          />
          )
        </Bar>
      ));
    }
  };

  const displayLabels = (label: Key) => {
    if (transformedData) {
      const minLabel = Math.min(...transformedData.map((item) => Number(item.value)));
      return minLabel <= 0 && label === minLabel ? '' : `${label}%`;
    }
  };

  const handleRefetch = useInvalidateChartResponse(widgetId);

  const barChartScale: [number, number] = useMemo(() => {
    const highest = widgetChartData?.columnSeries
      ? roundHighest(
          Math.max(
            ...Object.entries(widgetChartData.columnSeries).map((entry) => {
              const valuesForKey = entry[1] as Array<{ field: string; value: number }>;
              return Math.max(...valuesForKey.map((item) => item.value));
            })
          )
        )
      : 0;
    return [0, highest];
  }, [widgetChartData?.columnSeries]);

  const ticks = useTicksBasedOnScale(barChartScale);

  return (
    <ChartGenericWrapper
      isError={isError}
      error={error}
      isFetching={isWidgetChartDataFetching || !transformedData}
      isLoading={isWidgetChartDataLoading}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isWidgetChartDataFetching && transformedData?.length === 0}
    >
      {transformedData && transformedData.length > 0 && (
        <ResponsiveContainer height="99%">
          <ComposedChart
            data={transformedData}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 10,
            }}
            layout="horizontal"
          >
            <CartesianGrid vertical={false} horizontal />

            <Tooltip
              content={<CustomTooltip type="difference" chartData={transformedData} />}
              cursor={false}
              isAnimationActive={false}
            />
            <XAxis {...xAxisSettings} interval={intervalCounter(transformedData)} tick={CustomXAxis} />
            <YAxis {...yAxisSettings(barChartScale, 'Values', -90, -40, 'left')} ticks={ticks} />
            <YAxis {...yAxisSettings([-100, 100], 'Values', 90, 40, 'right')} />
            {includeLegend(configurationData) && (
              <Legend
                formatter={renderLegend}
                verticalAlign="bottom"
                wrapperStyle={defaultLegendWrapperStyle}
                onClick={(data) => selectItem(data)}
              />
            )}
            {renderBarChart()}
            <Scatter
              {...{ stackId: 'right' }}
              type="linear"
              name={t('riskReduction')}
              dataKey="value"
              yAxisId="right"
              strokeWidth={2}
              stroke={getStatusColor(10)}
              fill={getStatusColor(10)}
              hide={legendProps.value}
              onMouseEnter={() => handleMouseEnter(t('riskReduction'))}
              onMouseLeave={handleMouseLeave}
              isAnimationActive={false}
              r={20}
            >
              <LabelList
                dataKey="value_column"
                position="top"
                fontSize={12}
                formatter={(label: Key) => displayLabels(label)}
              />
            </Scatter>
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </ChartGenericWrapper>
  );
}
