import React from 'react';

export interface InfoMessageProps {
  text: string;
  field?: string;
}

export const OdinDataLabelMessage = ({ text, field }: InfoMessageProps) => {
  return (
    <div>
      <div className="px-1 !text-sm !font-bold">{field}</div>
      <div className=" my-1 ml-3 px-1 !text-xs  !text-gray-1">{text}</div>
    </div>
  );
};
