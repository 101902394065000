import React, { ReactElement, ReactNode, useState } from 'react';
import { DomElementAlignment, OdinIcon, OdinIconSize, OdinIconType, OverlayPanel } from '@myosh/odin-components';
import { Layout } from '../../redux/config/layout';
import { PermissionTabIcon } from './permissions-tab-icon';

type TabHeaderSettingsOverlayProps = {
  children: ReactNode | ReactElement;
  layout: Layout;
};
export const TabHeaderSettingsOverlay = ({ children, layout }: TabHeaderSettingsOverlayProps) => {
  const [settingsIconRef, setSettingsIconRef] = useState<HTMLDivElement | null>(null);
  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);

  return (
    <>
      <div ref={(el) => el && setSettingsIconRef(el)} onClick={() => setSettingsOpened(!settingsOpened)}>
        <OdinIcon icon="Settings5" size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} />
      </div>
      <OverlayPanel
        target={settingsIconRef}
        visible={settingsOpened}
        hidden={() => setSettingsOpened(false)}
        mountOnEnter={false}
        unmountOnExit={false}
        shouldScrollCloseOverlay={true}
        shouldCheckZIndex={true}
        shouldUseCreatePortal={true}
        transitionDuration={0}
        elementAlignment={DomElementAlignment.TopLeft}
        className="mt-4 flex cursor-pointer rounded-md shadow-md"
      >
        <div className="w-30 flex flex-col flex-wrap bg-gray-5 py-1 shadow group-hover:flex">
          {children}
          <PermissionTabIcon layout={layout} />
        </div>
      </OverlayPanel>
    </>
  );
};
