import React, { useEffect, useRef, useState } from 'react';
import { CheckboxChangeEventType, HfCheckbox, OdinController } from '@myosh/odin-components';
import { useFormContext } from 'react-hook-form';
import { PickList } from 'primereact/picklist';
import { useTranslation } from 'react-i18next';
import { PickListChangeEvent } from '../../../WidgetSettingWindow/Tabs/data-columns-setting-tab';
import { FiltersType } from '../../../../redux/config/filters-api';

interface WidgetNotBlankProps {
  sourceData?: FiltersType;
  targetData?: Array<string>;
}

const WidgetNotBlankComponent = ({ sourceData, targetData }: WidgetNotBlankProps) => {
  const [source, setSource] = useState<Array<string>>([]);
  const [target, setTarget] = useState<Array<string>>([]);
  const styleRef = useRef<Record<string, string>>({ height: '30rem' });
  const { t } = useTranslation();
  const { watch, control } = useFormContext();
  const notBlankValue = watch('notBlank');

  useEffect(() => {
    if (sourceData) {
      const currentData = sourceData.values.map((field) => field).filter((val) => !targetData?.includes(val));
      setSource(currentData);
    }
    if (targetData) {
      setTarget(targetData);
    }
  }, [sourceData, targetData]);

  const onChangeEventHandler = (event: PickListChangeEvent) => {
    setSource(event.source);
    setTarget(event.target);
  };

  return (
    <div className="gap-2 py-2">
      <HfCheckbox
        control={control}
        name="notBlank"
        label={t('notBlank')}
        fieldValueConverter={(value) => (value as CheckboxChangeEventType).checked}
      />

      {notBlankValue === false && (
        <>
          <span className="whitespace-pre-wrap p-3 text-sm font-normal italic">{t('values')}</span>
          <OdinController
            name="globalFiltersValues"
            control={control}
            render={({ field: { onChange } }) => (
              <PickList
                source={source}
                target={target}
                onChange={(event) => {
                  onChange(event.target);
                  onChangeEventHandler(event);
                }}
                sourceStyle={styleRef.current}
                targetStyle={styleRef.current}
                className="picklist"
                showSourceControls={false}
                showTargetControls={false}
              />
            )}
          />
        </>
      )}
    </div>
  );
};

export default WidgetNotBlankComponent;
