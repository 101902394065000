import { FunctionComponent } from 'react';
import { CHART_TYPE } from '../../constants/enums';
import BarChart from './bar-chart.component';
import ColumnChart from './column-chart.component';
import ColumnLineChart from './column-line-chart.component';
import ChartKPI from './kpi-chart.components';
import LineChart from './line-chart.component';
import PieChart from './pie-chart.component';
import TableChart from './table-chart.component';
import TableInjuryFreeDaysChart from './table-injury-free-days-chart.component';
import ColumnRate from './column-rate.component';
import { ChartProps } from './chart.interface';
import MapWidget from './map-widget.component';
import ManagementTableChart from './management-table-chart.component';
import ColumnBasic from './column-basic.component';
import ChartDifference from './difference-chart.components';
import RollingFRChart from './rollingfr-chart.component';
import CrossTabReport from './cross-tab-report.component';
import ComparedFrLine from './compared-fr-line-chart.component';
import TableRollingTRIFR from './table-rolling-trifr.component';
import TableRate from './table-rate.component';
import ColumnKpiPercentage from './kpi-percentage-chart.component';
import TableInjurySite from './table-injury-site.component';
import NcrAgingLineChart from './ncr-aging-line-chart.component';

export class DynamicChartService {
  private static instance: DynamicChartService;

  private readonly chartConfig: Record<CHART_TYPE, FunctionComponent<ChartProps>>;

  private constructor() {
    this.chartConfig = {
      CHART_BAR: BarChart,
      CHART_COLUMN: ColumnChart,
      CHART_LINE: LineChart,
      CHART_PIE: PieChart,
      CHART_COLUMN_LINE: ColumnLineChart,
      TABLE: TableChart,
      TABLE_INJURY_FREE_DAYS: TableInjuryFreeDaysChart,
      CHART_KPI: ChartKPI,
      CHART_COLUMN_RATE: ColumnRate,
      MAP: MapWidget,
      MANAGEMENT_TABLE: ManagementTableChart,
      CHART_COLUMN_BASIC: ColumnBasic,
      CHART_DIFFERENCE: ChartDifference,
      CHART_ROLLINGFR: RollingFRChart,
      CROSS_TAB_REPORT: CrossTabReport,
      COMPARED_FR_LINE: ComparedFrLine,
      TABLE_ROLLING_TRIFR: TableRollingTRIFR,
      TABLE_RATE: TableRate,
      CHART_COLUMN_KPI_PERCENTAGE: ColumnKpiPercentage,
      INJURY_NUMBER_BY_SITE_TABLE: TableInjurySite,
      CHART_NCR_AGING: NcrAgingLineChart,
    };
  }

  /**
   * To make sure that only one instance of the service is created this method
   * is used to get the service instance.
   * @returns {DynamicChartService} The instantiated service object.
   */
  public static getServiceInstance() {
    if (!this.instance) {
      this.instance = new DynamicChartService();
    }

    return this.instance;
  }

  /**
   * Gets a chart component based upon its chart type.
   * @param {CHART_TYPE} type - The chart type to return.
   * @returns {unknown} A chart component.
   */
  public getChartComponent(type: CHART_TYPE) {
    return this.chartConfig[type] || null;
  }
}
