import { isValid } from 'date-fns';
import { BasicDataChartResponse } from '../../../redux/config/chart-data';
import { Payload } from '../../CustomToolTip/custom-tooltip.interfaces';

type ChartDataSet = Array<BasicDataChartResponse>;

/**
 * Displays the month in short form (e.g., Jan) and the year in numeric form (e.g., 2023).
 * @param field - The key used for formatting.
 * @param long - A boolean flag indicating whether to use the long form of the month.
 * @param fullDate - A boolean flag indicating whether to return full date (month day, year) or month year.
 * @returns The formatted date string.
 */
export const dateStringFormatter = (field: string, long?: boolean, fullDate = false): string => {
  if (field === '') return '';

  // Assumes the field string is in 'Month Year' format, eg. 'June 2023'.
  const newDateStringArr = field.split(' ');

  // Add '01' to the string to make it a valid date string to be passed to `new Date()`
  if (newDateStringArr.length === 2) {
    newDateStringArr.unshift('01');
  }

  const isValidDate = isValid(new Date(newDateStringArr.join(' ')));

  if (!isValidDate) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = { month: long ? 'long' : 'short', year: 'numeric' };

  if (fullDate) {
    options.day = 'numeric';
  }

  return new Intl.DateTimeFormat('en', options).format(new Date(newDateStringArr.join(' ')));
};

/**
 * Parses an array of objects with field and value properties and updates the field property to the shorter date format.
 * @param array - An array of objects with field and value properties.
 * @returns The updated array with the formatted dates.
 */
export function formatDateFields(array: ChartDataSet) {
  return array?.map((el) => {
    return {
      ...el,
      field: dateStringFormatter(el.field || ''),
    };
  });
}

/**
 * Decrement year
 * @param {Payload} obj
 * @return {*} obj
 */
export const decrementYear = (obj: Payload) => {
  const [month, year] = obj.field.split(' ');
  const newYear = parseInt(year) - 1;
  const newField = `${month} ${newYear}`;
  return { ...obj, field: newField };
};
