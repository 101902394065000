import React, { useMemo } from 'react';
import { HfCheckboxGroup } from '@myosh/odin-components';
import { t } from 'i18next';
import DecimalConfiguration, { DecimalConfigProps } from './DecimalConfiguration';
import { getLabelOptions } from '../../../constants/label-configuration-options';
import { LabelFormatType } from '../widget-settings-dialog';

interface LabelConfigProps extends DecimalConfigProps {
  watchLabelFormat?: LabelFormatType[];
}
const PieChartLabelConfiguration = ({ control, watchLabelFormat, labelDecimals }: LabelConfigProps) => {
  const labelReadOnlyValue = useMemo(() => {
    if (watchLabelFormat) {
      return watchLabelFormat.find((el) => el.value === 'percentage');
    }
  }, [watchLabelFormat]);

  return (
    <div className="my-2 flex flex-col pt-2">
      <HfCheckboxGroup
        control={control}
        id="labelFormat"
        name="labelFormat"
        data={getLabelOptions()}
        label={t('labelFormatting')}
        textField="text"
        valueField="value"
      />
      {labelReadOnlyValue ? (
        <DecimalConfiguration labelDecimals={labelDecimals} control={control} pieChartDecimal={true} />
      ) : null}
    </div>
  );
};

export default PieChartLabelConfiguration;
