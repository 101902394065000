import { isNil, omitBy } from 'lodash';
import { DataKPI } from '../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';

export const getApplicableKPIValues = (data: DataKPI[], config: WidgetConfigurationData): DataKPI[] => {
  const hideKpi = config.widgetConfig?.kpiTargetConfiguration?.hideKPI;
  return data.map((item) => {
    return omitBy(
      {
        ...item,
        value_FR: item.value_FR,
        value_KPI: hideKpi ? 0 : item.value_KPI,
        value_TRIFR: item.value_TRIFR,
      },
      isNil
    );
  });
};
