import React from 'react';
import { HfCheckboxGroup } from '@myosh/odin-components';
import { t } from 'i18next';
import { Control } from 'react-hook-form';
import { getLabelOptions } from '../../../constants/label-configuration-options';

interface LabelConfigProps {
  control: Control;
}

const LabelConfiguration = ({ control }: LabelConfigProps) => {
  return (
    <div className="my-2 flex flex-col pt-2">
      <HfCheckboxGroup
        id="labelFormat"
        name="labelFormat"
        data={getLabelOptions()}
        control={control}
        label={t('lineLabelFormatting')}
        textField="text"
        valueField="value"
      />
    </div>
  );
};

export default LabelConfiguration;
