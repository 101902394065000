import { Key } from 'react';
import { ChartResponse } from '../../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { transformBarChartData } from '../../bar-chart/transform-bar-chart-data';
import { IDataCommand } from '../../data-transformer.interface';

export class HideBarChartBlankCategories
  implements IDataCommand<ChartResponse[], WidgetConfigurationData, ChartResponse[]>
{
  process(data: ChartResponse[], config: WidgetConfigurationData): ChartResponse[] {
    const response = data && config && (transformBarChartData(data) as Record<string, Key>[]);
    const values = config?.widgetConfig?.hideBlankCategory
      ? response.filter((item) => item.field !== '[none]')
      : response;
    return values;
  }
}
