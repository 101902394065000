import { useEffect, useState } from 'react';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { generateArrayColors } from '../../../helpers/getStatusColor';

export type RollingTRIFRConfigs = {
  legend: string[];
  available: boolean;
  displayValues: boolean;
  colors: string[];
};

const defaultState: RollingTRIFRConfigs = {
  legend: [],
  available: false,
  displayValues: false,
  colors: [],
};

export function useFRConfigs(configurationData?: WidgetConfigurationData) {
  const [frConfig, setfrConfig] = useState<RollingTRIFRConfigs>(defaultState);

  useEffect(() => {
    let newConfig = defaultState;
    const widgetData = configurationData?.widgetData;

    if (widgetData) {
      const rollingFR = widgetData.rollingFR;
      if (rollingFR) {
        const legend = rollingFR.legend ?? [rollingFR.title];
        newConfig = {
          legend,
          available: true,
          displayValues: rollingFR.displayValues,
          colors: rollingFR.colors ?? generateArrayColors(legend.length),
        };
      }
    }
    setfrConfig(newConfig);
  }, [configurationData]);

  return frConfig;
}
