import { ChartDifferenceProps, ColumnSeries, ChartResponsePercentage } from '../../components/Charts/chart.interface';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { IDataCommand } from '../data-transformer.interface';

export class TransformDifferenceChartData
  implements IDataCommand<ChartDifferenceProps, WidgetConfigurationData, ColumnSeries[]>
{
  process(data: ChartDifferenceProps) {
    const result: ColumnSeries[] = [];
    const riskData = data.riskReductionSeries.map((item) => {
      return {
        ...item,
        value_column: Math.round(Number(item.value)),
      };
    });
    Object.entries(data.columnSeries).map(([field, values]) => {
      const obj: ColumnSeries = { field };
      const arrayValues = typeof values === 'string' ? [] : (values as ChartResponsePercentage[]);
      arrayValues.map((value) => {
        obj[value.field] = value.value;
      });
      result.push(
        Object.assign(
          obj,
          riskData.find((series) => series.field === field)
        )
      );
    });
    return result;
  }
}
