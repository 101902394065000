import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { useState } from 'react';

export interface MenuItem {
  title: string;
  isSupported?: boolean;
  isAvailableToUser?: boolean;
  clickHandler?: () => void;
  icon?: string;
  iconType?: OdinIconType;
  subMenuItems?: MenuItem[];
}

export interface DataGridSettingsMenuProps {
  items: Array<MenuItem>;
}

export interface DataGridSettingsSubMenuProps extends DataGridSettingsMenuProps {
  subMenuVisibility: boolean;
}
const itemStyle = `text-sm flex items-center whitespace-nowrap px-2 py-1 leading-5 cursor-pointer`;
const supportedItemStyle = `${itemStyle} hover:bg-primary-4 text-mono-0`;
const nonSupportedItemStyle = `${itemStyle} text-gray-2`;

const SubmenuItems = ({ items, subMenuVisibility }: DataGridSettingsSubMenuProps) => {
  return (
    <div
      style={{ left: '-50px' }}
      className={`inset-y-15 absolute -top-2 z-10 ${
        !subMenuVisibility && 'hidden'
      } origin-top-left rounded-bl-md rounded-tl-md bg-mono-1 py-2 text-sm shadow-md drop-shadow`}
    >
      {items.map((item) => {
        return (
          <div
            key={item.title}
            className={
              `px-3 py-px leading-6 hover:bg-primary-4 ` +
              (!item.isSupported ? 'cursor-none text-gray-2' : 'cursor-pointer')
            }
            onClick={() => item.isSupported && item.clickHandler?.()}
          >
            {item.icon && (
              <IconButton classNames="mr-2 ml-1 px-1">
                <OdinIcon size={OdinIconSize.ExtraSmall} type={item.iconType} icon={item.icon} />
              </IconButton>
            )}
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

export default function SettingsMenu({ items }: DataGridSettingsMenuProps) {
  const [subMenuVisibility, setSubMenuVisibibilty] = useState(false);
  return (
    <div className="flex w-full flex-col flex-wrap bg-mono-1 py-1 shadow group-hover:flex">
      {items.map((item) => (
        <div
          key={item.title}
          className={item.isSupported ? supportedItemStyle : nonSupportedItemStyle}
          onClick={() => item.clickHandler?.()}
          onMouseEnter={() => item?.subMenuItems && setSubMenuVisibibilty(true)}
          onMouseLeave={() => item?.subMenuItems && setSubMenuVisibibilty(false)}
        >
          {item.icon && (
            <IconButton classNames="mr-2 ml-1 px-1">
              <OdinIcon size={OdinIconSize.ExtraSmall} type={item.iconType} icon={item.icon} />
            </IconButton>
          )}
          {item.title}
          {item.subMenuItems && (
            <SubmenuItems
              items={item.subMenuItems.filter((item) => item.isAvailableToUser)}
              subMenuVisibility={subMenuVisibility}
            />
          )}
        </div>
      ))}
    </div>
  );
}
