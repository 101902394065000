import { ColumnLineChartValues } from './../../../shared/widget-data.interfaces';
import { ChartColumnLineResponse } from '../../../redux/config/chart-data';
import { WidgetConfigurationData } from '../../../redux/config/widgetApi';
import { IDataCommand } from '../../data-transformer.interface';
import { ColumnLineChartValueBuilder } from '../column-line-chart-value-builder';

export class BuildColumnLineChartValues
  implements IDataCommand<ChartColumnLineResponse, WidgetConfigurationData, ColumnLineChartValues[]>
{
  process(data: ChartColumnLineResponse, config: WidgetConfigurationData): ColumnLineChartValues[] {
    const builder = new ColumnLineChartValueBuilder(data, config);
    return data.xaxeValues.map((value) => builder.buildColumnLineChartValue(value));
  }
}
