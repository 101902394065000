import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReAuth } from './api';

import { ChartDifferenceProps } from '../../components/Charts/chart.interface';
import { ComparedFrLineResponse } from '../../data-transformer/compared-fr-line-chart/compared-fr-line.interface';
import { getWidgetIdFromUrl } from '../../helpers/general-helpers';
import { appendQueryParameters } from './utils';

export interface ChartQueryParameters {
  url?: string;
  widgetId?: string;
  from?: string;
  to?: string;
  viewId?: string;
}

export interface BasicDataChartResponse extends Record<string, unknown> {
  field?: string;
  secondField?: string;
  value?: string | number;
}

export interface ChartResponse extends BasicDataChartResponse {
  value_column?: number;
  localizedFieldCaption?: string;
}

export interface ChartColumnLineResponse {
  columnData: Record<string, Record<string, number>>;
  columnDataSum: Record<string, number>;
  lineData: Record<string, number>;
  lineDataCount: Record<string, number>;
  lineDataDenom: Record<string, number>;
  lineNumerate: Record<string, number>;
  xaxeValues: Array<string>;
  lineValues: number;
  calculationType: Record<string, number>;
}

export interface ValueKpi {
  value_KPI?: number;
}

export interface DataKPI extends Record<string, string | number | undefined> {
  name?: string;
  value_FR?: number;
  value_KPI?: number;
  value_TRIFR?: number;
}

export type RollingSiteTRIFR = Record<string, BasicDataChartResponse[]>;

export interface ChartDataKPI {
  basicValue: BasicDataChartResponse[];
  kpi?: BasicDataChartResponse[];
  rollingFR?: Array<Array<BasicDataChartResponse>>;
  rollingTRIFR?: BasicDataChartResponse[];
  rollingSiteTRIFR?: RollingSiteTRIFR;
}

export interface ColumnRateResponse {
  field: string;
  percentageValue: string;
  value: string;
  recordIds: Array<string>;
}

export interface MapChartData {
  lat: number;
  lon: number;
  recordIDs: string[];
  value: number;
}

export interface ColumnKpiPercentageResponse {
  field: string;
  value: string;
  percentage: string;
  kpi: string;
  color?: string;
}

export interface NCRLineChartResponse {
  [key: string]: {
    field: string;
    value: number;
  }[];
}

export const chartApi = createApi({
  reducerPath: 'chart',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['ChartResponse'],
  endpoints: (builder) => ({
    pieChart: builder.query<Array<ChartResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-pie/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    lineChart: builder.query<Array<ChartResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-line/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    columnLineChart: builder.query<ChartColumnLineResponse, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-column-line/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    columnChart: builder.query<ChartDataKPI, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-column/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    columnRate: builder.query<Array<ColumnRateResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-column-rate/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    columnBasic: builder.query<Array<ChartResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-column-basic/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    barChart: builder.query<Array<ChartResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-bar/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
      transformResponse(response: Array<ChartResponse>) {
        return response.map((item) => {
          return {
            ...item,
            field: item.field || '[none]',
          };
        });
      },
    }),
    chartKPI: builder.query<ChartDataKPI, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-kpi/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    chartKPIPercentage: builder.query<Array<ColumnKpiPercentageResponse>, ChartQueryParameters>({
      query: (args) => {
        let url = `${args.url}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { url }) => [{ type: 'ChartResponse', id: getWidgetIdFromUrl(url) }],
    }),
    chartDifference: builder.query<ChartDifferenceProps, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-difference/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    chartRollingFR: builder.query<BasicDataChartResponse[], ChartQueryParameters>({
      query: (args) => {
        let url = `/data/chart-rollingfr/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    comparedFrLine: builder.query<ComparedFrLineResponse, ChartQueryParameters>({
      query: (args) => {
        let url = `/data/compared-fr-line/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    mapWidget: builder.query<MapChartData[], ChartQueryParameters>({
      query: (args) => {
        let url = `/data/map/${args.widgetId}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { widgetId }) => [{ type: 'ChartResponse', id: widgetId }],
    }),
    ncrLineChart: builder.query<NCRLineChartResponse, ChartQueryParameters>({
      query: (args) => {
        let url = `${args.url}`;
        url = appendQueryParameters(url, args);

        return { url, method: 'GET' };
      },
      providesTags: (result, error, { url }) => [{ type: 'ChartResponse', id: getWidgetIdFromUrl(url) }],
    }),
  }),
});

export const {
  usePieChartQuery,
  useLineChartQuery,
  useColumnLineChartQuery,
  useColumnChartQuery,
  useBarChartQuery,
  useChartKPIQuery,
  useChartKPIPercentageQuery,
  useColumnRateQuery,
  useColumnBasicQuery,
  useMapWidgetQuery,
  useChartDifferenceQuery,
  useComparedFrLineQuery,
  useChartRollingFRQuery,
  useNcrLineChartQuery,
} = chartApi;
