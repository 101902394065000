import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  DataGridColumnSettings,
  DataGridSettings,
  JsonDataItem,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataSender,
  OdinDataRetrievalOptions,
  DynamicFormFieldType,
  DataGridRef,
  OverlayScrollDetectionType,
} from '@myosh/odin-components';
import { useSelector } from 'react-redux';

import { ChartProps } from './chart.interface';
import { SelectionSearchTypeProps } from '../../constants/enums';
import useStartEndDates from './hooks/useStartEndDates';
import { getValueOrDefault } from '../../helpers/getvalueOrDefault';
import { useRecordOdinUrl } from './hooks/useRecordOdinUrl';
import ChartGenericWrapper from '../common/chart-generic-wrapper';

import {
  tableDataApi,
  useTableDataQuery,
  useTableFiltersQuery,
  useTableHeaderQuery,
} from '../../redux/config/table-data';
import { WidgetConfigurationData } from '../../redux/config/widgetApi';
import { useGetWorkflowStepsQuery } from '../../redux/config/workflowStepsApi';
import { useAppDispatch } from '../../redux/hooks';
import { getWidgets } from '../../redux/slices/layout-tabs-slice';
import { setTableFilters } from '../../redux/slices/update-widget-table-sort-field';
import { isEmpty, isEqual } from 'lodash';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { customFiltersApi, useGetWidgetCustomFiltersQuery } from '../../redux/config/CustomFiltersApi';
import { useSelectedView } from './hooks/use-selected-view';
import { ViewWithRange } from '../../redux/slices/selected-view';

export default function OdinTableChart({ widgetId, layoutProps }: ChartProps) {
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const [columns, setColumns] = useState<Array<DataGridColumnSettings>>();
  const [dataSender, setDataSender] = useState<OdinDataSender<JsonDataWrapper>>();
  const [configurationData, setConfigurationData] = useState<WidgetConfigurationData>();
  const currentConfigurationData = useRef<WidgetConfigurationData>();

  const dispatch = useAppDispatch();
  const { startDate, endDate } = useStartEndDates();
  const view = useSelectedView();
  const dateRef = useRef<Record<string, string>>({ startDate, endDate });
  const viewRef = useRef<ViewWithRange | undefined>(view);
  const gridApi = useRef<DataGridRef>(null);
  const widgets = useSelector(getWidgets);

  const { isFetching: fetchingWidgetId } = useGetWidgetCustomFiltersQuery({ widgetId });
  const {
    data: tableHeader,
    isLoading: isHeaderLoading,
    isFetching: isHeaderFetching,
    isError: isTableHeaderError,
    refetch: handleRefetchHeader,
  } = useTableHeaderQuery({
    widgetId,
  });

  const gridSettings = useMemo<DataGridSettings>(() => {
    return {
      columns: columns ?? [],
      initialPageSize: 50,
      fullHeight: true,
      stopRequestsOnNoData: true,
      filterLocation: 1,
      scrollDetection: OverlayScrollDetectionType.All,
    };
  }, [columns]);

  useEffect(() => {
    dateRef.current = { startDate, endDate };
    if (startDate && endDate) {
      viewRef.current = undefined;
      handleRefetch();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    viewRef.current = view;
    if (view?.id) {
      dateRef.current = { startDate: '', endDate: '' };
      handleRefetch();
    }
  }, [view]);

  const {
    data: widgetChartData,
    isUninitialized: isUninitializedData,
    isLoading: widgetChartDataLoading,
    isFetching: isTableDataFetching,
    isError,
  } = useTableDataQuery(
    {
      widgetId,
      from: dateRef.current.startDate,
      to: dateRef.current.endDate,
      page: gridOptions?.page || 1,
      pageSize: gridOptions?.pageSize || 50,
      fieldFilters: gridOptions?.fieldFilters,
      sortedFields: gridOptions?.sortedFields,
      viewId: viewRef.current?.id,
    },
    {
      skip:
        configurationData === undefined ||
        tableHeader === undefined ||
        isHeaderFetching ||
        (!viewRef.current?.id && dateRef.current.startDate === '' && dateRef.current.endDate === ''),
    }
  );
  const { data: tableFilterData, isFetching: isFetchingTableFilterData } = useTableFiltersQuery({ widgetId });

  const validIconColumn = getValueOrDefault(
    configurationData?.widgetData?.visibleColumns?.includes('system::Icon Column'),
    false
  );

  const createDetailsUrl = useRecordOdinUrl(configurationData?.widgetData?.moduleViewId);

  const formId = useMemo(() => {
    if (configurationData?.widgetData?.forms) {
      return Number(configurationData?.widgetData.forms[0]);
    }
  }, [configurationData]);

  const { data: workflowSteps } = useGetWorkflowStepsQuery({ formId }, { skip: !validIconColumn });

  useEffect(() => {
    const newConfiguration = widgets?.find((widget) => widget.id === layoutProps.widgetLayoutId)?.widget;
    if (newConfiguration) {
      if (!isEqual(newConfiguration, currentConfigurationData.current)) {
        setConfigurationData(newConfiguration);
      }
      currentConfigurationData.current = newConfiguration;
    }
  }, [widgets, layoutProps]);

  useEffect(() => {
    if (tableHeader && tableFilterData && !isFetchingTableFilterData && !isHeaderFetching) {
      const gridColumns: Array<DataGridColumnSettings> = tableHeader.map((column) => {
        const searchType = SelectionSearchTypeProps[column.type as keyof typeof SelectionSearchTypeProps];
        const dropDownText = column.type === 'COMBOBOX' || column.type === 'MULTISELECTCOMBOBOX' ? 'field' : '';
        const isDateField = column.field.includes('Date');
        const isPersonField =
          tableFilterData.findIndex((el) => el.fieldId === column.field && el.subType === 'PERSON_FIELD') !== -1;
        return {
          id: column.field,
          title: column.field,
          field: column.field,
          visible: true,
          isIdField: column.field === 'Doc Number' || column.field === 'recordId',
          searchType,
          dropDownText,
          dropDownValue: dropDownText,
          type: isDateField ? 2 : 0,
          showBlankAction: false,
          customDataProperties: {
            isPersonField: isPersonField,
          },
        };
      });
      //if there is no id column, make record id one
      //to make sure pagination works
      if (gridColumns.filter((column) => column.isIdField).length === 0) {
        gridColumns.push({
          id: 'recordId',
          title: 'Record ID',
          field: 'recordId',
          visible: false,
          isIdField: true,
          showBlankAction: false,
        });
      }
      setColumns(gridColumns);
    }
  }, [tableHeader, isHeaderFetching, tableFilterData, isFetchingTableFilterData]);

  useEffect(() => {
    if (dataSender && gridOptions) {
      if (widgetChartData && !isTableDataFetching) {
        dataSender.sendData({ data: widgetChartData ?? [], requestId: gridOptions.requestId });
      } else {
        dataSender.sendData();
      }
    }
  }, [widgetChartData, isTableDataFetching, dataSender, gridOptions]);

  const rowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        const backgroundColor = workflowSteps?.find((step) => step?.label === data?.status)?.backgroundColor;
        return validIconColumn && <div className="h-4 w-4 rounded" style={{ backgroundColor }} />;
      },
    };
  }, [workflowSteps, validIconColumn]);

  const dataRetrieval = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          setDataSender(subscriber);
        }
      },
      getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX' || options?.fieldType === 'DROPDOWNMULTISELECT') {
          if (options.customProperties?.isPersonField) {
            getUserList(subscriber, options, dispatch);
          } else if (options.page === 1 && options.fieldId) {
            getTableFilterOptions(subscriber, widgetId, options, dispatch);
          } else {
            subscriber.sendData();
          }
        } else if (options?.page && options?.pageSize) {
          setGridOptions(options);
          dispatch(setTableFilters({ id: widgetId, ...options }));
        } else {
          subscriber.sendData();
        }
      },
    };
  }, [widgetId]);

  const resetGridData = () => {
    gridApi.current?.api.data.getDataOverwritePageCache();
  };

  const handleRefetch = () => {
    if (widgetChartData && !isTableDataFetching) {
      setGridOptions({ ...gridOptions, page: 1 });
      dispatch(tableDataApi.util.invalidateTags([{ type: 'RegularTableData', id: `LIST-${widgetId}` } as const]));
      handleRefetchHeader();
      setTimeout(() => {
        resetGridData();
      }, 0);
    }
  };

  return (
    <ChartGenericWrapper
      isLoading={isHeaderLoading || widgetChartDataLoading || isUninitializedData}
      isFetching={isHeaderFetching || columns === undefined || fetchingWidgetId}
      isError={isError || isTableHeaderError}
      refetch={handleRefetch}
      widgetId={widgetId}
      widgetConfigurationData={configurationData && { ...configurationData, layoutProps }}
      widgetChartData={widgetChartData}
      renderNoDataByDefault={!isHeaderFetching && isEmpty(tableHeader)}
    >
      <DataGrid
        data={dataRetrieval}
        gridSettings={gridSettings}
        rowIndicator={rowIndicator}
        ref={gridApi}
        onRowSelectionChange={(gridId, selectedRows) => {
          if (selectedRows.length > 0 && createDetailsUrl) {
            const selectedRow = selectedRows[0];
            const baseUrl = createDetailsUrl(String(selectedRow.formId));
            window.open(`${baseUrl}${selectedRow.recordId}`);
          }
        }}
        customSettingsMenuItems={[]}
      />
    </ChartGenericWrapper>
  );
}

const getTableFilterOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  widgetId: string,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const fieldId = options.fieldId;
  if (fieldId && options?.page === 1) {
    const dispatchResult = dispatch(tableDataApi.endpoints.tableFilters.initiate({ widgetId }));
    const result = await dispatchResult;

    if ('fulfilled' === result.status) {
      let dataResult = result.data.find((el) => el.fieldId === fieldId)?.values;
      if (dataResult && options.fieldFilters?.field) {
        const filterValue = options.fieldFilters.field.value as string;
        dataResult = dataResult.filter((item) => item.title.toLowerCase().includes(filterValue.toLowerCase()));
      }
      subscriber.sendData({ data: dataResult, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData({ data: [], requestId: options.requestId });
  }
};

const getUserList = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const dispatchResult = dispatch(customFiltersApi.endpoints.userController.initiate({ ...options }));
  const result = await dispatchResult;

  if ('fulfilled' === result.status) {
    const dataResult = result.data;
    subscriber.sendData({ data: dataResult, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
  dispatchResult.unsubscribe();
};
